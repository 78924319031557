import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LargeHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins-SemiBold',
  color: '#3C6B49',
  fontSize: '20px',
}));
export const DefinationHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins-SemiBold',
  color: '#313131',
  fontSize: '17px',
}));
export const MainHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins-SemiBold',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  marginTop: 30,
  marginBottom: 30,
  alignItems: 'center',
  fontStyle: 'normal',
  fontSize: '2.4rem',
  lineHeight: '64px',
  letterSpacing: '-0.005em',
  '@media(max-width: 800px)': {
    fontSize: '1.8rem',
  },
  '@media(max-width: 700px)': {
    fontSize: '1.6rem',
  },
  '@media(max-width: 400px)': {
    fontSize: '6.3vw',
  },
}));
export const Orange = styled('span')(() => ({
  color: '#E38C09',
}));
export const Black = styled('span')(() => ({
  color: '#313131',
}));
export const MediumHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins-Medium',
  color: '#313131',
  fontSize: '16px',
}));
export const SmallHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins-Medium',
}));
export const SideHeading = styled(Typography)(() => ({
  fontFamily: 'Poppins-Medium',
  color: '#E38C09',
}));
export const Text = styled(Typography)(() => ({
  fontFamily: 'Poppins-Regular',
  fontSize: '14px',
}));
