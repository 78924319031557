import React, { useState, useRef, useEffect } from 'react';

import Emoji from './Emoji';
import moment from 'moment';
import dots from '../../assets/profile/dots.png';
import send from '../../assets/messaging/send.png';
import smily from '../../assets/messaging/smily.png';
import block from '../../assets/messaging/block.png';
import check from '../../assets/messaging/check.png';
import report from '../../assets/messaging/report.png';
import deletex from '../../assets/messaging/delete.png';
import attachment from '../../assets/messaging/attachment.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GreenLoader from '../../assets/greenLoader.gif';
import ImageModal from './ImageModal';
import { collection, doc, onSnapshot, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

import { UserName } from '../Elements';
import { useSelector } from 'react-redux';
import { FireStore } from '../../utills/FireStore';
import { uploadImagesToS3 } from '../../features/general/action';
import { messageInputStyles } from '../../components/InputFields/styles';
import { getFullName, dateToFromNowDaily } from '../../utills/constants';
import {
  Box,
  TextField,
  useMediaQuery,
  ImageList,
  ImageListItem,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Tooltip,
} from '@mui/material';
import {
  DeleteMessage,
  DeleteSuccessful,
  ReportUser,
  ReportSuccessful,
  BlockSuccessful,
  BlockUser,
} from '../../components/Modal/Message';
import {
  Time,
  Message,
  MobileScreen,
  NameRow,
  Footer,
  MessagesMobileBox,
  HeaderMobile,
  FooterMain,
  SendButton,
  OtherUserMsg,
  LoggedInUserMsg,
  FirstScreen,
} from './Elements';

const CustomConversationBox = ({ messages, setDateMessage, receiverId, selectedChat }) => {
  const messagesEndRef = useRef(null);

  const { userInfo } = useSelector((state) => state.auth);

  const scrollToBottom = () => {
    messagesEndRef?.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    var initState = true;
    const curentUserId = userInfo?._id;
    const roomId = selectedChat?._id;
    const docRef = doc(db, 'chatrooms', roomId);
    const ref = collection(docRef, 'messages');

    const observer = onSnapshot(ref, (documentSnapshot) => {
      if (initState) {
        initState = false;
      } else {
        let fdata = [];
        const data = documentSnapshot?.docs || [];
        fdata = data
          .map((item) => ({ ...item.data(), _id: item.id }))
          .sort((a, b) => a.createdAt - b.createdAt)
          .filter((item) => {
            let isShow = true;
            item.deletedBy.forEach((element) => {
              if (element == curentUserId) {
                isShow = false;
              }
            });
            if (isShow) return item;
          });
        setDateMessage(fdata);
        scrollToBottom();
        FireStore.readAllMessageInRoom(roomId, receiverId);
      }
    });

    return () => {
      observer();
      //   store.User.setpasObj(false);
    };
  }, []);

  return (
    <>
      <MessagesMobileBox>
        {messages?.map((res, i) => {
          if (res?.user?._id === userInfo?._id) {
            return (
              <Box sx={{ paddingLeft: 3, paddingRight: 3 }} key={i}>
                <LoggedInUserMsg>
                  {res?.image?.length === 0 ? (
                    res.message
                  ) : (
                    <ImageList sx={{ width: 'auto', height: 'auto' }} cols={res?.image?.length}>
                      {res?.image?.map((item) => (
                        <ImageListItem key={item}>
                          <img
                            src={`${item}?w=50&h=50&fit=crop&auto=format`}
                            srcSet={`${item}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
                            alt={item}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  )}
                </LoggedInUserMsg>
                <Time sx={{ ml: 'auto' }}>
                  {moment(res.createdAt).format('hh:mm:s a')} <image src={check} alt="check" />
                </Time>
              </Box>
            );
          } else if (res?.user?._id !== userInfo?._id) {
            return (
              <>
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Avatar src={res?.user?.image} alt="convo" style={{ marginRight: 8 }} />
                  <OtherUserMsg>
                    {res?.image?.length === 0 ? (
                      res.message
                    ) : (
                      <ImageList sx={{ width: 'auto', height: 'auto' }} cols={res?.image?.length}>
                        {res?.image?.map((item) => (
                          <ImageListItem key={item}>
                            <img
                              src={`${item}?w=50&h=50&fit=crop&auto=format`}
                              srcSet={`${item}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
                              alt={item}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    )}
                  </OtherUserMsg>
                </Box>
                <Box>
                  <Time sx={{ textAlign: 'left', paddingLeft: 8 }}>
                    {moment(res.createdAt).format('hh:mm:s a')}
                  </Time>
                </Box>{' '}
              </>
            );
          } else return '';
        })}
        <div ref={messagesEndRef} />
      </MessagesMobileBox>
    </>
  );
};

const Conversation = ({ handleParentChange, getInbox, parentState }) => {
  var userName, userImage, secondUser;
  const [viewfreemiumsg, setviewviewfreemiumsg] = useState(false);
  const { selectedChat, receiverId } = parentState;

  const classes = messageInputStyles();

  const { userInfo, conversation = [], loading } = useSelector((state) => state.auth);

  const matches700 = useMediaQuery('(max-width:700px)');

  const [state, setState] = useState({
    typing: false,
    istyping: false,
    messages: conversation,
    image: '',
    message: '',
    openDeleteSuccessfull: false,
    openDelete: false,
    openBlock: false,
    openBlockSuccessful: false,
    openReportUser: false,
    userName: '',
    userImage: '',
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const optionsArr = [
    { icon: block, function: 'openBlock', alt: 'Block User' },
    { icon: report, function: 'openReportUser', alt: 'Report User' },
    { icon: deletex, function: 'openDelete', alt: 'Delete Chat' },
  ];
  const setDateMessage = (values) => {
    handleChange('messages', [...values]);
  };
  const sendImage = async (e) => {
    const timestamp = serverTimestamp();

    const chatMessageObject = {
      user: userInfo,
      isRead: false,
      message: state.imgMsg ? state.imgMsg : '',
      type: 'image',
      image: state.image,
      deletedBy: [],
      createdAt: timestamp,
      updatedAt: timestamp,
    };
    if (state?.image?.length) {
      FireStore.sendChatMessage(
        selectedChat?._id,
        receiverId,
        chatMessageObject,
        () => {},
        '',
        false,
        null,
        userInfo
      );
      handleChange('image', []);
      handleChange('imageUpload', false);
      handleChange('imgMsg', '');
    }
  };
  useEffect(() => {
    handleChange('messages', []);
    if (!(Object.keys(selectedChat).length === 0)) {
      FireStore.getAllMessageInRoom(
        userInfo?._id,
        selectedChat?._id,
        receiverId,
        () => {},
        setDateMessage
      );
      if (userInfo?._id === selectedChat?.userId1?._id) {
        secondUser = selectedChat?.userId2;
        userImage = selectedChat?.userId2?.image;
        userName = getFullName(selectedChat?.userId2);
      } else if (userInfo?._id === selectedChat?.userId2?._id) {
        secondUser = selectedChat?.userId1;
        userImage = selectedChat?.userId1?.image;
        userName = getFullName(selectedChat?.userId1);
      }
      handleChange('userName', userName);
      handleChange('userImage', userImage);
      handleChange('secondUser', secondUser);
    } else return;
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    handleChange('messages', [...conversation]);
    // eslint-disable-next-line
  }, [conversation]);
  useEffect(() => {
    FireStore.getAllCurrentUserRooms(userInfo?._id);
  }, []);
  const sendMessage = () => {
    if (selectedChat?.userId2?.subscriptionStatus === 'freemium') {
      setviewviewfreemiumsg(true);
      setTimeout(() => {
        setviewviewfreemiumsg(false);
      }, 3000);
    }

    const timestamp = serverTimestamp();

    const chatMessageObject = {
      user: userInfo,
      isRead: false,
      message: state?.message,
      type: 'text',
      image: '',
      deletedBy: [],
      createdAt: timestamp,
      updatedAt: timestamp,
    };
    if (state?.message !== '') {
      FireStore.sendChatMessage(
        selectedChat?._id,
        receiverId,
        chatMessageObject,
        () => {},
        '',
        false,
        null,
        userInfo
      );
      handleChange('message', '');
    }
  };

  const sendMessageOnEnter = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const uploadFunction = async (e) => {
    const timestamp = serverTimestamp();

    let result = await uploadImagesToS3(e);
    let temp = [];
    result.forEach((res) => {
      temp.push(res?.imgrUrl);
    });
    handleChange('image', temp);
    const chatMessageObject = {
      user: userInfo,
      isRead: false,
      message: '',
      type: 'image',
      image: temp,
      deletedBy: [],
      createdAt: timestamp,
      updatedAt: timestamp,
    };
    if (state?.image?.length) {
      FireStore.sendChatMessage(
        selectedChat?._id,
        receiverId,
        chatMessageObject,
        () => {},
        '',
        false,
        null,
        userInfo
      );
      handleChange('image', []);
    }
  };

  return (
    <MobileScreen item xs={12} sm={12} md={12} lg={12}>
      {Object.keys(selectedChat).length === 0 ? (
        <FirstScreen>
          <NameRow sx={{ color: 'rgba(17, 17, 17, 0.8)' }}>Pick up where you left off</NameRow>
          <Message sx={{ color: 'rgba(17, 17, 17, 0.8)' }}>
            Select a conversation and chat away.
          </Message>
        </FirstScreen>
      ) : (
        <>
          <HeaderMobile>
            <UserName>
              <ArrowBackIcon
                sx={{ color: '#3C6B49' }}
                onClick={() => handleParentChange('isMobile', false)}
              />
              <Avatar
                src={state?.userImage}
                alt="convo"
                sx={{ width: 50, height: 50, my: 1 }}
                style={{ marginRight: 10 }}
              />
              {state?.userName}
            </UserName>
            {matches700 ? (
              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <img src={dots} alt="dots" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {optionsArr.map((res, i) => {
                    return (
                      <MenuItem sx={{ opacity: 0.4 }}>
                        <Tooltip title={res?.alt}>
                          <img
                            style={{ cursor: 'pointer' }}
                            alt={res?.alt}
                            src={res.icon}
                            onClick={() => handleChange(res.function, true)}
                            key={i}
                          />
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', opacity: 0.4 }}>
                {optionsArr.map((res, i) => {
                  return (
                    <Tooltip title={res?.alt}>
                      <img
                        style={{ marginRight: 15, cursor: 'pointer' }}
                        src={res.icon}
                        onClick={() => handleChange(res.function, true)}
                        alt=""
                        key={i}
                      />
                    </Tooltip>
                  );
                })}
              </Box>
            )}
          </HeaderMobile>
          {loading && (
            <div style={{ position: 'absolute', top: '40%', left: '45%' }}>
              <img src={GreenLoader} width="80" alt="GreenLoader" />
            </div>
          )}
          <Time sx={{ textAlign: 'center', my: 3 }}>
            <span style={{ fontWeight: 'bold' }}>
              {dateToFromNowDaily(selectedChat?.createdAt)}
            </span>{' '}
            {moment(selectedChat?.createdAt).format('hh:mm:ss a')}
          </Time>
          <CustomConversationBox
            setDateMessage={setDateMessage}
            receiverId={receiverId}
            selectedChat={selectedChat}
            messages={state?.messages}
            handleChange={handleChange}
          />
          <FooterMain>
            {viewfreemiumsg ? (
              <p
                style={{
                  color: 'rgba(17, 17, 17, 0.4)',
                  fontFamily: 'Poppins-Medium',
                  backgroundColor: 'white',
                  fontSize: '0.8rem',
                }}
              >
                The person you messaged is not currently a subscribed user. We have notified them
                about your message, and they will be able to reply once they subscribe.
              </p>
            ) : (
              ''
            )}
            <Footer>
              <img
                src={attachment}
                alt="attachment"
                style={{ cursor: 'pointer' }}
                onClick={() => handleChange('imageUpload', true)}
              />
              <img
                onClick={(e) => handleChange('openEmoji', true)}
                src={smily}
                alt="smily"
                style={{ cursor: 'pointer' }}
              />
              <TextField
                sx={{ width: '80%' }}
                value={state.message}
                onKeyPress={sendMessageOnEnter}
                onChange={(e) => handleChange('message', e.target.value)}
                className={classes.root}
                inputProps={{ style: { color: '#1E3625', zIndex: 20, height: '25px' } }}
                placeholder={'Type your message here'}
              />
              <SendButton onClick={sendMessage}>
                <img src={send} alt="send" />
              </SendButton>
            </Footer>
          </FooterMain>
        </>
      )}
      <Emoji data={state} open={state.openEmoji} handle={handleChange} stateName="openEmoji" />
      <ImageModal
        _state={state}
        stateName="imageUpload"
        handle={handleChange}
        sendImage={sendImage}
        uploadFunction={uploadFunction}
        open={state.imageUpload}
        _setState={setState}
      />
      <BlockUser data={state} open={state.openBlock} handle={handleChange} stateName="openBlock" />
      <DeleteMessage
        data={state}
        state={parentState}
        open={state.openDelete}
        handle={handleChange}
        stateName="openDelete"
      />
      <ReportUser
        data={state}
        open={state.openReportUser}
        handle={handleChange}
        stateName="openReportUser"
      />
      <BlockSuccessful
        data={state}
        open={state.openBlockSuccessful}
        handle={handleChange}
        stateName="openBlockSuccessful"
      />
      <ReportSuccessful
        data={state}
        open={state.openReportSuccessful}
        handle={handleChange}
        stateName="openReportSuccessful"
      />
      <DeleteSuccessful
        data={state}
        state={parentState}
        open={state.openDeleteSuccessfull}
        handle={handleChange}
        stateName="openDeleteSuccessfull"
      />
    </MobileScreen>
  );
};
export default Conversation;
