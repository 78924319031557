
import { styled } from '@mui/material/styles';
import { Button, Box } from '@mui/material';

export const CustomGreenLargeButton = styled(Button)({
    boxShadow: 'none',
    width: "100%",
    height: "7vh",
    textTransform: 'none',
    fontSize: "1vw",
    textSizeAdjust: "inherit",
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
    borderRadius: "5px",
    fontFamily: 'Poppins-SemiBold',
    '&:hover': {
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3C6B49',
    },
    '@media(max-width: 900px)': {
        height: '8vh',
        fontSize: '2vw'
    },
    '@media(max-width: 800px)': {
        fontSize: '2.4vw'
    },

    '@media(max-width: 600px)': {
        height: '7vh',
        fontSize: '2.8vw'
    },
    '@media(max-width: 400px)': {
        height: '6vh',
        fontSize: '3.4vw'
    },
});
export const CustomOrangeLargeButton = styled(Button)({
    boxShadow: 'none',
    width: "100%",
    height: "7vh",
    textTransform: 'none',
    fontSize: "1rem",
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#D07E02',
    borderColor: '#D07E02',
    borderRadius: "10px",
    fontFamily: 'Poppins-SemiBold',
    color: 'white',
    '&:hover': {
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #D07E02',
    },
    '@media(max-width: 600px)': {
        height: '7vh',

    },
});
export const CustomGreenTransLargeButton = styled(Button)({
    textSizeAdjust: "80%",
    boxShadow: 'none',
    width: "100%",
    height: "7vh",
    textTransform: 'none',
    fontSize: "1vw",
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#F9F9F9',
    borderColor: '#CECECE',
    borderRadius: "10px",
    fontFamily: 'Poppins-SemiBold',
    color: '#30563A',
    '&:hover': {
        backgroundColor: '#F9F9F9',
        borderColor: '#CECECE',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#F9F9F9',
        borderColor: '#F9F9F9',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #F9F9F9',
    },
    '@media(max-width: 900px)': {
        height: '8vh',
        fontSize: '2vw'
    },
    '@media(max-width: 600px)': {
        height: '7vh',
        fontSize: '2.4vw'
    },
    '@media(max-width: 400px)': {
        height: '6vh',
        fontSize: '3.4vw'
    },
});
export const CustomCrossButton = styled(Button)({
    boxShadow: 'none',
    backgroundColor: 'none',
    fontFamily: 'Poppins-SemiBold',
    paddingTop: 20
});
export const SmallButton = styled(Button)(() => ({
    fontSize:'14px',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "17px 50px",
    textTransform: 'none',
    background: "#D07E02",
    borderRadius: "10px",
    width:'max-content',
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    color: '#FFFFFF',
    cursor: 'pointer',
    marginTop: 10,
    '@media(max-width: 2000px)': {
        fontSize: "1.2vw",
    },
    '&:hover': {
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #D07E02',
    },
    '@media(max-width: 1000px)': {
        padding: "14px 50px",
        fontSize: "2.5vw",

    },
   '@media(max-width: 610px)': {
        width: "max-content",
    },
    '@media(max-width: 600px)': {
        fontSize: "3vw",

    },
    '@media(max-width: 400px)': {
        padding: "10px 20px",
        fontSize: "3.5vw",

    },
}));
export const CancelButton = styled(Button)(() => ({
    color: '#30563A',
    border: '1px solid #DFDFDF',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 22px",
    height: '44px',
    textTransform: 'capitalize',
    borderRadius: "8px",
    fontFamily: 'Poppins-SemiBold', backgroundColor: 'white',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'white',
        borderColor: '#DFDFDF',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'white',
        borderColor: '#DFDFDF',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #DFDFDF',
    },
    '@media(max-width: 600px)': {
        padding: "10px",
        fontSize:'small',
        width:'100%',
    }
}));
export const Label = styled(Box)(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#50555C',
    opacity: 0.5,
    marginRight: 10
}));
