import React from 'react';
import filter from '../../assets/home/filter.png';
import search from '../../assets/home/search.png';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { InputAdornment, TextField } from '@mui/material';

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D8E1DB',
      borderRadius: '100px',
      boxShadow: 'inset 1px 1px 1px rgba(0, 0, 0, 0.2)',
      backgroundColor: 'white',
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E3625',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3C6B49',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px',
      color: '#1E3625',
      zIndex: 20,
      '&::placeholder': {
        zIndex: 20,
        fontSize: 'small',
        fontFamily: 'Poppins-Regular',
        '@media(max-width: 2000px)': {
          fontSize: 'medium',
        },
      },
    },
    '&:hover .MuiOutlinedInput-input': {
      color: '#1E3625',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: '#1E3625',
    },
    '& .MuiInputLabel-outlined': {
      color: 'green',
    },
    '&:hover .MuiInputLabel-outlined': {
      color: '#1E3625',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#1E3625',
    },
  },
});

const SearchInput = ({ isEndAdornment, state, handleClick, handleChange, handleSearch }) => {
  const classes = useStyles();
  const handleClickOnEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <>
      <TextField
        sx={{ width: '100%' }}
        className={classes.root}
        onChange={(e) => handleChange('query', e.target.value)}
        onKeyPress={handleClickOnEnter}
        value={state?.query}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ zIndex: 20 }} position="start">
              <SearchIcon sx={{ color: '#283c2e', fill: '#283c2e' }} />
            </InputAdornment>
          ),
          endAdornment: isEndAdornment ? (
            <InputAdornment style={{ zIndex: 20 }} onClick={handleClick} position="end">
              <img src={filter} alt="filter" style={{ cursor: 'pointer' }} />
            </InputAdornment>
          ) : (
            ''
          ),
        }}
        placeholder={'Search'}
      />
    </>
  );
};

export default SearchInput;
