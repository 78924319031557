import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

export const MainGrid = styled(Grid)(() => ({
  height: '100vh',
  background: '#F8F6F3',
}));
export const SubGrid = styled(Grid)(() => ({
  display: 'flex',
  height: '100vh',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  width: '80%',
  '@media(max-width: 800px)': {
    width: '100%',
  },
  '@media(max-width: 600px)': {
    width: '80%',
  },
}));

export const LeftGrid = styled(Box)(() => ({
  width: '36vw',
  height: '48vh',
  marginTop: '-8.5vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  '@media(max-width: 1250px)': {
    width: '40vw',
  },
  '@media(max-width: 800px)': {
    width: '45vw',
    marginLeft: '5vw',
  },
  '@media(max-width: 600px)': {
    width: '80vw',
    marginLeft: 0,
    marginTop: 0,
  },
}));
export const RightGrid = styled(Box)(() => ({
  '& img': {
    width: '100%',
    height: '80vh',
    objectFit: 'contain',
  },
  '@media(max-width: 900px)': {
    height: '60vh',
    '& img': {
      height: '100%',
    },
  },
  '@media(max-width: 800px)': {
    height: '50vh',
    '& img': {
      height: '100%',
    },
  },
  '@media(max-width: 600px)': {
    height: '40vh',
    '& img': {
      height: '100%',
    },
  },
}));
export const Heading = styled('h1')(() => ({
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  fontSize: '2.4rem',
  lineHeight: '64px',
  letterSpacing: '-0.005em',
  marginBottom: 0,
  '@media(max-width: 800px)': {
    fontSize: '1.8rem',
  },
  '@media(max-width: 700px)': {
    fontSize: '1.6rem',
  },
  '@media(max-width: 400px)': {
    fontSize: '6.3vw',
  },
}));
export const Orange = styled('span')(() => ({
  color: '#E38C09',
}));
export const Black = styled('span')(() => ({
  color: '#313131',
}));

export const Description = styled(Box)(() => ({
  fontFamily: 'Poppins-Regular',
  fontStyle: 'normal',
  marginBottom: 40,
  color: '#313131',
  '@media(max-width: 400px)': {
    fontSize: 'small',
    marginBottom: 10,
  },
}));

export const IOSAndroindHeading = styled('p')(() => ({
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  color: '#313131',
  fontSize: '16px',
  lineHeight: '30px',
}));

export const ImageGrid = styled('div')(() => ({
  display: 'flex',
  width: '20vw',
  cursor: 'pointer',
  alignItems: 'center',
  '& a & img': {
    width: '60%',
    marginRight: 20,
  },
  '@media(max-width: 800px)': {
    '& img': {
      width: '100%',
    },
  },
}));
