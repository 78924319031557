import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    reviews: [],
    blocked: [],
    following: [],
    followers: [],
    profileInfo: [],
};
const profileSlice = createSlice({
    name: "profileService",
    initialState,
    reducers: {
        startLoading: (state) => { state.loading = true },
        stopLoading: (state) => { state.loading = false },
        
        saveData: (state, { payload }) => {
            state[payload.name] = payload.data;
        },
    },
});
const profileReducer = profileSlice.reducer;
export default profileReducer;

export const { saveData, startLoading, stopLoading } = profileSlice.actions;
