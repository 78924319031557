import React, { useState } from 'react';

import moment from 'moment';
import calender from '../../assets/createTrip/calender.png';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { dateStyles } from './styles';
import { GreenButton, CancelButton } from './Elements';
import { InputAdornment, TextField, Popover } from '@mui/material';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { monthName, getZero, swalWithStyledButton } from '../../utills/constants';
import cross from '../../assets/cross.png';
export const DateRangeInput = ({
  state,
  handleChange,
  selectedDayRange,
  width,
  setSelectedDayRange,
  handleNestedChange = null,
}) => {
  const classes = dateStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if ((state?.hostOffer?.durationValue || state?.durationValue) == 0) {
      swalWithStyledButton.fire({
        icon: 'error',
        allowOutsideClick: false,
        allowEscapeKey: false,
        text: `Please enter the duration first.`,
        confirmButtonText: 'Okay',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setAnchorEl(null);
      return;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    handleChange('availablityDateError', false);
    handleChange('availablityDateError', false);
    setAnchorEl(null);
  };
  const handleCancel = () => {
    handleChange('availablityDateError', false);
    handleChange('availablityDateError', false);
    setAnchorEl(null);
    setSelectedDayRange({
      from: null,
      to: null,
    });
  };
  console.log(selectedDayRange);
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const handleSelectedDayRange = (date) => {
    handleChange('unAvailableDays', {
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      allUnavailableDates: [],
      excludeSpecificDates: [],
    });
    if (handleNestedChange !== null) {
      handleNestedChange('unAvailableDays', {
        dayWeekText: '',
        excludeDateText: '',
        daysOfWeek: [],
        disableDates: [],
        disableDatesText: '',
        repeatEvery: 'weeks',
        repeatEveryNumber: 1,
        disableDatesFormated: [],
        unavailableDaysOfWeek: [],
        allUnavailableDates: [],
        excludeSpecificDates: [],
      });
    }
    // alert("i am hit")
    // console.log("date in trip",date)
    setSelectedDayRange(date);

    if (date?.from != null && date?.to != null) {
      const fromDate = moment
        .utc(date?.from?.year + '-' + date?.from?.month + '-' + date?.from?.day)
        .format();

      const toDate = moment
        .utc(date?.to?.year + '-' + date?.to?.month + '-' + date?.to?.day)
        .format();
      // console.log('MOMENT ', fromDate, toDate);
      // console.log('from date', date?.from?.year + '-' + date?.from?.month + '-' + date?.from?.day);
      // console.log('to date', date?.to?.year + '-' + date?.to?.month + '-' + date?.to?.day );
      // console.log('FROM DATE MOMENT', moment.utc(date?.from?.year + '-' + date?.from?.month + '-' + date?.from?.day).format() );

      const diffInDays = toDate.diff(fromDate, 'days');

      const _input =
        (state?.hostOffer?.durationTitle || state?.durationTitle) == 'weeks'
          ? Number(state?.hostOffer?.durationValue || state?.durationValue) * 7
          : state?.hostOffer?.durationValue || state?.durationValue;
      if (_input > diffInDays) {
        handleClose();
        swalWithStyledButton
          .fire({
            icon: 'error',
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: `Select a start and end date that are at least ${
              state?.hostOffer?.durationValue || state?.durationValue
            } ${state?.hostOffer?.durationTitle || state?.durationTitle} apart.`,
            confirmButtonText: 'Okay',
            showClass: { popup: 'animate__animated animate__fadeInDown' },
            hideClass: { popup: 'animate__animated animate__fadeOutUp' },
          })
          .then((result) => {
            setSelectedDayRange({ from: null, to: null });
          });
      }
    }
  };

  return (
    <>
      <TextField
        onClick={handleClick}
        sx={{ width: width }}
        className={classes.root}
        value={
          selectedDayRange?.from && selectedDayRange?.to
            ? `${monthName(selectedDayRange?.from?.month)} ${selectedDayRange?.from?.day}, ${
                selectedDayRange?.from?.year
              } - ${monthName(selectedDayRange?.to?.month)} ${selectedDayRange?.to?.day}, ${
                selectedDayRange?.to?.year
              }`
            : 'mm / dd / yyyy'
        }
        placeholder={'Select a date range'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        readOnly
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <div style={{ padding: 10, cursor: 'pointer', display: 'flex', justifyContent: 'end' }} onClick={()=> setAnchorEl(null)}>
          <img src={cross} alt="cross" width={15} height={15} />
        </div>
        <Calendar
          minimumDate={utils().getToday()}
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          value={selectedDayRange}
          onChange={handleSelectedDayRange}
          colorPrimary="#3C6B49"
          inputPlaceholder="Select a day range"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={handleCancel} sx={{ mr: 1 }}>
            Clear
          </CancelButton>
          <GreenButton onClick={handleClose}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};
