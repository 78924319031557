import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const LeftGrid = styled(Grid)(() => ({
  '@media(max-width: 600px)': {
    display: 'none',
  },
}));
export const IdentityImageGrid = styled(Box)(() => ({
  marginTop: 20,
  marginBottom: 20,
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'dashed',
  borderRadius: 10,
  '& img': {
    borderRadius: 10,
    maxWidth: '100%',
    maxHeight: '100%',
  },
}));
export const ProfileImageGrid = styled(Box)(() => ({
  height: '180px',
  width: '180px',
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    maxWidth: '100%',
  },
}));
export const MainGrid = styled(Grid)(() => ({
  position: 'relative',
  display: 'flex',
  background: '#FAFAFA',
}));
export const Centered = styled(Grid)(() => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: '95vh',
  background: 'transparent',
  color: '#ffffff',
  marginTop: 20,
}));
export const RightGrid = styled(Grid)(() => ({
  background: '#FAFAFA',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  // padding: 20
}));
export const OverlayGrid = styled(Grid)(() => ({
  width: '20vw',
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 35,
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '100%',
  },
  '@media(max-width: 1200px)': {
    width: '20vw',
  },
}));
export const RightSubGrid = styled(Grid)(() => ({
  display: 'flex',
  height: '80vh',
  marginTop: 'auto',
  marginBottom: 'auto',
  width: '62%',
  justifyContent: 'space-between',
  flexDirection: 'column',
  '@media(max-width: 600px)': {
    width: '75%',
    height: '80vh',
  },
}));
export const MobileGrid = styled(Grid)(() => ({
  display: 'none',
  '@media(max-width: 600px)': {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 50,
  },
}));
export const Heading = styled('h2')(() => ({
  fontFamily: 'Poppins',
  fontSize: '2rem',
  fontStyle: 'normal',
  fontWeight: 600,
  color: '#0D2229',
  marginBottom: 0,
  marginTop: 0,
  '@media(max-width: 600px)': {
    fontSize: '1.4rem',
  },
}));
export const HeadingDiv = styled(Grid)(() => ({
  width: '80%',
  paddingLeft: '6vw',
}));
export const LeftHeading = styled('h2')(() => ({
  fontFamily: 'PoppinsLight',
  fontSize: '1.6rem',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#FFFFFF',
  '@media(max-width: 1000px)': {
    fontSize: '1.6rem',
  },
}));
export const DashedUploadBox = styled(Box)(() => ({
  background: '#EEF6EF',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 170,
  position: 'relative',
  paddingTop: 30,
  border: '1px dashed #3C6B49',
  '@media(max-width: 1000px)': {
    height: 120,
  },
}));
export const UploadText = styled('p')(() => ({
  fontFamily: 'PoppinsLight',
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#1A5B2C',
  marginBottom: 2,
  fontSize: '.9rem',
  letterSpacing: '0.0168em',
  cursor: 'pointer',
  '@media(max-width: 600px)': {
    fontSize: '.7rem',
  },
}));

export const UploadBox = styled(Box)(() => ({
  background: '#EEF6EF',
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 170,
  paddingTop: 30,
  position: 'relative',
  '@media(max-width: 1000px)': {
    height: 120,
  },
}));
export const DisplayImageBox = styled(Box)(() => ({
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: 340,
  '@media(max-width: 600px)': {
    '& > img': {
      width: '98%',
    },
  },
}));
export const ProgressBox = styled(Box)(() => ({
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 170,
  paddingTop: 30,
}));
export const SubHeading = styled('p')(() => ({
  fontFamily: 'PoppinsLight',
  fontSize: '.9rem',
  fontStyle: 'normal',
  fontWeight: 400,
  color: '#1E3625',
  marginTop: '6px',
  '& > :first-of-type': {
    textDecorationLine: 'underline',
    color: '#1E3625',
    textUnderlineOffset: '3px',
    cursor: 'pointer',
  },
  '@media(max-width: 600px)': {
    fontSize: '.8rem',
  },
}));
export const AccountReadyText = styled(Box)(() => ({
  fontFamily: 'Poppins',
  fontWeight: 400,
  color: '#101B10',
  fontSize: '1rem',
  letterSpacing: '0.0168em',
  textAlign: 'center',
  width: '80%',
  marginLeft: 'auto',
  marginRight: 'auto',
  '@media(max-width: 600px)': {
    fontSize: '.8rem',
    width: '100%',
  },
}));
export const ImageContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '& span': {
    position: 'absolute',
    top: 70,
  },
}));
export const Save = styled(Box)(() => ({
  fontFamily: 'PoppinsLight',
  lineHeight: '21px',
  fontStyle: 'normal',
  fontWeight: 600,
  color: '#101B10',
  opacity: '0.6',
  fontSize: '.9rem',
  letterSpacing: '0.0168em',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
}));
export const Light = styled(Box)(() => ({
  color: '#767676',
}));
export const Dark = styled(Box)(() => ({
  color: '#111111',
  fontFamily: 'Poppins-SemiBold',
}));
export const DarkWithLine = styled(Box)(() => ({
  fontFamily: 'PoppinsLight',
  fontStyle: 'normal',
  fontWeight: 600,
  color: '#1A5B2C',
  marginBottom: 2,
  fontSize: '.8rem',
  letterSpacing: '0.0168em',
  cursor: 'pointer',
}));
export const Details = styled(Box)(() => ({
  lineHeight: '21px',
  fontStyle: 'normal',
  fontSize: '14px',
  letterSpacing: '0.0168em',
  fontFamily: 'Poppins-Medium',
  display: 'flex',
  alignItems: 'center',
}));
export const RightSubGridStep3 = styled(Grid)(() => ({
  display: 'flex',
  height: '80%',
  marginTop: 'auto',
  marginBottom: 'auto',
  width: '62%',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  '@media(max-width: 600px)': {
    width: '75%',
    height: '75vh',
    margin: 'auto',
  },
}));

export const RightSubGridStep2 = styled(Grid)(() => ({
  display: 'flex',
  width: '62%',
  height: '90vh',
  // height: '80%',
  marginTop: '20px',
  marginBottom: 'auto',
  justifyContent: 'space-evenly',
  flexDirection: 'column',
  '@media(max-width: 600px)': {
    width: '75%',
    height: '100vh',
    margin: 'auto',
  },
}));

export const BtnRow = styled(Box)(() => ({
  fontFamily: 'PoppinsLight',
  fontSize: '.9rem',
  fontStyle: 'normal',
  fontWeight: 400,
  marginTop: '6px',
  borderRadius: '8px',
}));
export const PriceRow = styled(Box)(() => ({
  fontStyle: 'normal',
  lineHeight: '48px',
  '& :nth-child(1)': {
    fontFamily: 'Poppins-Medium',
    fontSize: '2rem',
    color: '#101B10',
  },
  '& :nth-child(2)': {
    fontSize: '0.875rem',
    fontFamily: 'Poppins-SemiBold',
    lineHeight: '21px',
    opacity: 0.6,
  },
}));
export const Savings = styled(Box)(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '.9rem',
  lineHeight: '21px',
  color: '#16953A',
}));
export const Content = styled('p')(() => ({
  fontFamily: 'Poppins-Medium',
  fontStyle: 'normal',
  color: '#101B10',
  opacity: '0.6',
  fontSize: '0.9375rem',
  marginBottom: 8,
  marginTop: 8,
  letterSpacing: '0.0168em',
  cursor: 'pointer',
}));
