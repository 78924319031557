import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const LeftGrid = styled(Grid)(() => ({
    '@media(max-width: 600px)': {
        display: 'none'
    },
}));
export const MainGrid = styled(Grid)(() => ({
    position: 'relative',
    display: 'flex',
    background: "#FAFAFA",

}));
export const Centered = styled(Grid)(() => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '90vh',
    background: 'transparent',
    color: '#ffffff',
    marginTop: 40,
    paddingLeft: "6vw",
    '@media(max-width: 900px)': {
        paddingLeft: "13vw",
    },

}))
export const CenteredCA = styled(Grid)(() => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '90vh',
    background: 'transparent',
    color: '#ffffff',
    marginTop: 40,
    paddingLeft: "6vw",
    '@media(max-width: 900px)': {
        paddingLeft: "13vw",
    },

}))
export const RightGrid = styled(Grid)(() => ({
    background: "#FAFAFA",
    display: 'flex',
    justifyContent: 'space-between',
    height: '102.5vh',
    flexDirection: 'column',
    '@media(max-width: 600px)': {
        height: '100vh',

    },
}));
export const RightGridCA = styled(Grid)(() => ({
    background: "#FAFAFA",
    display: 'flex',
    height: '102.5vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 900px)': {
        height: '120vh',

    },
}));
export const LoaderSpan = styled('span')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
}));
export const RightSubGrid = styled(Grid)(() => ({
    display: 'flex',
    height: '80vh',
    width: '62%',
    justifyContent: 'space-around',
    flexDirection: 'column',
    margin: 'auto',
    '@media(max-width: 600px)': {
        width: '78%',
        height: '80vh',
    },
}));
export const RightSubGridCA = styled(Grid)(() => ({
    display: 'flex',
    height: '102vh',
    width: '65%',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(max-width: 900px)': {
        height: '120vh',
    },

    '@media(max-width: 600px)': {
        width: '75%',
        height: '120vh',
    },

}));
export const MobileGrid = styled(Grid)(() => ({
    display: 'none',
    '@media(max-width: 600px)': {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginTop: 50
    },

}));
export const Heading = styled('h2')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontSize: '2rem',
    fontStyle: 'normal',
    color: "#0D2229",
    marginTop: 0,
    marginBottom: 0,
    '@media(max-width: 600px)': {
        fontSize: '1.4rem',

    },
}));
export const LeftHeading = styled('h2')(() => ({
    fontFamily: 'PoppinsLight',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#FFFFFF",
    '@media(max-width: 1000px)': {
        fontSize: '1.6rem',

    },
}));
export const Text = styled('p')(() => ({
    fontFamily: 'PoppinsLight',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#FFFFFF",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 'medium',
    marginBottom: 8,
    marginTop: 8
}));
export const FP = styled('a')(() => ({
    textDecoration: 'none',
    fontFamily: 'Poppins-SemiBold',
    textAlign: 'center',
    fontStyle: 'normal',
    color: "#1E3625",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media(max-width: 600px)': {
        fontSize: '.9rem',

    },
}));
export const SubHeading = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    fontSize: '1rem',
    fontStyle: 'normal',
    color: "#1E3625",
    marginBottom: 0,
    marginTop: "6px",
    '& > :first-of-type': {
        textDecorationLine: "underline",
        color: "#1E3625",
        fontFamily: 'Poppins-SemiBold',
        textUnderlineOffset: "3px",
        cursor: 'pointer'
    },
    '@media(max-width: 600px)': {
        fontSize: '.9rem',

    },
}));