import { styled } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

export const Heading = styled('h4')(() => ({
    margin: 0,
    fontFamily: 'Poppins-SemiBold',
    fontSize: '25px',
    color: "#101B10",
    '@media(max-width: 900px)': {
        fontSize: '3.4vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '5.5vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '6vw',
    },

}));
export const PhotoGrid = styled(Box)(() => ({
    width: 'inherit',
    height: 200,
    '& img': {
        width: '100%',
        height: '100%'
    }
}));
export const Instruction = styled('p')(() => ({
    fontFamily: 'Poppins-Medium',
    color: "rgba(17, 17, 17, 0.6)",
    fontSize: '13px',
    cursor: 'pointer',
    marginBottom: 8,
}));
export const Description = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    color: "#101B10",
    fontSize: 15,
    textAlign: 'center',
    '@media(max-width: 600px)': {
        fontSize: '3vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3.4vw',
    },
    // marginBottom: 6,
}));
export const DescriptionX = styled('span')(() => ({
    fontFamily: 'Poppins-Regular',
    color: "#101B10",
    fontSize: 15,
    textAlign: 'center',
    '@media(max-width: 600px)': {
        fontSize: '3vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3.4vw',
    },
    // marginBottom: 6,
}));
export const BrowseFiles = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    color: "#1A5B2C",
    textUnderlineOffset: "3px",
    fontSize: '.9rem',
    letterSpacing: "0.0168em",
    cursor: 'pointer',
    textDecorationLine: 'underline',

}));
export const InfoHeading = styled('p')(() => ({
    fontFamily: 'Poppins-SemiBold',
    color: "#111111",
    fontSize: '.9rem',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    '@media(max-width: 900px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 800px)': {
        fontSize: '2.0vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.5vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3.5vw',
    },
}));
export const ToHeading = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    color: "rgba(17, 17, 17, 0.6)",
    fontSize: '.9rem',
    cursor: 'pointer',
    display: 'flex', alignItems: 'center'
}));
export const InfoDescription = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    color: "rgba(17, 17, 17, 0.6)",
    fontSize: '.9rem',
    cursor: 'pointer',
    marginBottom: 8,
    marginTop: 6,
    '@media(max-width: 900px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 800px)': {
        fontSize: '2vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3.4vw',
    },
}));
export const Label = styled('label')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: "21px",
    color: "#111111"
}));
export const style = {
    position: 'absolute',
    top: '50%',
    overflowY: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: 'none',
    borderRadius: 4,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 900px)': {
        width: '70% !important'
    },
    '@media(max-width: 600px)': {
        width: '90% !important',
    },
};

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    overflowY: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: 'none',
    borderRadius: 4,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto', height: 'max-content',
    justifyContent: 'space-between',
    '@media(max-width: 900px)': {
        width: '70% !important'
    },
    '@media(max-width: 600px)': {
        width: '90% !important',
        height: '600px'
    },
};

export const GreenButton = styled(Button)(() => ({
    background: "#3C6B49",
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 22px",
    height: '44px',
    textTransform: 'capitalize',
    borderRadius: "8px",
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    cursor: 'pointer',
    "&:disabled": {
        backgroundColor: '#3C6B49',
        color: '#FFFFFF',
        borderColor: '#3C6B49',
        boxShadow: 'none',
        },
    '&:hover': {
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3C6B49',
    },
    '@media(max-width: 600px)': {
        padding: "10px",
        fontSize: 'small',
        width: '100%'
    }
}));
export const RedButton = styled(Button)(() => ({
    background: "#B93B3B",
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 22px",
    height: '44px',
    textTransform: 'capitalize',
    borderRadius: "8px",
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#B93B3B',
        borderColor: '#B93B3B',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#B93B3B',
        borderColor: '#B93B3B',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #B93B3B',
    },
    '@media(max-width: 600px)': {
        padding: "10px",
        fontSize: 'small',
        width: '100%',
    }
}));
export const ImageContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    "& span": {
        position: "absolute",
        top: 70
    }
}));
export const DisputeDescription = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    color: "#111111",
    fontSize: '.9rem',
    cursor: 'pointer',
    marginTop: 4,   
    marginBottom: 4,
    '& > span': {
        fontFamily: 'Poppins-SemiBold',
    },
    '@media(max-width: 600px)': {
        fontSize: 'small',
        
    }
}));
export const ReviewMessage = styled(Box)(() => ({
    background: '#FAFAFA',
    borderRadius: 5,
    fontFamily: 'Poppins-Regular',
    opacity: .8,
    border: '1px solid #D5D5D5',
    padding: "10px 20px",
    width: '100%'
}));
export const CheckBoxLabel = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    color: "#111111",
    fontSize: '.9rem',
    cursor: 'pointer',
    marginTop: 16,
    '& > span': {
        fontFamily: 'Poppins-SemiBold',
    },
    '@media(max-width: 600px)': {
        fontSize: 'small',
    }
}));
export const DisputeReason = styled(Box)(() => ({
    maxHeight: 80,
    fontSize: '13px',
    overflowY: 'scroll',
    fontFamily: 'Poppins-Regular',
}));