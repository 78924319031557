import React, { useEffect } from "react";
import moment from "moment";
import Sidebar from "../../components/Sidebar"
import GreenLoader from "../../assets/greenLoader.gif"

import { MainBox } from "../Elements";
import { getTrip } from "../../features/trip/action"
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, Grid, Box } from "@mui/material"
import { useNavigate, useParams, Link } from "react-router-dom";
import { FormBox3, Edit, TransparentBtn } from "./Elements"
import { UserName, ActivityDetail, ActivityHeading } from "../Elements";
import { capitalize, modifyDurationTitle } from "../../utills/constants";

import rightArrow from "../../assets/rightArrow.png"

import "./style.css"
import "../Profile/style.css"

const SuccessPageEdit = () => {
    const matches600 = useMediaQuery('(max-width:600px)');

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userInfo } = useSelector(state => state.auth)
    const { trip, loading } = useSelector(state => state.trip)
    const { id } = useParams()
    useEffect(() => {
        dispatch(getTrip(id))
        // eslint-disable-next-line
    }, [])
    const getUnAvailableDays = () => {
        if (trip?.length) {
            const a = trip[0]?.unAvailableDays?.dayWeekText || ''
            const b = trip[0]?.unAvailableDays?.excludeDateText || ''
            return a + " " + b
        }
    }
    return <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }}>
        <Sidebar />
        <FormBox3 sx={{ height: '80vh' }}>
            {loading &&
                <div style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', left: "38%", top: "50%" }}>
                    <img src={GreenLoader} width="80" alt="GreenLoader" />
                </div>}
            <UserName sx={{ fontSize: '18px' }}>Trip Updated Successfully!</UserName>
            {/* {userInfo?.subscriptionStatus === 'freemium' && <div className="subscribeAlert">
                <span><Link>Subscribe</Link> to make this trip available and receive trade offers.</span>  <Link to="/freePlan"><img src={rightArrow} alt="rightArrow" /></Link>
            </div>} */}
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                    <ActivityHeading>YOUR OFFERING</ActivityHeading>
                    <ActivityDetail sx={{ color: '#101B10' }}>{trip && trip[0]?.species} {trip && trip[0]?.tradeType} </ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                    <ActivityHeading>TRIP LOCATION</ActivityHeading>
                    <ActivityDetail sx={{ color: '#101B10' }}>{trip && capitalize(trip[0]?.location?.city) + ", " + trip[0]?.location?.state}</ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                    <ActivityHeading>TRIP DURATION</ActivityHeading>
                    <ActivityDetail sx={{ color: '#101B10' }}>{trip && trip[0]?.duration?.value + " " + modifyDurationTitle(trip[0]?.duration?.title, trip[0]?.duration?.value)}</ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                    <ActivityHeading>TRIP Availability</ActivityHeading>
                    <ActivityDetail sx={{ color: '#101B10' }}>{trip && moment.utc(trip[0]?.availableFrom).format('MMM DD') + " - " + moment.utc(trip[0]?.availableTo).format('MMM DD')}</ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
                    <ActivityHeading>UNAVAILABLE DAYS</ActivityHeading>
                    <ActivityDetail sx={{ color: '#101B10' }}>{trip ? getUnAvailableDays() : 'Null'}</ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <ActivityHeading>TRIP PHOTOS</ActivityHeading>
                    <Grid container sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {trip && trip[0]?.photos?.map((res, i) => {
                            return <Grid item key={i} xs={2} sm={2} md={2} lg={2} className="uploadedImages" >
                                <img src={res} alt="images" />
                            </Grid>
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TransparentBtn onClick={() => navigate(`/editTrip/${trip[0]?._id}`)} sx={{ px: { lg: 4 } }} >Edit Trip</TransparentBtn>
                    <Edit onClick={() => navigate(`/profile/${userInfo?._id}`)}>Go to My Profile</Edit>
                </Box>
            </Grid>
        </FormBox3 >
    </MainBox >
}
export default SuccessPageEdit