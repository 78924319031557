import React from "react";

import { Back } from "../Settings/Elements";
import { MainBox,UserName } from "../Elements"
import { useNavigate } from "react-router-dom";
import { useMediaQuery, Grid, Box } from "@mui/material"
import { FormBox,  CoverHeading, Content, Date } from "./Elements"

import back from "../../assets/news/back.png"
import Sidebar from "../../components/Sidebar"
import cover from "../../assets/news/coverpage2.png"

const Detail = () => {
    const navigate = useNavigate()
    const matches600 = useMediaQuery('(max-width:600px)');
    return <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
        <Sidebar />
        <FormBox sx={{ width: '50%' }}>
            <UserName sx={{ fontSize: '18px' }}>Latest News</UserName>
            <Back sx={{ mb: 3, mt: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => navigate('/news')}> <img src={back} alt="back" style={{ marginRight: 10 }} />Back to Latest News</Back>
            <Grid container spacing={matches600 ? 0 : 1}>
                <Box sx={{ pr: 4, pb: 3 }}>
                    <img src={cover} alt="cover" />
                    <Date>June 3, 2022</Date>
                    <CoverHeading sx={{ fontSize: '28px' }}>Get more engagement by offering a variety of trip activities</CoverHeading>
                    <Content sx={{ color: '#111111', fontSize: '18px', opacity: .6, my: 3 }}>You can significantly boost your reputation and trades by offering more trip activities. We’ll share some ideas you can think about integrating right away.</Content>
                    <Content sx={{ color: '#111111', fontSize: '14px', opacity: 1, my: 3 }}>The first thing you’ll want to decide is what activities you’re willing and able to support on a mostly-regular basis. Other traders should feel confident that they will get the experience as advertised, if not better. </Content>
                    <Content sx={{ color: '#111111', fontSize: '14px', opacity: 1, my: 3 }}>Do you have experience in other areas that you think would be an excellent activity? Create a New Trip  and see what happens! You might just develop a deeper passion for the new activity and let it become your primary focus.</Content>
                    <Content sx={{ color: '#111111', fontSize: '14px', opacity: 1, my: 3 }}>All of this text is intended to be an example of how this full article might look. There will of course be differences, but it should demonstrate at least most of the article appearance and how scroll behavir might look.</Content>
                </Box>
            </Grid>
        </FormBox>
    </MainBox >
}
export default Detail