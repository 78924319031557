import React, { useEffect, useState } from 'react';

import { FireStore } from '../../utills/FireStore';
import { getFullName } from '../../utills/constants';
import { signout } from '../../features/auth/action';
import { saveData } from '../../features/auth/reducer';
import { StyledBadge, StyledMesgBadge } from './Badge';
import { useSelector, useDispatch } from 'react-redux';
import { returnHeaderName } from '../../utills/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { Restriction } from '../../components/Modal/Restriction';
import { FreeUserRestriction } from '../../components/Modal/FreeUser';
import { addMargin, removeMargin } from '../../features/auth/reducer';
import { sidebarList1, sidebarList2, sidebarList3 } from '../../data';
import { DrawerFooter, GetListItem, ListText, PageName } from './Elements';
import { CustomDivider, LogoText, AppBar, CustomerToolBar } from './Elements';
import { config } from '../../utills/constants';
import api from '../../api';
import { Drawer, ListItem, ListItemIcon, useMediaQuery } from '@mui/material';
import { Box, Grid, List, Avatar, CssBaseline, IconButton } from '@mui/material';
import { DrawerHeader, drawerStyles, UserName, CustomDrawer } from './Elements';

import menu from '../../assets/search/menu.png';
import logo from '../../assets/sidebar/Logo.svg';
import bell from '../../assets/sidebar/bell.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import logoutIcon from '../../assets/sidebar/Logout.png';

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const classes = drawerStyles();
  const navigation = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    dispatch(removeMargin());
    // eslint-disable-next-line
  }, []);

  const [state, setState] = useState({
    freeUserOpen: false,
    openDrawer: false,
    restrictionModel: false,
  });
  const [userSubscriptionStatus, setuserSubscriptionStatus] = useState('');
  const { role, userInfo, notifications, inbox, isAuth } = useSelector((state) => state.auth);
  console.log(role, userSubscriptionStatus);
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleDrawerOpen = () => {
    handleChange('openDrawer', true);
  };

  const handleDrawer = () => {
    state.openDrawer === false ? dispatch(addMargin()) : dispatch(removeMargin());
    handleChange('openDrawer', !state.openDrawer);
  };

  const logout = () => {
    let body = {
      userId: userInfo?._id,
      registrationCode: '',
      notificationEnabled: false,
    };
    dispatch(signout(body, navigation));
  };
  useEffect(() => {
    if (location.pathname.includes('profile') && !matches) {
      handleDrawerOpen();
    }
  }, []);
  const getSubsctiptionStatus = async () => {
    const customerId = userInfo?.customerId; // Replace with the actual customer ID
    await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
      setuserSubscriptionStatus(res.data.status);
    });
  };
  useEffect(() => {
    if (role == 'user') {
      getSubsctiptionStatus();
    }
  }, []);
  const redirect = (url) => {
    handleDrawer();
    if (role === 'guest') {
      handleChange('restrictionModel', true);
    } else if (url === '/search') {
      navigation('/search');
    } else if (url === '/notification') {
      navigation('/notification');
    } else if (url === '/settings') {
      navigation('/settings');
    } else if (url === '/news') {
      navigation('/news');
    } else if (url === '/support') {
      navigation('/support');
    } else if (url === '/savedTrips') {
      navigation('/savedTrips');
    } else if (url === '/tradeOffer' && role === 'user') {
      navigation('/tradeOffer');
    } else if (url === '/messages' && role === 'user') {
      navigation('/messages');
    } else if (url === '/createTrip' && role === 'user') {
      navigation('/createTrip');
    } else if (
      url === '/createTrip' &&
      (userSubscriptionStatus !== 'freemium' || userSubscriptionStatus !== 'No subscriptions found')
    ) {
      navigation('/createTrip');
    } else if (
      (url === '/tradeOffer' && userSubscriptionStatus === 'freemium') ||
      userSubscriptionStatus === 'No subscriptions found'
    ) {
      navigation('/tradeoffer');
    } else if (
      (url === '/confirmedTrips' && userSubscriptionStatus === 'freemium') ||
      userSubscriptionStatus === 'No subscriptions found'
    ) {
      navigation('/confirmedTrips');
    } else if (
      userSubscriptionStatus === 'freemium' ||
      userSubscriptionStatus === 'No subscriptions found'
    ) {
      handleChange('freeUserOpen', true);
    } else navigation(url);
  };
  const drawer = (
    <>
      <DrawerHeader>
        <Grid
          sx={{
            cursor: 'pointer',
            display: state.openDrawer ? 'flex' : 'block',
            justifyContent: state.openDrawer ? 'initial' : 'center',
            alignItems: 'center',
            px: state.openDrawer ? 2.2 : 0,
          }}
        >
          <Box
            onClick={() => navigation('/search')}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={logo} alt="logo" />
            <LogoText
              variant="h6"
              component="div"
              sx={{
                opacity: state.openDrawer ? 1 : 0,
                display: state.openDrawer ? 'flex' : 'none',
                flexGrow: 1,
                pl: 1.5,
                alignItems: 'center',
              }}
            >
              TRIP TRADER
            </LogoText>
          </Box>
          {state.openDrawer && (
            <IconButton onClick={() => handleChange('openDrawer', false)}>
              <ChevronLeftIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
        </Grid>
      </DrawerHeader>
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // height: '70%',
        }}
      >
        {[sidebarList1, sidebarList2, sidebarList3].map((text, i) => (
          <div key={i}>
            {text?.map((res, i) => {
              return (
                <ListItem
                  key={i}
                  disablePadding
                  sx={{ display: 'block' }}
                  onClick={() => redirect(res.url)}
                >
                  <GetListItem url={res.url} location={location} open={state.openDrawer}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        justifyContent: state.openDrawer ? 'center' : 'center',
                        mr: state.openDrawer ? 'auto' : '',
                        width: state.openDrawer ? 50 : 'max-content',
                      }}
                      onClick={handleDrawer}
                    >
                      {res?.name === 'Notifications' &&
                      notifications?.some((message) => message?.isRead === false) ? (
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <img src={res?.icon} alt={res?.icon} />
                        </StyledBadge>
                      ) : res?.name === 'Messages' &&
                        inbox?.some(
                          (message) =>
                            message?.latestMessage?.user?._id !== userInfo?._id &&
                            message?.latestMessage?.isRead === false
                        ) ? (
                        <StyledMesgBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <img src={res?.icon} alt={res?.icon} />
                        </StyledMesgBadge>
                      ) : (
                        <img src={res.icon} alt={res.icon} />
                      )}{' '}
                    </ListItemIcon>
                    <ListText
                      primary={res.name}
                      sx={{
                        opacity: state.openDrawer ? 1 : 0,
                        display: state.openDrawer ? 'block' : 'none',
                        fontFamily: 'PoppinsLight',
                      }}
                    />
                  </GetListItem>
                </ListItem>
              );
            })}
            <CustomDivider />
          </div>
        ))}
      </List>
      <DrawerFooter>
        <Grid
          sx={{
            width: '100%',
            bottom: 0,
            // position: 'absolute',
            mb: 2,
            cursor: 'pointer',
            display: state.openDrawer ? 'flex' : '',
            justifyContent: state.openDrawer ? 'initial' : 'center',
            px: state.openDrawer ? 2.2 : 0,
          }}
        >
          <Box onClick={handleDrawer} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Avatar src={userInfo?.image} alt="" />
          </Box>
          <UserName
            onClick={() =>
              role === 'guest' ? navigation('/search') : navigation(`/profile/${userInfo?._id}`)
            }
            variant="h6"
            sx={{
              opacity: state.openDrawer ? 1 : 0,
              display: state.openDrawer ? 'flex' : 'none',
              flexGrow: 1,
              pl: matches ? 0.5 : 1.5,
              alignItems: 'center',
            }}
          >
            {role === 'guest' ? 'Guest User' : getFullName(userInfo)}
          </UserName>
          {role !== 'guest' && (
            <Box
              onClick={logout}
              sx={{
                opacity: state.openDrawer ? 1 : 0,
                display: state.openDrawer ? 'flex' : 'none',
                alignItems: 'center',
              }}
            >
              <img src={logoutIcon} alt="logout" />
            </Box>
          )}
        </Grid>
      </DrawerFooter>
    </>
  );
  return (
    <>
      <CssBaseline />
      {matches && (
        <AppBar position="fixed">
          <CustomerToolBar>
            <PageName variant="h6" noWrap component="div">
              <img src={menu} onClick={handleDrawerOpen} alt="menu" style={{ marginRight: 20 }} />
              {returnHeaderName(location)}
            </PageName>
            {notifications?.some((message) => message?.isRead === false) ? (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                variant="dot"
              >
                <img src={bell} alt={'bell'} onClick={() => navigation('/notification')} />
              </StyledBadge>
            ) : (
              <img src={bell} alt={'bell'} onClick={() => navigation('/notification')} />
            )}
          </CustomerToolBar>
        </AppBar>
      )}
      <Drawer
        variant="temporary"
        open={state.openDrawer}
        onClose={() => handleChange('openDrawer', false)}
        ModalProps={{ keepMounted: true }}
        sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}
        classes={{ paper: classes.MuiDrawer }}
      >
        {drawer}
      </Drawer>
      {!state.openDrawer && (
        <CustomDrawer
          open={state.openDrawer}
          variant={'permanent'}
          onClose={() => handleChange('openDrawer', false)}
          sx={{
            display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
          }}
        >
          {drawer}
        </CustomDrawer>
      )}
      {state.openDrawer && (
        <Drawer
          open={state.openDrawer}
          classes={{ paper: classes.MuiCustomDrawer }}
          variant={'persistent'}
          onClose={() => handleChange('openDrawer', false)}
          sx={{
            display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
          }}
        >
          {drawer}
        </Drawer>
      )}
      {state.openDrawer &&
        (location.pathname.includes('profile') || location.pathname.includes('messages')) && (
          <CustomDrawer
            open={state.openDrawer}
            variant="permanent"
            onClose={() => handleChange('openDrawer', false)}
            sx={{
              display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
            }}
          >
            {drawer}
          </CustomDrawer>
        )}

      <Restriction
        open={state.restrictionModel}
        stateName="restrictionModel"
        handle={handleChange}
      />
      <FreeUserRestriction
        open={state.freeUserOpen}
        stateName="freeUserOpen"
        handle={handleChange}
      />
    </>
  );
};

export default Sidebar;
