import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from "../../../components/Button"
import { Heading, AccountReadyText, ImageContainer, RightSubGridStep3 } from "../Components/Elements"

import successImage from "../../../assets/create account/successImage.png"
import correctCircle from "../../../assets/create account/correctCircle.png"
import { getFullName } from "../../../utills/constants";

const Step3 = () => {
    const { userInfo } = useSelector(state => state.auth)
    const navigate = useNavigate()
    return <RightSubGridStep3>
        <ImageContainer>
            <img src={successImage} alt="successImage" />
            <span><img src={correctCircle} alt="correctCircle" /></span>
        </ImageContainer>
        <Heading sx={{ textAlign: 'center' }}>{`Welcome, ${getFullName(userInfo)}!`}</Heading>
        <AccountReadyText >Your account is ready to go. Good luck with your offers and enjoy happy trading!</AccountReadyText>
        <CustomGreenLargeButton onClick={() => navigate('/search')} type="submit" variant="contained" disableripple>
            Find a Trip
        </CustomGreenLargeButton>
        <CustomGreenTransLargeButton onClick={() => navigate(`/profile/${userInfo?._id}`)}>Go to my profile</CustomGreenTransLargeButton>
    </RightSubGridStep3>
}

export default Step3