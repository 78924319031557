import React from "react"
import logoIcon from "../../assets/logo-svg.svg"
import { Typography, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";

import "./style.css"
const Logo = () => {
    const navigate = useNavigate()
    const redirectToHome=()=>{
        navigate("/")
    }
    return <Grid sx={{ height: { lg: 20 },cursor:'pointer' }} onClick={redirectToHome}>
        <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, fontFamily: "Suez One", display: 'flex', alignItems: 'center', }} >
            <img src={logoIcon} width={80} alt="logo"className="img-logo" />  TRIP TRADER
        </Typography>
    </Grid>
}
export default Logo