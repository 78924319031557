import axios from "axios";
import { baseURL } from "../utills/constants"
const backend = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? baseURL
      : baseURL,
});
backend.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) config.headers = { Authorization: `Bearer ${token}` };
    return config;
  },
  (error) => Promise.reject(error)
);

export default backend;
