import api from '../../api';
import { paymentrequest, saveFeaturedData, saveDropdowns, failed, saveData } from './reducer';
import { config } from '../../utills';
import toast from 'react-hot-toast';

export const getFeatured = () => async (dispatch) => {
  await api
    .get(`/dashboard/featured`, config)
    .then((data) => {
      dispatch(saveFeaturedData(data.data));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(failed(err));
      }
    });
};
export const getDropdowns = () => async (dispatch) => {
  await api
    .get(`/dashboard/dropdown`, config)
    .then((data) => {
      dispatch(saveDropdowns(data.data));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(failed(err));
      }
    });
};
export const getPlans = () => async (dispatch) => {
  await api
    .get(`/plan?status=active`, config)
    .then((data) => {
      dispatch(saveData({ name: 'plans', data: data.data.data }));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(failed(err));
      }
    });
};
export const uploadDragImageToS3 = async (body) => {
  const formData = new FormData();
  formData.append('files', body);
  const response = await api.post(`/upload`, formData, {
    headers: {
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
  return response.data.data;
};
export const uploadImageToS3 = async (body) => {
  const formData = new FormData();
  formData.append('files', body.target.files[0]);
  const response = await api.post(`/upload`, formData, {
    headers: {
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
  return response.data.data;
};
export const getPaymentRequest = (body, navigate) => async (dispatch) => {
  try {
    console.log('body', body);
    const resp = await api.get(`/planrequest?customer_id=${body}`);
    console.log('*********** getPaymentRequest line 56 from action', resp?.data?.data);
    dispatch(paymentrequest({ name: 'paymentRequest', data: resp?.data?.data }));
  } catch (err) {
    console.log('*********** getPaymentRequest catch err ', err);
    const msg =
      err?.response?.data?.error?.message ||
      err?.response?.data?.message ||
      err?.response?.status ||
      err;
    console.log('msg: ', msg);
    if (msg === 'No records found') {
      dispatch(paymentrequest({ name: 'paymentRequest', data: [] }));
    }
  }
};

export const deleteTheRequest = (body, navigate) => async (dispatch) => {
  try {
    const resp = await api.delete(`/planrequest/${body}`);
    toast.success(resp?.data?.message, { duration: 4000 });
    dispatch(paymentrequest({ name: 'paymentRequest', data: [] }));
    navigate('/settings');
  } catch (error) {
    console.log(error);
    if (error.response.data?.message === 'No records found') {
      dispatch(paymentrequest({ name: 'paymentRequest', data: [] }));
    }
    toast.error(error?.response?.data?.message || error?.message || error);
  }
};

export const uploadImagesToS3 = async (body) => {
  const formData = new FormData();
  if (body.target && body.target.files) {
    for (let i = 0; i < body.target.files.length; i++) {
      formData.append('files', body.target.files[i]);
    }
  }else if (body.dataTransfer && body.dataTransfer.files) {
    for (let i = 0; i < body.dataTransfer.files.length; i++) {
      formData.append('files', body.dataTransfer.files[i]);
    }
  }
  // for (let i = 0; i < body.target.files.length; i++) {
  //   formData.append('files', body.target.files[i]);
  // }
  //  formData.append("files", body.target.files);
  const response = await api.post(`/upload`, formData, {
    headers: {
      accept: 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
  return response.data.data;
};
