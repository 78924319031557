import { styled } from '@mui/material/styles';
import {  Box, Button } from '@mui/material';

export const InstructionsSpan = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    color: '#111111',
    '@media(max-width: 600px)': {
        fontSize: '2.7vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '13px'
    },
}));
export const LoaderSpan = styled('span')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
}));
export const Edit = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    textTransform:'capitalize',
    background: '#3C6B49',
    borderRadius: '5px',
    color: '#FAFAFA',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: '13px',
    cursor: 'pointer',
    padding: '16px 20px 16px 20px',
    '&:hover': {
        background: '#3C6B49',
        borderColor: '#3C6B49',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        background: '#3C6B49',
        borderColor: '#3C6B49',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3C6B49',
    },
    '@media(max-width: 600px)': {
        padding: 3,
        width: 'max-content',
        textAlign: 'center',
        fontSize: '10px',

    },

}));
export const TransparentBtn = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    textTransform: 'capitalize',
    alignItems: 'center',
    borderRadius: '5px',
    color: '#30563A',
    border: '1px solid #DFDFDF',
    // height: '32px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'white',
        borderColor: '#DFDFDF',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'white',
        borderColor: '#DFDFDF',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem white',
    },
    }));
export const FormBox = styled(Box)(() => ({
    width: '38%',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: 'max-content',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 1200px)': {
        height: 'max-content',
        width: '50%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
    },
}));

