import React, { useState } from 'react';

import api from '../../api';
import MakeOffer from '../MakeOffer';
import star from '../../assets/home/star.png';
import saved from '../../assets/createTrip/save.png';
import { FreeUserRestriction } from '../../components/Modal/Restriction';

import { useSelector, useDispatch } from 'react-redux';
import { Grid, Avatar, useMediaQuery } from '@mui/material';
import { ActivityDetail, ActivityHeading, LeftBox, RightBox, NameSection } from '../Elements';
import { GreenButton, WhiteButton } from '../Search/Elements';
import { UnSaveTrip } from '../../components/Modal/TripModals';
import { TripBox, ReviewCount } from './Elements';
import { TripBoxHeader, RatingBox, RatingCount } from './Elements';
import { FirstMessage, MessageSent } from '../../components/Modal/Message';
import {
  getDurationFromDate,
  getFullName,
  config,
  swalWithStyledButton,
} from '../../utills/constants';
import { startLoading, stopLoading } from '../../features/auth/reducer';
import { reload } from '../../features/auth/action';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const CustomTripBox = ({ res, updateUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches1200 = useMediaQuery('(max-width:1200px)');

  const matches450 = useMediaQuery('(max-width:450px)');

  const { userInfo } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    freeUser: false,
    selectedTrip: {},
    unSaveTripModal: false,
    openMakeOffer: false,
    openMessage: false,
    openMessageSent: false,
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const getSubsctiptionStatus = async () => {
    const customerId = userInfo?.customerId; // Replace with the actual customer ID
    let status = '';
    await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
      status = res.data.status;
    });
    return status;
  };
  const openMakeOfferModal = async () => {
    const subsStatus = await getSubsctiptionStatus();
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      handleChange('freeUser', true);
    } else {
      handleChange('selectedTrip', res);
      handleChange('openMakeOffer', true);
    }
  };

  const openModal = () => {
    handleChange('selectedTrip', res);
    handleChange('unSaveTripModal', true);
  };

  const unSaveTrip = async () => {
    dispatch(startLoading());
    let body = {
      tripId: state?.selectedTrip?._id,
      name: 'userInfo',
      hostId: state?.selectedTrip?.hostId?._id,
      url: `/user/unSaveTrip/${userInfo?._id}`,
    };
    // dispatch(updateFuntion(body))
    await api
      .put(body.url, body, config)
      .then((res) => {
        updateUser();
        dispatch(stopLoading());
        swalWithStyledButton
          .fire({
            icon: 'success',
            allowOutsideClick: false,
            allowEscapeKey: false,
            text: res.data.message,
            confirmButtonText: 'Reload',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              dispatch(reload(userInfo?._id, navigate));
            } else if (result.isDenied) {
              dispatch(reload(userInfo?._id, navigate));
            }
          });
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      });
    handleChange('unSaveTripModal', false);
  };
  const openMessageBox = async () => {
    const subsStatus = await getSubsctiptionStatus();
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      handleChange('freeUser', true);
    } else {
      handleChange('selectedUser', res?.hostId);
      handleChange('openMessage', true);
    }
  };

  return (
    <>
      <TripBox container sx={{ width: '100%', mb: 2 }}>
        <TripBoxHeader item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
          <LeftBox>
            <Avatar src={res?.hostId?.image} sx={{ width: 50, height: 50 }} />
            <NameSection>
              <span>{getFullName(res?.hostId)}</span>
              <RatingBox>
                <img className="imageShadow" src={star} alt="star" />
                <RatingCount>
                  {' '}
                  {res?.hostId?.rating > 0 ? res?.hostId?.rating : ''}{' '}
                </RatingCount>{' '}
                <ReviewCount> {res?.hostId?.reviews} reviews</ReviewCount>
              </RatingBox>
            </NameSection>
          </LeftBox>
          <RightBox onClick={openModal}>
            <img src={saved} alt="save" />
          </RightBox>
        </TripBoxHeader>
        <Grid item xs={12} sm={12} md={12} lg={7} sx={{ my: 2, px: 2 }}>
          <ActivityHeading>OFFERING</ActivityHeading>
          <ActivityDetail>{`${res?.species} ${res?.tradeType}`} </ActivityDetail>
          <ActivityDetail>
            {`${res?.duration?.value} ${res?.duration?.title}`} •{' '}
            {`${res?.location?.city} ${res?.location?.state}`}{' '}
          </ActivityDetail>
        </Grid>
        <Grid
          item
          xs={matches450 ? 12 : 6}
          sm={6}
          md={6}
          lg={5}
          sx={{ my: matches1200 ? 0 : 2, mb: matches450 ? 2 : 0, px: 2 }}
        >
          <ActivityHeading>TRIP AVAILABILITY</ActivityHeading>
          <ActivityDetail>
            {getDurationFromDate(res?.availableFrom, res?.availableTo)}
          </ActivityDetail>
        </Grid>
        <Grid item xs={matches450 ? 12 : 6} sm={6} md={6} lg={12} sx={{ mb: 2, px: 2 }}>
          <ActivityHeading>IN RETURN FOR</ActivityHeading>
          <ActivityDetail>{res?.returnActivity}</ActivityDetail>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          sx={{ mb: 2, px: 2, flexDirection: 'row', display: 'flex' }}
        >
          <GreenButton
            onClick={openMakeOfferModal}
            sx={{ mr: 2, width: '100%' }}
            type="submit"
            variant="contained"
            disableripple
          >
            Make Offer
          </GreenButton>
          <WhiteButton onClick={openMessageBox} type="submit" variant="contained" disableripple>
            Message
          </WhiteButton>
        </Grid>
      </TripBox>
      <FreeUserRestriction open={state.freeUser} handle={handleChange} stateName="freeUser" />
      <MessageSent
        data={state}
        open={state.openMessageSent}
        handle={handleChange}
        stateName="openMessageSent"
      />
      <FirstMessage
        data={state}
        open={state.openMessage}
        handle={handleChange}
        stateName="openMessage"
      />
      <MakeOffer
        parentState={state}
        open={state.openMakeOffer}
        handle={handleChange}
        stateName="openMakeOffer"
      />
      <UnSaveTrip
        deleteFunction={unSaveTrip}
        data={state}
        open={state.unSaveTripModal}
        handle={handleChange}
        stateName="unSaveTripModal"
      />
    </>
  );
};
export default CustomTripBox;
