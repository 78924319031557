import React, { useState, useEffect } from 'react';

import Sidebar from '../../components/Sidebar';
import correct from '../../assets/create account/correct.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { planContent } from '../../data';
import { useNavigate } from 'react-router-dom';
import { MainBox, UserName } from '../Elements';
import { useSelector, useDispatch } from 'react-redux';
import { deleteTheRequest, getPaymentRequest, getPlans } from '../../features/general/action';
import { Divider, useMediaQuery, Grid } from '@mui/material';
import { PriceRow, Content, Save, Savings, BtnRow } from '../Join/Components/Elements';
import { FormBox, PlanName, FormBoxA, Text, Back, GreenButton } from './Elements';
import { config } from '../../utills/constants';
import api from '../../api';
import '../Join/SubscriptionPlan/style.css';
import toast from 'react-hot-toast';
import ChangePlan from '../../components/Modal/ChangePlan';

const FreePlan = () => {
  const matches600 = useMediaQuery('(max-width:600px)');
  const matches400 = useMediaQuery('(max-width:400px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [primaryCard, setPrimaryCard] = useState('');
  const [open, setOpen] = useState(false);
  const { plans, paymentRequest } = useSelector((state) => state.general);
  const { userInfo } = useSelector((state) => state.auth);
  const getAll = useSelector((state) => state.general);
  const getActiveRequest =
    paymentRequest && paymentRequest?.length && paymentRequest?.find((e) => e?.status === 'active');
  console.log('paymentRequest', getActiveRequest);
  const date = new Date();
  const [state, setState] = useState({ plan: 'annual', step: 1, total: 0, monthly: 0 });
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  console.log('primaryCard Workker', primaryCard);
  const handleopen = () => setOpen(true);
  const handlePlanChange = async () => {
    const results = plans?.filter((obj) => obj.type === state.plan)[0];
    handleChange(
      'total',
      results.charges * (state.plan === 'annual' ? 12 : 1) - (state.plan === 'annual' ? 0.01 : 0)
    );
    handleChange('monthly', results.charges);
    handleChange('discount', results.discount);
    const body = { customerId: userInfo?.customerId, priceId: results?.stripeId };
    await api
      .post(`/stripe/create-subscription`, body, config)
      .then((data) => {
        navigate(
          `/buyplan/${state.plan}/${data?.data?.clientSecret}/${data?.data?.subscriptionId}`
        );

        // handleChange('clientSecret', data?.data?.clientSecret)
        // handleChange('subscriptionId', data?.data?.subscriptionId)
      })
      .catch((err) => {
        if (err.response) {
          // setcodevalid(false)
        }
      });
  };
  const checkprimaryCard = async () => {
    const resp = await api.get(`/stripe/subscriptions/${userInfo?.customerId}`);
    const result = resp?.data?.subscriptions?.data;
    // console.log('Respponse of the primary key', resp?.data?.subscriptions?.data);

    let data = null;
    if (result.length > 0) {
      const filterData = result.filter(
        (val) =>
          (val?.status === 'active' || val?.status === 'canceled') && val?.default_payment_method
      );

      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        data = element;
        if (filterData.length > 0) {
          if (element.status === 'active' && data?.default_payment_method) {
            break;
          }

          if (element.status === 'canceled' && data?.default_payment_method) {
            data = element;
            break;
          }
        } else {
          break;
        }
      }
    }

    console.log('Tauseef Data', data);
    // // console.log('management Data', data);

    resp?.data?.subscriptions?.data && setPrimaryCard(data);
  };
  console.log('state.plan', state);

  const requestForUpdatePlan = async () => {
    try {
      if (userInfo?.subscription?.title === state.plan) {
        toast.error(`You have already subscribed for this ${userInfo?.subscription?.title} plan`, {
          duration: 8000,
        });
      } else {
        const respData = await getAll?.plans?.find((e) => e?.type === state.plan);
        console.log(respData);
        const body = {
          userId: userInfo?._id,
          customerId: userInfo?.customerId,
          subscriptionId: primaryCard?.id,
          priceId: respData?.stripeId,
          itemId: primaryCard?.items?.data[0]?.id,
          subscription: {
            title: respData?.type,
            charges: respData?.charges,
            discount: respData?.discount,
          },
          status: 'active',
        };
        console.log(body);
        const response = await api.post('/planrequest', body);
        toast.success(response?.data?.message, { duration: 4000 });
        navigate('/settings');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { duration: 4000 });
    }
  };
  const getTheRequest = async () => {};
  useEffect(() => {
    getTheRequest();
    checkprimaryCard();
    dispatch(getPaymentRequest(userInfo?.customerId));
    dispatch(getPlans());
    const results = plans?.filter((obj) => obj?.type === 'annual')[0];
    handleChange('total', results?.charges * 12);
    handleChange('monthly', results?.charges);
    handleChange('discount', results?.discount);
    // eslint-disable-next-line
  }, []);

  const getPlanPrice = (planType) => {
    const results = plans?.filter((obj) => obj?.type === planType);
    if (results?.length > 0) {
      return `$${results[0]['charges']}`;
    }
  };
  const deletePaymentRequest = () => {
    dispatch(deleteTheRequest(getActiveRequest?._id, navigate));
  };

  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      <FormBox>
        <FormBoxA sx={{ height: 'max-content' }}>
          <Back sx={{ ml: matches400 ? 0 : 1, mt: 1 }} onClick={() => navigate('/settings')}>
            <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
            Back to Settings
          </Back>
          <UserName sx={{ fontSize: '18px', mx: 2, mt: 1, mb: 1.5 }}>Manage Subscription</UserName>
          <Grid sx={{ mx: 2 }}>
            <Text>
              You are currently on {userInfo?.subscription?.title === 'annual' ? 'an' : 'a'}{' '}
              <PlanName>
                {userInfo?.subscription?.title && userInfo?.subscrption?.endDate >= date
                  ? userInfo?.subscription?.title
                  : 'Free'}{' '}
                plan
              </PlanName>
              . Subscribe today to unlock full access to all Trip Trader features!{' '}
            </Text>
          </Grid>
          <Divider sx={{ m: 2 }} />
          <BtnRow sx={{ mb: 2, mx: 2 }}>
            <button
              className={
                state.plan === 'annual' ? 'leftBtn selectedPlanBtn' : 'leftBtn notSelectedPlanBtn'
              }
              onClick={() => handleChange('plan', 'annual')}
            >
              Annual
            </button>
            <button
              className={
                state.plan === 'monthly'
                  ? 'rightBtn selectedPlanBtn'
                  : 'rightBtn notSelectedPlanBtn'
              }
              onClick={() => handleChange('plan', 'monthly')}
            >
              Monthly
            </button>
          </BtnRow>
          <Grid sx={{ mx: 2 }}>
            <PriceRow>
              <span>
                {state.plan === 'annual' ? getPlanPrice('annual') : getPlanPrice('monthly')}
              </span>
              <span>{state.plan === 'annual' ? '/month (Billed annually)' : '/month'}</span>
            </PriceRow>
            {state.plan === 'annual' ? (
              <Savings>Best Value • $20 Savings</Savings>
            ) : (
              <Save>
                Save $20 with an&nbsp;
                <Savings onClick={() => handleChange('plan', 'annual')}>Annual Plan</Savings>
              </Save>
            )}
          </Grid>
          <Grid sx={{ mx: 2 }}>
            {planContent?.map((res, i) => {
              return (
                <Content sx={{ fontSize: 14 }} key={i}>
                  <img alt="correct" src={correct} style={{ paddingRight: 15 }} />
                  {res}
                </Content>
              );
            })}
          </Grid>
          <GreenButton
            sx={{ width: 'max-content', m: 2 }}
            onClick={handlePlanChange}
            type="submit"
            variant="contained"
            disableripple
          >
            Choose {state.plan === 'annual' ? 'Annual' : 'Monthly'} Plan
          </GreenButton>
        </FormBoxA>
      </FormBox>
    </MainBox>
  );
};
export default FreePlan;
