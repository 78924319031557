import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import trip from '../features/trip/reducer';
import auth from '../features/auth/reducer';
import storage from 'redux-persist/lib/storage';
import general from '../features/general/reducer';
import profile from '../features/profile/reducer';
import loader from '../features/loader/loader.reducer';
import StripeReducer from '../features/stripe/reducer';

const reducers = combineReducers({
  trip: trip,
  auth: auth,
  loader: loader,
  general: general,
  profile: profile,
  stripe: StripeReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});
export default store;
