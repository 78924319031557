import React, { useState } from 'react';
import calender from '../../../assets/createTrip/calender.png';

import { monthName } from '../../../utills/constants';
import { InputAdornment, TextField, Popover } from '@mui/material';
import { dateStyles } from '../../../components/InputFields/styles';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { GreenButton, CancelButton } from '../../../components/InputFields/Elements';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { useSelector } from 'react-redux';
import moment from 'moment';

const DatePicker = ({ state, handleChange }) => {
  const classes = dateStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { userInfo } = useSelector((state) => state.auth);
  console.log('user', userInfo);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const applyDate = (value) => {
    handleChange('birthDate', `${monthName(value?.month)} ${value?.day}, ${value?.year}`);
    handleChange('birthdayObj', value);
  };
  return (
    <>
      <TextField
        onClick={handleClick}
        aria-describedby={id}
        className={classes.root}
        // value={
        //   state?.birthdayObj
        //     ? moment.utc(userInfo?.birthDate).format('MMM  DD , YYYY')
        //     : 'mm / dd / yyyy'
        // }
        value={state?.birthDate ? moment.utc(state.birthDate).format('MMM DD, YYYY') : 'mm / dd / yyyy'}
        placeholder={'Select dates'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20, height: 19 } }}
        readOnly
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Calendar
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          calendarSelectedDayClassName="calendarSelectedDay"
          value={state?.birthdayObj}
          onChange={applyDate}
          maximumDate={utils().getToday()}
          colorPrimary="#3C6B49"
          calendarPopperPosition="auto"
          inputPlaceholder="Select a day range"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </CancelButton>
          <GreenButton onClick={handleClose}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};

export default DatePicker;
