import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import reviewBg from "../../../assets/home/reviewbg.png"

export const MainContainer = styled(Container)(() => ({
    display: 'flex',
    height: '700px',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${reviewBg})`,
    boxShadow: '0px 4.00411px 5.33881px rgba(0, 0, 0, 0.1)',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    justifyContent: 'center',
    fontStyle: 'normal',
    fontFamily: 'Poppins-Light',
}));
export const Heading = styled('h1')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '2.3rem',
    lineHeight: "64px",
    letterSpacing: "-0.005em",
    '@media(max-width: 450px)': {
        fontSize: "6.3vw",
    },
}));
export const Orange = styled('span')(() => ({
    color: "#E38C09",
}));
export const Black = styled('span')(() => ({
    color: "#313131",
}));
export const Description = styled(Box)(() => ({
    fontFamily: 'Poppins-Medium',
    fontStyle: 'normal',
    marginBottom: 40,
    color: '#241C10',
    opacity: 0.5,
    '@media(max-width: 450px)': {
        fontSize: "3.5vw",
        textAlign: 'center'
    },
}));
export const FeaturedItem = styled(Box)(() => ({
    display: 'flex',
    background: "#FFFFFF",
    boxShadow: "1px 14px 50px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    flexDirection: 'row',
    margin: 20,
    padding: 10,
    '@media(max-width: 430px)': {
        flexDirection: 'column',
    },
}));
export const FeaturedItemImage = styled(Box)(() => ({
    width: 280,
    height: 200,
    display:'flex',

    '& img': {
        maxHeight: '100%',
        maxWidth: 'fit-content',
        objectFit: 'cover',
        borderRadius: 5,
    },
    '@media(max-width:960px)': {
        display: 'flex',
        width: '70vw',
        height: 200,
        '& img': {
            maxWidth: '100%'
        },
    },
    '@media(max-width:430px)': {
        width: '100%',
        '& img': {
            width: '100%',

            maxWidth: '100%',
            maxHeight: 'fit-content'

        },
    },
}));
export const ReviewDetails = styled(Box)(() => ({
    padding: '4vh 6vw',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-around',
    '& img': {
        maxWidth: 'fit-content'
    },
    '@media(max-width: 960px)': {
        padding: '0vh 3vw',
    },
    '@media(max-width: 430px)': {
        padding: 0,
        height: '27vh'

    }

}));
export const Address = styled('span')(() => ({
    letterSpacing: "0.03em",
    fontSize: 'small',
    color: "#111111",
    fontFamily: "Poppins-Medium",
    opacity: 0.6,
}));
export const Message = styled('span')(() => ({
    fontFamily: "Poppins-Regular",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#111111",
    alignSelf: 'stretch',
    flexGrow: 0,
    '@media(max-width:960px)': {
        fontSize: "small",

    }
}));
export const Name = styled('span')(() => ({
    letterSpacing: "0.03em",
    fontSize: 'small',
    color: "#111111",
    fontFamily: "Poppins-SemiBold",
}));