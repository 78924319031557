import React, { useEffect, useState } from 'react';
import {
  UploadBox,
  DashedUploadBox,
  IdentityImageGrid,
  DisplayImageBox,
  UploadText,
  ProgressBox,
  ProfileImageGrid,
} from './Elements';
import { LinearProgressWithLabel } from '../../../components/ProgressBar';
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from '../../../components/Button';
import { LoaderSpan } from '../../SignIn/Elements';
import { useNavigate, useLocation } from 'react-router-dom';
import { BrowseFiles } from '../../Elements';
import { uploadDragImageToS3, uploadImageToS3 } from '../../../features/general/action';
import { updateAccount } from '../../../features/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import upload from '../../../assets/create account/upload.png';
import Loader from '../../../assets/loader1.gif';
import { FileUploader } from 'react-drag-drop-files';

const Step1 = ({ location, handleChange }) => {
  const GetUploadBox = ({ children }) => {
    const { userInfo } = useSelector((state) => state.auth);
    console.log('userInfo', userInfo?.identityProof);
    if (location.pathname === '/verifyId') {
      return <DashedUploadBox>{children}</DashedUploadBox>;
    } else if (location.pathname === '/uploadImage') {
      return <UploadBox>{children}</UploadBox>;
    }
  };
  const uploadFunction = async (e) => {
    console.log('working', e);

    let result = await uploadDragImageToS3(e);
    console.log('result', result);
    handleChange('step', 2);
    handleChange('image', result[0]?.imgrUrl);
  };
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  return (
    <GetUploadBox style={{ position: 'relative' }}>
      <label className="filebutton" style={{ cursor: 'pointer' }}>
        <img src={upload} alt="upload" />
        <span>
          <input
            accept="image/png,image/jpg, image/jpeg"
            type="file"
            id="myfile"
            onChange={uploadFunction}
            style={{ display: 'none' }}
            name="myfile"
          />
        </span>
      </label>
      <UploadText>
        Drag and drop here, or{' '}
        <BrowseFiles>
          <label className="filebutton" style={{ cursor: 'pointer' }}>
            browse files
            <span>
              <input
                accept="image/png,image/jpg, image/jpeg"
                type="file"
                id="myfile"
                onChange={uploadFunction}
                style={{ display: 'none' }}
                name="myfile"
              />
            </span>
            <div
              className="dragndrop"
              style={{ position: 'absolute', top: 0, left: 0, right: '6px' }}
            >
              <FileUploader handleChange={uploadFunction} name="images" types={fileTypes} />
            </div>
          </label>
        </BrowseFiles>
      </UploadText>
    </GetUploadBox>
  );
};
const Step2 = ({ handleChange, progress, setProgress }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 400);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (progress === 100) {
        handleChange('step', 3);
      }
    },
    // eslint-disable-next-line
    [progress]
  );

  return (
    <ProgressBox>
      <LinearProgressWithLabel value={progress} />
    </ProgressBox>
  );
};
export const Step3 = ({ handleChange, location, state }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, userInfo } = useSelector((state) => state.auth);

  const navigateTo = (path) => {
    navigate(path);
  };
  const submitFunction = async () => {
    let body = {};
    let path = '';
    if (location.pathname === '/verifyId') {
      body['identityProof'] =
        userInfo?.identityProof === '' ? state.image : userInfo?.identityProof;
      body['identityStatus'] = 'appliedFor';
      path = '/plan';
    } else if (location.pathname === '/uploadImage') {
      // body['image'] = userInfo?.image === '' ? state.image : userInfo?.image;
      body['image'] = state.image  ? state.image : userInfo?.image;
      path = '/verifyId';
    }
    dispatch(updateAccount(body, navigateTo(path), 'otherPage'));
  };

  const getImage = () => {
    if (location.pathname === '/verifyId') {
      return (
        <IdentityImageGrid>
          <img
            src={state.image === '' ? userInfo?.identityProof : state.image}
            alt="reviewIdentity"
          />
        </IdentityImageGrid>
      );
    } else if (location.pathname === '/uploadImage') {
      return (
        <ProfileImageGrid>
          {' '}
          {/* <img src={state.image === '' ? userInfo?.image : state.image} alt="reviewUser" /> */}
          <img src={state.image  ?  state.image : userInfo?.image} alt="reviewUser" />
        </ProfileImageGrid>
      );
    }
  };

  return (
    <DisplayImageBox>
      {getImage()}
      <div style={{ width: '100%' }}>
        <CustomGreenLargeButton
          style={{ marginBottom: 15 }}
          onClick={submitFunction}
          variant="contained"
          disableripple
        >
          {' '}
          {loading ? (
            <LoaderSpan>
              <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
              Confirm and Continue
            </LoaderSpan>
          ) : (
            'Confirm and Continue'
          )}{' '}
        </CustomGreenLargeButton>
        <CustomGreenTransLargeButton onClick={() => handleChange('step', 1)}>
          Change Photo
        </CustomGreenTransLargeButton>
      </div>
    </DisplayImageBox>
  );
};
const CustomUploadBox = ({ state, handleChange }) => {
  const [progress, setProgress] = useState(10);
  const location = useLocation();
  if (state.step === 1) return <Step1 handleChange={handleChange} location={location} />;
  else if (state.step === 2)
    return <Step2 handleChange={handleChange} progress={progress} setProgress={setProgress} />;
  else if (state.step === 3)
    return <Step3 state={state} handleChange={handleChange} location={location} />;
};
export default CustomUploadBox;
