import React, { useState } from 'react';

import { Skip } from '../../Elements';
import { useLocation, useNavigate } from 'react-router-dom';
import { identityVerificationLeft } from '../../../data';
import { CrossDesktopButton, CrossMobileButton } from '../../../components/Button/CrossButton';
import {
  MobileGrid,
  RightGrid,
  SubHeading,
  Heading,
  RightSubGrid,
  MainGrid,
} from '../Components/Elements';

import Logo from '../../../components/Logo';
import LeftPanel from '../Components/LeftPanel';
import UploadSteps, { Step3 } from '../Components/UploadSteps';
import CustomCopyright from '../../../components/Footer/CopyRight';
import { useSelector } from 'react-redux';

const IdentityVerification = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  console.log('userInfo', userInfo);

  const [state, setState] = useState({ image: '', step: 1 });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <MainGrid container spacing={2}>
      <MobileGrid>
        <Logo />
        <CrossMobileButton />
      </MobileGrid>
      <LeftPanel data={identityVerificationLeft} />
      <RightGrid item xs={12} sm={12} md={6} lg={6}>
        <CrossDesktopButton />
        <RightSubGrid>
          <div>
            <Heading>{state.step === 3 ? 'Review Your ID Card' : 'Verify Your Identity'}</Heading>
            <SubHeading>
              {state.step === 3
                ? 'Make sure you are only submitting a valid government-issued ID and that your picture and all information is clearly visible.'
                : 'To help build a trusting and safe community, please provide proof of identity in the form of a valid driver’s license or other government-issued ID.'}
            </SubHeading>
            {userInfo?.identityProof === '' ? (
              <UploadSteps state={state} handleChange={handleChange} />
            ) : (
              <Step3 state={state} handleChange={handleChange} location={location} />
            )}
          </div>
          {state.step !== 2 && (
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              <Skip onClick={() => navigate('/plan')}>Skip for now</Skip>
            </div>
          )}
        </RightSubGrid>
        <CustomCopyright />
      </RightGrid>
    </MainGrid>
  );
};
export default IdentityVerification;
