import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  trip: null,
  freemiumMakeOfferTrip: {},
  freemiumRecieveOfferTrip: {},
  freemiumNotesMessage: false,
  conversationMessage: [],
};
const tripSlice = createSlice({
  name: 'tripService',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    saveData: (state, { payload }) => {
      state[payload.name] = payload.data;
    },
    freemiumMakeOffer: (state, { payload }) => {
      state.freemiumMakeOfferTrip = payload;
    },
    freemiumRecieveOfferTrips: (state, { payload }) => {
      state.freemiumRecieveOfferTrip = payload;
    },
    freemiumMessage: (state, { payload }) => {
      state.freemiumNotesMessage = payload;
    },
    conversationInboxMessage: (state, { payload }) => {
      state.conversationMessage = payload;
    },
  },
});
const tripReducer = tripSlice.reducer;
export default tripReducer;

export const {
  startLoading,
  stopLoading,
  saveData,
  freemiumMakeOffer,
  freemiumRecieveOfferTrips,
  freemiumMessage,
  conversationInboxMessage,
} = tripSlice.actions;
