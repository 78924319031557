import React, { useEffect, useState } from 'react';

import api from '../../api';
import moment from 'moment';
import Sidebar from '../../components/Sidebar';
import GreenLoader from '../../assets/greenLoader.gif';

import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { guestNotifications } from '../../data';
import { MainBox, UserName } from '../Elements';
import { config } from '../../utills/constants';
import { getFullName } from '../../utills/constants';
import { useSelector, useDispatch } from 'react-redux';
import { FormBox, Count, ScrollAbleGrid, Back, Title } from './Elements';
import { GreenTitle, Message, CustomListItemText, UnRead, Read } from './Elements';
import { getFunction, _updateFuntion, updateFuntion } from '../../features/auth/action';
import {
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  Grid,
  ListSubheader,
  Avatar,
} from '@mui/material';
import './style.css';

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches600 = useMediaQuery('(max-width:600px)');

  const [guestNotification, setguestNotification] = useState(guestNotifications);
  const { role, userInfo, count, notifications, loading, isAuth } = useSelector(
    (state) => state.auth
  );

  const apiCall = (type) => {
    let body = {};
    if (type === 'notifications') {
      body['name'] = 'notifications';
      body['url'] = `/notification/app/${userInfo?._id}?page=1&limit=10000`;
    }
    dispatch(getFunction(body));
  };

  const markAllRead = async () => {
    if (isAuth) {
      let body = { name: 'notifications' };
      await api.put(`/notification/markAllRead/${userInfo?._id}`, body, config).then((res) => {
        apiCall('notifications');
      });
    } else {
      guestNotification.forEach((res) => {
        res.isRead = true;
      });
      setguestNotification([...guestNotification]);
    }
  };
  const getSplitedMesg = (msg, word) => {
    return msg.split(word);
  };

  useEffect(() => {
    if (role === 'user' && isAuth === true) {
      apiCall('notifications');
    }
    // eslint-disable-next-line
  }, []);

  const getTime = (res) => {
    var a = moment();
    var b = moment(res);
    return a.diff(b, 'days') > 7 ? moment(res).format('MMM DD') : moment(res).fromNow();
  };

  const redirect = (res) => {
    let body = { url: `notification/${res?._id}`, isRead: true };
    if (res.title === 'New Trip Created') {
      dispatch(updateFuntion(body));
      navigate(`/profile/${userInfo?._id}`);
    } else if (res.message.includes('followed')) {
      dispatch(updateFuntion(body));
      navigate(`/profile/public/${userInfo?._id}`);
    } else if (res.title === 'Profile Updated') {
      dispatch(updateFuntion(body));
      navigate(`/profile/${userInfo?._id}`);
    }
  };

  const markRead = (msgId, res) => {
    if (isAuth) {
      let body = {};
      body['name'] = 'notifications';
      body['url'] = `/notification/markRead/${userInfo?._id}/${msgId}`;
      dispatch(_updateFuntion(body), redirect(res));
    }
  };

  const getImage = (res) => {
    if (
      res?.message?.includes('following') ||
      res?.title === 'Your offer was accepted!' ||
      res?.title === 'A saved trip is expiring soon' ||
      res?.title === 'You have a new review' ||
      res?.title === 'A review for you was updated' ||
      res?.title?.includes('New offer from') ||
      res?.title?.includes('posted a new trip') ||
      res?.title?.includes('New message from') ||
      res?.title?.includes('Your offer from') ||
      res?.topic?.includes('subscription reminder for accept offer') ||
      res?.title?.includes('You’re hosting a trip in') ||
      res?.title.includes(`forget to leave a review!`) ||
      res?.title === 'An offer was canceled' ||
      res?.title === 'Your offer was declined' ||
      res?.title === 'Your review was disputed' ||
      res?.title === 'Don’t forget to leave a review!' ||
      res?.title.includes('You’re hosting')
    ) {
      return (
        <ListItemIcon sx={{ display: 'flex', justifyContent: 'center', px: 2 }}>
          <Avatar src={res?.senderId?.image} alt="confirm" />
        </ListItemIcon>
      );
    } else {
      return (
        <ListItemIcon sx={{ display: 'flex', justifyContent: 'center', px: 2 }}>
          <Avatar src={res.icon} alt="confirm" sx={{ '& img': { objectFit: 'scale-down' } }} />
        </ListItemIcon>
      );
    }
  };

  const getMessage = (res) => {
    if (res?.message?.includes('following')) {
      return (
        <Message onClick={() => navigate(`/profile/public/${res?.senderId?._id}`)}>
          <GreenTitle>{getFullName(res?.senderId)} </GreenTitle> is following you.
        </Message>
      );
    } else if (res?.title === 'Your offer was accepted!') {
      return (
        <p className="message">
          {getFullName(res?.senderId)} accepted {getSplitedMesg(res?.message, 'accepted')[1]}
          <Link className="green-link" to="/confirmedTrips">
            See Confirmed trips
          </Link>
        </p>
      );
    } else if (res?.title === 'A saved trip is expiring soon') {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to="/savedTrips">
            make an offer ?
          </Link>
        </p>
      );
    } else if (
      res?.title === 'You have a new review' ||
      res?.title === 'A review for you was updated'
    ) {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to={`/profile/${res?.userId?._id}`}>
            Read it now
          </Link>
        </p>
      );
    } else if (res?.title === 'Trip Confirmed') {
      return (
        <p className="message">
          {`Congrats! You have a new `}
          <Link className="green-link" to="/confirmedTrips">
            confirmed trip
          </Link>
        </p>
      );
    } else if (res?.title?.includes('Your trip starts')) {
      return (
        <p className="message">
          <Link className="first-link" to="/confirmedTrips">
            Review the details
          </Link>
          {res?.message}
        </p>
      );
    } else if (res?.title?.includes('New offer from')) {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to="/tradeOffer">
            See trip details.
          </Link>
        </p>
      );
    } else if (res?.title?.includes('posted a new trip')) {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to={`/profile/public/${res?.senderId?._id}#trips`}>
            See trip details.
          </Link>
        </p>
      );
    } else if (res?.title?.includes('New message from')) {
      return (
          <p className="message">
            {res?.message}
            {res?.message?.length > 70 && (
              <Link className="green-link" to="/messages">
                Read full message
              </Link>
            )}
          </p>
      );
    } else if (
      res?.title === 'Your next adventure awaits!' ||
      res?.message ===
        'You have received a new message from a trader. Subscribe to view the full conversation' ||
      res?.title?.includes('Your offer from')
    ) {
      const isFreemium = userInfo.subscription.status === "freemium"
      return (
        <p className="message">
          {res?.message}
          <Link style={{ marginLeft: '0.3rem' }} className="first-link" to={isFreemium ? `/freePlan` : "/manageSubscription"}>
            Subscribe
          </Link>
        </p>
      );
    } else if (
      res?.message?.includes(
        'You have received a new message from a trader. Subscribe to view the full conversation'
      )
    ) {
      return (
        <Link className="first-link" to={`/messages`}>
          <p className="message">{res.message}</p>
        </Link>
      );
    } else if (res?.title?.includes('You’re hosting a trip in')) {
      return (
        <p className="message">
          <Link className="first-link" to={`/confirmedTrips`}>
            Review the details
          </Link>
          {res?.message}
        </p>
      );
    } else if (res?.title.includes(`forget to leave a review!`)) {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to={`/profile/public/${res?.senderId?._id}`}>
            Leave a review.
          </Link>
        </p>
      );
    } else if (res?.title === 'Your offer was declined') {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to={`/profile/public/${res?.senderId?._id}#trips`}>
            Make new offer
          </Link>
        </p>
      );
    } else if (res?.title === 'Your ID has been verified!') {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to="/profile/edit">
            Check it out
          </Link>
        </p>
      );
    } else if (res?.title === 'Your ID has been rejected!') {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to="/profile/edit">
            reapply for ID Verification
          </Link>
        </p>
      );
    } else if (res?.title === 'Apply for ID Verification') {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to="/profile/edit">
            Apply for Verification
          </Link>
        </p>
      );
    } else if (res?.title === 'Your subscription payment failed') {
      return (
        <p className="message">
          {res?.message} &gt;{' '}
          <Link className="green-link" to="/freePlan">
            Manage Subscription
          </Link>
        </p>
      );
    } else if (res?.title === 'Email Verification') {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/profile/${res?.userId?._id}`}
          className="message"
        >
          {res?.message}
        </Link>
      );
    } else if (res?.title === 'Your review was disputed') {
      return (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/profile/public/${res?.senderId?._id}`}
          className="message"
        >
          {res?.message}
        </Link>
      );
    } else if (res?.title === 'An offer was canceled') {
      return (
        <p className="message">
          {res?.message}
          <Link className="green-link" to={`/profile/public/${res?.senderId?._id}`}>
            Send a message
          </Link>
        </p>
      );
    } else return <p className="message">{res?.message}</p>;
  };

  const getTitle = (res) => {
    if (res?.title?.includes('New offer from')) {
      return <Title>{`New offer from ${getFullName(res?.senderId)}`}</Title>;
    } else if (res?.title?.includes('posted a new trip')) {
      return <Title>{`${getFullName(res?.senderId)} posted a new trip`}</Title>;
    } else if (res?.title?.includes('posted a new trip')) {
      return <Title>{`${getFullName(res?.senderId)} posted a new trip`}</Title>;
    } else return <Title>{res?.title}</Title>;
  };

  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }}>
      <Sidebar />
      <FormBox sx={{ height: role === 'guest' ? 'max-content' : '94vh' }}>
        {isAuth && loading && (
          <div
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              left: '50%',
              top: '50%',
            }}
          >
            <img src={GreenLoader} width="80" alt="GreenLoader" />
          </div>
        )}
        <List
          sx={{ mt: 2 }}
          subheader={
            <ListSubheader>
              <UserName sx={{ fontSize: '18px', mb: 2 }}>Notifications</UserName>
            </ListSubheader>
          }
        >
          <Grid
            sx={{
              mx: 2,
              mt: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {role === 'guest' && (
              <Count>
                {guestNotification?.length} notifications,{' '}
                {
                  guestNotification?.filter((x) => {
                    return !x.isRead;
                  }).length
                }{' '}
                unread
              </Count>
            )}
            {role === 'user' && notifications?.length > 0 && (
              <Count>
                {notifications?.length} notifications, {count && count[0]?.unRead} unread
              </Count>
            )}
            {role === 'user' && notifications?.length === 0 ? (
              ''
            ) : (
              <Back onClick={markAllRead}>Mark all as read</Back>
            )}
          </Grid>
          <ScrollAbleGrid>
            {role === 'user' && notifications?.length === 0 && (
              <ListItem alignItems="flex-start">
                <Message>{'No notification(s) found'}</Message>
              </ListItem>
            )}

            {role === 'user' &&
              [...notifications]?.map((res, i) => {
                return (
                  <ListItem
                    alignItems="flex-start"
                    key={i}
                    onClick={() => markRead(res?.messageId, res)}
                    sx={{
                      px: 0,
                      py: 2,
                      background: !res.isRead ? '#EAF1E3' : 'white',
                      border: '1px solid #F2F2F2',
                    }}
                  >
                    {res?.message?.includes('following') ? (
                      <>
                        {getImage(res)}
                        <CustomListItemText id="switch-list-label-wifi">
                          {getMessage(res)}
                          {res?.isRead ? (
                            <Read>{getTime(res?.createdAt)}</Read>
                          ) : (
                            <UnRead>{getTime(res?.createdAt)}</UnRead>
                          )}
                        </CustomListItemText>
                      </>
                    ) : (
                      <>
                        {getImage(res)}
                        <CustomListItemText id="switch-list-label-wifi">
                          {getTitle(res)}
                          {getMessage(res)}
                          {res?.isRead ? (
                            <Read>{getTime(res?.createdAt)}</Read>
                          ) : (
                            <UnRead>{getTime(res?.createdAt)}</UnRead>
                          )}
                        </CustomListItemText>
                      </>
                    )}
                  </ListItem>
                );
              })}
            {role === 'guest' &&
              guestNotifications.map((res, i) => {
                return (
                  <ListItem
                    alignItems="flex-start"
                    key={i}
                    sx={{
                      px: 0,
                      my: 1,
                      boxShadow: '0px 1px 0px #EDEDED',
                      background: !res.isRead ? '#EAF1E3' : 'white',
                    }}
                  >
                    <ListItemIcon sx={{ display: 'flex', justifyContent: 'center' }}>
                      <img src={res.icon} alt="confirm" />
                    </ListItemIcon>
                    <CustomListItemText id="switch-list-label-wifi">
                      <Title> {res.title}</Title>
                      <Message>{res.message}</Message>
                      {res?.isRead ? <Read>{res.time}</Read> : <UnRead>{res.time}</UnRead>}
                    </CustomListItemText>
                  </ListItem>
                );
              })}
          </ScrollAbleGrid>
        </List>
      </FormBox>
    </MainBox>
  );
};
export default Notifications;
