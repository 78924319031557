import React from "react";

import { forgetPasswordLeft } from "../../data"
import { MobileGrid, RightGrid, RightSubGrid, MainGrid } from "./Elements";
import { CrossDesktopButton, CrossMobileButton } from "../../components/Button/CrossButton"

import RightPanel from "./RightPanel"
import Logo from "../../components/Logo"
import LeftPanel from "../Join/Components/LeftPanel"
import CustomCopyright from "../../components/Footer/CopyRight"

const ForgetPassword = () => {

    return <MainGrid container spacing={2} >
        <MobileGrid ><Logo /><CrossMobileButton /></MobileGrid>
        <LeftPanel data={forgetPasswordLeft} />
        <RightGrid item xs={12} sm={12} md={6} lg={6}>
            <CrossDesktopButton />
            <RightSubGrid>
                <RightPanel />
            </RightSubGrid>
            <CustomCopyright />
        </RightGrid>
    </MainGrid>
}
export default ForgetPassword