import React from 'react';

import { useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, Toolbar, IconButton, Typography } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText, Drawer } from '@mui/material';
import { JoinNow, SignIn, JoinNowMob, SignInMob, CustomLink, Border } from './Elements';

import logo from '../../assets/logo-svg.svg';
import CloseIcon from '@mui/icons-material/Close';

const HomeHeader = ({ props, refs }) => {
  const scrollToDownload = () =>
      refs.download.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
    scrollToFeatured = () =>
      refs.featured.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      }),
    scrollToHome = () => refs.home.current.scrollIntoView({ behavior: 'smooth', block: 'center' }),
    scrollToReviews = () =>
      refs.reviews.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

  const navItems = [
    { url: '', name: 'Home', scroll: scrollToHome },
    { url: 'featured', name: 'Featured', scroll: scrollToFeatured },
    { url: 'reviews', name: 'Testimonials', scroll: scrollToReviews },
    { url: 'download', name: 'Download', scroll: scrollToDownload },
  ];

  const { window } = props || {};
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        p: 2,
        backgroundColor: '#283C2E',
        color: '#fff',
        opacity: 0.8,
      }}
    >
      <Toolbar className="header-left-logo" sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <Box
          sx={{
            display: { md: 'none', sm: 'flex', xs: 'flex' },
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: 'Suez One',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={logo} alt="logo" width={80} style={{ paddingRight: 20 }} /> TRIP TRADER
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <CloseIcon sx={{ color: '#3C6B49 !important' }} />
          </IconButton>
        </Box>
      </Toolbar>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ fontFamily: 'Poppins-SemiBold', textAlign: 'center' }}>
              <ListItemText
                onClick={item.scroll}
                primary={item.name}
                sx={{ fontFamily: 'Poppins-SemiBold' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              fontFamily: 'Poppins-SemiBold',
              disply: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            <SignInMob href="/#/signin"> Sign In </SignInMob>
            <JoinNowMob href="/#/join"> Join Now </JoinNowMob>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window()?.document.body : undefined;

  return (
    <Box sx={{ display: 'flex', px: 0 }}>
      <AppBar
        position="static"
        sx={{
          background: 'transparent',
          boxShadow: 'none',
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Toolbar className="header-left-logo" sx={{ paddingLeft: 0, paddingRight: 0 }}>
          <Box
            sx={{
              display: { md: 'none', sm: 'flex', xs: 'flex' },
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontFamily: 'Suez One',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={logo} alt="logo" width={80} style={{ paddingRight: 20 }} /> TRIP TRADER
            </Typography>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              fontFamily: 'Suez One',
              display: { xs: 'none', sm: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <img src={logo} alt="logo" width={80} style={{ paddingRight: 20 }} />{' '}
            <p
              style={{
                marginLeft: '2.8rem',
              }}
            >
              TRIP TRADER
            </p>
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
            {navItems?.map((item) => (
              <CustomLink key={item.name} onClick={item.scroll}>
                {item.name}
                {`/${item.url}` === location.pathname ? <Border /> : ''}
              </CustomLink>
            ))}
            <SignIn href="/#/signin"> Sign In </SignIn>
            <JoinNow href="/#/join"> Join Now </JoinNow>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          anchor={'top'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
              background: 'transparent',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
export default HomeHeader;
