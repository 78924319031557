import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier } from 'firebase/auth';
import { authentication } from '../../../firebase';
import { LoaderSpan } from '../../SignIn/Elements';
import { MainBox, UserName } from '../../Elements';
import { signInWithPhoneNumber } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '../../../components/InputFields';
import { getFunction } from '../../../features/auth/action';
import { useMediaQuery, Grid, Avatar } from '@mui/material';
import { updateAccount } from '../../../features/auth/action';
import { uploadImageToS3 } from '../../../features/general/action';
import { Text, FormBox, Edit, IdentityImageGrid } from '../Elements';
import { CustomLabel2 } from '../../../components/InputFields/Elements';
import { OtpVerification } from '../../../components/Modal/OtpVerification';
import '../style.css';
import api from '../../../api';
import DatePicker from './DatePicker';
import Loader from '../../../assets/loader1.gif';
import Sidebar from '../../../components/Sidebar';
import verified from '../../../assets/profile/verified.png';
import editImage from '../../../assets/profile/editImage.png';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches600 = useMediaQuery('(max-width:600px)');

  const { userInfo, loading } = useSelector((state) => state.auth);
  const [loadingImage, setLoadingImage] = useState({ type: '', loader: false });
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    identityProof: '',
    profileUpdateByUser: true,
    phoneCountryCode: 'US',
    firstName: '',
    email: '',
    lastName: '',
    image: '',
    phone: '',
    birthDate: '',
    birthdayObj: null,
    openOtpModal: false,
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const uploadFunction = async (e, type) => {
    console.log('type', type);
    // setLoadingImage(true);
    setLoadingImage({ type: type, loader: true });
    let result = await uploadImageToS3(e);
    if (type === 'image') {
      handleChange('image', result[0]?.imgrUrl);
      // setLoadingImage(false);
      setLoadingImage({ type: '', loader: false });
    } else if (type === 'identityProof') {
      setState((prevState) => ({
        ...prevState,
        identityProof: result[0]?.imgrUrl && result[0]?.imgrUrl,
        identityStatus: 'appliedFor',
      }));
      setLoadingImage({ type: '', loader: false });
      // setLoadingImage(false);
    }
  };

  const getUpdatedUser = () => {
    let body = { name: 'userInfo', url: `/user/getUser/${userInfo?._id}` };
    dispatch(getFunction(body));
  };
  console.log('state', state);
  useEffect(() => {
    setState({
      ...state,
      phoneCountryCode: userInfo?.phoneCountryCode || 'US',
      firstName: userInfo?.firstName,
      email: userInfo?.email,
      lastName: userInfo?.lastName,
      image: userInfo?.image,
      phone: userInfo?.phone ? `+${userInfo?.phone}` : '',
      birthDate: userInfo?.birthDate,
      identityProof: userInfo?.identityProof,
      birthdayObj: {
        month: new Date(userInfo?.birthDate).getMonth() + 1,
        year: new Date(userInfo?.birthDate).getFullYear(),
        day: new Date(userInfo?.birthDate).getUTCDate(),
      },
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUpdatedUser();
  }, []);

  const navigatePage = () => {
    navigate(`/profile/${userInfo?._id}`);
  };
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {},
      },
      authentication
    );
  };
  const updateProfileFunction = () => {
    const phoneNumber = state?.phone ? parsePhoneNumber(state?.phone) : '';
    state['phoneCountryCode'] = state?.phone ? phoneNumber?.country : 'US';
    state['phone'] = state?.phone ? state?.phone?.replace('+', '') : null;
    dispatch(updateAccount(state, navigatePage, 'editProfilePage'));
    handleChange('openOtpModal', false);
  };

  //New Code Start
  const handleSignInWithPhoneNumber = async () => {
    let appVerifier = window.recaptchaVerifier;
    return signInWithPhoneNumber(authentication, state?.phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        toast.success(`Otp sent to ${state?.phone}`, { duration: 4000 });
        handleChange('openOtpModal', true);
      })
      .catch((error) => {
        if (error.message == 'Firebase: TOO_LONG (auth/invalid-phone-number).') {
          toast.error('Invalid phone number.  Please try again', { duration: 4000 });
        } else if (error.message == 'reCAPTCHA has already been rendered in this element') {
          toast.error('Please reload and try again', { duration: 4000 });
        } else {
          toast.error('Please try again.', { duration: 4000 });
        }
        handleChange('openOtpModal', false);
      });
  };

  const updateFunction = async () => {
    setIsLoading(true);

    console.log(state?.phone?.replace('+', '') !== userInfo?.phone);
    if (state?.phone?.replace('+', '') && state?.phone?.replace('+', '') !== userInfo?.phone) {
      generateRecaptcha();
      await api
        .get(`/user/verifyPhoneNumber?phone=${state?.phone?.replace('+', '')}`)
        .then((res) => {
          console.log(res.data.message, 'res.data.message');
          if (res?.data?.[0]?._id !== userInfo?._id) {
            toast.error('This mobile number is already in use.', { duration: 4000 });
          } else {
            return handleSignInWithPhoneNumber();
          }
        })
        .catch((err) => {
          const msg = err.response.data.message || err.response.status || err;
          if (msg == 'No user found.') {
            return handleSignInWithPhoneNumber();
          }
        });
    } else {
      updateProfileFunction();
    }
    setIsLoading(false);
  };
  //New Code END

  // const updateFunction = async () => {
  //   if (state?.phone && state.phone !== userInfo?.phone) {
  //     generateRecaptcha();
  //     await api.get(`/user/verifyPhoneNumber?phone=${state?.phone?.replace("+", "")}`).then((res) => {
  //       console.log(res.data.message,"res.data.message")
  //       if(res?.data?.[0]?._id !== userInfo?._id ){
  //         toast.error('This mobile number is already in use.', { duration: 4000 });
  //       }
  //       // if (res.data.message === 'User found') {
  //       //   toast.error('This mobile number is already in use.', { duration: 4000 });
  //       // }
  //       else {
  //         let appVerifier = window.recaptchaVerifier;
  //         signInWithPhoneNumber(authentication, state?.phone, appVerifier)
  //           .then((confirmationResult) => {
  //             window.confirmationResult = confirmationResult;
  //             toast.success(`Otp sent to ${state?.phone}`, { duration: 4000 });
  //             handleChange('openOtpModal', true);
  //           })
  //           .catch((error) => {
  //             if (error.message == 'Firebase: TOO_LONG (auth/invalid-phone-number).') {
  //               toast.error('Invalid phone number.  Please try again', { duration: 4000 });
  //               handleChange('openOtpModal', false);
  //             } else if (error.message == 'reCAPTCHA has already been rendered in this element') {
  //               toast.error('Please reload and try again', { duration: 4000 });
  //               handleChange('openOtpModal', false);
  //             } else {
  //               toast.error('Please try again.', { duration: 4000 });
  //               handleChange('openOtpModal', false);
  //             }
  //           });
  //       }
  //     }).catch((err) => {
  //       const msg = err.response.data.message || err.response.status || err;
  //       if (msg == "No user found.") {
  //         let appVerifier = window.recaptchaVerifier;
  //         signInWithPhoneNumber(authentication, state?.phone, appVerifier)
  //           .then((confirmationResult) => {
  //             window.confirmationResult = confirmationResult;
  //             toast.success(`Otp sent to ${state?.phone}`, { duration: 4000 });
  //             handleChange('openOtpModal', true);
  //           })
  //           .catch((error) => {
  //             if (error.message == 'Firebase: TOO_LONG (auth/invalid-phone-number).') {
  //               toast.error('Invalid phone number.  Please try again', { duration: 4000 });
  //               handleChange('openOtpModal', false);
  //             } else if (error.message == 'reCAPTCHA has already been rendered in this element') {
  //               toast.error('Please reload and try again', { duration: 4000 });
  //               handleChange('openOtpModal', false);
  //             } else {
  //               toast.error('Please try again.', { duration: 4000 });
  //               handleChange('openOtpModal', false);
  //             }
  //           });
  //       }
  //     });
  //   } else {
  //     updateProfileFunction();
  //   }
  // };
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      <FormBox>
        <div id="recaptcha-container"></div>

        <UserName sx={{ fontSize: '18px', mb: 0 }}>Edit Profile</UserName>
        <Grid sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <div className="avatar-upload">
            <div className="avatar-edit">
              <input
                type="file"
                id="imageUpload"
                onChange={(e) => uploadFunction(e, 'image')}
                accept=".png, .jpg, .jpeg"
              />
              <label htmlFor="imageUpload">
                <img src={editImage} alt="editImage" />
              </label>
            </div>
            <div className="avatar-preview">
              {loadingImage.loader && loadingImage.type === 'image' ? (
                <img src={Loader} alt="loading" />
              ) : (
                <Avatar src={state?.image} sx={{ width: 100, height: 100 }} />
              )}
            </div>
          </div>
        </Grid>
        <Grid container spacing={matches600 ? 0 : 3}>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ my: matches600 ? 1 : 0 }}>
            <Input
              value={state?.firstName}
              title="First Name"
              onChange={(e) => handleChange('firstName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ my: matches600 ? 1 : 0 }}>
            <Input
              title="Last Name"
              value={state?.lastName}
              onChange={(e) => handleChange('lastName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: matches600 ? 1 : 0 }}>
            <Input title="Email Address" value={state?.email} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ my: matches600 ? 1 : 0 }}>
            <CustomLabel2>{'Phone Number'}</CustomLabel2>
            <PhoneInput
              defaultCountry={state?.phoneCountryCode}
              international
              countryCallingCodeEditable={false}
              placeholder="Enter phone number"
              value={state?.phone}
              onChange={(e) => handleChange('phone', e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} sx={{ my: matches600 ? 1 : 0 }}>
            <CustomLabel2>{'Date of Birth'}</CustomLabel2>
            <DatePicker state={state} handleChange={handleChange} />
          </Grid>
          {console.log('state?.identityProof', userInfo?.identityStatus)}
          {userInfo?.identityStatus == 'notVerified' ||
          userInfo?.identityStatus == 'appliedFor' ||
          (userInfo?.identityStatus == 'pending' && state?.identityProof) ? (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: matches600 ? 1 : 0 }}>
              <CustomLabel2>{'Identity Card'}</CustomLabel2>
              <IdentityImageGrid>
                {loadingImage.loader && loadingImage.type === 'identityProof' ? (
                  <img src={Loader} alt="loading" />
                ) : (
                  <img
                    src={state?.identityProof ? state?.identityProof : userInfo?.identityProof}
                    alt="reviewIdentity"
                  />
                )}
              </IdentityImageGrid>
            </Grid>
          ) : (
            ''
          )}

          {userInfo?.identityStatus === 'verified' && (
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: matches600 ? 1 : 0 }}>
              <UserName sx={{ fontSize: '14px' }}>
                <img src={verified} alt="verified" />
                Your ID has been verified!
              </UserName>
            </Grid>
          )}
        </Grid>
        {loadingImage.loader && loadingImage.type === 'identityProof' ? (
          <Text sx={{ textAlign: 'start' }}>Please Wait Image Uploading</Text>
        ) : (
          <>
            {userInfo?.identityStatus !== 'verified' && (
              <Text sx={{ textAlign: 'start' }}>
                <span className="identity-upload">
                  <input
                    type="file"
                    id="identityUpload"
                    onChange={(e) => uploadFunction(e, 'identityProof')}
                    accept=".png, .jpg, .jpeg"
                  />
                  <label style={{ cursor: 'pointer' }} htmlFor="identityUpload">
                    Click here
                  </label>
                </span>{' '}
                to upload a {state?.identityProof === undefined ? '' : 'new'} ID card.
              </Text>
            )}
          </>
        )}
        <Grid sx={{ display: 'flex', flexDirection: 'row', pb: 2 }}>
          <Edit
            onClick={updateFunction}
            type="submit"
            variant="contained"
            disableripple
            // disabled={state?.firstName === '' || state?.lastName === '' ? true : false}
            disabled={loading || isLoading || state?.firstName === '' || state?.lastName === ''}
            sx={{ px: { lg: 4 }, py: 2.4 }}
          >
            {loading || isLoading ? (
              <LoaderSpan>
                <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                Saving...
              </LoaderSpan>
            ) : (
              'Save Profile'
            )}
          </Edit>
        </Grid>
      </FormBox>
      <OtpVerification
        phoneNumber={state?.phone}
        updateProfileFunction={updateProfileFunction}
        open={state?.openOtpModal}
        handle={handleChange}
        stateName={'openOtpModal'}
      />
    </MainBox>
  );
};
export default EditProfile;
