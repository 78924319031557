import React, { useEffect } from "react";
import moment from "moment";
import star from "../../../assets/profile/star.png"
import dispute from "../../../assets/profile/dispute.png"

import { useNavigate } from "react-router-dom";
import { RightSideRating } from "../../Elements"
import { Avatar, Box, Grid } from "@mui/material";
import { getFullName } from "../../../utills/constants";
import { ReviewBox, Message, DisputeText } from "../Elements"
import { Text, Edit, TransparentBtn, Delete } from "../Elements"
import { Date, ReviewUserDetail, DateSpan } from "../Private/Elements"
import { useSelector } from "react-redux";
const CustomReviewBox = ({ handleChange, res }) => {
    const navigate = useNavigate()

    const { blocked, blockedBy } = useSelector(state => state.auth)

    const handleModals = (type) => {
        if (type === 'reply') { handleChange('openReviewModal', true); handleChange('replied', res) }
        else if (type === 'dispute') { handleChange('openDisputeModal', true); handleChange('dispute', res) }
        else if (type === 'edit') { handleChange('openEditModal', true); handleChange('edited', res); handleChange('editData', res?.messages[1]) }
        else if (type === 'delete') { handleChange('openDeleteReview', true); handleChange('delete', res); handleChange('deletemsgid', res?.messages[1]) }
        else if (type === 'deleteReply') { handleChange('openDeleteReply', true); handleChange('delete', res); handleChange('deletemsgid', res?.messages[1]) }
    }

    const checkIfBlock = (id) => {
        const a = blocked || [];
        const b = blockedBy || [];
        const userExists = [...a, ...b]?.some((user) => user?.userId?._id == id);
        if (userExists) {
            return true
        } else {
            return false
        }
    }
    return <><ReviewBox>
        <Grid sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <ReviewUserDetail>
                <Avatar src={(res?.guestId == null || checkIfBlock(res?.guestId?._id)) ? "" : res?.guestId?.image} alt={'Photo1'} sx={{ width: 40, height: 40 }} />
                <Box sx={{ pl: 1.5, cursor: 'pointer' }}>
                    <p onClick={() => (res?.guestId == null || checkIfBlock(res?.guestId?._id)) ? {} : navigate(`/profile/public/${res?.guestId?._id}`)}>{(res?.guestId == null || checkIfBlock(res?.guestId?._id)) ? 'Trip Trader User' : getFullName(res?.guestId)}</p>
                    <DateSpan >Posted on {moment(res?.createdAt).format('DD MMM, yyyy')} {res?.messages[0]?.isEdited && <i>• Edited</i>}</DateSpan>
                </Box>
            </ReviewUserDetail>
            <RightSideRating>
                <img src={star} className="imageShadow" alt={'star'} style={{ marginBottom: 4, marginRight: 3 }} /> {res?.guestRating}
            </RightSideRating>
        </Grid>
        <Message>{res?.messages[0]?.message}</Message>
        {res?.status === "dispute" && <DisputeText><img src={dispute} style={{ marginRight: 4 }} alt="dispute" />You disputed this review on {moment(res?.disputeOpenDate).format('DD MMM, yyyy')}</DisputeText>}
        {res?.messages?.length > 1 && res?.guestId != null && !checkIfBlock(res?.guestId?._id) && res?.status === "active" && <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            <TransparentBtn sx={{ width: 'auto', color: 'rgba(17, 17, 17, 0.6)', py: 1, px: 2 }} onClick={() => handleModals('dispute')}>Dispute Review</TransparentBtn>
        </Grid>}
        {res?.status === "active" && res?.guestId != null && !checkIfBlock(res?.guestId?._id) && res?.messages?.length === 1 && <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
            <Edit onClick={() => handleModals('reply')}>Reply</Edit>
            <TransparentBtn sx={{ color: 'rgba(17, 17, 17, 0.6)', px: 2 }} onClick={() => handleModals('dispute')}>Dispute</TransparentBtn>
        </Grid>}
    </ReviewBox>
        {res?.messages?.length > 1 && res?.status !== "dispute" &&
            <Grid sx={{ display: 'flex', flexDirection: 'column', width: '85%', mx: 'auto', mb: 1, border: '1px solid #E5E5E5', borderRadius: '5px', padding: 2 }}>
                <Date sx={{ mb: 1 }}>You replied on {moment(res?.messages[1]?.createdAt).format('DD MMM, yyyy')} </Date>
                <Text sx={{ textAlign: 'start' }}>{res?.messages[1]?.message}</Text>
                {res?.guestId != null && !checkIfBlock(res?.guestId?._id) && <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Edit onClick={() => handleModals('edit')}>Edit</Edit>
                    <Delete sx={{ width: 'auto' }} onClick={() => handleModals('deleteReply')}>Delete</Delete>
                </Grid>}
            </Grid>
        }
    </>
}
export default CustomReviewBox