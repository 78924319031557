import React from 'react';

import { CancelButton } from '../Button';
import { Box, Modal } from '@mui/material';
import { Heading, style, RedButton } from './Elements';

const CancelSubscription = ({ open, cancelFunction, handleChange, returnEndDate }) => {
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 550,
            height: 'max-content',
            pb: 4,
            pt: 2,
            px: 2,
            alignItems: 'flex-start',
          }}
        >
          <Heading sx={{ my: 2, marginLeft: '16px' }}>Cancel Subscription</Heading>
          {returnEndDate()}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              mt: 2,
              marginLeft: '16px',
              overflowX: 'hidden',
              width: 'fit-content',
            }}
          >
            <RedButton
              sx={{ mr: 2 }}
              onClick={cancelFunction}
              type="submit"
              variant="contained"
              disableripple
            >
              Yes, Cancel Now
            </RedButton>
            <CancelButton
              type="submit"
              onClick={() => handleChange('open', false)}
              variant="contained"
              disableripple
            >
              No, keep it
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CancelSubscription;
