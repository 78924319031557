import React, { useEffect, useState } from 'react';

import Inbox from './Inbox';
import InboxMobile from './InboxMobile';
import Conversation from './Conversation';
import Sidebar from '../../components/Sidebar';
import ConversationMobile from './ConversationMobile';

import { MainBox } from './Elements';
import { useMediaQuery, Grid } from '@mui/material';
import { FireStore } from '../../utills/FireStore';
import { saveData } from '../../features/auth/reducer';
import { useSelector, useDispatch } from 'react-redux';

const Messages = () => {
  const dispatch = useDispatch();

  const matches600 = useMediaQuery('(max-width:600px)');

  const { userInfo, inbox = [] } = useSelector((state) => state.auth);

  const [state, setState] = useState({ isMobile: false, selectedChat: {}, messages: [] });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [data, setData] = useState([]);

  const getInbox = async () => {
    const inbox = await FireStore.getAllCurrentUserRooms(userInfo?._id);
    dispatch(saveData({ name: 'inbox', data: inbox }));
    setData([...inbox]);
  };
  useEffect(() => {
    setData([...inbox]);
    // eslint-disable-next-line
  }, [inbox]);

  useEffect(() => {
    getInbox();
    // eslint-disable-next-line
  }, []);
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      {matches600 ? (
        <Grid container>
          {!state?.isMobile ? (
            <InboxMobile
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
          ) : (
            <ConversationMobile
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid container>
            <Inbox
              data={data}
              setData={setData}
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
            <Conversation
              getInbox={getInbox}
              parentState={state}
              handleParentChange={handleChange}
            />
          </Grid>
        </>
      )}
    </MainBox>
  );
};
export default Messages;
