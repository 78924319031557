import React from "react";

import { Text } from "../Elements";
import { signUpData } from "../../data"
import { useLocation } from "react-router-dom";
import { LeftGrid, LeftHeading, Centered, CenteredCA } from "./Elements";

import Logo from "../../components/Logo"
import bg from "../../assets/signin/leftside.png"
import correct from "../../assets/signin/correct.png"

const LeftPanel = () => {
    const location = useLocation();
    const GetCentered = ({ children }) => {
        if (location.pathname === "/join") { return <CenteredCA>{children}</CenteredCA> }
        else if (location.pathname === "/signin") { return <Centered >{children}</Centered> }
    }
    return <LeftGrid item xs={12} sm={12} md={6} lg={6} className={location.pathname === "/join" ? "col2signup" : "col2signup"}>
        <img src={bg} alt="bg" />
        <GetCentered>
            <Logo />
            <div >
                <LeftHeading>Join the Trip Trader community <br /> to <b>unlock full access</b></LeftHeading>
                {signUpData.map((res, i) => {
                    return <Text key={i}><img alt="correctIcon" src={correct} style={{ paddingRight: 15 }} />{res}</Text>
                })}
            </div>
        </GetCentered>
    </LeftGrid>

}
export default LeftPanel