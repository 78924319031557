import React from 'react';

import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { PrivateRoute } from './PrivateRoute';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import News from '../pages/News';
import SignIn from '../pages/SignIn';
import Search from '../pages/Search';
import Support from '../pages/Support';
import Terms from '../pages/Misc/Terms';
import Settings from '../pages/Settings';
import Messages from '../pages/Messages';
import Loader from '../components/Loader';
import CreateTrip from '../pages/CreateTrip';
import SavedTrips from '../pages/SavedTrips';
import TermsApp from '../pages/Misc/TermsApp';
import TradeOffers from '../pages/TradeOffers';
import BuyPlan from '../pages/Settings/BuyPlan';
import NewsDetails from '../pages/News/details';
import Notification from '../pages/Notification';
import FreePlan from '../pages/Settings/FreePlan';
import UploadImage from '../pages/Join/UploadImage';
import EditTrip from '../pages/CreateTrip/EditTrip';
import PublicProfile from '../pages/Profile/Public';
import ConfirmedTrips from '../pages/ConfirmedTrips';
import ForgetPassword from '../pages/ForgetPassword';
import PrivateProfile from '../pages/Profile/Private';
import CreateAccount from '../pages/Join/CreateAccount';
import PrivacyPolicy from '../pages/Misc/PrivacyPolicy';
import BlockedUsers from '../pages/Settings/BlockedUsers';
import SupportSuccess from '../pages/Support/SuccessPage';
import AccountVerified from '../pages/Join/AccountVerified';
import PrivacyPolicyApp from '../pages/Misc/PrivacyPolicyApp';
import ChangePassword from '../pages/Settings/ChangePassword';
import SubscriptionPlan from '../pages/Join/SubscriptionPlan';
import SuccessCreateTrip from '../pages/CreateTrip/SuccessPage';
import SuccessPageEdit from '../pages/CreateTrip/SuccessPageEdit';
import EditProfile from '../pages/Profile/LoggedInUser/EditProfile';
import ManageSubscription from '../pages/Settings/ManageSubscription';
import IdentityVerification from '../pages/Join/IdentityVerification';
import CardManagment from '../pages/Settings/CardManagment';
import AddNewCard from '../pages/Settings/AddNewCard';
import AddCardContainer from '../pages/Settings/AddCardContainer';

const Component = () => {
  const { loading } = useSelector((state) => state.loader);

  return (
    <>
      <Toaster position="top-right" toastOptions={{ style: { fontFamily: 'Poppins-Medium' } }} />
      <Routes>
        <Route path="/verifyEmail/:mode/:id" exact element={<AccountVerified />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/search" exact element={<Search />} />
        <Route path="/signin" exact element={<SignIn />} />
        <Route path="/join" exact element={<CreateAccount />} />
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/privacypolicyapp" element={<PrivacyPolicyApp />} />
        <Route exact path="/termsandconditions" element={<Terms />} />
        <Route exact path="/termsandconditionsapp" element={<TermsApp />} />

        {/* <Route element={<PrivateRoute />}> */}
        <Route exact path="/uploadImage" element={<UploadImage />} />
        {/* </Route> */}
        <Route element={<PrivateRoute />}>
          <Route exact path="/plan" element={<SubscriptionPlan />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/verifyId" element={<IdentityVerification />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/manageSubscription" element={<ManageSubscription />} />
          <Route exact path="/manage/cards" element={<CardManagment />} />
          <Route exact path="/manage/addnewcard" element={<AddCardContainer />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/freePlan" element={<FreePlan />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/buyplan/:plan/:clientSecret/:subscriptionId" element={<BuyPlan />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/changepassword" element={<ChangePassword />} />
        </Route>
        <Route path="/forgetpassword" exact element={<ForgetPassword />} />
        <Route path="/profile/:id" exact element={<PrivateProfile />} />
        <Route element={<PrivateRoute />}>
          <Route path="/profile/edit" exact element={<EditProfile />} />
        </Route>
        <Route path="/settings" exact element={<Settings />} />
        <Route element={<PrivateRoute />}>
          <Route path="/settings/blockedusers" exact element={<BlockedUsers />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/createTrip" exact element={<CreateTrip />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/updateTrip/success/:id" exact element={<SuccessPageEdit />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/createTrip/success/:id" exact element={<SuccessCreateTrip />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/editTrip/:id" exact element={<EditTrip />} />
        </Route>
        <Route path="/profile/public/:id" exact element={<PublicProfile />} />
        <Route element={<PrivateRoute />}>
          <Route path="/support" exact element={<Support />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/support/success" exact element={<SupportSuccess />} />
        </Route>
        <Route path="/notification" exact element={<Notification />} />
        <Route element={<PrivateRoute />}>
          <Route path="/savedTrips" exact element={<SavedTrips />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/tradeOffer" exact element={<TradeOffers />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/confirmedTrips" exact element={<ConfirmedTrips />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/messages" element={<Messages />} />
        </Route>
        <Route path="/news" exact element={<News />} />
        <Route path="/news/details" exact element={<NewsDetails />} />
      </Routes>
      {loading && <Loader />}
    </>
  );
};
export default Component;
