import React from 'react';
import cross from '../../assets/cross.png';

import { CustomCrossButton } from './index';
import { useNavigate } from 'react-router-dom';

import './index.css';

export const CrossDesktopButton = () => {
  const navigate = useNavigate();
  return (
    <div className="desktopCrossButton">
      <CustomCrossButton onClick={() => navigate(-1)}>
        <img src={cross} alt="cross" />
      </CustomCrossButton>
    </div>
  );
};

export const CrossMobileButton = () => {
  const navigate = useNavigate();
  return <img src={cross} alt="cross" onClick={() => navigate(-1)} />;
};
