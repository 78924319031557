
import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

export const UploadHeading = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '16px',
    color: '#101B10',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    "& :nth-child(1)": {
        marginRight: 16,
    },
    '@media(max-width: 600px)': {
        "& :nth-child(2)": {
            fontSize: '14px'
        },

    },
}));
export const DashedUploadBox = styled(Box)(() => ({
    background: "#EEF6EF",
    borderRadius: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 224,
    paddingTop: 30,
    border: "1px dashed #3C6B49",
    '@media(max-width: 1000px)': {
        height: 180,
    },
}));
export const AddGrid = styled(Grid)(() => ({
    background: "#EEF6EF",
    borderRadius: 10,
    marginRight: '10px',
    width: '180px',
    height: '140px',
    cursor: 'pointer',
    border: "1px dashed #3C6B49",
    display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5,
    '@media(max-width:800px)': {
        height: '21vh',
    },
}));
export const UploadText = styled('p')(() => ({
    fontFamily: 'PoppinsLight',
    fontStyle: 'normal',
    fontWeight: 500,
    color: "#1A5B2C",
    marginBottom: 2,
    fontSize: '.9rem',
    letterSpacing: "0.0168em",
    cursor: 'pointer',
    '@media(max-width: 600px)': {
        fontSize: '.7rem',

    },

}));
export const FormBox = styled(Box)(() => ({
    overflowY: 'auto',
    width: '38%',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '94vh',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 1200px)': {
        height: 'max-content',
        width: '50%',
    },
    '@media(max-width: 800px)': {

        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
        padding: '10px 10px'
    },
}))
export const Delete = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#B93B3B',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    borderRadius: '5px',
    height: '32px',
    cursor: 'pointer',
    padding: '10px',
    '@media(max-width: 600px)': {
        padding: 0,
        width: 'max-content',
        textAlign: 'center',
        height: 'max-content',
    },
}));
export const Edit = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: '#3C6B49',
    borderRadius: '5px',
    border: 'none',
    color: '#FAFAFA',
    height: '38px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    cursor: 'pointer',
    padding: '16px 20px 16px 20px',
    '@media(max-width: 600px)': {
        width: 'max-content',
        textAlign: 'center',
        fontSize: '12px',
        padding: '10px 10px'

    },
}));
export const TransparentBtn = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '5px',
    color: '#30563A',
    border: '1px solid #DFDFDF',
    height: '40px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    marginRight: 10,
    cursor: 'pointer',
    padding: '10px ',
    '@media(max-width: 600px)': {
        width: 'max-content',
        textAlign: 'center',
        fontSize: '12px',
        padding: '10px 10px'
    },
}));
export const Text = styled(Box)(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#111111',
    "& span": {
        textDecorationLine: "underline",
        color: "#1E3625",
        fontWeight: 500,
        textUnderlineOffset: "3px",
        cursor: 'pointer',
    },
    '@media(max-width: 600px)': {
        fontSize: 'small',
    },
}));
export const FormBox2 = styled(Box)(() => ({
    width: '38%',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '94vh',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 1200px)': {
        height: 'max-content',
        width: '50%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
        padding: '10px 10px'
    },
}));
export const FormBox3 = styled(Box)(() => ({
    width: '38%',
    height: 'max-content',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '@media(max-width: 1200px)': {
        // height: 'max-content',
        width: '50%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
        padding: '10px 10px'
    },
}))
