import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featuredTrips: [],
  featuredReviews: [],
  paymentRequest: {},
  plans: [],
  types: [],
  species: [],
  states: [],
};
const generalSlice = createSlice({
  name: 'generalService',
  initialState,
  reducers: {
    saveFeaturedData: (state, { payload }) => {
      state.featuredTrips = payload.trips;
      state.featuredReviews = payload.reviews;
      state.footer = payload.footer;
    },
    saveData: (state, { payload }) => {
      state[payload.name] = payload.data;
    },
    paymentrequest: (state, { payload }) => {
      state[payload.name] = payload.data;
    },
    failed: (state) => {
      state.featuredTrips = [];
      state.featuredReviews = [];
    },
    saveDropdowns: (state, { payload }) => {
      state.types = payload.types;
      state.species = payload.species;
      state.states = payload.states;
    },
  },
});
const generalReducer = generalSlice.reducer;
export default generalReducer;

export const { saveDropdowns, saveFeaturedData, failed, saveData, paymentrequest } =
  generalSlice.actions;
