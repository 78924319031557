import React, { useEffect, useState } from 'react';
import moment from 'moment';
import speciesIcon from '../../assets/home/species.png';

import { useSelector } from 'react-redux';
import { daysDropdown } from '../../data';
import { getZero } from '../../utills/constants';
import { useMediaQuery, Grid, Button } from '@mui/material';
import { UserName, InstructionsSpan } from '../Elements';
import { Text, FormBox, Edit, TransparentBtn } from './Elements';
import { CustomLabel2 } from '../../components/InputFields/Elements';
import { UnAvailableDays } from '../../components/Modal/UnAvailableDays';
import { DateRangeInput } from '../../components/InputFields/DateRangeInput';
import {
  Input,
  Dropdown,
  EmptyDropdown,
  CustomCheckbox,
  SpeciesDropdown,
} from '../../components/InputFields/CreateTripFormFields';

const Step1 = ({ handleChange, state, selectedDayRange, setSelectedDayRange }) => {
  const matches600 = useMediaQuery('(max-width:600px)');
  console.log('selectedDayRange', selectedDayRange);
  const [isChange, setIsChange] = useState(false);

  const { types, species, states } = useSelector((state) => state.general);
  console.log('types', types);
  const [_state, setState] = useState({
    isEdit: false,
    openUnAvailableDays: false,
    unAvailableDaysText: '',
    speciesData: [],
    typesData: [],
  });

  const _handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOpenDaysModal = () => {
    if (selectedDayRange?.from !== null || selectedDayRange?.to !== null) {
      _handleChange('openUnAvailableDays', true);
      setSelectedDayRange({ ...selectedDayRange });
    }
  };

  useEffect(() => {
    setIsChange(false);
    const filteredSpecies = species?.filter((elm) => elm?.type?.name === state?.tradeType);
    _handleChange('speciesData', filteredSpecies);
    handleChange('species', state?.species);
    setTimeout(() => {
      setIsChange(true);
    }, 500);
  }, [state.tradeType]);
  console.log('state.tradeType', state.tradeType);

  // useEffect(() => {  
  //   handleChange('unAvailableDays', {});
  //   // eslint-disable-next-line
  // }, [selectedDayRange]);

  const handleStep = () => {
    if (
      state?.tradeType &&
      state?.locationState &&
      state?.locationCity &&
      state?.species &&
      state?.returnActivity &&
      state.durationValue
    ) {
      handleChange('steps', 2);
    }
  };
  const checkValidity = () => {
    if (
      selectedDayRange['from'] !== null &&
      state?.tradeType &&
      state?.locationState &&
      state?.locationCity &&
      state?.species &&
      state?.returnActivity &&
      state.durationValue
    )
      return true;
    else return false;
  };
  const getUnAvailableDays = () => {
    return state?.unAvailableDays?.dayWeekText + ' ' + state?.unAvailableDays?.excludeDateText;
  };

  const handleDurationValue = (e) => {
    const input = e.target.value;
    if (selectedDayRange?.from == null && selectedDayRange?.to == null) {
      if (/^\d*$/.test(input)) {
        handleChange('durationValue', e.target.value);
      }
    } else if (selectedDayRange?.from != null && selectedDayRange?.to != null) {
      const fromDate = moment(
        selectedDayRange?.from?.year +
          '-' +
          getZero(selectedDayRange?.from?.month) +
          '-' +
          selectedDayRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment(
        selectedDayRange?.to?.year +
          '-' +
          getZero(selectedDayRange?.to?.month) +
          '-' +
          selectedDayRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      const _input = state.durationTitle == 'weeks' ? Number(input) * 7 : input;
      if (_input > diffInDays) {
        setSelectedDayRange({ from: null, to: null });
        if (/^\d*$/.test(input)) {
          handleChange('durationValue', e.target.value);
        }
      } else {
        if (/^\d*$/.test(input)) {
          handleChange('durationValue', e.target.value);
        }
      }
    }
  };

  const handleDurationTitle = (name, value) => {
    if (selectedDayRange?.from == null && selectedDayRange?.to == null) {
      handleChange('durationTitle', value);
    } else if (selectedDayRange?.from != null && selectedDayRange?.to != null) {
      const fromDate = moment(
        selectedDayRange?.from?.year +
          '-' +
          getZero(selectedDayRange?.from?.month) +
          '-' +
          selectedDayRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment(
        selectedDayRange?.to?.year +
          '-' +
          getZero(selectedDayRange?.to?.month) +
          '-' +
          selectedDayRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      const _input = value == 'weeks' ? Number(state.durationValue) * 7 : state.durationValue;
      if (_input > diffInDays) {
        setSelectedDayRange({ from: null, to: null });
        handleChange('durationTitle', value);
      } else {
        handleChange('durationTitle', value);
      }
    }
  };

  useEffect(() => {
    const uniqueCategoryNames = [...new Set(species?.map((item) => item.type.name))];
    const filteredCategoriesArray = types?.filter((item) =>
      uniqueCategoryNames?.includes(item.name)
    );
    _handleChange('typesData', filteredCategoriesArray);
  }, []);

  const clearTheDate = () => {
    setSelectedDayRange({
      from: null,
      to: null,
    });
    handleChange('unAvailableDays', {
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      allUnavailableDates: [],
      excludeSpecificDates: [],
    });
  };
  return (
    <FormBox sx={{ width: '40%' }}>
      <UserName sx={{ fontSize: '18px' }}>Create New Trip</UserName>
      <Grid container spacing={matches600 ? 0 : 1}>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
          <CustomLabel2>I want to trade...</CustomLabel2>
          <Dropdown
            value={state.tradeType}
            placeholder="Example: Central NC Whitetail Hunting"
            data={types}
            handleChange={handleChange}
            handleFor="tradeType"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomLabel2>Located in... </CustomLabel2>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={8} lg={7}>
              <Input
                value={state.locationCity}
                placeholder={'Example: Southeastern'}
                onChange={(e) => handleChange('locationCity', e.target.value)}
              />
            </Grid>
            <Grid item xs={4} lg={5}>
              <Dropdown
                value={state.locationState}
                placeholder="State"
                data={states}
                handleChange={handleChange}
                handleFor="locationState"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomLabel2>Please enter the species </CustomLabel2>
          {isChange ? (
            <SpeciesDropdown
              value={state.species}
              label="Any species"
              title="Species"
              data={_state.speciesData}
              handleChange={handleChange}
              handleFor="species"
              placeholder="Example: Whitetail Deer"
              icon={speciesIcon}
            />
          ) : (
            <EmptyDropdown placeholder="Example: Whitetail Deer" />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Input
            value={state.returnActivity}
            title="In return for..."
            placeholder="Example: Florida Alligator Hunting"
            onChange={(e) => handleChange('returnActivity', e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: "0px !important" }}>
                <CustomCheckbox value={state.acceptTradeOffers} handleChange={handleChange} handleFor="acceptTradeOffers" title="Accept other trade offers" />
            </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomLabel2>Trip Duration </CustomLabel2>
          <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <Input placeholder={'3'} value={state.durationValue} onChange={handleDurationValue} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3}>
              <Dropdown
                value={state.durationTitle}
                placeholder={'Days'}
                data={daysDropdown}
                handleChange={handleDurationTitle}
                handleFor="durationTitle"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomLabel2>Trip Availability </CustomLabel2>
          <InstructionsSpan>Guests will be able to choose between these dates.</InstructionsSpan>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DateRangeInput
            state={state}
            title=""
            sx={{ width: '100%' }}
            width="100%"
            handleChange={handleChange}
            selectedDayRange={selectedDayRange}
            setSelectedDayRange={setSelectedDayRange}
          />
        </Grid>
        <button className="ClearDates" onClick={clearTheDate}>
          Clear Dates
        </button>
      </Grid>

      {state?.unAvailableDays?.excludeDateText || state?.unAvailableDays?.dayWeekText ? (
        <Text sx={{ textAlign: 'start', mt: 2 }}>
          <CustomLabel2>Unavailable Days</CustomLabel2>
          {getUnAvailableDays()}
          <span
            style={{ marginLeft: 20 }}
            onClick={() => {
              _handleChange('isEdit', true);
              _handleChange('openUnAvailableDays', true);
            }}
          >
            Edit
          </span>
        </Text>
      ) : (
        <Text sx={{ textAlign: 'start', mt: 2 }} onClick={handleOpenDaysModal}>
          <span>Set unavailable days</span>
        </Text>
      )}
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 2,
          mb: 1,
          justifyContent: 'flex-end',
        }}
      >
        <TransparentBtn sx={{ px: { lg: 4 } }}>Cancel</TransparentBtn>
        <Edit onClick={handleStep} sx={{ opacity: checkValidity() ? 1 : 0.5 }}>
          Next: Add Photos
        </Edit>
      </Grid>
      <UnAvailableDays
        grandState={state}
        open={_state.openUnAvailableDays}
        dateRange={selectedDayRange}
        stateName="openUnAvailableDays"
        parentState={_state}
        handle={_handleChange}
        handleState={handleChange}
      />
    </FormBox>
  );
};
export default Step1;
