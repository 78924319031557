import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import coverImage from "../../../assets/profile/coverImage.png"

export const LeftGrid = styled(Grid)(() => ({
    background: '#F9F9F9',
    paddingLeft: "6px !important",
    height: '95vh'
}));
export const RightGrid = styled(Grid)(() => ({
    paddingLeft: "8px !important",
    paddingRight: "8px !important",
}));
export const CoverGrid = styled(Grid)(({ image }) => ({

    height: '22vh',
    backgroundImage: `url(${image || coverImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: '100%'

}));
export const MainGrid = styled(Grid)(() => ({
    width: '85%',
    margin: 'auto',
    height: '100vh',
    background: '#F9F9F9',
    justifyContent: 'space-evenly',
    '@media(max-width: 600px)': {
        width: '93%',
    },
}));
export const FollowersHeading = styled(Grid)(() => ({
    cursor: 'pointer',
    display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around', height: '65px',
    '@media(max-width: 900px)': {
        height: '50px'
    },
}));
export const FollowersRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 15px',
    alignItems: 'center',
    width: '100%',
    cursor: 'pointer',
    '@media(max-width: 900px)': {
        padding: '10px',
        width: '35vw',
    },
    '@media(max-width: 700px)': {
        width: '45vw',
    },
    '@media(max-width: 600px)': {
        width: '65vw',
    },
    '@media(max-width: 400px)': {
        width: '80vw',
    },
}));
export const NameSpan = styled('span')(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '13px',
    color: '#111111',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}));
export const GreenText = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    cursor:'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#3C6B49'
}));
export const MessageBtn = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#3C6B49',
    padding: '8px 30px',
    flex: 'none',
    order: 0,
    background: '#FFFFFF',
    border: '1px solid #3C6B49',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '30px',
    height: 'max-content',
    cursor: 'pointer'
}));
export const FollowBtn = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '21px',
    color: 'white',
    padding: '8px 30px',
    flex: 'none',
    order: 0,
    background: '#3C6B49',
    border: 'none',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '30px',
    height: 'max-content',
    cursor: 'pointer',
    display: 'flex', flexDirection: 'row'
}));
export const FollowButton = styled('span')(() => ({
    fontStyle: 'normal',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '21px',
    color: 'white',
    flex: 'none',
    width: '34px',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    height: '34px',
    order: 0,
    background: '#3C6B49',
    border: 'none',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '30px',
    cursor: 'pointer'
}));
export const FollowerCountRow = styled(Box)(() => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
}));
export const FollowerBox = styled(Box)(() => ({
    marginTop: 7,
    marginBottom: 7,
    borderRadius: "5px",
    marginRight: 25,
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
    alignItems: 'center',
}));
export const Count = styled(Box)(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    marginRight: 6,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3C6B49',
}));
export const ReviewTabs = styled(Grid)(() => ({
    display: 'flex', flexDirection: 'row',
    justifyContent: 'space-around', height: '50px', width: '60%',
    "& span": {
        fontFamily: 'Poppins-SemiBold',
    },
    '@media(max-width: 900px)': {
        width: '100%',
    },
}));
export const Date = styled('span')(() => ({
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    color: '#111111',
    opacity: 0.6,

}));
export const DateSpan = styled('span')(() => ({
    display: 'flex', flexDirection: 'row',
    justifyContent: 'center', alignItems: 'center',
}))
export const TripBox = styled(Box)(() => ({
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: "5px",
    padding: 20,
    marginBottom: 10,
    '@media(max-width: 400px)': {
        padding: 10,
    },

}));
export const ReviewUserDetail = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    "& :nth-child(1)": {
        fontFamily: 'Poppins-SemiBold',
    },
    "& :nth-child(2)": {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '100%',
        justifyContent: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        "& :nth-child(1)": {
            color: '#3C6B49',
            fontFamily: 'Poppins-SemiBold',
            marginTop: 4,
            marginBottom: 4,
        },
        "& :nth-child(2)": {
            fontFamily: 'Poppins-Medium',
            color: '#111111',
            opacity: 0.6,
            display: 'flex',
            flexDirection: 'row',
            '@media(max-width: 450px)': {
                fontSize: 'small',
                flexDirection: 'column',
                alignItems: 'baseline'
            },
            "& :nth-child(1)": {
                fontFamily: 'Poppins-Medium',
                color: '#111111',
                display: 'flex', flexDirection: 'row',
                '@media(max-width: 500px)': {
                    fontSize: 'small',
                },
            }
        }
    },
}));
export const MainReviewGrid = styled(Grid)(() => ({
    paddingLeft: 30,
    paddingRight: 30,
    '@media(max-width: 600px)': {
        paddingLeft: 10,
        paddingRight: 10,
    },
}))
export const ScrollAbleGrid = styled(Grid)(() => ({
    overflowY: "auto",
    maxHeight: "40vh",
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '8px',
        marginLeft: '-4px'
    },
    '&::-webkit-scrollbar-track': {
        background: "white",
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#D9D9D9',
    },
    '@media(max-width: 1000px)': {
        maxHeight: '100%',

    },
}));

