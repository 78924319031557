import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { secretStripeKey } from '../../utills/constants';

import './stripe.css';
import './style.css';
import AddNewCard from './AddNewCard';

function AddCardContainer() {
  const promise = loadStripe(secretStripeKey);
  return (
    <Elements zipCode={false} stripe={promise}>
      <AddNewCard />
    </Elements>
  );
}

export default AddCardContainer;
