import React, { useEffect, useState } from 'react';

import { dateStyles } from '../../components/InputFields/styles';
import { InputAdornment, TextField, FormHelperText, Popover } from '@mui/material';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { GreenButton, CancelButton } from '../../components/InputFields/Elements';

import moment from 'moment';
import calender from '../../assets/createTrip/calender.png';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { getZero, getDateRangeA } from '../../utills/constants';

export const DateSingleInput = ({ state, handleChange, selectedTrip }) => {
  console.log('state?.preferredDates', state?.preferredDates);
  console.log('state?.defaultCalenderValue', state?.defaultCalenderValue);
  console.log('state', state);
  const classes = dateStyles();
  const getFormatedResult = () => {
    var test = [],
      name = [],
      dateformat = 'YYYY-MM-DD';
    state?.selectedDay?.forEach((res) => {
      const date = res?.year + '-' + getZero(res?.month) + '-' + getZero(res?.day);
      test.push(date);
    });
    var result = test
      .sort(function (a, b) {
        return new Date(a) - new Date(b);
      })
      .reduce(function (acc, val) {
        var present,
          date = moment.utc(val, dateformat);
        acc.forEach(function (arr, index) {
          if (present) return;
          else if (
            arr.indexOf(date.clone().subtract(1, 'day').format(dateformat)) > -1 ||
            arr.indexOf(date.clone().add(1, 'day').format(dateformat)) > -1
          ) {
            present = true;
            arr.push(val);
          }
        });

        if (!present) acc.push([val]);
        return acc;
      }, []);
    result.forEach(function (arr, index) {
      if (arr.length > 1) {
        let custom = `${moment.utc(arr[0]).format('MMM DD, YYYY')} - ${moment
          .utc(arr[arr.length - 1])
          .format('MMM DD, YYYY')}`;
        name.push(custom);
      } else {
        name.push(moment.utc(arr[0]).format('MMM DD, YYYY'));
      }
    });

    return name.toString();
  };
  const handleDate = (data) => {
    if (data.length === 0) {
      data = state.defaultCalenderValue;
    }
    handleChange('maxDateError', false);

    const _value = data[data.length - 1];
    const totalDays =
      selectedTrip?.duration?.title == 'weeks'
        ? selectedTrip?.duration?.value * 7
        : selectedTrip?.duration?.value;
    const dateFrom = moment
      .utc(`${_value.month} ${_value.day}, ${_value.year}`)
      .format('DD MMM YYYY');
    const range = getDateRangeA(dateFrom, totalDays, state.allUnavailableDates);
    const maxDateCheck = moment
      .utc(`${state?.maxDate?.month} ${state?.maxDate?.day}, ${state?.maxDate?.year}`)
      .format('DD MMM YYYY');
    const hasGreaterDates = range?.some((date) => new Date(date) > new Date(maxDateCheck));
    if (!hasGreaterDates) {
      const arr = [];
      range.forEach((res) => {
        console.log('hello this is response', res);
        arr.push({
          month: new Date(res).getMonth() + 1,
          year: new Date(res).getFullYear(),
          day: new Date(res).getDate(),
        });
      });
      console.log('hello this is response in arr', arr);
      //   setSelectedDay(arr);
      handleChange('selectedDay', arr);
      handleChange('preferredDates', range);
      handleChange('defaultCalenderValue', arr);
      handleChange('preferredDateError', false);
    } else {
      handleChange('maxDateError', true);
      handleChange('preferredDates', []);
      handleChange('defaultCalenderValue', []);
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    handleChange('maxDateError', false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const applyDate = () => {
    if (state?.selectedDay.length == 0) {
      handleDate(state.defaultCalenderValue);
    }
    handleClose();
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  console.log('state?.disableDays : ', state);

  return (
    <>
      <TextField
        onClick={handleClick}
        aria-describedby={id}
        sx={{ width: '100%' }}
        className={classes.root}
        // value={selectedDay?.length > 0 ? getFormatedResult() : ""}
        value={state?.selectedDay?.length > 0 ? getFormatedResult() : ''}
        placeholder={'Select dates'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        readOnly
        helperText={state?.preferredDateError ? 'Select Dates.' : ''}
        error={state?.preferredDateError ? true : false}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Calendar
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          calendarSelectedDayClassName="calendarSelectedDay"
          // value={state?.defaultCalenderValue}
          value={state?.defaultCalenderValue}
          minimumDate={state?.minDate}
          maximumDate={state?.maxDate}
          disabledDays={state?.disableDays}
          customDaysClassName={state?.disableDays}
          onChange={handleDate}
          colorPrimary="#3C6B49"
          calendarPopperPosition="auto"
          inputPlaceholder="Select a day range"
        />
        {state?.maxDateError && (
          <FormHelperText
            sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginBottom: 1, pl: 2.5 }}
          >
            {'Please choose a date within trip duration.'}
          </FormHelperText>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </CancelButton>
          <GreenButton onClick={applyDate}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};
