import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';
import MuiTabs from "@mui/material/Tabs"

export const Tabs = styled(MuiTabs)(() => ({
    "& .MuiTab-root": {
        fontFamily: 'Poppins-Medium',
        textTransform: 'capitalize',
        color: 'rgba(17, 17, 17, 0.6)',
    },
    "& .MuiTabs-indicator": {
        backgroundColor: "#3C6B49",
    },
    "& .MuiTab-root.Mui-selected": {
        color: '#3C6B49',
        fontFamily:'Poppins-SemiBold'
    }
})
);
export const RedButton = styled(Box)(() => ({
    display: 'flex',
    padding: "8px 32px",
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#B93B3B',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '14px',
    textTransform: 'capitalize',
    background: '#F8ECEC',
    borderRadius: '5px',
    height: '38px',
    cursor: 'pointer',
    width: 'max-content',
    '@media(max-width: 600px)': {
        padding: 10,
        fontSize: 'small',
    },
}));
export const ArrowGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: .3,
    paddingTop: 26
}));
export const ArrowGrid1 = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    opacity: .3,
}));

export const ScrollAbleGrid = styled(Grid)(() => ({
    overflowY: "scroll",
    maxHeight: "480px",
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '8px',
        marginLeft: '-4px'
    },
    '&::-webkit-scrollbar-track': {
        background: "white",
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#D9D9D9',
    }
}));
export const LeftBox = styled(Box)(() => ({
    display: 'flex', flexDirection: 'row', justifyContent: 'center',
    alignItems: 'center'
}));
export const RightBox = styled(Box)(() => ({
    display: 'flex', alignItems: 'center', marginRight: 6
}));
export const RatingBox = styled(Box)(() => ({
    display: 'inline-block',
    width: '100%',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        verticalAlign: 'middle',
    }
}));
export const RatingCount = styled('span')(() => ({
    color: '#101B10',
    fontFamily: 'Poppins-Medium',
    fontSize: '12px',
    marginLeft: "2px",
    verticalAlign: 'text-bottom',

    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '13px'
    },
}));
export const ReviewCount = styled('span')(() => ({
    textDecorationLine: 'underline',
    fontFamily: 'Poppins-Medium',
    color: 'rgba(17, 17, 17, 0.4)',
    fontSize: '1vw',
    marginLeft: "2px",
    '@media(max-width: 960px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '13px'
    },

}));
export const TripBoxHeader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '10px 10px 0px 0px'
}));
export const TripBox = styled(Grid)(() => ({
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    height: 'max-content',
    padding: '12px 20px'

}));
export const CountName = styled(Box)(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#111111',
    opacity: 0.6,
    marginTop: 20,
    marginBottom: 8,
    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },
}));
export const ActivityFeatures = styled('p')(() => ({
    fontStyle: 'normal',
    fontSize: '13px',
    fontFamily: 'Poppins-Regular',
    lineHeight: '140%',
    color: '#101B10',
    textTransform:'capitalize',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3.2vw',
    },
}));
export const FormBox = styled(Box)(() => ({
    width: '45%',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowY: 'auto',
    height: '94vh',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 1200px)': {
        width: '50%',
    },
    '@media(max-width: 1000px)': {
        width: '70%',
    },
    '@media(max-width: 900px)': {
        width: '70%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        padding: '10px 12px',
        width: '93%',
        height: 'max-content',

    },
}));
export const Edit = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    textTransform: 'capitalize',
    background: '#3C6B49',
    borderRadius: '5px',
    color: '#FAFAFA',
    height: '38px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    cursor: 'pointer',
}));
export const TransparentBtn = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    textTransform: 'capitalize',
    alignItems: 'center',
    borderRadius: '5px',
    color: '#30563A',
    border: '1px solid #DFDFDF',
    height: '38px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    cursor: 'pointer',
}));