import { styled } from '@mui/material/styles';
import { Grid, Box, ListItem } from '@mui/material';
import MuiListItemText from "@mui/material/ListItemText"

export const FormBox = styled(Box)(() => ({
    width: '640px',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '94vh',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 1200px)': {
        width: '70%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
    },
}));
export const CustomListItemText = styled(MuiListItemText)(() => ({
    "& span": {
        display: 'flex', flexDirection: 'column',
    },
}));
export const Message = styled('div')(() => ({
    marginBottom: 2,
    color: '#101B10',
    fontFamily: 'Poppins-Regular',
    fontSize: '14px',
    display: 'inline-flex',
    textAlign: 'left',
    '@media(max-width: 600px)': {
        fontSize: '2.5vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '3vw',
    },

}));
export const Read = styled('span')(() => ({
    fontSize: '13px',
    fontFamily: 'Poppins-Medium',
    color: 'rgba(17, 17, 17, 0.4)',
    paddingLeft:0,
    '@media(max-width: 500px)': {
        fontSize: '3vw',
    },
}));
export const Title = styled('span')(() => ({
    marginBottom: 2,
    color: '#101B10',
    fontSize: '15px',
    fontFamily: 'Poppins-SemiBold',
    paddingLeft:'0px !important',
    '@media(max-width: 500px)': {
        fontSize: '3.5vw',
    },
}));
export const GreenTitle = styled('span')(() => ({
    color: '#3C6B49',
    fontSize: '14px',
    marginRight:3,
    fontFamily: 'Poppins-SemiBold',   
    '@media(max-width: 500px)': {
        fontSize: '3.5vw',
    },

}));
export const UnRead = styled('span')(() => ({
    color: '#3C6B49',
    fontSize: '13px',
    fontFamily: 'Poppins-Medium',
    '@media(max-width: 500px)': {
        fontSize: '3vw',
    },

}));
export const Count = styled('span')(() => ({
    fontFamily: 'Poppins-Medium',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#111111',
    opacity: 0.6,
    marginBottom: 5,
    '@media(max-width: 500px)': {
        fontSize: '3vw',
    },
}));
export const CustomListItem = styled(ListItem)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '5px 15px',
    alignItems: 'center',
    width: '100%',
    '@media(max-width: 900px)': {
        padding: '10px',
        width: '35vw',
    },
    '@media(max-width: 700px)': {
        width: '45vw',
    },
    '@media(max-width: 600px)': {
        width: '65vw',
    },
    '@media(max-width: 400px)': {
        width: '80vw',
    },
}));
export const Back = styled('span')(() => ({
    fontFamily: 'Poppins-Medium',
    fontSize: '13px',
    marginBottom: 5,
    lineHeight: '20px',
    color: '#3C6B49',
    opacity: 0.9,
    cursor: 'pointer',
    '@media(max-width: 600px)': {
        fontSize: '3vw',
    },
}));
export const ScrollAbleGrid = styled(Grid)(() => ({
    overflowY: "auto",
    maxHeight: "80vh",
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '8px',
        marginLeft: '-4px'
    },
    '&::-webkit-scrollbar-track': {
        background: "white",
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#D9D9D9',
    }
}));