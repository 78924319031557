import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFullName } from "../../../utills/constants"
import { Grid, useMediaQuery, Drawer, Avatar } from "@mui/material"
import { FollowersRow, NameSpan, GreenText, FollowersHeading } from "../Private/Elements"
import "../style.css"

const Followers = ({ mobileOpen, handleDrawerToggle }) => {
    const matches = useMediaQuery('(max-width:1000px)');
    const { follower, following } = useSelector(state => state.profile)
    const { role, userInfo } = useSelector(state => state.auth)
    const navigate = useNavigate()

    const handleNavigation = (res) => {
        if (role === 'user') {
            if (userInfo?._id === res?.userId?._id) {
                navigate(`/profile/${res?.userId?._id}`, { replace: true })
                window.location.reload();
            }
            else navigate(`/profile/public/${res?.userId?._id}`, { replace: true })
            window.location.reload();
        }
        else return;
    }

    const GetFilterBox = ({ children }) => {
        if (matches) {
            return <Drawer
                anchor={'right'}
                open={mobileOpen}
                onClose={handleDrawerToggle} >
                <div style={{ padding: 10 }}>{children}</div>
            </Drawer>
        }
        else if (!matches) { return children }
    }
    const [selectedTab, setSelectedTab] = useState('one')
    const getClassName = (value) => {
        if (value === selectedTab) { return "tab selectedTab" }
        else return "tab"
    }
    return <Grid container sx={{ backgroundColor: 'white', height: '95vh', margin: 'auto', display: 'flex', flexDirection: 'column' }}>
        <GetFilterBox>
            <FollowersHeading>
                <span value="one" className={getClassName('one')} onClick={(e) => setSelectedTab('one')}>Followers</span>
                <span value="two" className={getClassName('two')} onClick={(e) => setSelectedTab('two')}>Following</span>
            </FollowersHeading>
            {selectedTab === "one" && <Grid sx={{ width: '100%' }}>
                {follower?.length === 0 && <GreenText sx={{ justifyContent: 'center', mt: 30 }} >{'No follower(s) found'}</GreenText>}
                {follower?.map((res, i) => {
                    return <FollowersRow key={i}>
                        <NameSpan onClick={() => handleNavigation(res)}>
                            <Avatar src={res?.userId?.image} style={{ marginRight: 10 }} />
                            {res?.userId != null ? getFullName(res?.userId) : 'Trip Trader User'}
                        </NameSpan>
                    </FollowersRow>
                })}
            </Grid>}
            {selectedTab === "two" && <Grid sx={{ width: '100%' }}>
                {following.length === 0 &&
                    <GreenText sx={{ justifyContent: 'center', mt: 30 }} >{'Not following anyone yet'}</GreenText>
                }
                {following?.map((res, i) => {
                    return <FollowersRow key={i}>
                        <NameSpan onClick={() => handleNavigation(res)}>
                            <Avatar src={res?.userId?.image} style={{ marginRight: 10 }} />
                            {res?.userId != null ? getFullName(res?.userId) : 'Trip Trader User'}
                        </NameSpan>
                    </FollowersRow>
                })}
            </Grid>}
        </GetFilterBox>
    </Grid >

}
export default Followers