import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import api from '../../api';
import Emoji from './Emoji';
import moment from 'moment';
import ImageModal from './ImageModal';
import dots from '../../assets/profile/dots.png';
import send from '../../assets/messaging/send.png';
import smily from '../../assets/messaging/smily.png';
import block from '../../assets/messaging/block.png';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import report from '../../assets/messaging/report.png';
import deletex from '../../assets/messaging/delete.png';
import attachment from '../../assets/messaging/attachment.png';
import GreenLoader from '../../assets/greenLoader.gif';
import { collection, doc, onSnapshot, serverTimestamp, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { config } from '../../utills';
import { UserName } from '../Elements';
import { useDispatch, useSelector } from 'react-redux';
import { getFunction } from '../../features/auth/action';
import { uploadImagesToS3 } from '../../features/general/action';
import { messageInputStyles } from '../../components/InputFields/styles';
import { getFullName, dateToFromNowDaily, getSubsctiptionStatus } from '../../utills/constants';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  TextField,
  useMediaQuery,
  ImageList,
  ImageListItem,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import {
  DeleteMessage,
  DeleteSuccessful,
  ReportUser,
  ReportSuccessful,
  BlockSuccessful,
  BlockUser,
} from '../../components/Modal/Message';
import {
  Time,
  Message,
  NameRow,
  Footer,
  MessagesBox,
  Header,
  FooterMain,
  SendButton,
  RightGrid,
  OtherUserMsg,
  LoggedInUserMsg,
  FirstScreen,
} from './Elements';
import { FireStore } from '../../utills/FireStore';
import Loader from '../../components/Loader/index';

const formatedDate = (timestamp) => {
  const date = new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000);
  const serializableValue = new Date(date.getTime());
  return serializableValue;
  // create = formatDate(serializableValue)
};

const CustomConversationBox = ({
  messages,
  setDateMessage,
  receiverFollowers,
  receiverId,
  selectedChat,
  setisBlockOther,
}) => {
  const messagesEndRef = useRef(null);
  const [receiverBlockArr, setReceiverBlockArr] = useState(receiverFollowers || []);

  const { userInfo } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [showImg, setShowImg] = useState('');

  const handleOpen = (item) => {
    console.log(item);
    setOpen(true);
    setShowImg(item);
  };
  const handleClose = () => setOpen(false);

  const scrollToBottom = () => {
    messagesEndRef?.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setReceiverBlockArr(receiverFollowers);
  }, [receiverFollowers]);

  useEffect(() => {
    var initState = true;
    var initState2 = true;
    const curentUserId = userInfo?._id;
    const roomId = selectedChat?._id;
    const chatroomsRef = collection(db, 'chatrooms');
    const ref = query(collection(chatroomsRef, roomId, 'messages'), orderBy('createdAt', 'asc'));
    const ref2 = doc(chatroomsRef, roomId);

    const observer = onSnapshot(ref, (documentSnapshot) => {
      if (initState) {
        initState = false;
      } else {
        let fdata = [];

        const data = documentSnapshot?.docs || [];

        fdata = data
          .map((item) => ({
            ...item.data(),
            _id: item.id,
          }))
          .filter((item) => {
            let isShow = true;

            item.deletedBy.forEach((element) => {
              if (element == curentUserId) {
                isShow = false;
              }
            });
            if (isShow) return item;
          });
        setDateMessage(fdata);
        FireStore.readAllMessageInRoom(roomId, receiverId);
      }
    });

    const observer2 = onSnapshot(ref2, (documentSnapshot) => {
      if (initState2) {
        initState2 = false;
      } else {
        const item = documentSnapshot.data() || [];
        let userObj = null;

        if (item) {
          if (item.userId1 && item.userId1._id == receiverId) {
            userObj = item.userId1;
          }

          if (item.userId2 && item.userId2._id == receiverId) {
            userObj = item.userId2;
          }
        }
        setReceiverBlockArr(userObj ? userObj.followers : []);
      }
    });
    return () => {
      observer();
      observer2();
    };
  }, [receiverId]);

  useEffect(() => {
    let blk = false;
    if (receiverBlockArr?.length > 0) {
      const dtt = receiverBlockArr;
      const fi = dtt.findIndex((x) => x.userId == userInfo?._id);
      if (fi > -1) {
        if (dtt[fi].block == true) blk = true;
      }
    }
    setisBlockOther(blk);
  }, [receiverBlockArr, selectedChat]);

  useEffect(scrollToBottom, [messages]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              style={{ width: '-webkit-fill-available', height: 'auto' }}
              src={showImg}
              alt="show Image"
            />
          </Box>
        </Modal> */}
        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
          <DialogTitle style={{ textAlign: 'end' }}>
            <span onClick={handleClose} style={{ textAlign: 'end', cursor: 'pointer' }}>
              &#10005;
            </span>
          </DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            <img
              style={{
                width: '100%',
                height: `100%`,
                objectFit: 'contain',
              }}
              src={showImg}
              alt="AvatarPhotos"
            />
          </DialogContent>
        </Dialog>
      </div>
      <MessagesBox>
        {messages?.map((res, i) => {
          if (res?.user?._id === userInfo?._id) {
            return (
              <Box sx={{ paddingLeft: 3, paddingRight: 3 }} key={i}>
                <LoggedInUserMsg>
                  {res?.image?.length === 0 ? (
                    res.message
                  ) : (
                    <ImageList
                      sx={{ width: 'auto', height: 'auto', color: 'black' }}
                      cols={res?.image?.length}
                    >
                      {res?.image?.map((item) => (
                        <ImageListItem key={item}>
                          <img
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleOpen(item)}
                            src={`${item}?w=50&h=50&fit=crop&auto=format`}
                            srcSet={`${item}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
                            alt={item}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                      <br /> {res.message}
                    </ImageList>
                  )}
                </LoggedInUserMsg>
                <Time
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {moment(formatedDate(res.createdAt))
                    .format('hh:mm a')
                    .replace(/^0(?:0:0?)?/, '')}{' '}
                  {res?.isRead ? (
                    <DoneAllIcon sx={{ color: 'green', fontSize: 15 }} />
                  ) : (
                    <CheckIcon sx={{ fontSize: 15 }} />
                  )}
                </Time>
              </Box>
            );
          } else if (res?.user?._id !== userInfo?._id) {
            return (
              <>
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Avatar src={res?.user?.image} alt="convo" style={{ marginRight: 8 }} />
                  <OtherUserMsg>
                    {res?.image?.length === 0 ? (
                      res.message
                    ) : (
                      <ImageList sx={{ width: 'auto', height: 'auto' }} cols={res?.image?.length}>
                        {res?.image?.map((item) => (
                          <ImageListItem key={item} sx={{ margin: '0px 5px' }}>
                            <img
                              onClick={() => handleOpen(item)}
                              src={`${item}?w=50&h=50&fit=crop&auto=format`}
                              srcSet={`${item}?w=50&h=50&fit=crop&auto=format&dpr=2 2x`}
                              alt={item}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                        <br /> {res.message}
                      </ImageList>
                    )}
                  </OtherUserMsg>
                </Box>
                <Box>
                  <Time sx={{ textAlign: 'left', paddingLeft: 8 }}>
                    {moment(formatedDate(res.createdAt)).format('hh:mm a')}
                  </Time>
                </Box>
              </>
            );
          } else return '';
        })}
        <div ref={messagesEndRef} />
      </MessagesBox>
    </>
  );
};

const Conversation = ({ handleParentChange, getInboxWithoutLoading, getInbox, parentState }) => {
  var userName, userImage, secondUser;

  const { selectedChat, receiverId, receiverFollowers } = parentState;

  const dispatch = useDispatch();
  const [viewfreemiumsg, setviewviewfreemiumsg] = useState(false);
  const classes = messageInputStyles();

  const { userInfo, conversation = [], loading, blocked } = useSelector((state) => state.auth);

  const matches700 = useMediaQuery('(max-width:700px)');

  const [loader, setLoader] = useState(false);

  const [state, setState] = useState({
    isBlocked: false,
    typing: false,
    istyping: false,
    messages: conversation,
    image: [],
    imageUpload: false,
    message: '',
    openDeleteSuccessfull: false,
    openDelete: false,
    openBlock: false,
    openBlockSuccessful: false,
    openReportUser: false,
    userName: '',
    userImage: '',
  });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConversation = async () => {
    const updatedBlocked = await api.get(`/user/blockedUsers/${userInfo?._id}`, config);
    updatedBlocked?.data['blocked']?.forEach((res) => {
      if (res?.userId?._id === state?.secondUser?._id) {
        handleChange('isBlocked', true);
      }
    });
  };
  const setDateMessage = (values) => {
    handleChange('messages', [...values]);
  };

  useEffect(() => {
    setviewviewfreemiumsg(false);
    handleChange('messages', []);
    if (!(Object.keys(selectedChat).length === 0)) {
      FireStore.getAllMessageInRoom(
        userInfo?._id,
        selectedChat?._id,
        receiverId,
        () => {},
        setDateMessage
      );
      if (userInfo?._id === selectedChat?.userId1?._id) {
        secondUser = selectedChat?.userId2;
        userImage = selectedChat?.userId2?.image;
        userName = getFullName(selectedChat?.userId2);
      } else if (userInfo?._id === selectedChat?.userId2?._id) {
        secondUser = selectedChat?.userId1;
        userImage = selectedChat?.userId1?.image;
        userName = getFullName(selectedChat?.userId1);
      }
      handleChange('userName', userName);
      handleChange('userImage', userImage);
      handleChange('secondUser', secondUser);
      console.log('secondUser: ', secondUser);
      let c = false;
      blocked?.forEach((res) => {
        console.log('res?.userId?._id: ', res?.userId?._id);
        if (secondUser?._id === res?.userId?._id) {
          c = true;
        }
      });
      handleChange('isBlocked', c);
    } else return;
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    FireStore.getAllCurrentUserRooms(userInfo?._id);
  }, []);
  useEffect(() => {
    handleChange('messages', [...conversation]);
    // eslint-disable-next-line
  }, [conversation]);

  const sendMessage = async () => {
    setLoader(true);
    const userStatus = await getSubsctiptionStatus(selectedChat?.userId2?.customerId);
    console.log('work', userStatus);
    if (userStatus !== 'Premium' && userStatus !== 'PremiumC') {
      setviewviewfreemiumsg(true);
    }
    const timestamp = serverTimestamp();
    const chatMessageObject = {
      user: userInfo,
      isRead: false,
      message: state?.message,
      type: 'text',
      image: '',
      deletedBy: [],
      createdAt: timestamp,
      updatedAt: timestamp,
    };
    if (state?.message !== '') {
      FireStore.sendChatMessage(
        selectedChat?._id,
        receiverId,
        chatMessageObject,
        () => {},
        '',
        false,
        null,
        userInfo
      );
      handleChange('message', '');
    }
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  };

  const sendMessageOnEnter = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const uploadFunction = async (e) => {
    let result = await uploadImagesToS3(e);
    let temp = [];
    result.forEach((res) => {
      temp.push(res?.imgrUrl);
    });
    handleChange('image', [...state.image, ...temp]);
  };
  const sendImage = async (e) => {
    const timestamp = serverTimestamp();
    const userStatus = await getSubsctiptionStatus(selectedChat?.userId2?.customerId);
    console.log('work', userStatus);
    if (userStatus !== 'Premium' && userStatus !== 'PremiumC') {
      setviewviewfreemiumsg(true);
    }
    const chatMessageObject = {
      user: userInfo,
      isRead: false,
      message: state.imgMsg ? state.imgMsg : '',
      type: 'image',
      image: state.image,
      deletedBy: [],
      createdAt: timestamp,
      updatedAt: timestamp,
    };
    if (state?.image?.length) {
      FireStore.sendChatMessage(
        selectedChat?._id,
        receiverId,
        chatMessageObject,
        () => {},
        '',
        false,
        null,
        userInfo
      );
      handleChange('image', []);
      handleChange('imageUpload', false);
      handleChange('imgMsg', '');
    }
  };

  useEffect(() => {
    let body = { name: 'blocked', url: `/user/blockedUsers/${userInfo?._id}` };
    dispatch(getFunction(body));
  }, [selectedChat]);

  const [isBlockOther, setisBlockOther] = useState(false);

  const optionsArr = blocked?.some((e) => e?.userId?._id === selectedChat.userId2?._id)
    ? [
        { icon: report, function: 'openReportUser', alt: 'Report User' },
        { icon: deletex, function: 'openDelete', alt: 'Delete Chat' },
      ]
    : [
        { icon: block, function: 'openBlock', alt: 'Block User' },
        { icon: report, function: 'openReportUser', alt: 'Report User' },
        { icon: deletex, function: 'openDelete', alt: 'Delete Chat' },
      ];

  return (
    <RightGrid item xs={12} sm={matches700 ? 12 : 7} md={8} lg={8}>
      {Object.keys(selectedChat).length === 0 ? (
        <FirstScreen>
          <NameRow sx={{ color: 'rgba(17, 17, 17, 0.8)' }}>Pick up where you left off</NameRow>
          <Message sx={{ color: 'rgba(17, 17, 17, 0.8)' }}>
            Select a conversation and chat away.
          </Message>
        </FirstScreen>
      ) : (
        <>
          <Header>
            <UserName>
              <Avatar
                src={state?.userImage}
                alt="convo"
                sx={{ width: 50, height: 50, my: 1 }}
                style={{ marginRight: 10 }}
              />
              {state?.userName == ' ' ? 'Trip Trader User' : state.userName}
            </UserName>
            {matches700 ? (
              <div>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <img src={dots} alt="dots" />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {optionsArr.map((res, i) => {
                    return (
                      <MenuItem sx={{ opacity: 0.4 }}>
                        <Tooltip title={res?.alt}>
                          <img
                            style={{ cursor: 'pointer' }}
                            alt={res?.alt}
                            src={res.icon}
                            onClick={() => handleChange(res.function, true)}
                            key={i}
                          />
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            ) : state?.userName != ' ' ? (
              <Box sx={{ display: 'flex', alignItems: 'center', opacity: 0.4 }}>
                {optionsArr.map((res, i) => {
                  return (
                    <Tooltip title={res?.alt}>
                      <img
                        style={{ marginRight: 15, cursor: 'pointer' }}
                        src={res.icon}
                        onClick={() => handleChange(res.function, true)}
                        alt=""
                        key={i}
                      />
                    </Tooltip>
                  );
                })}
              </Box>
            ) : (
              ''
            )}
          </Header>
          {loading && (
            <div style={{ position: 'absolute', top: '45%', left: '40%' }}>
              <img src={GreenLoader} width="80" alt="GreenLoader" />
            </div>
          )}
          <Time sx={{ textAlign: 'center', my: 3 }}>
            <span style={{ fontWeight: 'bold' }}>
              {dateToFromNowDaily(formatedDate(selectedChat?.latestMessage?.createdAt))}
            </span>{' '}
            {moment(formatedDate(selectedChat?.latestMessage?.createdAt))
              .format('hh:mm a')
              .replace(/^0(?:0:0?)?/, '')}
          </Time>

          <CustomConversationBox
            receiverFollowers={receiverFollowers}
            setDateMessage={setDateMessage}
            receiverId={receiverId}
            selectedChat={selectedChat}
            messages={state?.messages}
            handleChange={handleChange}
            setisBlockOther={setisBlockOther}
          />
          <FooterMain>
            {viewfreemiumsg ? (
              <p
                style={{
                  color: 'rgba(17, 17, 17, 0.4)',
                  fontFamily: 'Poppins-Medium',
                  backgroundColor: 'white',
                  fontSize: '0.8rem',
                  zIndex: '99999',
                  position: 'absolute',
                  bottom: '73px',
                  padding: '1rem',
                }}
              >
                The person you messaged is not currently a subscribed user. We have notified them
                about your message, and they will be able to reply once they subscribe.
              </p>
            ) : (
              ''
            )}

            {/* {blocked?.some((e) => e?.userId?._id === selectedChat.userId2?._id) ? ( */}
            {isBlockOther || (state?.isBlocked && state?.userName != ' ') ? (
              <Time sx={{ textAlign: 'center', mb: 5 }}>
                {state?.isBlocked && `You have blocked this user.`}
                {isBlockOther && `You can't send message to this user.`}
              </Time>
            ) : (
              <Footer sx={{ paddingLeft: 3, paddingRight: 3 }}>
                <img
                  src={attachment}
                  alt="attachment"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleChange('imageUpload', true)}
                />
                <img
                  onClick={(e) => handleChange('openEmoji', true)}
                  src={smily}
                  alt="smily"
                  style={{ cursor: 'pointer' }}
                />
                <TextField
                  sx={{ width: '80%' }}
                  value={state.message}
                  onKeyPress={sendMessageOnEnter}
                  onChange={(e) => handleChange('message', e.target.value)}
                  className={classes.root}
                  inputProps={{
                    style: { color: '#1E3625', zIndex: 20, height: '25px' },
                  }}
                  placeholder={'Type your message here'}
                />
                {loader ? (
                  <Loader styl={{}} width={'30'} />
                ) : (
                  <SendButton onClick={sendMessage}>
                    <img src={send} alt="send" />
                  </SendButton>
                )}
              </Footer>
            )}
            {/* // ))} */}
          </FooterMain>
        </>
      )}
      <ImageModal
        _state={state}
        stateName="imageUpload"
        handle={handleChange}
        sendImage={sendImage}
        uploadFunction={uploadFunction}
        open={state.imageUpload}
        _setState={setState}
      />
      <Emoji data={state} open={state.openEmoji} handle={handleChange} stateName="openEmoji" />
      <BlockUser
        // isBlock={state?.isBlocked}
        handleConversation={handleConversation}
        data={state}
        open={state.openBlock}
        handle={handleChange}
        stateName="openBlock"
      />
      <DeleteMessage
        handleParentChange={handleParentChange}
        selectedChat={selectedChat}
        getInbox={getInbox}
        data={state}
        state={parentState}
        open={state.openDelete}
        handle={handleChange}
        stateName="openDelete"
      />
      <ReportUser
        data={state}
        open={state.openReportUser}
        handle={handleChange}
        stateName="openReportUser"
      />
      <BlockSuccessful
        data={state}
        open={state.openBlockSuccessful}
        handle={handleChange}
        stateName="openBlockSuccessful"
      />
      <ReportSuccessful
        data={state}
        open={state.openReportSuccessful}
        handle={handleChange}
        stateName="openReportSuccessful"
      />
      <DeleteSuccessful
        data={state}
        state={parentState}
        open={state.openDeleteSuccessfull}
        handle={handleChange}
        stateName="openDeleteSuccessfull"
      />
    </RightGrid>
  );
};
export default Conversation;
