import React from "react";

import Slider from "react-slick";
import fwd from "../../../assets/home/fwd.png"
import path from "../../../assets/home/path.png"
import prev from "../../../assets/home/prev.png"
import defaultUser from "../../../assets/home/defaultuser.jpg"

import { Grid, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { MainContainer, Message, Name, FeaturedItemImage } from "./Elements"
import { ReviewDetails, FeaturedItem, Heading, Black, Orange, Description } from "./Elements"

import "./index.css"

const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (<div className="SampleNextArrow" onClick={onClick}>
        <img src={fwd} alt="fwd" />
    </div>
    );
}

const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (<div className="SamplePrevArrow" onClick={onClick}>
        <img src={prev} alt="prev" />
    </div>
    );
}
const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

};
const Reviews = ({ refs }) => {
    const { featuredReviews } = useSelector(state => state.general)

    return (
        <MainContainer maxWidth="xl" ref={refs} >
            <Heading> <Black> Member</Black> <Orange> Testimonials  </Orange></Heading>
            <Description>See what traders are saying about Trip Trader.</Description>
            <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                <Slider {...settings} className="slider-custom">
                    {featuredReviews?.map((res, i) => {
                        return (<div key={i}>
                            <FeaturedItem>
                                <FeaturedItemImage >
                                    <img src={res?.guestId?.image ? res?.guestId?.image : defaultUser} alt={`trade`} />
                                </FeaturedItemImage>
                                <ReviewDetails>
                                    <img src={path} alt="path" />
                                    <Message>
                                        “{res?.messages[0]?.message}”
                                    </Message>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        <Name sx={{ textTransform: 'capitalize' }}>{`${res?.guestId?.firstName} ${res?.guestId?.lastName} `}</Name>
                                    </Box>
                                </ReviewDetails>
                            </FeaturedItem></div>)
                    })}

                </Slider>
            </Grid>
        </MainContainer>
    )
}
export default Reviews