import React, { useEffect, useState } from 'react';

import { Header } from './ModalHeader';
import { CancelButton } from '../Button';
import { CenterHeader } from './ModalHeader';
import { useNavigate } from 'react-router-dom';
import { FireStore } from '../../utills/FireStore';
import { FullTextArea } from '../InputFields/Input';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import { Box, Modal, Grid, Avatar } from '@mui/material';
import { startLoading, stopLoading } from '../../features/auth/reducer';
import { Description, Heading, ToHeading, InfoHeading } from './Elements';
import { getFullName, swalWithStyledButton, config } from '../../utills/constants';
import { style, GreenButton, RedButton, Instruction, DisputeDescription } from './Elements';
import { _postFuntion, _updateFuntion, getFunction, reload } from '../../features/auth/action';
import api from '../../api';
import toast from 'react-hot-toast';
import Loader from '../../assets/loader1.gif';
import Illustration from '../../assets/messaging/Illustration.png';
import { CustomLabel2 } from '../InputFields/Elements';
import { freemiumNote } from '../../features/trip/action';

export const FirstMessage = ({ open, handle, stateName, data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [knowStatus, setknowStatus] = useState();
  const { userInfo } = useSelector((state) => state.auth);
  const { profileInfo } = useSelector((state) => state.profile);
  const [state, setState] = useState({ loading: false, message: '', isConvoExists: false });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const getSubsctiptionStatus = async (Id) => {
    try {
      const customerId = Id;
      let status = '';
      await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
        status = res.data.status;
      });
      setknowStatus(status);
      return status;
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => handle([stateName], false);

  const handleSuccess = () => {
    handle(['openMessageSent'], true);
    handle(['openMessage'], false);
    handleChange('loading', false);
  };

  const handleButton = async () => {
    dispatch(freemiumNote(false));
    handleChange('loading', true);
    const getchat = await getSubsctiptionStatus(profileInfo?.customerId);
    console.log('getchat', getchat);
    if (getchat !== 'Premium' && getchat !== 'PremiumC') {
      dispatch(freemiumNote(true));
    } else {
      dispatch(freemiumNote(false));
    }

    const blockedUsers = [];
    const blockedByUsers = await api.get(`/user/blockedByUsers/${userInfo?._id}`, config);

    blockedByUsers?.data?.blockedBy?.forEach((res) => {
      if (res?.userId?._id) {
        blockedUsers.push(res?.userId?._id?.toString());
      }
    });
    if (blockedUsers.includes(data?.selectedUser?._id)) {
      handle(['openMessage'], false);
      handleChange('loading', false);

      swalWithStyledButton
        .fire({
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: 'This user has blocked you.',
          confirmButtonText: 'Reload',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
        .then((result) => {
          // dispatch(stopLoading());
          if (result.isConfirmed) {
            dispatch(reload(userInfo?._id, navigate));
          } else if (result.isDenied) {
            dispatch(reload(userInfo?._id, navigate));
          }
        });
    } else {
      FireStore.sendMessage(
        userInfo, //senderUserObject
        data?.selectedUser, //reciverUserObject
        state?.message, //messageValue
        'text', //messageType
        [], //messageImage
        handleSuccess, //messageSendSuccessfully
        profileInfo
      );
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '386px',
            height: '400px',
            px: 3,
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <CenterHeader heading="Message User" handleClose={handleClose} />
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <ToHeading>To: &nbsp;</ToHeading>
            <Avatar src={data?.selectedUser?.image} alt="useer" /> &nbsp;
            <InfoHeading sx={{ my: 0 }}>{getFullName(data?.selectedUser)}</InfoHeading>
          </Box>
          <FullTextArea
            rows={4}
            onChange={(e) => handleChange('message', e.target.value)}
            placeholder="Type your message here"
          />
          <Box sx={{ width: '100%', opacity: !state.message ? 0.4 : 1 }}>
            <GreenButton
              disabled={state?.loading}
              sx={{ width: '100%' }}
              type="submit"
              onClick={handleButton}
              variant="contained"
              disableripple
            >
              {state?.loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Send
                  Message...
                </LoaderSpan>
              ) : (
                ' Send Message'
              )}
            </GreenButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export const MessageSent = ({ open, handle, stateName, data }) => {
  const handleClose = () => handle([stateName], false);
  const navigate = useNavigate();
  const [knowStatus, setknowStatus] = useState();
  const { profileInfo } = useSelector((state) => state.profile);
  const { freemiumNotesMessage } = useSelector((state) => state.trip);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '400px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Heading>Message Sent</Heading>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Illustration} alt="msgSent" />
            <Description sx={{ my: 1 }}>{getFullName(data?.selectedUser)}</Description>
            <CustomLabel2 sx={{ my: 1, fontSize: '0.7rem' }}>
              {freemiumNotesMessage == true
                ? 'Note:The person you messaged is not currently a subscribed user. We have notified them about your message, and they will be able to reply once they subscribe.'
                : ''}
            </CustomLabel2>
          </Box>
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              Done
            </GreenButton>
            <CancelButton
              onClick={() => navigate('/messages')}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 2 }}
            >
              Go to Inbox
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const DeleteMessage = ({
  handleParentChange,
  selectedChat,
  getInbox,
  open,
  handle,
  stateName,
  state,
  data,
}) => {
  const handleClose = () => handle([stateName], false);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleSucces = () => {
    handle([stateName], false);
    getInbox();
    handleParentChange('selectedChat', {});
    handle(['openDeleteSuccessfull'], true);
    dispatch(stopLoading());
    setLoading(false);
  };
  const handleButton = () => {
    setLoading(true);
    FireStore.deleteChat(
      [],
      state?.selectedChat?._id,
      userInfo?._id,
      '',
      [],
      () => {},
      handleSucces()
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '443px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Delete Message?" handleClose={handleClose} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ width: 90, height: 90 }} src={data?.secondUser?.image} alt="msgSent" />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {getFullName(data?.secondUser)}
            </Description>
            {/* <Instruction sx={{ textAlign: 'center', my: -1 }}>@{data?.secondUser?.email}</Instruction> */}
          </Box>
          <DisputeDescription>
            This will delete your entire conversation with this member. This action cannot be
            undone.
          </DisputeDescription>
          <Grid sx={{ width: '100%' }}>
            <RedButton
              onClick={handleButton}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                  delete it now...
                </LoaderSpan>
              ) : (
                ' Yes, delete it now'
              )}
            </RedButton>
            <CancelButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              No, keep it
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const ReportUser = ({ open, handle, stateName, data }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({ message: '' });

  const handleClose = () => handle([stateName], false);

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSucces = () => {
    handle([stateName], false);
    handle(['openReportSuccessful'], true);
  };
  const { userInfo, loading } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleButton = async () => {
    const body = {
      url: '/report',
      userId: data?.secondUser?._id || data?._id,
      reportby: userInfo?._id,
      status: 'active',
      reason: state.message,
    };
    dispatch(startLoading());
    await api
      .post(body.url, body, config)
      .then((res) => {
        if (res.data.message === `This user doesn't exist.`) {
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: res.data.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                dispatch(reload(userInfo?._id, navigate));
                handle([stateName], false);
              } else if (result.isDenied) {
                dispatch(reload(userInfo?._id, navigate));
                handle([stateName], false);
              }
            });
        } else {
          handleSucces();
        }
        dispatch(stopLoading());
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      });
    // dispatch(_postFuntion(body, handleSucces))
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '580px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Report User" handleClose={handleClose} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              sx={{ width: 90, height: 90 }}
              src={data?.secondUser?.image || data?.image}
              alt="msgSent"
            />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {getFullName(data?.secondUser || data)}
            </Description>
            {/* <Instruction sx={{ textAlign: 'center', my: -1 }}>@{data?.secondUser?.email || data?.email}</Instruction> */}
          </Box>
          <DisputeDescription sx={{ textAlign: 'center' }}>
            Tell us about the issues you are having or seeing with this user.
          </DisputeDescription>
          <Box sx={{ width: '100%' }}>
            <FullTextArea
              rows={4}
              onChange={(e) => handleChange('message', e.target.value)}
              placeholder="Describe your concerns"
            />
          </Box>{' '}
          <Grid sx={{ width: '100%' }}>
            <RedButton
              onClick={handleButton}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1, opacity: !state.message ? 0.4 : 1 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Report
                  User...
                </LoaderSpan>
              ) : (
                '  Report User'
              )}
            </RedButton>
            <CancelButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 2 }}
            >
              Cancel
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const BlockUser = ({ handleConversation, open, handle, stateName, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => handle([stateName], false);
  const { userInfo, loading } = useSelector((state) => state.auth);

  const handleSucces = (data) => {
    handle([stateName], false);
    handleConversation();
    handle(['openBlockSuccessful'], true);
    FireStore.updateUserinFirestoreOnlyRoom(userInfo?._id, data);
  };

  const handleButton = async () => {
    dispatch(startLoading());
    const body = { url: `/user/blockAUser/${userInfo?._id}/${data?.secondUser?._id || data?._id}` };
    await api
      .put(body.url, body, config)
      .then((res) => {
        if (
          res.data.message === `This user doesn't exist.` ||
          res.data.message === 'This user has blocked you.'
        ) {
          handle([stateName], false);
          // window.alert(res.data.message)
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: res.data.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                dispatch(reload(userInfo?._id, navigate));

                handle([stateName], false);
              } else if (result.isDenied) {
                dispatch(reload(userInfo?._id, navigate));
              }
            });
        } else {
          handleSucces(res?.data?.data);
        }
        dispatch(stopLoading());
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          dispatch(stopLoading());
        }
      });
    let _body = { name: 'blocked', url: `/user/blockedUsers/${userInfo?._id}` };
    dispatch(getFunction(_body));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '380px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Block User" handleClose={handleClose} />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              sx={{ width: 90, height: 90 }}
              src={data?.secondUser?.image || data?.image}
              alt="msgSent"
            />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {getFullName(data?.secondUser || data)}
            </Description>
            {/* <Instruction sx={{ textAlign: 'center', my: -1 }}>@{data?.secondUser?.email || data?.email}</Instruction> */}
          </Box>
          <DisputeDescription sx={{ textAlign: 'center' }}>
            Are you sure, you want to block this user?
          </DisputeDescription>
          <Grid sx={{ width: '100%' }}>
            <RedButton
              onClick={handleButton}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Block
                  User...
                </LoaderSpan>
              ) : (
                '  Block User'
              )}
            </RedButton>
            <CancelButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 2 }}
            >
              Cancel
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const ReportSuccessful = ({ open, handle, stateName, data }) => {
  const handleClose = () => handle([stateName], false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '380px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Heading>User Reported</Heading>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ width: 90, height: 90 }} src={data?.secondUser?.image} alt="msgSent" />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {getFullName(data?.secondUser || data)}
            </Description>
            {/* <Instruction sx={{ textAlign: 'center', my: -1 }}>{data?.secondUser?.email || data?.email}</Instruction> */}
          </Box>
          <DisputeDescription sx={{ textAlign: 'center' }}>
            Thank you. We’ll review your report and take any necessary action.
          </DisputeDescription>
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              Done
            </GreenButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const BlockSuccessful = ({ open, handle, stateName, data }) => {
  const handleClose = () => handle([stateName], false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '380px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Heading>User Blocked</Heading>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar
              sx={{ width: 90, height: 90 }}
              src={data?.secondUser?.image || data?.image}
              alt="msgSent"
            />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {getFullName(data?.secondUser || data)}
            </Description>
            {/* <Instruction sx={{ textAlign: 'center', my: -1 }}>{data?.secondUser?.email || data?.email}</Instruction> */}
          </Box>
          <DisputeDescription sx={{ textAlign: 'center' }}>
            {/* Thank you. We’ll review your report and take any necessary action. */}
          </DisputeDescription>
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              Done
            </GreenButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const DeleteSuccessful = ({ open, handle, stateName, data }) => {
  const handleClose = () => handle([stateName], false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '380px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Heading>Chat Deleted</Heading>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar sx={{ width: 90, height: 90 }} src={data?.secondUser?.image} alt="msgSent" />
            <Description sx={{ fontWeight: 600, my: 1 }}>
              {getFullName(data?.secondUser)}
            </Description>
            {/* <Instruction sx={{ textAlign: 'center', my: -1 }}>@{data?.secondUser?.email}</Instruction> */}
          </Box>
          {/* <DisputeDescription sx={{ textAlign: 'center' }}>
                        content ??????????????
                    </DisputeDescription> */}
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              Done
            </GreenButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
