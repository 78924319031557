import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const RatingBox = styled(Box)(() => ({
    display: 'inline-block',
    width: '100%',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        verticalAlign: 'middle',
    }
}));
export const RatingCount = styled('span')(() => ({
    color: '#101B10',
    fontFamily: 'Poppins-Medium',
    fontSize: '12px',
    marginLeft: "2px",
    verticalAlign: 'text-bottom',

    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },
}));
export const ReviewCount = styled('span')(() => ({
    textDecorationLine: 'underline',
    fontFamily: 'Poppins-Medium',
    color: 'rgba(17, 17, 17, 0.4)',
    fontSize: '12px',
    marginLeft: "2px",
    '@media(max-width: 960px)': {
        // fontSize: '2.2vw',
    },
    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },

}));
export const TripBoxHeader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "8px 18px",
    boxShadow: '0px 1px 0px #E7E7E7',
    borderRadius: '10px 10px 0px 0px'
}));
export const TripBox = styled(Grid)(() => ({
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',

}));
export const CountName = styled(Box)(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#111111',
    opacity: 0.6,
    marginTop: 20,
    marginBottom: 8,
    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },
}));
export const FormBox = styled(Box)(() => ({
    width: '40%',
    overflowY: 'scroll',
    border: '1px solid #E5E5E5',
    height: '94vh',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    overflow: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    '@media(max-width: 1200px)': {
        width: '50%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        padding: '10px 12px',
        width: '93%',
        height: 'max-content',
    },
}));
