import React, { useEffect, useState } from 'react';
import moment from 'moment';
import calender from '../../assets/createTrip/calender.png';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { dateStyles } from './styles';
import { CancelButton, GreenButton } from './Elements';
import { InputAdornment, TextField, Popover } from '@mui/material';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';

const getZero = (value) => {
  if (value < 10) return `0${value}`;
  else return value;
};
export const DateMultiInput = ({ handleChange, dateRange, state }) => {
  const classes = dateStyles();
  const [selectedDayRange, setSelectedDayRange] = useState([dateRange?.from]);
  const [displayFormat, setDisplayFormat] = useState('');
  console.log('state?.excludeSpecificDates', state?.excludeSpecificDates);
  const getFormatedResult = () => {
    console.log('state?.excludeSpecificDates', state?.excludeSpecificDates);
    if (state?.excludeSpecificDates.length > 0) {
      // alert(")
      var test = [],
        dateformat = 'YYYY-MM-DD';
      selectedDayRange.forEach((res) => {
        const date = res.year + '-' + getZero(res.month) + '-' + getZero(res.day);
        test.push(date);
      });
      var result = test
        .sort(function (a, b) {
          return new Date(a) - new Date(b);
        })
        .reduce(function (acc, val) {
          var present,
            date = moment.utc(val, dateformat);
          acc.forEach(function (arr, index) {
            if (present) return;
            else if (
              arr.indexOf(date.clone().subtract(1, 'day').format(dateformat)) > -1 ||
              arr.indexOf(date.clone().add(1, 'day').format(dateformat)) > -1
            ) {
              present = true;
              arr.push(val);
            }
          });

          if (!present) acc.push([val]);
          return acc;
        }, []);
      const name = [];
      result.forEach(function (arr, index) {
        if (arr.length > 1) {
          let custom = `${moment.utc(arr[0]).format('MMM DD')} - ${moment
            .utc(arr[arr.length - 1])
            .format('DD')}`;
          name.push(custom);
        } else {
          name.push(moment.utc(arr[0]).format('MMM DD'));
        }
      });
      const customFormatedDates = [];
      selectedDayRange.forEach((res) => {
        customFormatedDates.push({
          year: res.year,
          month: res.month,
          day: res.day,
          className: 'calendarSelectedDay',
        });
      });
      let newdisableDatesFormated = [...customFormatedDates, ...state.unavailableDaysOfWeek];
      const uniqdisableDatesFormated = [
        ...new Map(
          newdisableDatesFormated.map((v) => [JSON.stringify([v.year, v.month, v.day]), v])
        ).values(),
      ];

      handleChange('allUnavailableDates', uniqdisableDatesFormated);
      handleChange('excludeSpecificDates', selectedDayRange);
      setDisplayFormat(name?.toString());
      handleChange('excludeDateText', name.join(', '));
      handleClose();
    } else {
      handleClose();
    }
  };

  const handlesetSelectedDayRange = (value) => {
    const newValue = [...value];
    newValue.forEach((e) => (e.className = 'calendarSelectedDay'));
    setSelectedDayRange([...newValue]);
    handleChange('excludeSpecificDates', [...newValue]);
  };

  const Clear = () => {
    setDisplayFormat('');
    setSelectedDayRange([]);
    handleClose();
    handleChange('excludeSpecificDates', []);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  console.log('state?.excludeSpecificDates', state?.excludeSpecificDates);
  return (
    <>
      <TextField
        className={classes.root}
        value={state?.excludeDateText}
        onClick={handleClick}
        placeholder={'Select dates'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        readOnly
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Calendar
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          value={state?.excludeSpecificDates}
          minimumDate={dateRange?.from}
          maximumDate={dateRange?.to}
          disabledDays={state?.unavailableDaysOfWeek}
          customDaysClassName={state?.unavailableDaysOfWeek}
          onChange={handlesetSelectedDayRange}
          colorPrimary="#3C6B49"
          inputPlaceholder="Select a day range"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={Clear} sx={{ mr: 1 }}>
            Cancel
          </CancelButton>
          <GreenButton onClick={getFormatedResult}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};
