import { Box, Paper, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Text, UserName } from '../Elements';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import DeleteTheCard from '../../components/Modal/DeleteTheCard';
import MakeItPrimary from '../../components/Modal/MakeItPrimary';

function StripeCard({
  data,
  handleDeletebtn,
  setUpdateTheCardID,
  updateThePrimaryCard,
  showDeletebtn,
  deleteTheCardFunction,
  MakeItPrimaryCard,
  primaryCardId,
}) {
  const matches600 = useMediaQuery('(max-width:600px)');
  const matches400 = useMediaQuery('(max-width:400px)');
  const { plans } = useSelector((state) => state.general);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [makeItPrimary, setMakeItPrimary] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log('plans', plans);
  console.log('DATA ::::::', data);
  const handleDeleteBtn = () => {
    setDeleteBtn(true);
  };
  const handleMakeItPrimaryBtn = () => {
    setMakeItPrimary(true);
  };
  const GetTheObject = (data) => {
    setUpdateTheCardID(data);

    updateThePrimaryCard(data);
  };
  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // }, []);

  return (
    <Paper elevation={3} sx={{ mt: 3, minHeight: '100px' }}>
      <div className="manageCardDateAndName">
        <UserName sx={{ fontSize: '12px', ml: matches400 ? 0 : 2, mt: 1, mb: 1.5 }}>
          {data?.billing_details?.name ? data?.billing_details?.name : 'Manage Cards'}
          <Box sx={{ marginLeft: '1rem', fontSize: '12px' }} className="greenBtn">
            {' '}
            {data?.id === primaryCardId ? '(Primary Card)' : ''}
          </Box>
        </UserName>
        <UserName sx={{ fontSize: '10px', ml: matches400 ? 0 : 2, mr: 2, mb: 1.5 }}>
          {`${data?.card?.exp_month}/${data?.card?.exp_year}`}
        </UserName>
      </div>
      <UserName sx={{ fontSize: '12px', ml: matches400 ? 0 : 2, mb: 1.5 }}>
        {`${data?.card?.brand}******** ${data?.card?.last4}`}
      </UserName>
      {/* </div> */}
      {showDeletebtn === 'true' ? (
        loading ? (
          ' '
        ) : (
          <div className="btnContainer">
            <div className="greenBtn">
              {data?.id === primaryCardId ? (
                ''
              ) : (
                <Text
                  // onClick={() => GetTheObject(data)}
                  onClick={handleMakeItPrimaryBtn}
                >
                  Make It Primary
                </Text>
              )}
            </div>
            <div className="deleteBtn">
              {data?.id === primaryCardId ? (
                ''
              ) : (
                <Text
                  onClick={handleDeleteBtn}
                  sx={{ fontSize: '1rem' }}
                  // onClick={() => deleteTheCard(data)}
                >
                  Delete
                </Text>
              )}
            </div>
          </div>
        )
      ) : (
        ''
      )}

      <DeleteTheCard
        data={data}
        open={deleteBtn}
        deleteTheCard={deleteTheCardFunction}
        setOpen={setDeleteBtn}
      />
      <MakeItPrimary
        data={data}
        open={makeItPrimary}
        MakeItPrimary={MakeItPrimaryCard}
        setOpen={setMakeItPrimary}
      />
    </Paper>
  );
}

export default StripeCard;
