import React, { useEffect, useState } from 'react';

import Sidebar from '../../components/Sidebar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { config } from '../../utills';
import { useNavigate } from 'react-router-dom';
import { MainBox, UserName, Count } from '../Elements';
import { getFullName } from '../../utills/constants';
import { useSelector, useDispatch } from 'react-redux';
import { GreenText, NameSpan } from '../Profile/Private/Elements';
import { useMediaQuery, List, Avatar } from '@mui/material';
import { getFunction } from '../../features/auth/action';
import { Back, FormBox, CustomListItem, FormBoxA } from './Elements';
import { stopLoading, startLoading } from '../../features/auth/reducer';
import toast from 'react-hot-toast';
import api from '../../api';
import { FireStore } from '../../utills/FireStore';
const BlockedUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const matches600 = useMediaQuery('(max-width:600px)');

  const { userInfo, blocked } = useSelector((state) => state.auth);

  const apiCall = () => {
    let body = { name: 'blocked', url: `/user/blockedUsers/${userInfo?._id}` };
    dispatch(getFunction(body));
  };
  const unBlock = async (b_id) => {
    dispatch(startLoading());
    setLoading(true);
    let body = { name: 'blocked', url: `/user/unBlockAUser/${userInfo?._id}/${b_id}` };
    await api
      .put(body.url, body, config)
      .then((res) => {
        apiCall();
        dispatch(stopLoading());
        FireStore.updateUserinFirestoreOnlyRoom(userInfo?._id, res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message, { duration: 4000 });
          apiCall();
          dispatch(stopLoading());
        }
      });
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line
  }, []);
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      <FormBox>
        <FormBoxA sx={{ height: 'max-content' }}>
          <Back sx={{ ml: 1, mt: 1 }} onClick={() => navigate('/settings')}>
            <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
            Back to Settings
          </Back>
          <UserName sx={{ fontSize: '18px', ml: 2, mb: 3, mt: 1 }}>Blocked Users</UserName>
          <Count sx={{ ml: 2 }}>You have {blocked?.length} user(s) blocked</Count>
          <List>
            {blocked?.map((res) => {
              return (
                <CustomListItem>
                  <NameSpan>
                    <Avatar src={res?.userId?.image} style={{ marginRight: 10 }} />
                    {getFullName(res?.userId)}
                  </NameSpan>

                  <button disabled={loading} style={{ backgroundColor: 'unset', background: "none",border:"none" }}>
                    <GreenText onClick={() => unBlock(res?.userId?._id)}>Unblock</GreenText>
                  </button>
                </CustomListItem>
              );
            })}
          </List>
        </FormBoxA>
      </FormBox>
    </MainBox>
  );
};
export default BlockedUsers;
