import React, { useState } from "react";

import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ScrollAbleGrid } from "../Private/Elements";
import { useLocation, useNavigate } from "react-router-dom"
import { getDurationFromDate } from "../../../utills/constants";
import { UserName, ActivityDetail, ActivityHeading } from "../../Elements";
import { TripBox, MainReviewGrid, } from "../Private/Elements"
import { CountName, Edit, TransparentBtn } from "../Elements"
import MakeOffer from "../../MakeOffer"
import NoData from "./../ReviewComponent/NoData"

const Trips = () => {
    const location = useLocation()

    const navigate = useNavigate()

    const [state, setState] = useState({ openMakeOffer: false })

    const { trips } = useSelector(state => state.profile)

    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    const openMakeOfferModal = (data) => {
        handleChange('openMakeOffer', true)
        handleChange('selectedTrip', data)
    }

    return <MainReviewGrid >
        {trips?.length === 0 ?
            <NoData text={"No trips available."} /> :
            <CountName sx={{ my: 2 }}>{trips?.filter(res => res.status !== "suspended").length} trip{trips?.filter(res => res.status !== "suspended").length > 1 ? 's' : ''} available</CountName>}

        <ScrollAbleGrid>
            {trips?.filter(res => res.status !== "suspended")?.map((res, i) => {
                return <TripBox key={i} sx={{ marginRight: 2 }}>
                    <UserName sx={{ color: res.status === "suspended" ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}>{res?.tradeType} Trip&nbsp;{res.status === "suspended" && <ActivityDetail>(Suspended)</ActivityDetail>}</UserName>
                    <Grid container sx={{ width: '100%', my: 1 }}>
                        <Grid item xs={12} sm={12} md={6} lg={8} >
                            <ActivityHeading>Offering</ActivityHeading>
                            <ActivityDetail sx={{ color: res.status === "suspended" ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}>{res?.title}</ActivityDetail>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} >
                            <ActivityHeading>TRIP AVAILABILITY</ActivityHeading>
                            <ActivityDetail sx={{ color: res.status === "suspended" ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}>{getDurationFromDate(res?.availableFrom, res?.availableTo)}</ActivityDetail>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }} >
                            <ActivityHeading>IN RETURN FOR</ActivityHeading>
                            <ActivityDetail sx={{ color: res.status === "suspended" ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}>{res?.returnActivity}</ActivityDetail>
                        </Grid>
                    </Grid>
                    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                        {location.pathname.includes('myprofile') ?
                            <TransparentBtn onClick={() => navigate(`/editTrip/${res?._id}`)} sx={{ color: '#30563A', px: 3 }}>Edit Trip</TransparentBtn> :
                            res.status === 'active' && <Edit onClick={() => openMakeOfferModal(res)}>&nbsp; Make Offer&nbsp; </Edit>}
                    </Grid>
                </TripBox>
            })}
        </ScrollAbleGrid>

        <MakeOffer open={state.openMakeOffer} parentState={state} handle={handleChange} stateName="openMakeOffer" />

    </MainReviewGrid >
}
export default Trips