import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Box, Avatar, Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { stopLoading } from '../../features/auth/reducer';
import { getFunction, reload } from '../../features/auth/action';
import { NameSection, WhiteButton, GreenButton, Offered } from './Elements';
import moment from 'moment';
import {
  getFullName,
  getDurationFromDate,
  config,
  swalWithStyledButton,
  DateWithoutFormat,
} from '../../utills/constants';
import { ActivityDetails, Location, ReviewCount, ActivityHeading } from './Elements';
import {
  RatingCount,
  FeaturedItemImage,
  RatingBox,
  FeaturedItemTop,
  FeaturedItem,
} from './Elements';

import api from '../../api';
import star from '../../assets/home/star.png';
import defaultTrip from '../../assets/defaultTrip.png';
import bookmark from '../../assets/search/bookmark.png';
import verified from '../../assets/profile/verified.png';
import saveTripIcon from '../../assets/save trip icon.png';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import './style.css';
import { saveData } from '../../features/profile/reducer';

const TripBox = ({ handleChange, data, handleSearchButton }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo, role, isAuth } = useSelector((state) => state.auth);

  const saveTrip = async (id, hostId) => {
    handleChange('selectedTrip', data);
    if (!isAuth) {
      handleChange('restrictionModel', true);
    } else {
      let body = {
        tripId: id,
        hostId: hostId,
        name: 'userInfo',
        url: `/user/saveTrip/${userInfo?._id}`,
      };
      await api.put(body.url, body, config).then((res) => {
        if (
          res.data.message == `This user doesn't exist.` ||
          res.data.message == 'This user has blocked you.'
        ) {
          handleChange('savedTripModal', false);
          swalWithStyledButton
            .fire({
              icon: 'error',
              allowOutsideClick: false,
              allowEscapeKey: false,
              text: res.data.message,
              confirmButtonText: 'Reload',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                dispatch(stopLoading());
                dispatch(reload(userInfo?._id, navigate));
                handleSearchButton();
              } else if (result.isDenied) {
                dispatch(stopLoading());
                dispatch(reload(userInfo?._id, navigate));
                handleSearchButton();
              }
            });
        } else {
          let _body = {
            name: 'userInfo',
            url: `/user/getUser/${userInfo?._id}`,
          };
          dispatch(getFunction(_body));
          handleChange('savedTripModal', true);
        }
      });

      // updateUser()
    }
  };
  const unSaveTrip = () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
    } else {
      handleChange('selectedTrip', data);
      handleChange('unSaveTripModal', true);
    }
  };
  const getSubsctiptionStatus = async () => {
    const customerId = userInfo?.customerId; // Replace with the actual customer ID
    let status = '';
    await api.get(`/stripe/getActive/${customerId}`, config).then((res) => {
      status = res.data.status;
    });
    return status;
  };
  const openMakeOfferModal = async () => {
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    // const subsStatus = await getSubsctiptionStatus();
    // console.log('subsStatus', subsStatus);
    // if (
    //   subsStatus !== 'Premium' &&
    //   subsStatus !== 'PremiumC'
    //   // subsStatus === 'freemium' ||
    //   // subsStatus === 'No subscriptions found'
    // ) {
    //   handleChange('freeUser', true);
    // }
    // else {
    handleChange('openMakeOffer', true);
    handleChange('selectedTrip', data);
    // }
  };
  const openMessageBox = async (hostdata) => {
    console.log('openMessageBox', hostdata);
    localStorage.setItem('customerId', hostdata?.customerId);
    if (!isAuth) {
      handleChange('restrictionModel', true);
      return;
    }
    const subsStatus = await getSubsctiptionStatus();
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      handleChange('freeUser', true);
      return;
    }
    handleChange('selectedUser', hostdata);
    dispatch(saveData({ name: 'profileInfo', data: hostdata }));
    handleChange('openMessage', true);
  };
  const getSavedTripsIcon = (s_id) => {
    const isExists = userInfo?.savedTrips?.filter((trip) => trip?.tripId?._id === s_id);
    if (isExists?.length > 0)
      return <img alt="unSaveTrip" onClick={unSaveTrip} src={saveTripIcon} />;
    else
      return (
        <img src={bookmark} alt="bookmark" onClick={() => saveTrip(data?._id, data?.hostId?._id)} />
      );
  };

  const handleNavigation = async () => {
    if (role === 'user') {
      navigate(`/profile/public/${data?.hostId?._id}`);
      // const subsStatus = await getSubsctiptionStatus();
      // if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      //   console.log("abdullah is working")
      //   handleChange('freeUser', true);
      //   return;
      // } else {
      // }
    } else if (role === 'freemium' || role === 'guest') {
      handleChange('restrictionModel', true);
    }
  };

  const sd = DateWithoutFormat(data?.availableFrom);
  const sdy = parseInt(new Date(sd).getFullYear());
  const ed = DateWithoutFormat(data?.availableTo);
  const edy = parseInt(new Date(ed).getFullYear());
  let favlbl = '';

  if (sdy == edy) {
    favlbl = moment.utc(sd).format('MMM DD') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
  } else {
    favlbl = moment.utc(sd).format('MMM DD, YYYY') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
  }
  return (
    <>
      <FeaturedItem>
        <FeaturedItemTop>
          <span>
            {data?.hostId?.identityStatus === 'verified' ? (
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <img alt="Remy Sharp" src={verified} style={{ width: '20px', height: '20px' }} />
                }
              >
                <Avatar src={data?.hostId?.image} sx={{ width: 40, height: 40 }} />
              </Badge>
            ) : (
              <Avatar src={data?.hostId?.image} sx={{ width: 40, height: 40 }} />
            )}
            <NameSection>
              <span onClick={handleNavigation}>{getFullName(data?.hostId)}</span>
              <RatingBox>
                <img className="imageShadow" src={star} alt="star" />
                <RatingCount>
                  {' '}
                  {data?.hostId?.rating > 0 ? data?.hostId?.rating : ''}{' '}
                </RatingCount>{' '}
                <ReviewCount> {data?.hostId?.reviews} reviews</ReviewCount>
              </RatingBox>
            </NameSection>
          </span>
          {getSavedTripsIcon(data?._id)}
        </FeaturedItemTop>

        <Carousel showThumbs={false} autoPlay={false} showStatus={false}>
          {data?.photos?.map((res) => {
            return (
              <FeaturedItemImage>
                <img src={res} alt="trade" />
              </FeaturedItemImage>
            );
          })}
        </Carousel>
        {/* <img src={data?.photos?.length > 0 ? data?.photos[0] : defaultTrip} alt="trade" /> */}
        {/* </FeaturedItemImage> */}
        <ActivityDetails>
          <Offered sx={{ maxHeight: '3rem' }}>
            {data?.title} {data?.tradeType}
          </Offered>
          <Location>
            {data?.location?.city}, {data?.location?.state}
          </Location>
          <ActivityHeading>IN RETURN FOR</ActivityHeading>
          <Offered sx={{ mb: 1.5 }}>{data?.returnActivity} </Offered>
          <ActivityHeading>TRIP Availability</ActivityHeading>
          <Offered sx={{ color: '#101B10' }}>
            {/* {getDurationFromDate(data?.availableFrom, data?.availableTo)} */}
            {favlbl}
          </Offered>
        </ActivityDetails>
        <Box sx={{ px: 1.4, pb: 2, flexDirection: 'row', display: 'flex' }}>
          <GreenButton
            onClick={openMakeOfferModal}
            sx={{ mr: 2, width: '100%', whiteSpace: 'nowrap' }}
            type="submit"
            variant="contained"
            disableripple
          >
            Make Offer
          </GreenButton>
          <WhiteButton
            onClick={() => openMessageBox(data?.hostId)}
            type="submit"
            variant="contained"
            disableripple
          >
            Message
          </WhiteButton>
        </Box>
      </FeaturedItem>
    </>
  );
};
export default TripBox;
