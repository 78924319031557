import React from 'react';
import { Heading, } from "./Elements"
import cross from "../../assets/cross.png"
import { Box, IconButton } from '@mui/material';

export const Header = ({ heading, handleClose }) => {
    return (<Box sx={{ display: 'flex' }}>
        <Heading>{heading}</Heading>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 20, top: 20 }}>
            <img src={cross} />
        </IconButton>
    </Box>
    );
}
export const CenterHeader = ({ heading, handleClose }) => {
    return (<Box sx={{ mx: 'auto' }}>
        <Heading>{heading}</Heading>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 20, top: 20 }}>
            <img src={cross} />
        </IconButton>
    </Box>
    );
}
