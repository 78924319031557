import React, { useEffect } from 'react';
import Loader from "../../assets/loader1.gif"

import { Header } from "./ModalHeader"
import { CancelButton } from "../Button"
import { ReviewFeedback } from "./Review"
import { TextArea } from '../InputFields/Input';
import { Box, Modal, Grid } from '@mui/material';
import { capitalize, swalWithStyledButton } from '../../utills/constants';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import { _updateFuntion } from "../../features/auth/action"
import { config } from '../../utills/constants';
import { style, GreenButton, InfoHeading } from "./Elements"
import { useLocation, useNavigate } from 'react-router-dom';
import { startLoading, stopLoading } from '../../features/auth/reducer';
import api from "../../api"
import { reload } from '../../features/auth/action';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2'
export const ReplyToReview = ({ apiCall, data, open, handle, stateName }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [state, setState] = React.useState({ message: "", openFeedbackModel: false })

    const handleChange = (name, value) => {

        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const handleClose = () => {
        handle([stateName], false); apiCall('reviews')
    };

    const { loading, userInfo } = useSelector(state => state.auth)

    const handleSubmit = async () => {
        dispatch(startLoading());
        if (state.message) {
            const body = { 'url': `/review/replyToReview/${data?._id}`, guestId: data?.guestId?._id, message: state.message, role: 'host' }
            await api
                .put(body.url, body, config)
                .then((res) => {
                    if (res.data.message == `This user doesn't exist.` || res.data.message == 'This user has blocked you.') {
                        handle([stateName], false);
                        swalWithStyledButton.fire({
                            icon: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            text: res.data.message,
                            confirmButtonText: 'Reload',
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            }
                        })
                            .then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    dispatch(reload(userInfo?._id, navigate))
                                    dispatch(stopLoading());
                                } else if (result.isDenied) {
                                    dispatch(reload(userInfo?._id, navigate))
                                    dispatch(stopLoading());
                                }
                            })

                    } else {
                        dispatch(stopLoading());
                        handleClose()
                    }

                })
                .catch((err) => {
                    if (err.response) {
                        toast.error(err.response.data.message, { duration: 4000, });
                        dispatch(stopLoading());
                    }
                });

            // dispatch(_updateFuntion(body, handleClose))
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: '600px', height: 'max-content', alignItems: 'flex-start', p: 3, }}>
                    <Header heading="Reply to review" handleClose={handleClose} />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={12}>
                            <InfoHeading>Message</InfoHeading>
                            <TextArea handle={handleChange} placeholder="What do you want to say?" />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', mt: 2, width: '100%' }}>
                        <GreenButton disabled={loading} type="submit" onClick={handleSubmit} variant="contained" disableripple sx={{ mr: 2, opacity: !state.message ? 0.4 : 1 }}>
                            {loading ? <LoaderSpan><img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Post Reply...</LoaderSpan> : ' Post Reply'}
                        </GreenButton>
                        <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
                            Cancel
                        </CancelButton>
                    </Box>
                </Box>
            </Modal>
            <ReviewFeedback open={state.openFeedbackModel} stateName="openFeedbackModel" handle={handleChange} />
        </div >
    );
}
export const EditReply = ({ apiCall, data, open, handle, stateName, state }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [_state, setState] = React.useState({ message: state?.editData?.message, openFeedbackModel: false })

    const handleChange = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    useEffect(() => {
        handleChange('message', state?.editData?.message)
        // eslint-disable-next-line

    }, [state?.editData?.message])

    const handleClose = () => {
        handle([stateName], false); apiCall('reviews')
    }

    const { loading, userInfo } = useSelector(state => state.auth)

    const handleSubmit = async () => {
        dispatch(startLoading());

        if (_state.message) {
            const body = {
                'url': `/review/editAReply/${data?._id}/${state?.editData?._id}`, guestId: data?.guestId?._id, hostId: data?.hostId?._id, message: _state.message, role: 'host',
                guestName: capitalize(userInfo?.firstName) + " " + capitalize(userInfo?.lastName),
                guestImage: userInfo?.image, isReviewEdited: location?.pathname?.includes('public') ? true : false,
            }
            await api
                .put(body.url, body, config)
                .then((res) => {
                    if (res.data.message == `This user doesn't exist.` || res.data.message == 'This user has blocked you.') {
                        handle([stateName], false);
                        swalWithStyledButton.fire({
                            icon: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            text: res.data.message,
                            confirmButtonText: 'Reload',
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            }
                        })
                            .then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    dispatch(stopLoading());
                                    dispatch(reload(userInfo?._id, navigate))

                                } else if (result.isDenied) {
                                    dispatch(stopLoading());
                                    dispatch(reload(userInfo?._id, navigate))
                                }
                            })
                    } else {
                        dispatch(stopLoading());
                        handleClose()
                    }

                })
                .catch((err) => {
                    if (err.response) {
                        toast.error(err.response.data.message, { duration: 4000, });
                        dispatch(stopLoading());
                    }
                });
            // dispatch(_updateFuntion(body, handleClose))
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: '600px', height: 'max-content', alignItems: 'flex-start', p: 3, }}>
                    <Header heading="Edit Review" handleClose={handleClose} />
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InfoHeading>Message</InfoHeading>
                            <TextArea value={_state?.message} handle={handleChange} placeholder="What do you want to say?" />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', mt: 2 }}>
                        <GreenButton disabled={loading} type="submit" onClick={handleSubmit} variant="contained" disableripple sx={{ mr: 2, opacity: !_state.message ? 0.4 : 1 }}>
                            {loading ? <LoaderSpan><img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Update Reply...</LoaderSpan> : ' Update Reply'}
                        </GreenButton>
                        <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
                            Cancel
                        </CancelButton>
                    </Box>
                </Box>
            </Modal>
            <ReviewFeedback open={_state.openFeedbackModel} stateName="openFeedbackModel" handle={handleChange} />
        </div >
    );
}