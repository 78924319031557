import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "../../../features/general/action";
import { subscriptionLeft, successSubscriptionLeft } from "../../../data"
import { CrossDesktopButton, CrossMobileButton } from "../../../components/Button/CrossButton"
import { MobileGrid, RightGrid, RightSubGrid, RightSubGridStep2, MainGrid } from "../Components/Elements";

import Step1 from "./Step1"
import Step2 from "./Step2"
import Step3 from "./Step3"
import Logo from "../../../components/Logo"
import LeftPanel from "../Components/LeftPanel";
import CustomCopyright from "../../../components/Footer/CopyRight"

import "./style.css"

const SubscriptionPlan = () => {
    const dispatch = useDispatch()

    const [state, setState] = useState({ plan: 'annual', step: 1, total: 0, monthly: 0 })
    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    const { plans } = useSelector(state => state.general)

    useEffect(() => {
        dispatch(getPlans())
        const results = plans?.filter(obj => obj?.type === 'annual')[0]
        handleChange('total', results?.charges * 12)
        handleChange('monthly', results?.charges)
        handleChange('discount', results?.discount)
        // eslint-disable-next-line
    }, [])

    const getPlanPrice = (planType) => {
        const results = plans?.filter(obj => obj?.type === planType)
        if (results?.length > 0) { return `$${results[0]['charges']}` }
    }

    return <MainGrid container spacing={2} >
        {state.step === 1 &&
            <>
                <MobileGrid >
                    <Logo /><CrossMobileButton /></MobileGrid>
                <LeftPanel data={subscriptionLeft} />
                <RightGrid item xs={12} sm={12} md={6} lg={6}>
                    <CrossDesktopButton />
                    <RightSubGrid><Step1 getPlanPrice={getPlanPrice} handleChange={handleChange} state={state} /></RightSubGrid>
                </RightGrid>
            </>}
        {state.step === 2 &&
            <>
                <MobileGrid >
                    <Logo /><CrossMobileButton /></MobileGrid>
                <LeftPanel data={state.step === 3 ? successSubscriptionLeft : subscriptionLeft} />
                <RightGrid item xs={12} sm={12} md={6} lg={6}>
                    <CrossDesktopButton />
                    <RightSubGridStep2><Step2 getPlanPrice={getPlanPrice} handleChange={handleChange} state={state} /></RightSubGridStep2></RightGrid>
            </>}
        {state.step === 3 &&
            <>
                <MobileGrid >
                    <Logo /><CrossMobileButton /></MobileGrid>
                <LeftPanel data={state.step === 3 ? successSubscriptionLeft : subscriptionLeft} />

                <RightGrid item xs={12} sm={12} md={6} lg={6}>
                    <CrossDesktopButton /><Step3 getPlanPrice={getPlanPrice} handleChange={handleChange} state={state} />
                </RightGrid>
            </>}

        <CustomCopyright />

    </MainGrid>
}
export default SubscriptionPlan