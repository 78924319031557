import React from "react";
import Sidebar from "../../components/Sidebar"

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MainBox, UserName } from "../Elements"
import { useMediaQuery, Grid } from "@mui/material"
import { FormBox, Edit, TransparentBtn, InstructionsSpan } from "./Elements"

const Support = () => {

    const matches600 = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate()

    const { userInfo } = useSelector(state => state.auth)
    return <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }} >
        <Sidebar />
        <FormBox sx={{ width: '38%' }}>
            <UserName sx={{ fontSize: '18px' }}> Got it! We’re on it.</UserName>
            <InstructionsSpan >Your support request has been received and we’ll respond as soon as possible (usually 1-3 business days).
            </InstructionsSpan>
            <InstructionsSpan >If you do not see a response after 3 days, please check your Spam or Junk Mail folder.</InstructionsSpan>

            <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 2, mb: 1, justifyContent: 'flex-start' }}>
                <Edit onClick={() => navigate('/search')} type="submit" variant="contained" disableripple sx={{ marginRight: 2, px: { lg: 4 } }} >
                    Find Trips
                </Edit>
                <TransparentBtn onClick={() => navigate(`/profile/${userInfo?._id}`)} sx={{ px: { lg: 4 }, border: 'none' }}>Go to My Profile</TransparentBtn>

            </Grid>
        </FormBox>
    </MainBox >
}
export default Support