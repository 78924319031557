import React, { useState } from "react";

import ReviewBox from "./ReviewBox"

import { useSelector } from "react-redux";
import { CountName } from "../Elements"
import { ScrollAbleGrid, } from "../Elements"
import { ReplyToReview, EditReply } from "../../../components/Modal/ReplyToReview"
import { OpenDispute } from "../../../components/Modal/DisputeModals"
import { DeleteReview, DeleteReply } from "../../../components/Modal/Review"

const PrivateReviews = ({ blockedUsers, apiCall }) => {
    const { reviews } = useSelector(state => state.auth)

    const [state, setState] = useState({ edited: {}, replied: {}, dispute: {}, delete: {}, openDeleteReply: false, openEditModal: false, openReviewModal: false, openDisputeModal: false, openDeleteReview: false })

    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    return <>
        <CountName sx={{ my: 2 }}>Showing {reviews?.length} of {reviews?.length}</CountName>
        <ScrollAbleGrid>
            {reviews?.map((res, i) => {
                
                return <ReviewBox blockedUsers={blockedUsers} handleChange={handleChange} state={state} key={i} res={res} />
            })}
        </ScrollAbleGrid>

        <EditReply apiCall={apiCall} data={state.edited} state={state} open={state.openEditModal} handle={handleChange} stateName="openEditModal" />
        <ReplyToReview apiCall={apiCall} data={state.replied} open={state.openReviewModal} handle={handleChange} stateName="openReviewModal" />
        <OpenDispute apiCall={apiCall} data={state.dispute} open={state.openDisputeModal} handle={handleChange} stateName="openDisputeModal" />
        <DeleteReview apiCall={apiCall} data={state.delete} open={state.openDeleteReview} handle={handleChange} stateName="openDeleteReview" />
        <DeleteReply apiCall={apiCall} data={state.delete} open={state.openDeleteReply} handle={handleChange} stateName="openDeleteReply" />

    </>
}
export default PrivateReviews