import React from 'react';

import { Header } from './ModalHeader';
import { CancelButton } from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Modal, Grid } from '@mui/material';
import { style, GreenButton, Description, PhotoGrid, Instruction, RedButton } from './Elements';
import {
  getFunction,
  updateFuntion,
  deleteFuntion,
  _updateFuntion,
} from '../../features/auth/action';

import { LoaderSpan } from '../../pages/SignIn/Elements';
import Loader from '../../assets/loader1.gif';
import saveTrip from '../../assets/saveTripModal.png';
import { stopLoading } from '../../features/auth/reducer';
import { Text } from '../Footer/Elements';

export const SaveTrip = ({ open, handle, stateName, data }) => {
  console.log('handleClose', stateName);
  const handleClose = () => handle([stateName], false);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: '388px',
          height: '380px',
          alignItems: 'center',
          px: 3,
          pb: 3,
          pt: 2,
        }}
      >
        <Header heading="Trip Saved" handleClose={handleClose} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={saveTrip} alt="msgSent" />
          <Description sx={{ fontWeight: 600, my: 1 }}>“{data?.selectedTrip?.title}”</Description>
        </Box>
        <Grid sx={{ width: '100%' }}>
          <GreenButton
            onClick={handleClose}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            Done
          </GreenButton>
        </Grid>
      </Box>
    </Modal>
  );
};
export const DeleteTrip = ({ open, handle, stateName, deleteLoading, id, getTrip, data }) => {
  console.log('DELETE TRIP', stateName);
  const handleClose = (name) => {
    handle([stateName], false);
    if (name === 'keepIt') {
      handle('deleteLoading', false);
    } else {
      handle('deleteLoading', true);
    }
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const handleSuccess = () => {
    handleClose();
    navigate('/search');
  };
  const handleButton = () => {
    const body = { url: `/trip/${id}`, status: 'suspended' };
    dispatch(deleteFuntion(body, handleSuccess));
    dispatch(stopLoading());
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '350px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Delete Trip?" handleClose={handleClose} />
          <Description sx={{ fontWeight: 600 }}>“{data?.title}”</Description>
          <Instruction sx={{ textAlign: 'center', mb: -0.5 }}>
            This action cannot be undone. Any open offers for this trip will be automatically
            declined.
          </Instruction>
          <Grid sx={{ width: '100%' }}>
            <RedButton
              onClick={handleButton}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                  delete it now
                </LoaderSpan>
              ) : (
                ' Yes, delete it now'
              )}
            </RedButton>
            <CancelButton
              onClick={() => handleClose('keepIt')}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              No, keep it
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const SuspendTrip = ({ getTrip, open, handle, stateName, data, id }) => {
  const handleClose = (name) => {
    handle([stateName], false);
    if (name === 'keepIt') {
      handle('suspendLoading', false);
    } else {
      //   handle("suspendLoading", true);
      //   setTimeout(() => {
      // handle("suspendLoading", false);
      //   }, 1000);
    }
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const handleSuccess = () => {
    getTrip(id);
    handleClose();
  };
  const handleButton = () => {
    const body = { url: `/trip/suspendTrip/${id}`, status: 'suspended' };
    dispatch(_updateFuntion(body, handleSuccess));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '350px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Suspend Trip?" handleClose={handleClose} />
          <Description sx={{ fontWeight: 600 }}>“{data?.title}”</Description>
          <Instruction sx={{ textAlign: 'center', mb: -0.5 }}>
            This will hide the trip from public view, but you can still edit the details or
            reactivate it any time.
          </Instruction>
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              disabled={loading}
              onClick={handleButton}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                  suspend it now
                </LoaderSpan>
              ) : (
                ' Yes, suspend it now'
              )}
            </GreenButton>
            <CancelButton
              type="submit"
              onClick={() => handleClose('keepIt')}
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              No, keep it active
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const ActivateTrip = ({ open, handle, stateName, data, id }) => {
  const handleClose = () => {
    handle([stateName], false);
    handle('activateLoading', true);
    setTimeout(() => {
      handle('activateLoading', false);
    }, 1000);
  };
  const dispatch = useDispatch();
  const body = { url: `/trip?_id=${id}`, name: 'trip' };
  const handleSuccess = () => {
    dispatch(getFunction(body));
    handleClose();
  };
  const handleButton = () => {
    const body = { url: `/trip/suspendTrip/${id}`, status: 'active' };
    dispatch(_updateFuntion(body, handleSuccess));
  };
  const { loading } = useSelector((state) => state.auth);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: '350px',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Activate Trip?" handleClose={handleClose} />
          <Description sx={{ fontWeight: 600 }}>“{data?.title}”</Description>
          <Instruction sx={{ textAlign: 'center', mb: -0.5 }}>
            This will immediately make the trip public and available to receive trade offers.
          </Instruction>
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              disabled={loading}
              onClick={handleButton}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                  activate it now
                </LoaderSpan>
              ) : (
                ' Yes, activate it now'
              )}
            </GreenButton>
            <CancelButton
              type="submit"
              onClick={handleClose}
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              No, keep it suspended
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};
export const DeleteTripPhoto = ({ apiCall, data, open, handle, stateName }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const handleClose = () => {
    handle([stateName], false);
    apiCall('photos');
  };

  const deleteImage = () => {
    const body = {
      url: `/trip/deleteImage/${data?.selectedImage?._id}`,
      _id: data?.selectedImage?._id,
      photo: data?.selectedImage?.photos,
    };
    dispatch(updateFuntion(body));
    handleClose();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 'max-content',
            height: 'max-content',
            alignItems: 'center',
            px: 3,
            pb: 3,
            pt: 2,
          }}
        >
          <Header heading="Delete Photo?" handleClose={handleClose} />
          <PhotoGrid sx={{ mt: 3, mb: 1 }}>
            <img src={data?.selectedImage?.photos} alt="trip" />
          </PhotoGrid>
          <Instruction>This action cannot be undone.</Instruction>
          <RedButton
            onClick={deleteImage}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            Yes, delete photo
          </RedButton>
          <CancelButton
            onClick={handleClose}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            No, keep it
          </CancelButton>
        </Box>
      </Modal>
    </div>
  );
};
export const UnSaveTrip = ({ data, deleteFunction, open, handle, stateName }) => {
  const handleClose = () => handle([stateName], false);
  const { loading } = useSelector((state) => state.auth);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: '388px',
          height: '380px',
          alignItems: 'center',
          px: 3,
          pb: 3,
          pt: 2,
        }}
      >
        <Header heading="Delete Saved Trip" handleClose={handleClose} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={saveTrip} alt="msgSent" />
          <Description sx={{ fontWeight: 600, my: 1 }}>
            “{data?.selectedTrip?.title || data?.title}”
          </Description>
        </Box>
        <Grid sx={{ width: '100%' }}>
          <RedButton
            onClick={deleteFunction}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            {loading ? (
              <LoaderSpan>
                <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Yes,
                remove it now
              </LoaderSpan>
            ) : (
              ' Yes, remove it now'
            )}
          </RedButton>
          <CancelButton
            onClick={handleClose}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            No, keep it
          </CancelButton>
        </Grid>
      </Box>
    </Modal>
  );
};
export const DeclineBox = ({
  loading,
  text,
  title,
  data,
  deleteFunction,
  open,
  handle,
  stateName,
  buttonText,
}) => {
  const handleClose = () => handle([stateName], false);
  // const { loading } = useSelector(state => state.trip)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          width: '388px',
          height: '380px',
          alignItems: 'center',
          px: 3,
          pb: 3,
          pt: 2,
        }}
      >
        <Header heading={`${text}`} handleClose={handleClose} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <img src={saveTrip} alt="msgSent" /> */}
          <Description sx={{ fontWeight: 600, my: 1 }}>“{title}”</Description>
          <Text sx={{textAlign: "center"}}>Are you sure you would like to decline this offer?</Text>
        </Box>

        <Grid sx={{ width: '100%' }}>
          <RedButton
            onClick={deleteFunction}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            {loading ? (
              <>
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />{' '}
                </LoaderSpan>
                {buttonText}
              </>
            ) : (
              buttonText
            )}
          </RedButton>
          <CancelButton
            onClick={handleClose}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            No, keep it
          </CancelButton>
        </Grid>
      </Box>
    </Modal>
  );
};
