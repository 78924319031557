import React, { useState } from 'react';
import * as yup from 'yup';
import moment from 'moment';
import api from '../../../api';
import Loader from '../../../assets/loader1.gif';

import { useForm } from 'react-hook-form';
import { BrowseFiles } from '../../Elements';
import { LoaderSpan } from '../../SignIn/Elements';
import { config } from '../../../utills/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, FormHelperText } from '@mui/material';
import { updateAccount } from '../../../features/auth/action';
import { CustomGreenLargeButton } from '../../../components/Button';
import { CustomLabel2 } from '../../../components/InputFields/Elements';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Input, PromoInput, CustomCheckboxWithSmallLabel } from '../../../components/InputFields';
import { SubHeading, Heading, Details, Light, Dark, DarkWithLine } from '../Components/Elements';
import { subtractPercentage } from '../../../utills/constants';

import './style.css';
import './stripe.css';
import toast from 'react-hot-toast';

const schema = yup.object().shape({
  fullName: yup.string().required('Please enter your full name.'),
  terms: yup.boolean().oneOf([true], 'Agreement to Terms and Conditions is required.'),
});

const Step2 = ({ handleChange, state }) => {
  const dispatch = useDispatch();

  const [prevState, setPrevState] = useState({ ...state });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { loading, userInfo } = useSelector((state) => state.auth);
  const [strpeLoading, setStripeLoading] = useState(false);
  console.log('strpeLoading', strpeLoading);
  const [code, setcode] = useState('');
  const [isPromo, setIsPromo] = useState(false);
  const [promoDisc, setpromoDisc] = useState('');
  const [codevalid, setcodevalid] = useState(null);
  const [promoDiscAmt, setpromoDiscAmt] = useState(0);

  async function sendPaymentFailedNotification() {
    const notificationBody = {
      title: `Your subscription payment failed`,
      userId: userInfo?._id,
      message: `Please review your payment details. You can update your payment method under Settings`,
      icon: 'https://triptrader-assets.s3.amazonaws.com/dispute-1677063279066.png',
      data: { topic: 'paymentFailed' },
    };
    await api.post(`/user/sendNotification/${userInfo?._id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: notificationBody,
    });
  }

  const submitFunction = async () => {
    let body = {
      // customerId: customerId,
      subscriptionStatus: 'paid',
      subscription: {
        promoCode: code,
        status: 'active',
        title: state.plan,
        amtPaid: state.total,
        startDate: new Date(),
        charges: state.monthly,
        discount: state.discount,
        promoCodeDiscount: promoDisc,
        promoCodeDiscountAmt: promoDiscAmt,
        endDate: state.plan === 'annual' ? moment().add(12, 'month') : moment().add(1, 'month'),
      },
    };

    dispatch(updateAccount(body, handleChange('step', 3), 'otherPage'));
  };
  const handlePromoInput = (e) => {
    setError(null);
    setcode(e.target.value);
    setcodevalid(null);
  };
  const handleRemove = () => {
    handleChange('total', prevState?.total);
    setcode('');
    setpromoDisc(0);
    setpromoDiscAmt(0);
    setcodevalid(null);
  };

  // STRIPE CODE

  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [processing, setProcessing] = useState('');
  const [succeeded, setSucceeded] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Poppins-Regular',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        fontFamily: 'Poppins-Regular',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const handleChangeKey = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmitStripe = async (e) => {
    // e.preventDefault();
    setStripeLoading(true);
    const body = {
      customerId: userInfo?.customerId,
      priceId: state?.priceId,
      promoCode: code,
    };
    await api
      .post(`/stripe/create-subscription`, body, config)
      .then(async (data) => {
        console.log('create Subscription', data);
        const cardElement = elements.getElement(CardElement);

        if (data?.data?.clientSecret !== null) {
          let { error, paymentIntent } = await stripe.confirmCardPayment(data?.data?.clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details: {
                email: userInfo?.email,
              },
            },
          });
          console.log('error line 156', error);
          const body = {
            subscriptionId: data?.data?.subscriptionId, // subscribed key in the primaryCard
            newPaymentMethod: paymentIntent?.payment_method, //card Id
          };
          await api.post('/cardmangment/UpdateSubcribedCardInformation', body);
          if (error) {
            toast.error(error?.message, { duration: 2000 });
            setProcessing(false);
            setStripeLoading(false);
            return;
          }
          if (paymentIntent?.error) {
            console.log('paymentIntent Error', paymentIntent);
            setError(`Payment failed ${paymentIntent.error.message}`);
            sendPaymentFailedNotification();
            setProcessing(false);
            setStripeLoading(false);
          } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            submitFunction();
            setStripeLoading(false);
          }
        } else {
          try {
            stripe
              .createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                  name: e?.fullName,
                },
              })
              .then(async function (result) {
                // console.log('THE RESULT OF THE API IS', result?.paymentMethod?.id);
                const UpdateCardbody = {
                  payment_card_id: result?.paymentMethod?.id,
                  customer_id: userInfo?.customerId,
                  // customer_id: 'cus_OZX32K61HE82S4',
                };
                const resp = await api
                  .post('/cardmangment/updateCardInfo', UpdateCardbody)
                  .then(async (resp) => {
                    const createSetupIntentBody = {
                      customerId: userInfo?.customerId,
                      paymentMethods: result?.paymentMethod?.id,
                    };
                    await api
                      .post('/stripe/create-setupIntents', createSetupIntentBody)
                      .then(async (resp) => {
                        if (resp?.data?.message === 'sucessfully create setup intents') {
                          const body = {
                            subscriptionId: data?.data?.subscriptionId, // subscribed key in the primaryCard
                            newPaymentMethod: result?.paymentMethod?.id, //card Id
                          };
                          await api.post('/cardmangment/UpdateSubcribedCardInformation', body);
                          submitFunction();
                        }
                      });
                  });
              });
          } catch (error) {
            toast.error(error?.response?.data?.message, { duration: 4000 });
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
        if (err.response) {
          setStripeLoading(false);
          setError(`Payment failed ${err.response.data.error.message}`);
          setcodevalid(false);
        }
      });
  };

  const handlePromoCode = () => {
    setError(null);
    api
      .get(`/stripe/getCodeDetails/${code}`, config)
      .then((data) => {
        if (data?.data?.coupon?.valid == true) {
          setpromoDisc(data?.data?.coupon?.percent_off);
          setpromoDiscAmt(data?.data?.coupon?.percent_off);
          if (data?.data?.coupon?.valid == true) {
            setcodevalid(true);
            const finalAmount = subtractPercentage(
              prevState?.total,
              data?.data?.coupon?.percent_off
            );
            handleChange('total', finalAmount.toFixed(2));
          }
        } else {
          setcodevalid(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          setcodevalid(false);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitStripe)} className="submitForm">
      <Heading>{'Payment Information'}</Heading>
      <SubHeading>
        {'Your subscription will start after you make your first payment below.'}
      </SubHeading>
      <Divider />
      <div>
        <Details>
          <Light>Total Due Now:&nbsp; </Light>
          {promoDisc > 0 ? (
            <>
              <Dark>
                <s>
                  {state?.plan === 'annual' ? `$${prevState?.total}` : `$${state?.monthly} /mo`}
                </s>
              </Dark>
              &nbsp;&nbsp;
              <DarkWithLine>
                <span style={{ color: '#1E3625 !important' }}>
                  {`$${Number(state?.total)} (${promoDisc} % discount)`}
                </span>
              </DarkWithLine>
            </>
          ) : (
            <Dark>
              {state?.plan === 'annual'
                ? `$${prevState?.total} ( $${state?.monthly} /mo )`
                : `$${state?.monthly} /mo`}
            </Dark>
          )}
        </Details>
        <Details>
          <Light>Your Plan:&nbsp; </Light>
          <Dark> {state?.plan === 'annual' ? 'Annual' : 'Monthly'}</Dark>
          &nbsp;&nbsp; <BrowseFiles onClick={() => handleChange('step', 1)}>Change</BrowseFiles>
        </Details>
      </div>
      <Input
        title="Full Name"
        placeholder="Card holder’s first and last name"
        error={errors.fullName?.message}
        register={register}
        registerFor="fullName"
      />
      <div>
        <CustomLabel2>Card</CustomLabel2>
        <CardElement id="card-element" options={cardStyle} onChange={handleChangeKey} />
        {error && (
          <FormHelperText
            sx={{
              fontFamily: 'Poppins-Medium',
              color: '#d32f2f',
              marginTop: 0,
            }}
          >
            {error}
          </FormHelperText>
        )}
      </div>
      {!isPromo ? (
        <BrowseFiles onClick={() => setIsPromo(true)}>I have a promo code</BrowseFiles>
      ) : (
        <PromoInput
          handleRemove={handleRemove}
          handlePromoInput={handlePromoInput}
          codevalid={codevalid}
          code={code}
          isPromo={isPromo}
          error={codevalid}
          setcode={setcode}
          title="Promo Code"
          handlePromoCode={handlePromoCode}
          placeholder="Add a promo code"
        />
      )}

      <CustomCheckboxWithSmallLabel
        register={register}
        registerFor="terms"
        error={errors.terms?.message}
        title={
          <>
            I agree to Trip Trader’s <a href="/#/termsandconditions">Terms and Conditions</a> and
            understand that upon clicking “Subscribe” below, I will be charged ${state.total}{' '}
            {state.plan === 'annual' ? 'annually' : 'monthly'}.
          </>
        }
      />

      {strpeLoading ? (
        <CustomGreenLargeButton variant="contained">
          <LoaderSpan>
            <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
            Processing payment...
          </LoaderSpan>
        </CustomGreenLargeButton>
      ) : (
        <CustomGreenLargeButton
          type={'submit'}
          variant="contained"
          disbale={strpeLoading}
          disableripple
        >
          Subscribe
        </CustomGreenLargeButton>
      )}
      {/* <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <Skip onClick={() => navigate('/plan')}>Cancel and go back</Skip>
    </div> */}
    </form>
  );
};

export default Step2;
