import { styled } from '@mui/material/styles';
import { Button, Grid, Box } from '@mui/material';

export const Instruction = styled('p')(() => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: "rgba(17, 17, 17, 0.6)",
    fontSize: '13px',
    cursor: 'pointer',
    marginBottom: 8,
}));
export const ImageContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    "& span": {
        position: "absolute",
        top: 70
    }
}));
export const Heading = styled('h4')(() => ({
    margin: 0,
    fontFamily: 'Poppins-SemiBold',
    fontSize: '25px',
    color: "#101B10",
}));
export const TripDetailsGrid = styled(Grid)(() => ({
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: "16px 8px"
}));
export const HeadingSub = styled('p')(() => ({
    marginBottom: 2,
    fontFamily: 'Poppins-SemiBold',
    color: "#111111",
    cursor: 'pointer',
    marginTop: 3,
}));
export const GreyText = styled('p')(() => ({
    fontFamily: 'Poppins-Regular',
    marginBottom: 0,
    marginTop: 4,
    color: "#111111",
    cursor: 'pointer',
    fontSize: 12,
    opacity: 0.6
}));
export const Description = styled('span')(() => ({
    fontFamily: 'Poppins-Regular',
    color: "#101B10",
    fontSize: 15,
    textAlign: 'center',
    '@media(max-width: 900px)': {
        fontSize: 12,
    },
    // marginBottom: 6,
}));
export const Option = styled('li')(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    padding: "10px 15px",
    fontSize: '14px',
    color: '#111111',
    '@media(max-width: 600px)': {
        fontSize: '12px',
        padding: "5px 12px",
    },
}));
export const CustomOption = styled('li')(() => ({
    fontFamily: 'Poppins-SemiBold',
    padding: "10px 15px",
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    background: '#EEF6EF',
    color: '#3C6B49',
}));
export const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    borderRadius: 4,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 900px)': {
        width: '70% !important'
    },
    '@media(max-width: 600px)': {
        width: '90% !important'
    },
};
export const Text = styled(Box)(() => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#111111',
    "& span": {
        textDecorationLine: "underline",
        color: "#1E3625",
        fontWeight: 500,
        textUnderlineOffset: "3px",
        cursor: 'pointer',

    }
}));
export const TransparentBtn = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: '8px',
    color: '#30563A',
    border: '1px solid #DFDFDF',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    marginRight: 10,
    cursor: 'pointer',
    padding: "8px 22px",
    height: '44px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#FFFFFF',
        boxShadow: 'none',
    },
    '&:active': {
        backgroundColor: '#FFFFFF',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #FFFFFF',
    },
}));
export const RedButton = styled(Button)(() => ({
    background: "#B93B3B",
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 22px",
    height: '44px',
    textTransform: 'capitalize',
    borderRadius: "8px",
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#B93B3B',
        borderColor: '#B93B3B',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#B93B3B',
        borderColor: '#B93B3B',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #B93B3B',
    },
}));
export const Footer = styled(Grid)(() => ({
    display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center',
    '@media(max-width: 400px)': {
        flexDirection: 'column',
        justifyContent: 'flex-start'
    },
}));