import React from 'react';
import star from '../../../assets/home/star.png';
import userComplete1 from '../../../assets/home/userComplete1.png';
import defaultTrip from '../../../assets/home/defaultTrip.png';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Avatar } from '@mui/material';
import { CustomGreenLargeButton } from '../../../components/Button';
import { Heading, Black, Orange, Description } from './Elements';
import {
  MainContainer,
  NameSection,
  Return,
  Offered,
  ActivityDetails,
  ReviewCount,
} from './Elements';
import {
  ActivityHeading,
  RatingCount,
  FeaturedItemImage,
  RatingBox,
  FeaturedItemTop,
  FeaturedItem,
} from './Elements';

const Featured = ({ refs }) => {
  const { featuredTrips } = useSelector((state) => state.general);
  const navigate = useNavigate();
  const redirect = (id) => {
    navigate(`/profile/public/${id}`);
  };
  return (
    <MainContainer maxWidth="xl" ref={refs}>
      <Heading>
        <Orange> Featured </Orange> <Black> Traders</Black>
      </Heading>
      <Description>Here are a few of our most popular trip traders.</Description>
      <Grid container sx={{ justifyContent: 'center' }}>
        {featuredTrips?.map((res, i) => {
          return (
            <FeaturedItem item key={i} xs={12} sm={12} md={6} lg={4}>
              <FeaturedItemTop>
                <Avatar src={res?.hostId?.image} alt="user"></Avatar>
                <NameSection>
                  <span>{`${res?.hostId?.firstName} ${res?.hostId?.lastName} `}</span>
                  <RatingBox>
                    <img src={star} className="imageShadow" alt="star" />
                    <RatingCount> {res?.hostId?.rating} </RatingCount>{' '}
                    <ReviewCount> {res?.hostId?.reviews} reviews</ReviewCount>
                  </RatingBox>
                </NameSection>
              </FeaturedItemTop>
              <FeaturedItemImage>
                <img
                  style={{
                    objectFit: 'contain',
                  }}
                  src={res?.photos?.length ? res?.photos[0] : defaultTrip}
                  alt="trade"
                />
              </FeaturedItemImage>
              <ActivityDetails>
                <ActivityHeading>OFFERING</ActivityHeading>
                <Offered sx={{ textTransform: 'capitalize' }}>
                  {res?.title} {res?.tradeType}
                </Offered>
                <ActivityHeading>In return for</ActivityHeading>
                <Return sx={{ textTransform: 'capitalize' }}>{res?.returnActivity} </Return>
              </ActivityDetails>
              <Box sx={{ px: 1.4, pb: 2 }}>
                <CustomGreenLargeButton
                  onClick={() => {
                    redirect(res?.hostId?._id);
                  }}
                  type="submit"
                  variant="contained"
                  disableripple
                >
                  View Profile
                </CustomGreenLargeButton>
              </Box>
            </FeaturedItem>
          );
        })}
      </Grid>
    </MainContainer>
  );
};
export default Featured;
