import React, { useRef } from 'react';

import { CalendarIcon } from "../../utills/constants"
import { Option } from '../../pages/MakeOffer/Elements';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Label, CustomLabel2, CustomAutocomplete } from "./Elements"
import { textFieldStyles, daysdropdownStyles, dateStyles } from "./styles"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Checkbox, TextField, FormControlLabel, FormControl, FormHelperText } from '@mui/material'

export const Input = ({ title, value, placeholder, error, onChange }) => {
    const classes = textFieldStyles();
    return (
        <div>
            {title && <CustomLabel2>{title}</CustomLabel2>}
            <TextField
                onChange={onChange}
                value={value}
                sx={{ width: "100%" }}
                className={classes.root}
                error={error ? true : false}
                helperText={error}
                inputProps={{ style: { color: "#1E3625", zIndex: 20, height: { lg: '25px' } } }}
                placeholder={placeholder}
            />
        </div>
    );
}
export const CustomCheckbox = ({ title, error, value, handleChange, handleFor }) => {
    return (
        <FormControl component="fieldset" variant="standard">
            <FormControlLabel
                label={<Label>{title}</Label>}
                control={<Checkbox style={{ color: "#1E3625" }} checked={value} onChange={() => handleChange(handleFor, !value)} />}
            />
            <FormHelperText sx={{ fontFamily: "Poppins-Medium", color: "#d32f2f", marginTop: 0 }}>{error}</FormHelperText>
        </FormControl>
    );
}
export const Dropdown = ({ value, placeholder, data, handleChange, handleFor }) => {
    let defaultValue = { 'name': value === undefined ? '' : value }
    const classes = daysdropdownStyles();
    return <CustomAutocomplete
        disableClearable
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e, value) => {
            if (handleFor === 'species') { handleChange('species', value?.name); handleChange('category', value?.category?.name) }
            else handleChange(handleFor, value?.name)
        }}
        options={data}
        getOptionLabel={(option) => handleFor == 'tradeType' && value != undefined ? `${option?.name} Trip` : option?.name}
        renderOption={(props, option) => {
            return (<Option {...props}>{handleFor == 'tradeType' ? `${option?.name} Trip` : option?.name}</Option>);
        }}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => <TextField id={"species-input"} {...params} placeholder={placeholder} className={classes.root} />
        }
    />
}

export const SpeciesDropdown = ({ value, placeholder, data, handleChange, handleFor }) => {
    let defaultValue = { 'name': value === undefined ? '' : value }
    const classes = daysdropdownStyles();
    return <CustomAutocomplete
        disableClearable
        placeholder={placeholder}
        defaultValue={defaultValue}

        onChange={(e, value) => {
            if (handleFor === 'species') { handleChange('species', value?.name); handleChange('category', value?.category?.name) }
            else handleChange(handleFor, value?.name)
        }}
        options={data}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
            return (<Option {...props}>{option?.name}</Option>);
        }}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} className={classes.root} />
        }
    />
}
export const EmptyDropdown = ({ placeholder }) => {
    const classes = daysdropdownStyles();
    return <CustomAutocomplete
        disableClearable
        options={[]}
        placeholder={placeholder}
        popupIcon={<KeyboardArrowDownIcon />}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} className={classes.root} />}
    />
}
export const DateInput = ({ title, error }) => {
    const classes = dateStyles();
    return (
        <div>
            <CustomLabel2>{title}</CustomLabel2>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    className={classes.root}
                    inputFormat="MM/DD/YYYY"
                    components={{ OpenPickerIcon: CalendarIcon }}
                    renderInput={(params) =>
                        <TextField {...params}
                            error={error ? true : false}
                            placeholder="Select a date range"
                            helperText={error}
                            inputProps={{ style: { color: "#1E3625", zIndex: 20 } }} sx={{ width: '100%' }} />}
                />
            </LocalizationProvider>
        </div>
    );
}