import { styled } from '@mui/material/styles';
import { Grid, Box, IconButton, Button } from '@mui/material';
import MuiTabs from '@mui/material/Tabs';

export const AddGrid = styled(Grid)(() => ({
  background: '#EEF6EF',
  borderRadius: 10,
  marginRight: '10px',
  width: '180px',
  height: '20.5vh',
  cursor: 'pointer',
  border: '1px dashed #3C6B49',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 5,
  '@media(max-width:800px)': {
    height: '21vh',
  },
}));
export const GreenButton = styled(Button)(() => ({
  background: '#3C6B49',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #3C6B49',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 20px',
  height: '44px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  cursor: 'pointer',
  '&:disabled': {
    backgroundColor: '#3C6B49',
    color: '#FFFFFF',
    borderColor: '#3C6B49',
    boxShadow: 'none',
  },
  '&:hover': {
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #3C6B49',
  },
}));
export const OtherUserMsg = styled(Box)(() => ({
  background: '#3C6B49',
  width: 'max-content',
  color: '#FAFAFA',
  fontFamily: 'Poppins-Light',
  fontSize: '14px',
  maxWidth: '400px',
  wordWrap: 'break-word',
  textAlign: 'left',
  padding: 10,
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  borderBottomRightRadius: 12,
}));
export const LoggedInUserMsg = styled(Box)(() => ({
  textAlign: 'left',
  borderTopLeftRadius: 12,
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  background: '#F2F3F1',
  padding: 10,
  width: 'max-content',
  fontSize: '14px',
  maxWidth: '400px',
  wordWrap: 'break-word',
  marginLeft: 'auto',
  marginTop: 5,
  marginBottom: 5,
  fontFamily: 'Poppins-Light',
}));
export const MainBox = styled(Grid)(() => ({
  display: 'flex',
  background: '#F9F9F9',
  justifyContent: 'center',
}));
export const LeftGrid = styled(Grid)(() => ({
  display: 'flex',
  background: 'white',
  flexDirection: 'column',
  height: 'max-content',
}));
export const SendButton = styled(IconButton)(() => ({
  background: '#3C6B49',
  '&:hover': {
    backgroundColor: '#3C6B49',
    borderColor: '#DFDFDF',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#3C6B49',
    borderColor: '#DFDFDF',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem white',
  },
}));
export const MessagesBox = styled(Box)(() => ({
  overflowY: 'auto',
  maxHeight: '62%',
}));
export const MessagesMobileBox = styled(Box)(() => ({
  overflowY: 'auto',
  maxHeight: '70%',
}));
export const Footer = styled(Box)(() => ({
  height: 80,
  // marginTop: 50,
  paddingLeft: 10,
  paddingRight: 10,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: '1px solid #DFDFDF',
  '@media(max-width: 700px)': {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));
export const FooterMain = styled(Box)(() => ({
  width: '-webkit-fill-available',
  position: 'absolute',
  bottom: 0,
}));
export const RedButton = styled(Button)(() => ({
  background: '#B93B3B',
  color: '#FFFFFF',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 22px',
  height: '44px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#B93B3B',
    borderColor: '#B93B3B',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#B93B3B',
    borderColor: '#B93B3B',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #B93B3B',
  },
  '@media(max-width: 600px)': {
    padding: '10px',
    fontSize: 'small',
    width: '100%',
  },
}));
export const Tabs = styled(MuiTabs)(() => ({
  '& .MuiTab-root': {
    fontFamily: 'Poppins-Medium',
    textTransform: 'capitalize',
    color: 'rgba(17, 17, 17, 0.6)',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#3C6B49',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#3C6B49',
    fontFamily: 'Poppins-SemiBold',
  },
}));
export const NameSection = styled(Box)(() => ({
  padding: '0px 10px',
  fontFamily: 'Poppins-Medium',
  fontStyle: 'normal',
  fontSize: '14px',
  width: '100%',
  '@media(max-width: 700px)': {
    fontSize: 'small',
  },
}));
export const MessageBox = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid #DFDFDF',
}));
export const FirstScreen = styled(Box)(() => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '@media(max-width: 700px)': {
    display: 'none',
  },
}));
export const MobileScreen = styled(Box)(() => ({
  paddingTop: 8,
  paddingBottom: 8,
  background: 'white',
  borderLeft: '1px solid #DFDFDF',
  height: '80vh',
  width: '100%',
}));
export const Message = styled('span')(() => ({
  // color: 'rgba(17, 17, 17, 0.4);',
  fontFamily: 'Poppins-Medium',
}));
export const NameRow = styled('span')(() => ({
  fontFamily: 'Poppins-SemiBold',
  color: '#101B10',
  fontWeight: 600,
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 3,
  justifyContent: 'space-between',
}));
export const NameSectionRead = styled('span')(() => ({
  fontFamily: 'Poppins-Medium',
  color: '#101B10',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 3,
  justifyContent: 'space-between',
}));
export const NameSectionUnRead = styled('span')(() => ({
  fontFamily: 'Poppins-SemiBold',
  color: '#101B10',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 3,
  justifyContent: 'space-between',
  '@media(max-width: 750px)': {
    // fontSize: 'small',
  },
}));
export const Time = styled(Box)(() => ({
  fontFamily: 'Poppins-Medium',
  color: 'rgba(17, 17, 17, 0.4)',
  fontSize: '13px',
  textAlign: 'right',
  '@media(max-width: 700px)': {
    fontSize: '10px',
  },
}));
export const CountName = styled(Box)(() => ({
  fontFamily: 'Poppins-Medium',
  fontStyle: 'normal',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#111111',
  opacity: 0.6,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
export const Header = styled(Box)(() => ({
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 0,
  paddingBottom: 8,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #DFDFDF',
}));
export const HeaderMobile = styled(Box)(() => ({
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 0,
  paddingBottom: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #DFDFDF',
}));
export const RightGrid = styled(Grid)(() => ({
  paddingTop: 8,
  paddingBottom: 8,
  background: 'white',
  borderLeft: '1px solid #DFDFDF',
  height: '100vh',
}));
