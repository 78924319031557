import { styled } from '@mui/material/styles';
import { Box, ListItem, Button } from '@mui/material';

import MuiListItemText from '@mui/material/ListItemText';

export const FormBox = styled(Box)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  '@media(max-width: 800px)': {
    width: '94%',
  },
  '@media(max-width: 600px)': {
    width: '93%',
  },
}));
export const FormBoxA = styled(Box)(() => ({
  width: '503px',
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
  borderRadius: '5px',
  background: 'white',
  height: '94vh',
  padding: '10px 10px',
  marginTop: 20,
  marginBottom: 20,
  display: 'flex',
  overflowY: 'auto',
  flexDirection: 'column',
  '@media(max-width: 800px)': {
    width: '94%',
  },
  '@media(max-width: 600px)': {
    width: '100%',
  },
}));
export const FormBoxB = styled(Box)(() => ({
  width: '503px',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
  borderRadius: '5px',
  background: 'white',
  height: '94vh',
  padding: '10px 10px',
  marginTop: 20,
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'column',
  '@media(max-width: 1200px)': {
    width: '50%',
  },
  '@media(max-width: 800px)': {
    width: '80%',
  },
  '@media(max-width: 600px)': {
    width: '93%',
  },
}));
export const CustomListItemText = styled(MuiListItemText)(() => ({
  cursor: 'pointer',
  '& span': {
    fontFamily: 'Poppins-SemiBold',
    fontSize: '14px',
    color: '#30563A',
  },
}));

export const SideHeading = styled('span')(() => ({
  fontFamily: 'Poppins-Light',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#111111',
  marginBottom: 5,
  marginRight: 2,
}));
export const PlanName = styled('span')(() => ({
  fontFamily: 'Poppins-SemiBold',
  fontSize: '13px',
  lineHeight: '20px',
  color: 'black',
  opacity: 1,
  marginBottom: 5,
  marginLeft: 2,
  textTransform: 'capitalize',

  '&:first-letter': {
    textTransform: 'capitalize',
  },
}));
export const Text = styled('span')(() => ({
  fontFamily: 'Poppins-Medium',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#111111',
  marginBottom: 5,
  marginLeft: 2,
  '&:first-letter': {
    textTransform: 'capitalize',
  },
}));
export const CustomListItem = styled(ListItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '5px 15px',
  alignItems: 'center',
  width: '100%',
  '@media(max-width: 900px)': {
    padding: '10px',
    // width: '35vw',
  },
  '@media(max-width: 700px)': {
    width: '100%',
  },
}));
export const Back = styled('span')(() => ({
  fontFamily: 'Poppins-Medium',
  fontSize: '13px',
  marginBottom: 5,
  lineHeight: '20px',
  color: '#3C6B49',
  opacity: 0.9,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));
export const GreenButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  textSizeAdjust: 'inherit',
  padding: '6px 20px',
  color: '#FFFFFF',
  height: '42px',
  lineHeight: 1.5,
  backgroundColor: '#3C6B49',
  border: '1px solid #3C6B49',
  borderRadius: '8px',
  fontFamily: 'Poppins-SemiBold',
  '&:disabled': {
    backgroundColor: '#3C6B49',
    color: '#FFFFFF',
    borderColor: '#3C6B49',
    boxShadow: 'none',
  },
  '&:hover': {
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #3C6B49',
  },

  '@media(max-width: 700px)': {
    fontSize: '1.9vw',
  },
  '@media(max-width: 600px)': {
    height: '6vh',
    fontSize: '2.6vw',
  },
  '@media(max-width: 400px)': {
    height: '6vh',
    fontSize: '13px',
  },
});
export const RedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '14px',
  height: '42px',
  textSizeAdjust: 'inherit',
  padding: '6px 20px',
  color: '#B93B3B',
  lineHeight: 1.5,
  backgroundColor: 'white',
  border: '1px solid #DFDFDF',
  borderRadius: '8px',
  fontFamily: 'Poppins-Medium',
  '&:hover': {
    backgroundColor: 'white',
    borderColor: '#DFDFDF',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'white',
    borderColor: '#DFDFDF',
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #DFDFDF',
  },

  '@media(max-width: 700px)': {
    fontSize: '1.9vw',
  },
  '@media(max-width: 600px)': {
    height: '6vh',
    fontSize: '2.6vw',
  },
  '@media(max-width: 400px)': {
    height: '6vh',
    fontSize: '13px',
  },
});
