import React, { useEffect } from 'react';

import Logo from '../../../components/Logo';
import LeftPanel from '../Components/LeftPanel';
import UploadSteps, { Step3 } from '../Components/UploadSteps';
import CustomCopyright from '../../../components/Footer/CopyRight';

import { Skip } from '../../Elements';
import { useLocation, useNavigate } from 'react-router-dom';
import { uploadImageLeft } from '../../../data';
import { CrossDesktopButton, CrossMobileButton } from '../../../components/Button/CrossButton';
import {
  MobileGrid,
  RightGrid,
  SubHeading,
  Heading,
  RightSubGrid,
  MainGrid,
} from '../Components/Elements';
import { useSelector } from 'react-redux';

const UploadImage = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  console.log('userInfo', userInfo?.image);

  const [state, setState] = React.useState({ image: '', step: 1 });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    if (userInfo?.image) {
      handleChange('step', 2);
    }
  }, []);
  return (
    <MainGrid container spacing={2}>
      <MobileGrid>
        <Logo />
        <CrossMobileButton />
      </MobileGrid>
      <LeftPanel data={uploadImageLeft} />
      <RightGrid item xs={12} sm={12} md={6} lg={6}>
        <CrossDesktopButton />
        <RightSubGrid>
          <div>
            <Heading>{state.step === 3 ? 'Review Profile Photo' : 'Add Profile Photo'}</Heading>
            <SubHeading>
              {state.step === 3
                ? 'If you are happy with the result, click Confirm and Continue below or try a different photo.'
                : 'Upload a photo to help other members recognize and connect with you in the community.'}
            </SubHeading>
            {state.step === 1 ? (
              // <Step3 state={state} handleChange={handleChange} location={location} />
              <UploadSteps state={state} handleChange={handleChange} />
            ) : (
              <Step3 state={state} handleChange={handleChange} location={location} />
            )}
            {/* {userInfo?.image === '' ? (
            // <Step3 state={state} handleChange={handleChange} location={location} />
              <UploadSteps state={state} handleChange={handleChange} />
            ) : (
              <Step3 state={state} handleChange={handleChange} location={location} />
            )} */}
          </div>
          {state.step !== 2 && (
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              <Skip onClick={() => navigate('/verifyId')}>Skip for now</Skip>
            </div>
          )}
        </RightSubGrid>
        <CustomCopyright />
      </RightGrid>
    </MainGrid>
  );
};
export default UploadImage;
