import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, Grid } from "@mui/material"
import { FirstMessage, MessageSent } from "../../components/Modal/Message";
import { ActivityHeading, ActivityDetail, TransparentBtn, CustomAvatar } from "../Elements";
import { getFullName, getTime, modifyDurationTitle, formatDates } from "../../utills/constants";
import { ActivityFeatures, ArrowGrid, LeftBox, TripBox, TripBoxHeader, NameSection, } from "./Elements"

import clock from "../../assets/createTrip/clock.png"
import calender from "../../assets/createTrip/calender.png"
import locationx from "../../assets/createTrip/location.png"
import leftarrow from "../../assets/createTrip/left arrow.png"
import rightarrow from "../../assets/createTrip/right arrow.png"

const CustomTripBox = ({ res }) => {
    const navigate = useNavigate()

    const matches600 = useMediaQuery('(max-width:600px)');

    const { userInfo } = useSelector(state => state.auth)

    const [state, setState] = useState({ openMessage: false })
    const matches420 = useMediaQuery('(max-width:420px)');

    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    let offer, trade, user, tripDate, preferDate;

    if (userInfo?._id === res?.offeredTo?._id) {
        offer = res?.offeredTrip
        user = res?.offeredBy
        trade = res?.hostTrip
        tripDate = res?.tripDates
        preferDate = res?.preferredDates

    } else if (userInfo?._id === res?.offeredBy?._id) {
        user = res?.offeredTo
        offer = res?.hostTrip
        trade = res?.offeredTrip
        tripDate = res?.preferredDates
        preferDate = res?.tripDates
    } else{
        user = ""
        offer = ""
        trade = ""
        tripDate = ""
    }
    const tripDatails = [
        { icon: clock, name: `${trade?.duration?.value} ${modifyDurationTitle(trade?.duration?.title, trade?.duration?.value)}`, name2: `${offer?.duration?.value} ${modifyDurationTitle(offer?.duration?.title, offer?.duration?.value)}` },
        { icon: calender, name2: formatDates(preferDate && preferDate), name: formatDates(tripDate && tripDate) },
        { icon: locationx, name: `${trade?.location?.city}, ${trade?.location?.state}`, name2: `${offer?.location?.city}, ${offer?.location?.state}`, }
    ]

    const openMessageBox = async () => {
        handleChange('selectedUser', user)
        handleChange('openMessage', true)
    }

    return <TripBox container sx={{ width: '100%', mb: 2 }}>
        <TripBoxHeader item xs={12} sm={12} md={12} lg={12} sx={{ my: .6 }} >
            <LeftBox>
                <CustomAvatar src={user?.image} alt="useer" sx={{ width: 70, height: 70 }} />
                <NameSection sx={{ cursor: 'pointer' }} onClick={() => navigate(`/profile/public/${user?._id}`)}>
                    <span>{getFullName(user)}</span>
                    <ActivityHeading sx={{ textTransform: 'none', fontFamily: 'Poppins-Medium' }}>Confirmed {getTime(res?.createdAt)}</ActivityHeading>
                </NameSection>
            </LeftBox>
        </TripBoxHeader>
        <Grid item xs={12} sm={5} md={5} lg={5} sx={{ mt: .5 }}>
            <ActivityHeading>OFFERING</ActivityHeading>
            <ActivityDetail>{trade?.species + " " + trade?.tradeType}</ActivityDetail>

            {matches600 && tripDatails.map((res) => {
                return <ActivityFeatures ><img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />{res.name}</ActivityFeatures>
            })}
        </Grid>
        <ArrowGrid item xs={matches420 ? 12 : 1} sm={2} md={2} lg={2} sx={{ my: matches420 ? 0 : 2 }}>
            <img src={rightarrow} alt="rightarrow" />
            <img src={leftarrow} alt="leftarrow" />
        </ArrowGrid>
        <Grid item xs={12} sm={5} md={5} lg={4}>
            <ActivityHeading>FOR TRADE</ActivityHeading>
            <ActivityDetail>{offer?.species + " " + offer?.tradeType}</ActivityDetail>
            {matches600 && tripDatails.map((res) => {
                return <ActivityFeatures ><img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />{res.name}</ActivityFeatures>
            })}
        </Grid>
        {!matches600 && <Grid item xs={12} sm={5} md={5} lg={5}>
            {tripDatails.map((res) => {
                return <ActivityFeatures><img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />{res.name}</ActivityFeatures>
            })}
        </Grid>}
        {!matches600 && <Grid item xs={12} sm={2} md={2} lg={2} sx={{ my: 1 }}></Grid>}
        {!matches600 && <Grid item xs={12} sm={5} md={5} lg={5}>
            {tripDatails.map((res) => {
                return <ActivityFeatures ><img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />{res.name2}</ActivityFeatures>
            })}
        </Grid>}
        {res?.note && <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 1 }}>
            <ActivityHeading>OFFER NOTE</ActivityHeading>
            <ActivityFeatures>{res?.note}</ActivityFeatures>
        </Grid>}
        {matches420 ?

            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', mt: 2, mb: 1, justifyContent: 'space-around' }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Grid item xs={12}>
                        <TransparentBtn onClick={openMessageBox} sx={{ mb: 2, width: '100%' }}>Message</TransparentBtn>
                    </Grid>
                    <Grid item xs={12}>
                        <TransparentBtn sx={{ width: '100%' }} onClick={() => navigate(`/profile/public/${user?._id}`)}>View Profile</TransparentBtn>
                    </Grid>
                </Grid>
            </Grid> :
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', mt: 2, mb: 1, justifyContent: 'space-around' }}>
                <TransparentBtn onClick={openMessageBox} sx={{ mr: 2, width: '100%' }}>Message</TransparentBtn>
                <TransparentBtn sx={{ width: '100%' }} onClick={() => navigate(`/profile/public/${user?._id}`)}>View Profile</TransparentBtn>
            </Grid>}
        <MessageSent data={state} open={state.openMessageSent} handle={handleChange} stateName="openMessageSent" />

        <FirstMessage data={state} open={state.openMessage} handle={handleChange} stateName="openMessage" />
    </TripBox>
}
export default CustomTripBox