import React from "react";
import CheckoutForm from "./StripeCheckout";
import Sidebar from "../../components/Sidebar"

import { MainBox } from "../Elements";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mui/material"
import { loadStripe } from "@stripe/stripe-js";
import { FormBox, FormBoxA, } from "./Elements";
import { Elements } from "@stripe/react-stripe-js";
import { secretStripeKey } from "../../utills/constants"

import "./stripe.css"

const promise = loadStripe(secretStripeKey);

const Step2 = () => {
    const matches600 = useMediaQuery('(max-width:600px)');

    const { plan, clientSecret,subscriptionId } = useParams()

    return <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
        <Sidebar />
        <FormBox >
            <FormBoxA sx={{ height: 'max-content' }}><Elements zipCode={false} stripe={promise}>
                <CheckoutForm plan={plan}clientSecret={clientSecret} subscriptionId={subscriptionId} />
            </Elements>
            </FormBoxA>
        </FormBox>
    </MainBox>

}

export default Step2