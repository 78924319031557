import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Header } from '../../../components/Modal/ModalHeader';

import { GreenButton } from '../../Elements';
import { DateSingleInput } from './DateInput';
import { Avatar, Box, Grid } from '@mui/material';
import {
  DateWithoutFormat,
  convertDateStringToObject,
  formatDateStringToObject,
  getFullName,
} from '../../../utills/constants';
import { HeadingSub, GreyText, Description, TripDetailsGrid } from './Elements';

const Step1 = ({ handleClose, handleChange, state, res }) => {
  const [disableDays, setdisableDays] = useState([]);

  useEffect(() => {
    const allUnavailableDates = res?.hostTrip?.unAvailableDays?.allUnavailableDates || [];
    const isPast = moment.utc(res?.hostTrip?.availableFrom).isBefore(new Date());
    // const _abc = {
    //     day: new Date(res?.hostTrip?.availableFrom).getUTCDate(),
    //     month: new Date(res?.hostTrip?.availableFrom).getMonth() + 1,
    //     year: new Date(res?.hostTrip?.availableFrom).getFullYear(),
    // }

    isPast
      ? handleChange('defaultCalenderValue', [
          {
            day: new Date().getDate(),
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
          },
        ])
      : handleChange('defaultCalenderValue', [
          {
            day: new Date(res?.hostTrip?.availableFrom).getDate(),
            month: new Date(res?.hostTrip?.availableFrom).getMonth() + 1,
            year: new Date(res?.hostTrip?.availableFrom).getFullYear(),
          },
        ]);

    // isPast
    //   ? handleChange('minDate', {
    //       day: new Date().getDate(),
    //       month: new Date().getMonth() + 1,
    //       year: new Date().getFullYear(),
    //     })
    //   : handleChange('minDate', {
    //       month: new Date(res?.hostTrip?.availableFrom).getMonth() + 1,
    //       year: new Date(res?.hostTrip?.availableFrom).getFullYear(),
    //       day: new Date(res?.hostTrip?.availableFrom).getDate(),
    //     });
    // handleChange('maxDate', {
    //   month: new Date(res?.hostTrip?.availableTo).getMonth() + 1,
    //   year: new Date(res?.hostTrip?.availableTo).getFullYear(),
    //   day: new Date(res?.hostTrip?.availableTo).getDate(),
    // });

    let minDate = '';
    let maxDate = '';

    const startDate = DateWithoutFormat(res?.hostTrip?.availableFrom);
    const endDate = DateWithoutFormat(res?.hostTrip?.availableTo);

    const start_date = moment(startDate).format('YYYY-MM-DD');
    const current_date = moment(new Date()).format('YYYY-MM-DD');

    if (minDate == '') {
      minDate =
        start_date < current_date
          ? moment(current_date).format('YYYY-MM-DD')
          : moment(start_date).format('YYYY-MM-DD');
    }
    if (maxDate == '') maxDate = moment(endDate).format('YYYY-MM-DD');

    console.log('minDate', minDate);
    console.log('maxDate', maxDate);

    handleChange('minDate', convertDateStringToObject(minDate));
    handleChange('maxDate', convertDateStringToObject(maxDate));
    console.log('line 83', res);
    let allUnavailableDatesFormated = [];

    let all_unavailable_dates = allUnavailableDates || [];
    all_unavailable_dates = all_unavailable_dates.map((e) =>
      formatDateStringToObject(DateWithoutFormat(e))
    );

    console.log('all_unavailable_dates', all_unavailable_dates);

    allUnavailableDates?.forEach((res) => {
      allUnavailableDatesFormated?.push(moment.utc(res).format('DD MMM YYYY'));
      console.log('response of te allunavliable dates is', allUnavailableDatesFormated);
      // disableDays?.push({
      //   month: Number(moment(res).format('MM')),
      //   year: new Date(res).getFullYear(),
      //   day: Number(res.split('-')[1]),
      //   day: new Date(res).getUTCDate(),
      //   className: 'customDisableDays',
      // });
      console.log('line 95', res);
      console.log('line 96', new Date(res).getUTCDate(), Number(res.split('-')[1]));
    });
    handleChange('allUnavailableDates', allUnavailableDatesFormated);
    setdisableDays(all_unavailable_dates);
    // handleChange('disableDays', disableDays);
    handleChange('disableDays', all_unavailable_dates);
    // eslint-disable-next-line
  }, []);
  // useEffect(() => {
  //   handleChange('defaultCalenderValue', []);
  //   // handleChange('minDate', {});
  // }, []);

  const changeStep = () => {
    if (state?.tripDates?.length > 0) {
      handleChange('step', 2);
    } else {
      handleChange('preferredDateError', true);
    }
  };
  const clearSelectedDates = () => {
    // setSelectedDay([]);
    handleChange('selectedDay', []);
    handleChange('defaultCalenderValue', []);
    // handleChange('preferredDates', []);
    handleChange('tripDates', []);
    handleChange('tripDates1', []);
  };

  return (
    <>
      <Header handleClose={handleClose} heading="Choose Trip Date" />
      <Description sx={{ textAlign: 'left' }}>
        To get started, choose your preferred dates for this trip.
      </Description>
      <TripDetailsGrid container>
        <Grid item xs={2} sm={3} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar src={res?.offeredBy?.image} alt="useer" sx={{ width: 70, height: 70 }} />
        </Grid>
        <Grid
          item
          xs={8}
          sm={9}
          lg={9}
          sx={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}
        >
          <HeadingSub
            sx={{ fontSize: { xs: '13px', sm: '15px', md: '16px', lg: '16px' }, fontWeight: 600 }}
          >{`${res?.hostTrip?.species} ${res?.hostTrip?.tradeType}`}</HeadingSub>
          <HeadingSub sx={{ fontSize: { xs: '10px', sm: '10px', md: '12px', lg: '12px' } }}>
            Duration: {`${res?.hostTrip?.duration?.value} ${res?.hostTrip?.duration?.title}`}
          </HeadingSub>
          <GreyText sx={{ fontSize: { xs: '10px' } }}>
            Hosted by {getFullName(res?.offeredBy)}
          </GreyText>
        </Grid>
      </TripDetailsGrid>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12} lg={12}>
          <HeadingSub
            sx={{ fontSize: { xs: '13px', sm: '15px', md: '16px', lg: '16px' }, fontWeight: 600 }}
          >
            Preferred Trip Date
          </HeadingSub>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DateSingleInput
            state={state}
            res={res}
            handleChange={handleChange}
            placeholder="Choose a date or date range"
            sx={{ width: '100%' }}
          ></DateSingleInput>
        </Grid>
        <button className="ClearDates" onClick={clearSelectedDates}>
          Clear dates
        </button>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          mt: 2,
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <GreyText sx={{ fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px' } }}>
          Step 1 of 2
        </GreyText>
        <GreenButton onClick={changeStep} type="submit" variant="contained" disableripple>
          Continue
        </GreenButton>
      </Box>
    </>
  );
};

export default Step1;
