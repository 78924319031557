import React from 'react';
import { CancelButton } from "../Button"
import { Box, Modal } from '@mui/material';
import { useNavigate } from "react-router-dom"
import { Heading, style, GreenButton, DescriptionX, ImageContainer } from "./Elements"

import successImage from "../../assets/create account/successImage.png"
import correctCircle from "../../assets/create account/correctCircle.png"

const PaymentSuccess = ({ open }) => {

    const navigate = useNavigate()

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: 450, height: 'max-content', py: 4, px: 2, alignItems: 'center' }}>
                    <ImageContainer>
                        <img src={successImage} alt="successImage" />
                        <span><img src={correctCircle} alt="correctCircle" /></span>
                    </ImageContainer>
                    <Heading sx={{ textAlign: 'center', my: 2 }}>Congrats! You’re subscribed!</Heading>
                    <DescriptionX sx={{ px: 2 }}>We’re excited to have you join our growing community! You now have full access to all member features. Happy Trading!</DescriptionX>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mt: 2 }}>
                        <GreenButton sx={{ width: '100%', mb: 2 }} onClick={() => navigate('/search')} type="submit" variant="contained" disableripple>
                            Find Trips
                        </GreenButton>
                        <CancelButton sx={{ width: '100%' }} type="submit" onClick={() => navigate('/settings')} variant="contained" disableripple>
                            Manage Subscription
                        </CancelButton>
                    </Box>
                </Box>
            </Modal>

        </div >
    );
}

export default PaymentSuccess