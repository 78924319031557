import React, { useEffect, useState } from 'react';

import api from '../../api';
import moment from 'moment';
import Sent from './SentOffers';
import Received from './ReceivedOffers';
import Sidebar from '../../components/Sidebar';
import GreenLoader from '../../assets/greenLoader.gif';

import { FormBox, Tabs } from './Elements';
import { useLocation, useNavigate } from 'react-router-dom';
import { MainBox, UserName } from '../Elements';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery, Box, Tab } from '@mui/material';
import { getFunction, reload } from '../../features/auth/action';
import './style.css';
import { getSubsctiptionStatus, swalWithStyledButton } from '../../utills/constants';
import { freemiumRecieveOffer } from '../../features/trip/action';
import FreeTrialModal from '../MakeOffer/FreeTrialModal';
import { saveData } from '../../features/auth/reducer';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TradeOffer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offervalue = queryParams?.get('offer');
  console.log('offervalue',queryParams, offervalue);
  const navigate = useNavigate();
  const [value, setValue] = useState(offervalue === 'send' ? 1 : 0);
  const [loading, setloading] = useState(false);
  const matches600 = useMediaQuery('(max-width:600px)');
  const { userInfo, received } = useSelector((state) => state.auth);
  const { freemiumRecieveOfferTrip } = useSelector((state) => state.trip);
  console.log('freemiumRecieveOfferTrip', freemiumRecieveOfferTrip);
  const getRecievedTrips = received.filter(
    (e) => e?._id !== freemiumRecieveOfferTrip?.getRecievedTrip?._id
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(freemiumRecieveOffer({}));
  };
  const config = { headers: { 'Content-Type': 'application/json' } };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTime = (res) => {
    var a = moment();
    var b = moment(res);
    return a.diff(b, 'days') > 7 ? moment(res).format('MMM DD') : moment(res).fromNow();
  };
  const submitFunction = async () => {
    const resp = await api.put(
      freemiumRecieveOfferTrip?.body?.url,
      freemiumRecieveOfferTrip.body,
      config
    );
    handleOpen();

    if (resp?.status == 200 && resp?.data?.message == 'Updated Successfully.') {
    } else if (
      resp?.status == 200 &&
      (resp?.data.message == `This offer doesn't exist.` ||
        resp?.data?.message == 'This user has blocked you.' ||
        resp?.data?.message == 'You have blocked this user.')
    ) {
      swalWithStyledButton
        .fire({
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: resp.data.message,
          confirmButtonText: 'Reload',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            dispatch(reload(userInfo?._id, navigate));
          } else if (result.isDenied) {
            dispatch(reload(userInfo?._id, navigate));
          }
        });
    }
  };
  const checkPremiumOfUser = async () => {
    const subsStatus = await getSubsctiptionStatus(userInfo?.customerId);
    if (subsStatus === 'Premium' || subsStatus === 'PremiumC') {
      if (Object.keys(freemiumRecieveOfferTrip).length !== 0) {
        submitFunction();
        dispatch(saveData({ name: 'received', data: getRecievedTrips }));
        navigate('/tradeOffer');
      } else {
        console.log('Object is empty');
        dispatch(freemiumRecieveOffer({}));
      }
    } else {
      dispatch(freemiumRecieveOffer({}));
    }
  };

  useEffect(() => {
    checkPremiumOfUser();
  }, []);
  const apiCall = (type) => {
    let body = {};
    if (type === 'received') {
      body['name'] = 'received';
      body['url'] = `/offer?offeredTo=${userInfo?._id}&status=pending&type=${type}`;
    } else if (type === 'sent') {
      body['name'] = 'sent';
      body['url'] = `/offer?offeredBy=${userInfo?._id}&status=pending&type=${type}`;
    }
    dispatch(getFunction(body));
  };

  const cancelOffer = async (_id, type, handle) => {
    setloading(true);

    let body = {
      type: type,
      url: `/offer/declineTrip/${_id}`,
      [type]: true,
      isCanceled: type === 'sent' ? true : false,
      isDeclined: type === 'received' ? true : false,
    };
    const res = await api.put(body.url, body, config);
    if (res.status == 200 && res?.data?.message == 'Updated Successfully.') {
      apiCall(type);
      setloading(false);
    } else if (
      res.status == 200 &&
      (res.data.message == `This offer doesn't exist.` ||
        res?.data?.message == 'This user has blocked you.' ||
        res?.data?.message == 'You have blocked this user.')
    ) {
      setloading(false);
      swalWithStyledButton
        .fire({
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: res.data.message,
          confirmButtonText: 'Reload',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            dispatch(reload(userInfo?._id, navigate));
          } else if (result.isDenied) {
            dispatch(reload(userInfo?._id, navigate));
          }
        });
    }
    // dispatch(updateFuntionTest(body, apiCall(type)))
    handle('openDeclineBox', false);
  };

  useEffect(() => {
    apiCall('received');
    apiCall('sent');
    // eslint-disable-next-line
  }, []);

  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }}>
      <Sidebar />
      <FormBox>
        {loading && (
          <div
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              left: '50%',
              top: '50%',
            }}
          >
            <img src={GreenLoader} width="80" alt="GreenLoader" />
          </div>
        )}
        <UserName sx={{ fontSize: '18px' }}>Trade Offers</UserName>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Received" {...a11yProps(0)} />
            <Tab label="Sent" {...a11yProps(1)} />
          </Tabs>
        </Box>
        {value === 1 && <Sent loading={loading} cancelOffer={cancelOffer} getTime={getTime} />}
        {value === 0 && (
          <Received
            loading={loading}
            apiCall={apiCall}
            cancelOffer={cancelOffer}
            getTime={getTime}
          />
        )}
      </FormBox>
      <FreeTrialModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        OfferSent={'You accepted an offer from'}
        freeTrialData={freemiumRecieveOfferTrip?.getRecievedTrip?.offeredBy}
      />
    </MainBox>
  );
};
export default TradeOffer;
