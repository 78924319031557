import React from 'react';

import { CustomLabel1, CustomLabel2, CustomLabelSmall } from './Elements';
import { InputAdornment, FormControlLabel, FormGroup } from '@mui/material';
import { textFieldStyles, textField1Styles, promoFieldStyles } from './styles';
import { TextField, Grid, Checkbox, FormControl, FormHelperText } from '@mui/material';

import eye from '../../assets/signin/eye.png';

export const Input = ({
  type = 'text',
  title,
  value,
  onChange,
  placeholder,
  error,
  register,
  registerFor,
}) => {
  const classes = textFieldStyles();
  return (
    <div>
      <CustomLabel2>{title}</CustomLabel2>
      {register ? (
        <TextField
          type={type}
          {...register(registerFor, { required: true })}
          sx={{ width: '100%' }}
          className={classes.root}
          error={error ? true : false}
          helperText={error}
          inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
          placeholder={placeholder}
        />
      ) : (
        <TextField
          sx={{ width: '100%' }}
          className={classes.root}
          onChange={onChange}
          error={error ? true : false}
          helperText={error}
          value={value.trim()}
          inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};
export const PromoInput = ({
  codevalid,
  handleRemove,
  handlePromoInput,
  code,
  title,
  value,
  setcode,
  error,
  handlePromoCode,
}) => {
  const classes = promoFieldStyles();
  return (
    <div>
      <CustomLabel2>{title}</CustomLabel2>

      {!error ? (
        <>
          <FormGroup row sx={{ display: 'flex', flexDirection: 'row' }}>
            <TextField
              sx={{ width: '80%' }}
              className={classes.root}
              onChange={handlePromoInput}
              error={error ? true : false}
              value={value}
              inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
              placeholder={'Add a promo code'}
            />
            <span
              onClick={handlePromoCode}
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottomRightRadius: '8px',
                color: '#FFFF',
                fontFamily: 'Poppins-Regular',
                borderTopRightRadius: '8px',
                background: '#487454',
                width: '20%',
                cursor: 'pointer',
              }}
            >
              Apply
            </span>
          </FormGroup>
          <span
            style={{
              fontSize: 'small',
              fontFamily: 'Poppins-Regular',
              color: error === true ? '#1E3625' : 'red',
              marginLeft: '0 !important',
            }}
          >
            {codevalid === false ? 'Invalid Code' : ''}
          </span>
        </>
      ) : (
        <FormGroup
          row
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              fontSize: 'small',
              fontFamily: 'Poppins-Regular',
              color: error === true ? '#1E3625' : 'red',
              marginLeft: '0 !important',
            }}
          >
            {code}
          </span>
          <span
            onClick={handleRemove}
            style={{
              textAlign: 'center',
              fontSize: 'small',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#487454',
              fontFamily: 'Poppins-Regular',
              textDecoration: 'underline',
              width: '20%',
              cursor: 'pointer',
            }}
          >
            Remove
          </span>
        </FormGroup>
      )}
    </div>
  );
};
export const TextArea = ({ value, placeholder, error, handle }) => {
  const classes = textField1Styles();
  return (
    <div>
      <TextField
        sx={{ width: '100%' }}
        onChange={(e) => handle('message', e.target.value)}
        multiline
        rows={4}
        value={value}
        className={classes.root}
        error={error ? true : false}
        helperText={error}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        placeholder={placeholder}
      />
    </div>
  );
};
export const FullTextArea = ({ rows, placeholder, error, onChange }) => {
  const classes = textField1Styles();
  return (
    <div>
      <TextField
        sx={{ width: '100%' }}
        onChange={onChange}
        multiline
        rows={rows}
        className={classes.root}
        error={error ? true : false}
        helperText={error}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        placeholder={placeholder}
      />
    </div>
  );
};
export const FullTextAreaWithRegister = ({
  registerFor,
  register,
  placeholder,
  error,
  onChange,
}) => {
  const classes = textField1Styles();
  return (
    <div>
      <TextField
        {...register(registerFor, { required: true })}
        sx={{ width: '100%' }}
        onChange={onChange}
        multiline
        rows={4}
        className={classes.root}
        error={error ? true : false}
        helperText={error}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        placeholder={placeholder}
      />
    </div>
  );
};
export const TextAreaWithRegister = ({ placeholder, error, title, register, registerFor }) => {
  const classes = textFieldStyles();
  return (
    <div>
      <CustomLabel2>{title}</CustomLabel2>
      <TextField
        sx={{ width: '100%' }}
        {...register(registerFor, { required: true })}
        multiline
        rows={2}
        className={classes.root}
        error={error ? true : false}
        helperText={error}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        placeholder={placeholder}
      />
    </div>
  );
};
export const InputRightIcon = ({ title, placeholder, error, register, registerFor }) => {
  const [type, setType] = React.useState('password');
  const classes = textFieldStyles();
  const handleIcon = () => {
    if (type === 'password') {
      setType('text');
    } else if (type === 'text') {
      setType('password');
    }
  };
  return (
    <div>
      <CustomLabel2>{title}</CustomLabel2>
      <TextField
        sx={{ width: '100%' }}
        className={classes.root}
        placeholder={placeholder}
        type={type}
        {...register(registerFor, { required: true })}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        error={error ? true : false}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={eye} style={{ cursor: 'pointer' }} alt="eye" onClick={handleIcon} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
export const CustomCheckbox = ({ title, handleChange, register, error, value }) => {
  return (
    <Grid sx={{ display: 'flex' }}>
      <FormControl component="fieldset" variant="standard">
        <FormControlLabel
          label={<CustomLabel1>{title}</CustomLabel1>}
          control={
            register ? (
              <Checkbox style={{ color: '#1E3625' }} {...register('terms', { required: true })} />
            ) : (
              <Checkbox style={{ color: '#1E3625' }} checked={value} onChange={handleChange} />
            )
          }
        />
        <FormHelperText sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginTop: 0 }}>
          {error}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};
export const CustomCheckboxWithSmallLabel = ({ title, register, error }) => {
  return (
    <Grid>
      <FormControl component="fieldset" variant="standard">
        <FormControlLabel
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}
          label={<CustomLabelSmall>{title}</CustomLabelSmall>}
          control={
            register ? (
              <Checkbox
                {...register('terms', { required: true })}
                style={{ color: '#1E3625' }}
                sx={{ color: '#1E3625', pt: 0.5 }}
              />
            ) : (
              <Checkbox style={{ color: '#1E3625' }} />
            )
          }
        />
        <FormHelperText sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginTop: 0 }}>
          {error}
        </FormHelperText>
      </FormControl>
    </Grid>
  );
};
