import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { GreyText, Footer } from './Elements';
import { LoaderSpan } from '../../SignIn/Elements';
import { config, getSubsctiptionStatus } from '../../../utills/constants';
import { Grid, Avatar, useMediaQuery } from '@mui/material';
import { Header } from '../../../components/Modal/ModalHeader';
import { getDurationFromDate } from '../../../utills/constants';
import { ActivityFeatures, ArrowGrid1, TripBox } from '../Elements';
import { TransparentBtn, ActivityDetail, ActivityHeading, GreenButton } from '../../Elements';
import { swalWithStyledButton } from '../../../utills/constants';
import api from '../../../api';
import Loader from '../../../assets/loader1.gif';
import clock from '../../../assets/createTrip/clock.png';
import calender from '../../../assets/createTrip/calender.png';
import locationx from '../../../assets/createTrip/location.png';
import leftarrow from '../../../assets/createTrip/left arrow.png';
import rightarrow from '../../../assets/createTrip/right arrow.png';
import { reload } from '../../../features/auth/action';
import { useNavigate } from 'react-router-dom';
import { freemiumRecieveOffer } from '../../../features/trip/action';
import moment from 'moment';

const Step3 = ({ apiCall, res, handle, handleClose, handleChange, state }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const matches600 = useMediaQuery('(max-width:600px)');
  const { userInfo, received } = useSelector((state) => state.auth);
  console.log();
  const getRecievedTrip = received.find((e) => e?._id === res?._id);
  console.log('getRecievedTrip', getRecievedTrip);
  const tripDatails = [
    {
      icon: clock,
      name: `${res?.hostTrip?.duration?.value} ${res?.hostTrip?.duration?.title}`,
      name2: `${res?.offeredTrip?.duration?.value} ${res?.offeredTrip?.duration?.title}`,
    },
    {
      icon: calender,
      name: getDurationFromDate(
        moment.utc(state?.tripDates && state?.tripDates[0]).format(),
        state?.tripDates && moment.utc(state?.tripDates[state?.tripDates.length - 1]).format()
      ),
      // name: getDurationFromDate(
      //   new Date(state?.tripDates && state?.tripDates[0]),
      //   state?.tripDates && new Date(state?.tripDates[state?.tripDates.length - 1])
      // ),
      name2: getDurationFromDate(
        res?.preferredDates && res?.preferredDates[0],
        res?.preferredDates && res?.preferredDates[res?.preferredDates.length - 1]
      ),
    },
    {
      icon: locationx,
      name: `${res?.hostTrip?.location?.city}, ${res?.hostTrip?.location?.state}`,
      name2: `${res?.offeredTrip?.location?.city}, ${res?.offeredTrip?.location?.state}`,
    },
  ];
  const handleStep = () => {
    handleChange('step', 3);
    apiCall('received');
  };

  const submitFunction = async () => {
    const subsStatus = await getSubsctiptionStatus(userInfo?.customerId);
    setLoading(true);
    console.log("state.tripDates ",state.tripDates);
    const trps=state.tripDates.map((e)=> moment.utc(e).format() )
  
    let body = {
      url: `/offer/confirmTrip/${res?._id}`,
      name: 'offer',
      tripDates:trps,
      status: 'confirmed',
    };
    console.log("Trade Offer Recieved",body)
    if (subsStatus !== 'Premium' && subsStatus !== 'PremiumC') {
      const payload = {
        body,
        getRecievedTrip,
      };
      console.log(payload);
      dispatch(freemiumRecieveOffer(payload));
      navigate('/freePlan');
      return;
    }
    const resp = await api.put(body.url, body, config);
    if (resp?.status == 200 && resp?.data?.message == 'Updated Successfully.') {
      handleStep();
      setLoading(false);
    } else if (
      resp?.status == 200 &&
      (resp?.data.message == `This offer doesn't exist.` ||
        resp?.data?.message == 'This user has blocked you.' ||
        resp?.data?.message == 'You have blocked this user.' ||
        resp?.data?.message == 'Sorry! User has cancelled this trip Offer')
    ) {
      setLoading(false);
      swalWithStyledButton
        .fire({
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: resp.data.message,
          confirmButtonText: 'Reload',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            dispatch(reload(userInfo?._id, navigate));
          } else if (result.isDenied) {
            dispatch(reload(userInfo?._id, navigate));
          }
        });
    }
  };

  return (
    <>
      <Header handleClose={handleClose} heading="Review Trade" />
      <TripBox container sx={{ width: '100%', mb: 2, boxShadow: 'none', p: 1 }}>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Avatar src={res?.offeredBy?.image} alt="user" sx={{ width: 70, height: 70, my: 1.5 }} />
        </Grid>
        <ArrowGrid1 item xs={2} sm={2} md={2} lg={2}></ArrowGrid1>
        <Grid item xs={5} sm={5} md={5} lg={4}>
          <Avatar src={res?.offeredTo?.image} alt="user" sx={{ width: 70, height: 70, my: 1.5 }} />
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <ActivityHeading>OFFERING</ActivityHeading>
        </Grid>
        <ArrowGrid1 item xs={2} sm={2} md={2} lg={2}>
          <img src={rightarrow} alt="rightarrow" />
          <img src={leftarrow} alt="leftarrow" />
        </ArrowGrid1>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <ActivityHeading>IN RETURN FOR</ActivityHeading>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <ActivityDetail>{res?.hostTrip?.species}</ActivityDetail>
          {matches600 &&
            tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name}
                </ActivityFeatures>
              );
            })}
        </Grid>
        <ArrowGrid1 item xs={2} sm={2} md={2} lg={2}></ArrowGrid1>
        <Grid item xs={5} sm={5} md={5} lg={4}>
          <ActivityDetail>{res?.offeredTrip?.species}</ActivityDetail>
          {matches600 &&
            tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name2}
                </ActivityFeatures>
              );
            })}
        </Grid>
        {!matches600 && (
          <Grid item xs={5} sm={5} md={5} lg={5}>
            {tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name}
                </ActivityFeatures>
              );
            })}
          </Grid>
        )}
        {!matches600 && <Grid item xs={2} sm={2} md={2} lg={2} sx={{ my: 1 }}></Grid>}
        {!matches600 && (
          <Grid item xs={5} sm={5} md={5} lg={5}>
            {tripDatails?.map((res, i) => {
              return (
                <ActivityFeatures key={i}>
                  <img src={res.icon} alt={res.name} style={{ marginRight: 10 }} />
                  {res.name2}
                </ActivityFeatures>
              );
            })}
          </Grid>
        )}
      </TripBox>
      <Footer container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <GreyText sx={{ fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px' } }}>
            Step {state.step} of 2
          </GreyText>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={8}
          sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}
        >
          <TransparentBtn sx={{ mr: 2 }} onClick={handleClose}>
            Cancel
          </TransparentBtn>
          <GreenButton disabled={loading} sx={{ px: 2 }} onClick={submitFunction}>
            {loading ? (
              <LoaderSpan>
                <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Confirm
                and Accept...
              </LoaderSpan>
            ) : (
              <>
                <p style={{ margin: 0 }}>
                  {userInfo?.subscription?.status === 'freemium' ? 'Subscribe to' : ''}
                  <p style={{ margin: 0 }}>Confirm and Send</p>
                </p>
              </>
            )}
          </GreenButton>
        </Grid>
      </Footer>
    </>
  );
};

export default Step3;
