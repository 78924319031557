import React, { useState } from 'react';

import { style } from '../MakeOffer/Elements';
import { RedButton, AddGrid, SendButton } from './Elements';
import { Modal, Box, Grid, TextField } from '@mui/material';
import { DashedUploadBox, UploadText } from '../Join/Components/Elements';
import { messageInputStyles } from '../../components/InputFields/styles';

import { Header } from '../../components/Modal/ModalHeader';
import cross from '../../assets/profile/cross.png';
import add from '../../assets/createTrip/add.png';
import send from '../../assets/messaging/send.png';
import upload from '../../assets/create account/upload.png';

const ImageUpload = ({ sendImage, open, handle, _state, uploadFunction, stateName, _setState }) => {
  const classes = messageInputStyles();

  const [state, setState] = useState({});

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleClose = () => {
    handle([stateName], false);
  };
  const deleteImage = async (index) => {
    // let arr = [..._state.image];
    // console.log(arr);
    // arr.splice(index, 1);
    // const updateArray = arr.splice(index, 1);
    // console.log(arr);
    // handleChange('image', updateArray);
    _setState((prevState) => {
      const updatedImages = [...prevState.image];
      updatedImages.splice(index, 1);
      return { ...prevState, image: updatedImages };
    });
    // console.log('updateArray', updateArray);
    console.log('_state', _state.image);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: _state?.image?.length > 0 ? '400px' : '388px',
            height: 'max-content',
            justifyContent: 'center',
            alignItems: 'center',
            px: 3,
            py: 2,
          }}
        >
          <Header handleClose={handleClose} heading="Add Chat Photos" />
          {_state?.image?.length > 0 ? (
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', my: 2 }}
            >
              {_state?.image?.map((res, i) => {
                return (
                  <Grid
                    key={i}
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    lg={2.9}
                    className="uploadImageModal"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}
                  >
                    <img src={res} alt="images" />
                    <button className="closeallimageParent" onClick={() => deleteImage(i)}>
                      <img src={cross} alt="cross" />
                    </button>
                  </Grid>
                );
              })}
              <AddGrid item xs={4} sm={3} md={3} lg={2.9} className="allimageParent">
                <label className="filebutton">
                  <img src={add} alt="add" />
                  <span>
                    <input
                      accept="image/png,image/jpg, image/jpeg"
                      multiple={true}
                      type="file"
                      id="myfile"
                      onChange={uploadFunction}
                      style={{ display: 'none' }}
                      name="myfile"
                    />
                  </span>
                </label>
              </AddGrid>
              <Grid
                item
                xs={9}
                sm={9}
                md={9}
                lg={10}
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 1.5 }}
              >
                <TextField
                  sx={{ width: '100%' }}
                  onChange={(e) => handle('imgMsg', e.target.value)}
                  className={classes.root}
                  inputProps={{ style: { color: '#1E3625', zIndex: 20, height: '25px' } }}
                  placeholder={'Type your message here'}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={2}
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', mt: 1.5 }}
              >
                <SendButton onClick={sendImage}>
                  <img src={send} alt="send" />
                </SendButton>
              </Grid>
            </Grid>
          ) : (
            <DashedUploadBox
              sx={{
                my: 4,
                height: 80,
                width: 200,
                flexDirection: 'row',
                alignItems: 'center',
                pt: 0,
              }}
            >
              <label className="filebutton" style={{ cursor: 'pointer', marginRight: 10 }}>
                <img src={upload} alt="upload" />
                <span>
                  <input
                    accept="image/png,image/jpg, image/jpeg"
                    type="file"
                    id="myfile"
                    onChange={uploadFunction}
                    style={{ display: 'none' }}
                    name="myfile"
                  />
                </span>
              </label>
              <UploadText sx={{ mb: 0, mt: 0 }}>
                <label className="filebutton" style={{ cursor: 'pointer', marginRight: 10 }}>
                  Add Photos
                  <span>
                    <input
                      accept="image/png,image/jpg, image/jpeg"
                      type="file"
                      id="myfile"
                      onChange={uploadFunction}
                      style={{ display: 'none' }}
                      name="myfile"
                    />
                  </span>
                </label>
              </UploadText>
            </DashedUploadBox>
          )}

          <RedButton onClick={handleClose} sx={{ width: '100%' }}>
            Cancel
          </RedButton>
        </Box>
      </Modal>
    </div>
  );
};

export default ImageUpload;
