import React from "react";
import Sidebar from "../../components/Sidebar"

import { MainBox,UserName } from "../Elements";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, Grid, Box, Divider } from "@mui/material"
import { FormBox, CoverHeading, Content, Date, SmallHeading } from "./Elements"
import { bottomNews, smallNews } from "../../data"

import cover from "../../assets/news/cover.png"

const News = () => {
    const navigate = useNavigate()
    const matches600 = useMediaQuery('(max-width:600px)');
    return <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
        <Sidebar />
        <FormBox sx={{ width: '50%' }}>
            <UserName sx={{ fontSize: '18px', mb: 2 }}>Latest News</UserName>
            <Grid container spacing={matches600 ? 0 : 1}>
                <Grid item xs={12} sm={12} md={7} lg={7} >
                    <Box sx={{ pr: 4, pb: 3 }} onClick={() => navigate("/news/details")}>
                        <img src={cover} alt="cover" />
                        <Date>June 3, 2022</Date>
                        <CoverHeading>Get more engagement by offering a variety of trip activities</CoverHeading>
                        <Content>You can significantly boost your reputation and trades by offering more trip activities.</Content>
                        <Divider sx={{ py: .4 }} />
                    </Box>
                    <Grid container>
                        {bottomNews.map((res) => {
                            return <Grid xs={12} sm={6} md={6} lg={6}>
                                <Box sx={{ pr: 2, pb: 2 }} onClick={() => navigate("/news/details")}>
                                    <img src={res.image} alt={res.heading}/>
                                    <Date sx={{ mt: 1 }}>{res.date}</Date>
                                    <SmallHeading sx={{ mb: 0 }}>{res.heading}</SmallHeading>
                                </Box>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} >
                    {smallNews.map((res) => {
                        return <Box  onClick={() => navigate("/news/details")}>
                            <Date sx={{ mt: 0 }}>{res.date}</Date>
                            <SmallHeading sx={{ mb: 0 }}>{res.heading}</SmallHeading>
                            <Content>{res.content}</Content>
                            <Divider sx={{ my: 1 }} />
                        </Box>
                    })}
                </Grid>
            </Grid>
        </FormBox>
    </MainBox >
}
export default News