import React from 'react';

import { Box } from '@mui/material';
import { CountName } from './Elements';
import { useSelector } from 'react-redux';
import { ReceivedTripBox } from './TripBox';

import './style.css';

const Received = ({ loading, getTime, cancelOffer, apiCall }) => {
  const { received } = useSelector((state) => state.auth);
  return received?.length > 0 ? (
    <>
      <CountName>You have received {received?.length} offer(s)</CountName>
      {[...received]?.map((res, i) => {
        return (
          <ReceivedTripBox
            loading={loading}
            apiCall={apiCall}
            cancelOffer={cancelOffer}
            getTime={getTime}
            res={res}
            key={i}
          />
        );
      })}
    </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '77vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CountName>No new trade offer(s)</CountName>
    </Box>
  );
};
export default Received;
