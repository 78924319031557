import React, { useState, useEffect } from "react";
import ProfileBox from "./ProfileBox"
import FollowersBox from "./FollowersBox"
import Sidebar from "../../../components/Sidebar"

import { Box, useMediaQuery } from "@mui/material"
import { LeftGrid, RightGrid, MainGrid } from "./Elements"
import { getFunction } from "../../../features/auth/action"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "../style.css"

import GreenLoader from "../../../assets/greenLoader.gif"

const Private = () => {

    const { id } = useParams()

    const dispatch = useDispatch()

    const matches600 = useMediaQuery('(max-width:600px)');

    const [state, setState] = useState({ mobileOpen: false })

    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); }

    const { loading } = useSelector(state => state.auth)

    const handleDrawerToggle = () => { handleChange('mobileOpen', !state.mobileOpen) };

    const apiCall = (type) => {
        let body = {};
        if (type === 'trips') { body['name'] = 'trips'; body['url'] = `/trip?hostId=${id}`; }
        else if (type === 'photos') { body['name'] = 'photos'; body['url'] = `/trip/getPhotos/${id}`; }
        else if (type === "followers") { body['name'] = 'follower'; body['url'] = `/user/followers/${id}` }
        else if (type === "userInfo") { body['name'] = 'userInfo'; body['url'] = `/user/getUser/${id}` }
        else if (type === "following") { body['name'] = 'following'; body['url'] = `/user/following/${id}` }
        else if (type === "reviews") { body['name'] = 'reviews'; body['url'] = `/user/reviews/${id}` }
        else if (type === "blocked") { body['name'] = 'blocked'; body['url'] = `/user/blockedUsers/${id}` }
        else if (type === "blockedBy") { body['name'] = 'blockedBy'; body['url'] = `/user/blockedByUsers/${id}` }
        dispatch(getFunction(body))
    }

    useEffect(() => {
        apiCall('trips')
        apiCall('followers')
        apiCall('userInfo')
        apiCall('following')
        apiCall('reviews')
        apiCall('photos')
        apiCall('blockedBy')
        apiCall('blocked')
        // eslint-disable-next-line
    }, [])

    return <Box sx={{ marginTop: matches600 ? 6 : 0, display: 'flex', background: '#F9F9F9' }}>
        <Sidebar />
        <MainGrid container spacing={2}>
            {loading &&
                <div style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', left: "38%", top: "50%" }}>
                    <img src={GreenLoader} width="80" alt="GreenLoader" />
                </div>}
            <LeftGrid item xs={12} sm={12} md={12} lg={7} >
                <ProfileBox apiCall={apiCall} handleDrawerToggle={handleDrawerToggle} />
            </LeftGrid>
            <RightGrid item sm={12} md={3} lg={4}>
                <FollowersBox mobileOpen={state.mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            </RightGrid>
        </MainGrid>
    </Box>
}
export default Private