import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';

export const MainContainer = styled(Grid)(() => ({
  padding: '3vh 6vw',
  display: 'flex',
  flexDirection: 'row',
}));
export const MainGrid = styled(Grid)(() => ({
  height: '65vh',
  backgroundColor: '#283C2E',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-around',
  '@media(max-width: 960px)': {
    height: 'max-content',
  },
}));
export const Text = styled('p')(() => ({
  fontFamily: 'Poppins-Medium',
  fontStyle: 'normal',
  cursor: 'pointer',
}));
export const GetStartText = styled('p')(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 600,
  cursor: 'pointer',
  fontSize: '20px',
}));
export const GetSupport = styled('p')(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  cursor: 'pointer',
  marginBottom: 4,
  marginTop: 15,
}));
export const PhoneNumber = styled('p')(() => ({
  fontFamily: 'Roboto, sans-serif',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '28px',
  cursor: 'pointer',
  marginTop: 7,
}));
export const Address = styled('p')(() => ({
  fontFamily: 'Poppins-Light',
  fontSize: '15px',
  cursor: 'pointer',
  marginTop: 33,
}));
export const Copyright = styled('p')(() => ({
  fontFamily: 'Poppins-Light',
  fontStyle: 'normal',
  fontSize: 'small',
  letterSpacing: 0.4,
  opacity: 0.6,
  // marginBottom:0,
  '@media(max-width: 600px)': {
    textAlign: 'center',
  },
}));
export const BrandName = styled('p')(() => ({
  fontFamily: 'Suez One, serif',
  fontWeight: 500,
  fontSize: '22.5455px',
  lineGeight: '29px',
  color: '#FFFFFF',
  cursor: 'pointer',
}));
export const LogoGrid = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  '@media(max-width: 600px)': {
    justifyContent: 'center',
  },
}));
