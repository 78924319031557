import { createSlice } from "@reduxjs/toolkit";

const initialState = { loading: false };
const loadingSlice = createSlice({
  name: "loadingService",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    closeLoading: (state) => {
      state.loading = false;
    },
  },
});
const loadingReducer = loadingSlice.reducer;
export default loadingReducer;

export const { setLoading, closeLoading } = loadingSlice.actions;
