import React from 'react';

import { CancelButton } from '../Button';
import { Box, Grid, Modal } from '@mui/material';
import { Heading, style, RedButton } from './Elements';
import { GreenButton, PlanName, Text } from '../../pages/Settings/Elements';
import { useSelector } from 'react-redux';
import moment from 'moment';
// import { Text } from '../../pages/Elements';

const DeleteTheCard = ({ data, open, deleteTheCard, setOpen }) => {
  const { userInfo, isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ border: 'unset' }}
      >
        <Box
          sx={{
            ...style,
            width: 550,
            height: 'max-content',
            pb: 4,
            pt: 2,
            px: 2,
            alignItems: 'flex-start',
          }}
        >
          <Box className="centerDiv">
            <Text>
              <PlanName sx={{ textAlign: 'center', fontSize: '1.375rem' }}>
                Are you sure you want to delete this card?
              </PlanName>
            </Text>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <RedButton
                sx={{ mr: 2 }}
                onClick={() => deleteTheCard(data)}
                type="submit"
                variant="contained"
                disableripple
              >
                Yes, delete it
              </RedButton>
              <CancelButton
                type="submit"
                onClick={() => setOpen(false)}
                variant="contained"
                disableripple
              >
                No, keep it
              </CancelButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteTheCard;
