import { styled } from '@mui/material/styles';
import { ListItemText, Divider, Typography, Box, Toolbar, ListItemButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

const drawerWidth = 290;
const drawerSmallWidth = 90;

export const openedMixin = (theme) => ({
    color: '#FFFFFF',
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '140%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#283C2E',
    overflowX: 'hidden',
});
export const closedMixin = (theme) => ({
    width: drawerSmallWidth,
    backgroundColor: '#283C2E',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'white',
    overflowX: 'hidden',
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});
export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'block',
    textAlign: 'center',
    color: 'white',
    padding: theme.spacing(2, 0),
    ...theme.mixins.toolbar,
}));
export const DrawerFooter = styled(Box)(({ theme }) => ({
    display: 'block',
    textAlign: 'center',
    ...theme.mixins.toolbar,
    padding: theme.spacing(2, 0),
}));
export const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
export const drawerStyles = makeStyles({
    MuiDrawer: {
        backgroundColor: "#283C2E !important",
        color: 'white'
    },
    MuiCustomDrawer: {
        backgroundColor: "#283C2E !important",
        color: 'white',
        width:drawerWidth
    }
});
export const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
    () => ({ backgroundColor: '#283C2E' }),
);
export const CustomerToolBar = styled(Toolbar)(() => ({
    display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'
}));
export const ListText = styled(ListItemText)(() => ({
    cursor: 'pointer',
    '& > :first-of-type': {
        marginBottom: 2,
        color: "#FFFFFF",
        fontSize: '.9rem',
        fontStyle: 'normal',
        letterSpacing: "0.0168em",
        fontFamily: 'Poppins-Light',
    },
}));
export const CustomDivider = styled(Divider)(() => ({
    marginBottom: 10,
    border: 'none'
}));
export const UserName = styled(Typography)(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '130%',
    color: '#FAFAFA',
}));
export const PageName = styled(Typography)(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '16px',
    textTransform: 'capitalize',
    lineHeight: '130%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
}));
export const LogoText = styled(Typography)(() => ({
    flexGrow: 1, fontFamily: "Suez One", paddingLeft: 1.5, display: 'flex', alignItems: 'center',
}));
export const GetListItem = ({ children, url, location, open }) => {
    if (location.pathname === url) {
        return <ListItemButton sx={{
            background: 'rgba(255, 255, 255, 0.1)',
            padding: '12px 20px 12px 16px',
            width: open ? '90%' : '56px',
            height: '48px',
            margin: 'auto',
            borderRadius: '5px',
            justifyContent: open ? 'initial' : 'center', px: 2.5
        }}>
            {children}
        </ListItemButton>
    } else {
        return <ListItemButton sx={{
            borderRadius: '5px',
            padding: '12px 20px 12px 16px',
            width: open ? '90%' : '56px',
            height: '48px',
            margin: 'auto',
            justifyContent: open ? 'initial' : 'center', px: 2.5
        }}>
            {children}
        </ListItemButton>
    }
}