import { initializeApp } from 'firebase/app';
import { getToken, getMessaging } from 'firebase/messaging';
import { isSupported } from "firebase/messaging";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore" // import Firestore package

const firebaseConfig = {
  apiKey: "AIzaSyAlmf6JkvxhYJOr2DuUvpuwZxKFQJniCNc",
  authDomain: "triptrader-b86d5.firebaseapp.com",
  projectId: "triptrader-b86d5",
  storageBucket: "triptrader-b86d5.appspot.com",
  messagingSenderId: "491634645448",
  appId: "1:491634645448:web:856930c20c879bc1b548fd",
  measurementId: "G-8C2V2Q4QWT"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp) // make database available in your app

const authentication = getAuth(firebaseApp);
export { authentication, firebaseApp, db };

export const getOrRegisterServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = async () => {

  const hasFirebaseMessagingSupport = await isSupported();
  if (hasFirebaseMessagingSupport) {
    const messaging = getMessaging(firebaseApp);

    getOrRegisterServiceWorker()
      .then((serviceWorkerRegistration) =>
        getToken(messaging, { vapidKey: 'BJbLw6jUgx0DRFrvpEL5ML8P59qFEbGKSwMWBT6Q9-uN6hlmGSKyr3sZFxJqI8JfnimuLwpZFmzR30Jk3a4eaJ8', serviceWorkerRegistration }));
  }
}
