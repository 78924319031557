import React from 'react';

import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Heading, Description, style } from "./Elements"
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from '../Button';
export const FreeUserRestriction = ({ open, handle, stateName }) => {
    const navigate = useNavigate()
    const handleClose = () => handle([stateName], false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: 400, height: 400, alignItems: 'center', pt: 5, px: 3, pb: 3, }}>
                    <Heading>Limited Access</Heading>
                    <Description>This feature is only available for subscribed members.<br /> Please subscribe for unlimited access.</Description>
                    <Box sx={{ width: '100%' }}>
                        <CustomGreenLargeButton onClick={() => navigate('/freePlan')} type="submit" variant="contained" disableripple sx={{ mb: 1 }}>
                        Choose a Subscription
                        </CustomGreenLargeButton>
                        <CustomGreenTransLargeButton onClick={handleClose} type="submit" variant="contained" disableripple>
                            Cancel
                        </CustomGreenTransLargeButton>
                    </Box>
                </Box>
            </Modal>

        </div>
    );
}