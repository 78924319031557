import toast from 'react-hot-toast';
import api from '../../api';
import { StripeData } from './reducer';

export const getTheAddedCards = (body) => async (dispatch) => {
  console.log(body);
  await api
    // .get(`/stripe?customerId=${body}`)
    .get(`/stripe?customerId=${body}`)
    .then((res) => {
      dispatch(StripeData(res?.data?.data?.data));
    })
    .catch((err) => {
      if (err.response) {
        toast.error(err.response.data.message, { duration: 4000 });
      }
    });
};
