import React, { useEffect } from "react";
import CustomTripBox from "./TripBox"
import Sidebar from "../../components/Sidebar"

import { FormBox } from "./Elements"
import { useMediaQuery, Box } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { getFunction } from "../../features/auth/action"
import { MainBox, UserName, CountName } from "../Elements";

const TradeOffer = () => {
    const dispatch = useDispatch()

    const { userInfo, confirmedTrips } = useSelector(state => state.auth)

    const matches600 = useMediaQuery('(max-width:600px)');

    const apiCall = () => {
        let body = { name: 'confirmedTrips', url: `/offer/getConfirmedTrips/${userInfo?._id}` };
        dispatch(getFunction(body))
    }

    useEffect(() => {
        apiCall()
        // eslint-disable-next-line
    }, [])


    return <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
        <Sidebar />
        <FormBox>
            {confirmedTrips?.length === 0 &&
                <>
                    <UserName sx={{ fontSize: '18px' }}>Confirmed Trips</UserName>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '77vh', justifyContent: 'center', alignItems: 'center' }}>
                        <CountName>You have no confirmed trip(s) yet.</CountName>
                    </Box>
                </>
            }
            {confirmedTrips?.length > 0 &&
                <>
                    <UserName sx={{ fontSize: '18px' }}>Confirmed Trips</UserName>
                    <CountName sx={{ mb: 2, mt: 1 }}>You have {confirmedTrips?.length} upcoming trip(s)</CountName>
                    {confirmedTrips?.map((res, i) => {
                        return <CustomTripBox res={res} key={i} />
                    })}
                </>
            }

        </FormBox >

    </MainBox >
}
export default TradeOffer