import React from "react";
import correct from "../../../assets/create account/correct.png"
import { PriceRow, Content, Save, Savings, BtnRow, SubHeading, Heading } from "../Components/Elements";
import { Divider } from "@mui/material";
import { planContent } from "../../../data"
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from "../../../components/Button"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style.css"
import { config, getDate } from "../../../utills/constants";
import api from "../../../api"

const Step1 = ({ state, handleChange, getPlanPrice }) => {
    const navigate = useNavigate()
    const { plans } = useSelector(state => state.general)
    const { userInfo } = useSelector(state => state.auth)
    const handlePlanChange = async () => {
        const results = plans?.filter(obj => obj.type === state.plan)[0]
        handleChange('total', (results.charges * (state.plan === "annual" ? 12 : 1) - (state.plan === "annual" ? .01 : 0)))
        handleChange('monthly', results.charges)
        handleChange('discount', results.discount)
        handleChange('step', 2)
        handleChange('priceId', results?.stripeId)
   
    }

    return <>
        <Heading>{'Choose a plan'}</Heading>
        <SubHeading>{'Unlock all features with a subscription.'}</SubHeading>
        <Divider />
        <BtnRow>
            <button className={state.plan === "annual" ? "leftBtn selectedPlanBtn" : "leftBtn notSelectedPlanBtn"} onClick={() => handleChange('plan', 'annual')}>Annual</button>
            <button className={state.plan === "monthly" ? "rightBtn selectedPlanBtn" : "rightBtn notSelectedPlanBtn"} onClick={() => handleChange('plan', 'monthly')}>Monthly</button>
        </BtnRow>
        <div>
            <PriceRow>
                <span>{state.plan === "annual" ? getPlanPrice('annual') : getPlanPrice('monthly')}</span><span>{state.plan === "annual" ? '/month (Billed annually)' : '/month'}</span>
            </PriceRow>
            {state.plan === "annual" ? <Savings>Best Value • $20 Savings</Savings> : <Save>Save $20 with an&nbsp;<Savings onClick={() => handleChange('plan', 'annual')}>Annual Plan</Savings></Save>}
        </div>
        <div>
            {planContent?.map((res, i) => {
                return <Content key={i}><img alt="correct" src={correct} style={{ paddingRight: 15 }} />{res}</Content>
            })}</div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div className="buttonDiv">
                <CustomGreenLargeButton onClick={handlePlanChange} type="submit" variant="contained" disableripple>Choose {state.plan === "annual" ? 'Annual' : 'Monthly'} Plan</CustomGreenLargeButton>
            </div>
            <div className="buttonDiv">
                <CustomGreenTransLargeButton onClick={() => navigate('/search')} type="submit" variant="contained" disableripple>Try for free</CustomGreenTransLargeButton>
            </div>
        </div>
    </>
}
export default Step1