import React, { useState,useEffect } from "react";

import { useSelector } from "react-redux";
import { MainReviewGrid } from "./Elements"
import { LeaveReviewModal } from "../../../components/Modal/Review"

import NoReviews from "../ReviewComponent/NoReviews"
import PrivateReviews from "../ReviewComponent/PrivateReviews"

const Reviews = ({ apiCall }) => {
    const [state, setState] = useState({ openLeaveReviewModal: false })
    const handleChange = (name, value) => { setState(prevState => ({ ...prevState, [name]: value })); };

    const { reviews } = useSelector(state => state.auth)

   
    return <MainReviewGrid>
        {reviews?.length === 0 ?
            <NoReviews handleChange={handleChange} /> :
            <PrivateReviews apiCall={apiCall} handleChange={handleChange} />}
        <LeaveReviewModal apiCall={apiCall} open={state.openLeaveReviewModal} stateName="openLeaveReviewModal" handle={handleChange} />
    </MainReviewGrid>
}
export default Reviews