import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { GreenButton } from '../Elements';
import { DateSingleInput } from './DateInput';
import { Avatar, Box, Button, Grid } from '@mui/material';
import { Header } from '../../components/Modal/ModalHeader';
import { DateWithoutFormat, getFullName, modifyDurationTitle } from '../../utills/constants';
import { HeadingSub, GreyText, Description, TripDetailsGrid } from './Elements';

const Step1 = ({ handleClose, handleChange, state, parentState, setState }) => {
  const [disableDays, setdisableDays] = useState([]);

  // const [selectedDay, setSelectedDay] = useState([]);

  const { selectedTrip } = parentState;
  console.log('selectedDay', state?.selectedDay);

  function convertDateStringToObject(dateString) {
    // Split the date string by "-"
    const parts = dateString.split('-');

    // Extract day, month, and year from the parts
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];

    // Return an object with day, month, and year properties
    return {
      day: day,
      month: month,
      year: year,
    };
  }

  useEffect(() => {
    const allUnavailableDates = selectedTrip?.unAvailableDays?.allUnavailableDates || [];
    const isPast = moment.utc(selectedTrip.availableFrom).isBefore(new Date());

    // isPast
    //   ? handleChange('minDate', {
    //       month: new Date().getMonth() + 1,
    //       year: new Date().getFullYear(),
    //       day: new Date().getDate(),
    //     })
    //   : handleChange('minDate', {
    //       month: new Date(selectedTrip.availableFrom).getMonth() + 1,
    //       year: new Date(selectedTrip.availableFrom).getFullYear(),
    //       day: new Date(selectedTrip.availableFrom).getUTCDate(),
    //     });

    isPast
      ? handleChange('defaultCalenderValue', [
          {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            day: new Date().getUTCDate(),
          },
        ])
      : handleChange('defaultCalenderValue', [
          {
            // month: 3, year: 2023, day: 15
            month: new Date(selectedTrip.availableFrom).getMonth() + 1,
            year: new Date(selectedTrip.availableFrom).getFullYear(),
            day: new Date(selectedTrip.availableFrom).getUTCDate(),
          },
        ]);

    // handleChange('maxDate', {
    //   day: new Date(selectedTrip.availableTo).getUTCDate(),
    //   month: new Date(selectedTrip.availableTo).getMonth() + 1,
    //   year: new Date(selectedTrip.availableTo).getFullYear(),
    // });

    let minDate = '';
    let maxDate = '';

    const startDate = DateWithoutFormat(selectedTrip?.availableFrom);
    const endDate = DateWithoutFormat(selectedTrip?.availableTo);

    const start_date = moment(startDate).format('YYYY-MM-DD');
    const current_date = moment(new Date()).format('YYYY-MM-DD');

    if (minDate == '') {
      minDate =
        start_date < current_date
          ? moment(current_date).format('YYYY-MM-DD')
          : moment(start_date).format('YYYY-MM-DD');
    }
    if (maxDate == '') maxDate = moment(endDate).format('YYYY-MM-DD');

    console.log('minDate', minDate);
    console.log('maxDate', maxDate);

    handleChange('minDate', convertDateStringToObject(minDate));
    handleChange('maxDate', convertDateStringToObject(maxDate));

    let allUnavailableDatesFormated = [];
    allUnavailableDates?.forEach((res) => {
      allUnavailableDatesFormated?.push(moment.utc(res).format('DD MMM YYYY'));
      // disableDays?.push({
      //   month: new Date(res).getMonth() + 1,
      //   year: new Date(res).getFullYear(),
      //   day: new Date(res).getUTCDate(),
      //   className: 'customDisableDays',
      // });
    });
    handleChange('allUnavailableDates', allUnavailableDatesFormated);

    const arr = [];
    function DateWithoutFormat(date) {
      return new Date(date).toISOString().slice(0, 10);
    }
    function convertDateStringToObject(dateString) {
      // Split the date string by "-"
      const parts = dateString.split('-');

      // Extract day, month, and year from the parts
      const day = parts[2];
      const month = parts[1];
      const year = parts[0];

      // Return an object with day, month, and year properties
      return {
        day: parseInt(day),
        month: parseInt(month),
        year: parseInt(year),
        className: 'customDisableDays',
      };
    }

    allUnavailableDates?.forEach((e) => arr.push(convertDateStringToObject(DateWithoutFormat(e))));

    setdisableDays(arr);
    handleChange('disableDays', arr);
    handleChange('preferredDates', state?.preferredDates);
    console.log('line 113', state);
    // eslint-disable-next-line
  }, []);
  console.log(state?.preferredDates);

  const changeStep = () => {
    if (state?.preferredDates?.length > 0) {
      handleChange('step', 2);
    } else {
      handleChange('preferredDateError', true);
    }
  };

  const clearSelectedDates = () => {
    // setSelectedDay([]);
    handleChange('selectedDay', []);
    handleChange('defaultCalenderValue', []);
    handleChange('preferredDates', []);
  };
  // useEffect(() => {
  //   handleChange('selectedDay', []);
  //   handleChange('defaultCalenderValue', []);

  // }, []);
  return (
    <>
      <Header handleClose={handleClose} heading="Make Offer" />
      <Description sx={{ textAlign: 'left' }}>
        To get started, choose your preferred dates for this trip.
      </Description>
      <TripDetailsGrid container>
        <Grid item xs={2} sm={3} lg={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Avatar src={selectedTrip?.hostId?.image} alt="useer" sx={{ width: 70, height: 70 }} />
        </Grid>
        <Grid
          item
          xs={8}
          sm={9}
          lg={9}
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}
        >
          <HeadingSub
            sx={{
              fontSize: { xs: '13px', sm: '15px', md: '16px', lg: '16px' },
            }}
          >{`${selectedTrip?.species} ${selectedTrip?.tradeType}`}</HeadingSub>
          <HeadingSub
            sx={{
              fontSize: { xs: '10px', sm: '10px', md: '12px', lg: '12px' },
            }}
          >
            Duration:{' '}
            {`${selectedTrip?.duration?.value} ${modifyDurationTitle(
              selectedTrip?.duration?.title,
              selectedTrip?.duration?.value
            )}`}
          </HeadingSub>
          <GreyText sx={{ fontSize: { xs: '10px' } }}>
            Hosted by {getFullName(selectedTrip?.hostId)}
          </GreyText>
        </Grid>
      </TripDetailsGrid>
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12} lg={12}>
          <HeadingSub
            sx={{
              fontSize: { xs: '13px', sm: '15px', md: '16px', lg: '16px' },
            }}
          >
            Preferred Trip Date
          </HeadingSub>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DateSingleInput
            // selectedDay={selectedDay}
            // setSelectedDay={setSelectedDay}
            state={state}
            selectedTrip={selectedTrip}
            handleChange={handleChange}
            placeholder="Choose a date or date range"
            sx={{ width: '100%' }}
          ></DateSingleInput>
          <button className="ClearDates" onClick={clearSelectedDates}>
            Clear dates
          </button>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          mt: 2,
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <GreyText sx={{ fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px' } }}>
          Step 1 of 4
        </GreyText>
        <GreenButton onClick={changeStep} type="submit" variant="contained" disableripple>
          Continue
        </GreenButton>
      </Box>
    </>
  );
};

export default Step1;
