import React, { useState } from 'react';

import { style } from './Elements';
import { Modal, Box } from '@mui/material';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

import EditOffer from './EditOffer';
import SuccessStep from './SuccessStep';
import EditOfferCustom from './EditOfferCustom';
import CustomOfferStep1 from './CustomOfferStep1';
import DeleteError from './DeleteError';

const MakeOffer = ({ parentState, open, handle, stateName }) => {
  const defaultState = {
    durationValue: 1,
    durationTitle: 'days',
    step: 1,
    preferedDates: [],
    isCustomOfferSelected: false,
    straight: true,
    preferedDatesText: '',
    openUnAvailableDays: false,
    unAvailableDaysText: '',
    defaultCalenderValue: [],
    isEdit: false,
    typesData: [],
    deleteErrorMessage: '',
    speciesData: [],
    hostOffer: { durationValue: 1, unAvailableDays: { dayWeekText: '', excludeDateText: '' } },
    selectedDay: [],
  };
  const [state, setState] = useState({ ...defaultState });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleNestedChange = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      hostOffer: { ...prevState.hostOffer, [name]: value },
    }));
  };
  const handleClose = () => {
    handle([stateName], false);
    setState({ ...defaultState });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: state.step !== 1 ? '512px' : state.step === 5 ? '388px' : '500px',
            height:
              state.step === 1
                ? '435px'
                : state.step === 2
                ? '304px'
                : state.step === 5
                ? 'max-content '
                : 'max-content',
            alignItems: 'flex-start',
            p: 3,
            overflow: state.step === 2 ? 'unset' : 'auto',
          }}
        >
          {state.step === 1 && (
            <Step1
              parentState={parentState}
              state={state}
              handleChange={handleChange}
              handleClose={handleClose}
              setState={setState}
            />
          )}
          {state.step === 2 && (
            <Step2 state={state} handleChange={handleChange} handleClose={handleClose} />
          )}
          {state.step === 3 && (
            <Step3
              handle={handle}
              parentState={parentState}
              state={state}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
          {state.step === 4 && (
            <CustomOfferStep1
              handleNestedChange={handleNestedChange}
              state={state}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
          {state.step === 5 && (
            <SuccessStep
              parentState={parentState}
              state={state}
              handle={handle}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
          {state.step === 6 && (
            <EditOffer
              handleNestedChange={handleNestedChange}
              parentState={parentState}
              state={state}
              handle={handle}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
          {state.step === 7 && (
            <EditOfferCustom
              handleNestedChange={handleNestedChange}
              parentState={parentState}
              state={state}
              handle={handle}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
          {state.step === 8 && (
            <DeleteError
              handleNestedChange={handleNestedChange}
              parentState={parentState}
              state={state}
              handle={handle}
              handleChange={handleChange}
              handleClose={handleClose}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default MakeOffer;
