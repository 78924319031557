import React, { useState, useEffect } from 'react';

import api from '../../../api';
import ProfileBox from './ProfileBox';
import FollowersBox from './FollowersBox';
import Sidebar from '../../../components/Sidebar';
import GreenLoader from '../../../assets/greenLoader.gif';

import { useNavigate, useParams } from 'react-router-dom';
import { config, swalWithStyledButton } from '../../../utills/constants';
import { Box, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFunction } from '../../../features/profile/action';
import { LeftGrid, RightGrid, MainGrid } from '../Private/Elements';
import { reload } from '../../../features/auth/action';
import '../style.css';

const Public = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const matches600 = useMediaQuery('(max-width:600px)');

  const [state, setState] = useState({ mobileOpen: false, isRefrsh: false });
  const [isLoadingApis, setIsLoadingApis] = useState(true);

  const { userInfo } = useSelector((state) => state.auth);

  const { loading } = useSelector((state) => state.profile);

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDrawerToggle = () => {
    handleChange('mobileOpen', !state.mobileOpen);
  };

  const apiCall = (type) => {
    let body = {};
    if (type === 'trips') {
      body['name'] = 'trips';
      body['url'] = `/trip?hostId=${id}`;
    } else if (type === 'photos') {
      body['name'] = 'photos';
      body['url'] = `/trip/getPhotos/${id}`;
    } else if (type === 'reviews') {
      body['name'] = 'reviews';
      body['url'] = `/user/reviews/${id}`;
    } else if (type === 'followers') {
      body['name'] = 'follower';
      body['url'] = `/user/followers/${id}`;
    } else if (type === 'following') {
      body['name'] = 'following';
      body['url'] = `/user/following/${id}`;
    } else if (type === 'profileInfo') {
      body['name'] = 'profileInfo';
      body['url'] = `/user/getUser/${id}`;
    }
    dispatch(getFunction(body));
    setIsLoadingApis(false);
  };
  const checkIFBlocked = async () => {
    const blockedByUsers = await api.get(`/user/blockedByUsers/${userInfo?._id}`, config);
    const hasId = blockedByUsers?.data?.blockedBy.some((item) => item?.userId?._id == id);
    if (hasId) {
      swalWithStyledButton
        .fire({
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: `This user is blocked.`,
          confirmButtonText: 'Reload',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            dispatch(reload(userInfo?._id, navigate));
            navigate('/search');
          } else if (result.isDenied) {
            navigate('/search');
            dispatch(reload(userInfo?._id, navigate));
          }
        });
    } else {
      apiCall('profileInfo');
      apiCall('photos');
      apiCall('trips');
      apiCall('followers');
      apiCall('following');
      apiCall('reviews');
    }
  };
  useEffect(() => {
    checkIFBlocked();
    // eslint-disable-next-line
  }, [id]);
//   useEffect(() => {
//     checkIFBlocked();
//     // eslint-disable-next-line
//   }, [state.isRefrsh]);

//   if (state.isRefrsh === true) {
//     setTimeout(() => {
//       handleChange('isRefrsh', false);
//     }, 600);
//   }
  return (
    <Box sx={{ marginTop: matches600 ? 6 : 0, display: 'flex', background: '#F9F9F9' }}>
      <Sidebar />
      {loading || isLoadingApis ? (
        <div
          style={{
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            left: '38%',
            top: '50%',
          }}
        >
          <img src={GreenLoader} width="80" alt="GreenLoader" />
        </div>
      ) : (
        <MainGrid container spacing={2}>
          <LeftGrid item xs={12} sm={12} md={12} lg={7}>
            <ProfileBox
              handleChange={handleChange}
              apiCall={apiCall}
              handleDrawerToggle={handleDrawerToggle}
            />
          </LeftGrid>
          <RightGrid item sm={12} md={3} lg={4}>
            <FollowersBox mobileOpen={state.mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          </RightGrid>
        </MainGrid>
      )}
    </Box>
  );
};
export default Public;
