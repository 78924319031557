import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { MainBox, UserName } from '../Elements';
import { useSelector, useDispatch } from 'react-redux';
import { CustomGreenLargeButton } from '../../components/Button';
import { FormBoxA, FormBox, CustomListItemText } from './Elements';
import { Grid, Avatar, List, ListItemAvatar } from '@mui/material';
import { useMediaQuery, ListSubheader, ListItem } from '@mui/material';
import { updateFuntion, getFunction } from '../../features/auth/action';
import { paidUserSettingsList, guestUserSettingsList } from '../../data';

import Sidebar from '../../components/Sidebar';
import right from '../../assets/settings/right.png';
import CustomSwitch from '../../components/Button/Switch';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches600 = useMediaQuery('(max-width:600px)');

  const { role, userInfo, isAuth } = useSelector((state) => state.auth);

  const [enable, setenable] = useState(userInfo?.notificationEnabled);

  const apiCall = () => {
    let body = { name: 'userInfo', url: `/user/getUser/${userInfo?._id}` };
    dispatch(getFunction(body));
    setenable(userInfo?.notificationEnabled);
  };
  const handleEnable = async () => {
    const value = !enable;
    setenable(value);
    const body = {
      url: `/user/${userInfo?._id}`,
      _id: userInfo?._id,
      notificationEnabled: value,
    };
    dispatch(updateFuntion(body));
    apiCall();
  };

  useEffect(() => {
    if (isAuth) {
      apiCall();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }}>
      <Sidebar />
      <FormBox>
        <FormBoxA sx={{ height: 'max-content' }}>
          <List
            subheader={
              <ListSubheader>
                <UserName sx={{ fontSize: '18px', mb: 3, mt: 1 }}>Account Settings</UserName>
              </ListSubheader>
            }
          >
            {role === 'guest'
              ? guestUserSettingsList.map((res, i) => {
                  return (
                    <ListItem sx={{ pl: 1.6 }} key={i}>
                      <ListItemAvatar>
                        <Avatar sx={{ background: '#F2F3F1' }}>
                          <img
                            alt={res.icon}
                            src={res.icon}
                            style={{ opacity: res.name === 'Blocked Users' ? 0.5 : 1 }}
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <CustomListItemText
                        id="switch-list-label-wifi"
                        style={{ opacity: res.name === 'Blocked Users' ? 0.5 : 1 }}
                        primary={res.name}
                      />
                      {res.name === 'Notifications' && (
                        <CustomSwitch handleToggle={handleEnable} checked={enable} />
                      )}
                      {res.name === 'Blocked Users' && (
                        <img src={right} alt="right" style={{ cursor: 'pointer', opacity: 0.5 }} />
                      )}
                    </ListItem>
                  );
                })
              : paidUserSettingsList.map((res, i) => {
                  return (
                    <ListItem sx={{ pl: 1.6 }} key={i}>
                      <ListItemAvatar>
                        <Avatar sx={{ background: '#F2F3F1' }}>
                          <img src={res.icon} alt={res.icon} />
                        </Avatar>
                      </ListItemAvatar>
                      {res.name === 'Change Password' && (
                        <CustomListItemText
                          id="switch-list-label-wifi"
                          onClick={() => navigate('/changepassword')}
                          primary={'Change Password'}
                        />
                      )}
                      {res.name === 'Cards' && (
                        <CustomListItemText
                          id="switch-list-label-wifi"
                          onClick={() => {
                            userInfo?.subscription?.status === 'Premium' ||
                            userInfo?.subscription?.status === 'PremiumC'
                              ? navigate('/manage/cards')
                              : navigate('/freePlan');
                          }}
                          primary={'Cards'}
                        />
                      )}
                      {res.name === 'Manage Subscription' && (
                        <CustomListItemText
                          id="switch-list-label-wifi"
                          onClick={() => {
                            userInfo?.subscription?.status === 'Premium' ||
                            userInfo?.subscription?.status === 'PremiumC'
                              ? navigate('/manageSubscription')
                              : navigate('/freePlan');
                          }}
                          primary={'Manage Subscription'}
                        />
                      )}
                      {res.name === 'Notifications' && (
                        <>
                          <CustomListItemText id="switch-list-label-wifi" primary={res.name} />
                          <CustomSwitch handleToggle={handleEnable} checked={enable} />
                        </>
                      )}
                      {res.name === 'Blocked Users' && (
                        <>
                          <CustomListItemText
                            id="switch-list-label-wifi"
                            primary={res.name}
                            onClick={() => navigate('/settings/blockedusers')}
                          />
                          <img
                            src={right}
                            alt="right"
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate('/settings/blockedusers')}
                          />
                        </>
                      )}
                    </ListItem>
                  );
                })}
          </List>

          {role === 'guest' && (
            <Grid sx={{ px: 1.8, mt: 2 }}>
              <CustomGreenLargeButton
                onClick={() => navigate('/join')}
                type="submit"
                variant="contained"
                disableripple
                sx={{ mb: 1 }}
              >
                Join Now for full access
              </CustomGreenLargeButton>
            </Grid>
          )}
        </FormBoxA>
      </FormBox>
    </MainBox>
  );
};
export default Settings;
