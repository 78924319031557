import React from "react"
import FindTrip from "./FindTrip"
import { useNavigate } from "react-router-dom"
import { homeBottomIcons } from "../../../data"
import { Grid, Container } from "@mui/material";
import { HomeHeader } from "../../../components/Header"
import { SmallButton } from "../../../components/Button"
import { JoinNowBoxLeft, MainGrid, BottomSubGrid, Description } from "./Elements"
import { BottomBoxText, SearchGrid, BottomBox, BottomGrid, Heading } from "./Elements"

const Home = ({ refs }) => {
    const navigate = useNavigate()
    const redirectToJoin = () => {
        navigate('/join')
    }

    return (<MainGrid ref={refs.home}>
        <Container maxWidth="xl" style={{ paddingTop: 20, px: 0 }}>
            <HomeHeader refs={refs} />
            <SearchGrid container spacing={2} >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <JoinNowBoxLeft>
                        <Heading>Your next outdoor adventure awaits</Heading>
                        <Description sx={{ mt: 3 }}>Join other outdoor enthusiasts and start turning trades into memorable experiences</Description>
                        <SmallButton onClick={redirectToJoin} sx={{ mt: 3 }}>Join Now</SmallButton>
                    </JoinNowBoxLeft>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FindTrip />
                </Grid>
            </SearchGrid>

        </Container>
        <BottomGrid >
            <BottomSubGrid item xs={12} sm={12} md={6} lg={6} >
                {homeBottomIcons.map((res, i) => {
                    return <BottomBox key={i}>
                        <img src={res.img} alt={res.title}/>
                        <BottomBoxText>{res.title}</BottomBoxText>
                    </BottomBox>
                })}
            </BottomSubGrid>
        </BottomGrid>
    </MainGrid >)
}

export default Home