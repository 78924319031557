import { Box, Grid, Button, Modal } from '@mui/material';
import React, { useState } from 'react';
import {} from '@mui/material';
import { ImageContainer } from './Elements';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../utills/constants';
import { CancelButton } from '../../components/Button';
import { CenterHeader } from '../../components/Modal/ModalHeader';
import { GreenButton, Description } from '../../components/Modal/Elements';

import successImage from '../../assets/create account/successImage.png';
import correctCircle from '../../assets/create account/correctCircle.png';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function FreeTrialModal({ open, setOpen, handleClose, OfferSent, freeTrialData }) {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: '388px',
            height: 'max-content',
            borderRadius: '0.625rem',
            alignItems: 'flex-start',
            p: 3,
            overflow: 'auto',
            border: 'unset',
          }}
        >
          <CenterHeader heading="Offer Sent" handleClose={handleClose} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <ImageContainer>
              <img src={successImage} alt="successImage" />
              <span>
                <img src={correctCircle} alt="correctCircle" />
              </span>
            </ImageContainer>
            <Description sx={{ mt: 3, fontWeight: 600, fontFamily: 'Poppins-Regular' }}>
              {OfferSent}
            </Description>
            <Description sx={{ textAlign: 'center', my: 1 }}>
              {getFullName(freeTrialData)}
            </Description>
          </Box>
          <Grid sx={{ width: '100%' }}>
            <GreenButton
              onClick={handleClose}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 1 }}
            >
              Done
            </GreenButton>
            <CancelButton
              onClick={() => navigate('/tradeOffer')}
              type="submit"
              variant="contained"
              disableripple
              sx={{ width: '100%', mt: 2 }}
            >
              Go to Trade offers
            </CancelButton>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default FreeTrialModal;
