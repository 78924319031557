import React, { useState } from 'react';
import NoData from '../ReviewComponent/NoData';

import {
  Button,
  Grid,
  Modal,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ScrollAbleGrid } from '../Private/Elements';
import { CountName } from '../Elements';

import '../style.css';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'inherit',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  minWidth: '60%',
};

const Photos = () => {
  const { photos } = useSelector((state) => state.profile);
  const [imagePreview, setImagePreview] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (i) => {
    setOpen(true);
    setImagePreview(i);
  };

  const nextImage = () => {
    setImagePreview((prevIndex) => (prevIndex + 1 < photos.length ? prevIndex + 1 : prevIndex));
  };

  const prevImage = () => {
    setImagePreview((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
  };

  const handleClose = () => setOpen(false);
  return (
    <Grid sx={{ px: 2, marginLeft: 2 }}>
      {photos?.length === 0 ? (
        <NoData text={'No photos available.'} />
      ) : (
        <CountName sx={{ my: 2 }}>
          {photos?.length} photo{photos?.length > 1 ? 's' : ''}
        </CountName>
      )}

      <ScrollAbleGrid container>
        {photos?.map((res, index) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={3} className="imgParent">
              <img
                style={{ cursor: 'pointer' }}
                src={res?.photos}
                alt="images"
                onClick={() => handleOpen(index)}
              />
            </Grid>
          );
        })}
      </ScrollAbleGrid>
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'end' }}>
          <span onClick={handleClose} style={{ textAlign: 'end', cursor: 'pointer' }}>
            &#10005;
          </span>
        </DialogTitle>
        <DialogContent>
          <img
            style={{
              width: '100%',
              height: 600,
              objectFit: 'contain',
            }}
            src={photos[imagePreview]?.photos}
            alt="AvatarPhotos"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={prevImage} disabled={imagePreview === 0}>
            Previous
          </Button>
          <span>{imagePreview + 1} / {photos?.length}</span>
          <Button onClick={nextImage} disabled={imagePreview === photos.length - 1}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={photos[imagePreview]?.photos}
            alt="AvatarPhotos"
          />
        </Box>
      </Modal> */}
    </Grid>
  );
};
export default Photos;
