import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const FormBox = styled(Box)(() => ({
    width: '38%',
    border: '1px solid #E5E5E5',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '94vh',
    overflowY: 'scroll',
    padding: '10px 30px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 1200px)': {
        height: 'max-content',
        width: '50%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
    },
}))
export const CoverHeading = styled('h4')(() => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    textAlign: 'left',
    marginTop: 10, marginBottom: 10,
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '140%',
    color: '#101B10',
    display: 'flex',
    alignItems: 'center',
    '& > :first-of-type': {
        fontSize: '14px',
        fontWeight: 100,
    },
}));
export const SmallHeading = styled('h4')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    textAlign: 'left',
    marginTop: 10, marginBottom: 10,
    fontWeight: 800,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#111111',
    display: 'flex',
    alignItems: 'center',
    '& > :first-of-type': {
        fontSize: '14px',
        fontWeight: 100,
    },
}));
export const Content = styled('span')(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    marginTop: 5, marginBottom: 5,
    fontSize: '11px',
    opacity: .6,
    lineHeight: 1.2,
    color: '#111111',
    display: 'flex',
    alignItems: 'center',
}));
export const Date = styled('span')(() => ({
    fontFamily: 'Poppins-Medium',
    fontStyle: 'normal',
    marginTop: 10, marginBottom: 10,
    fontSize: '11px',
    opacity: .4,
    lineHeight: 1.2,
    color: '#111111',
    display: 'flex',
    alignItems: 'center',
}));