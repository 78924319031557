import React from 'react';

import { Box, Grid } from '@mui/material';
import { ImageContainer } from './Elements'
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../../utills/constants';
import { CancelButton } from "../../../components/Button"
import { CenterHeader } from '../../../components/Modal/ModalHeader';
import { GreenButton, Description } from "../../../components/Modal/Elements"

import successImage from "../../../assets/create account/successImage.png"
import correctCircle from "../../../assets/create account/correctCircle.png"

const SuccessStep = ({ handleChange, handle, res }) => {
    const navigate = useNavigate()

    const handleClose = () => {
        handleChange('step', 1)
        handle('openPrefferedDates', false);
    }

    return (
        <>
            <CenterHeader heading="Choose Trip Date" handleClose={handleClose} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <ImageContainer>
                    <img src={successImage} alt="successImage" />
                    <span><img src={correctCircle} alt="correctCircle" /></span>
                </ImageContainer>
                <Description sx={{ fontWeight: 600, mt: 3 }}>You accepted an offer from</Description>
                <Description sx={{ textAlign: 'center', my: 1 }}>{getFullName(res?.offeredBy)}</Description>
            </Box>
            <Grid sx={{ width: '100%' }}>
                <GreenButton onClick={handleClose} type="submit" variant="contained" disableripple sx={{ width: '100%', mt: 1 }}>
                    Done
                </GreenButton>
                <CancelButton onClick={() => navigate("/confirmedTrips")} type="submit" variant="contained" disableripple sx={{ width: '100%', mt: 2 }}>
                    Go to Confirmed Trips
                </CancelButton>
            </Grid>
        </>
    );
}
export default SuccessStep