import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ScrollAbleGrid } from './Elements';
import { DeleteTripPhoto } from '../../../components/Modal/TripModals';
import { CountName } from '../Elements';

import NoData from '../ReviewComponent/NoData';
import cross from '../../../assets/profile/cross.png';

import '../style.css';

const Photos = ({ apiCall }) => {
  const { photos } = useSelector((state) => state.auth);

  const [state, setState] = useState({ selectedImage: {}, deletePhoto: false });
  const [imagePreview, setImagePreview] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (i) => {
    setOpen(true);
    setImagePreview(i);
  };

  const nextImage = () => {
    setImagePreview((prevIndex) => (prevIndex + 1 < photos.length ? prevIndex + 1 : prevIndex));
  };

  const prevImage = () => {
    setImagePreview((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : prevIndex));
  };

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const openDeleteImageModal = (res) => {
    handleChange('selectedImage', res);
    handleChange('deletePhoto', true);
  };
  const handleClose = () => setOpen(false);
  return (
    <Grid sx={{ px: 2, marginLeft: 2 }}>
      {photos?.length === 0 ? (
        <NoData text={'No photos available.'} />
      ) : (
        <CountName sx={{ my: 2 }}>
          {photos?.length} photo{photos?.length > 1 ? 's' : ''}
        </CountName>
      )}
      <ScrollAbleGrid container>
        {photos?.map((res,index) => {
          return (
            <Grid item xs={6} sm={6} md={4} lg={3} className="imgParent" key={index}>
              <img src={res?.photos} alt="images" onClick={() => handleOpen(index)} />
              <button className="close" onClick={() => openDeleteImageModal(res)}>
                <img src={cross} alt="cross" />
              </button>
            </Grid>
          );
        })}
        <DeleteTripPhoto
          apiCall={apiCall}
          data={state}
          open={state?.deletePhoto}
          stateName="deletePhoto"
          handle={handleChange}
        />
      </ScrollAbleGrid>
      <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
        <DialogTitle style={{ textAlign: 'end' }}>
          <span onClick={handleClose} style={{ textAlign: 'end', cursor: 'pointer' }}>
            &#10005;
          </span>
        </DialogTitle>
        <DialogContent>
          <img
            style={{
              width: '100%',
              height: 600,
              objectFit: 'contain',
            }}
            src={photos[imagePreview]?.photos}
            alt="AvatarPhotos"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={prevImage} disabled={imagePreview === 0}>
            Previous
          </Button>
          <span>
            {imagePreview + 1} / {photos?.length}
          </span>
          <Button onClick={nextImage} disabled={imagePreview === photos.length - 1}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default Photos;
