import { styled } from '@mui/material/styles';
import { Grid, Box, Button } from '@mui/material';

export const IdentityImageGrid = styled(Box)(() => ({
    marginTop: 20,
    marginBottom: 20,
    height: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    '& img': {
        padding: 2,
        border: '2px solid #3C6B49',
        borderStyle: 'dashed',
        borderRadius: 10,
        maxWidth: '100%',
        objectFit: 'cover',
        maxHeight: '100%'
    },
}));
export const DisputeText = styled('p')(() => ({
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#B93B3B',
}));
export const FollowBtn = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '21px',
    color: 'white',
    padding: '8px 30px',
    flex: 'none',
    order: 0,
    background: '#3C6B49',
    border: 'none',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '30px',
    height: 'max-content',
    cursor: 'pointer'
}));
export const DotsBtn = styled('span')(() => ({
    background: '#FFFFFF',
    border: '1px solid #3C6B49',
    cursor: 'pointer',
    height: 'max-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 10px',
    borderRadius: '50%'
}));
export const CountName = styled(Box)(() => ({
    fontFamily: 'Poppins-Regular',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#111111',
    opacity: 0.6,
    marginTop: 8,
    marginBottom: 8,
    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },
}));
export const ReviewBox = styled(Box)(() => ({
    background: '#F5F5F5',
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    paddingLeft: 20, paddingRight: 20,
    paddingTop: 10, paddingBottom: 10,
    marginBottom: 10,
    marginRight: 10,
    textAlign: 'left',
    '@media(max-width: 700px)': {
        paddingLeft: 10, paddingRight: 10,
    },
}));
export const TripBox = styled(Box)(() => ({
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: "5px",
    padding: 20,
    marginBottom: 10,
    '@media(max-width: 400px)': {
        padding: 10,
    },

}));
export const LeaveReviewText = styled(Box)(() => ({
    fontFamily: 'Poppins-SemiBold',
    cursor: 'pointer',
    fontSize: '14px',
    fontStyle: 'normal',
    display: 'flex',
    alignItems: 'center',
    "& :nth-child(1)": {
        color: '#3C6B49',
        marginBottom: 9
    },
}))
export const Edit = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: '#3C6B49',
    borderRadius: '5px',
    color: '#FAFAFA',
    height: '32px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    marginRight: 10,
    cursor: 'pointer',
    padding: '10px',
    textTransform: 'capitalize',
    '&:hover': {
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3C6B49',
    },

}));
export const TransparentBtn = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: 'rgba(17, 17, 17, 0.05)',
    borderRadius: '5px',
    color: 'rgba(17, 17, 17, 0.6);',
    height: '32px',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    marginRight: 10,
    cursor: 'pointer',
    padding: '10px'
}));
export const Delete = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    color: '#B93B3B',
    fontFamily: 'Poppins-SemiBold',
    fontSize: '13px',
    background: 'rgba(185, 59, 59, 0.08)',
    borderRadius: '5px',
    height: '32px',
    cursor: 'pointer',
    padding: '10px'    
}));
export const Message = styled('p')(() => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    marginTop: 13,
    marginBottom: 13
}));
export const ReplyUserName = styled('span')(() => ({
    letterSpacing: "0.1em",
    textTransform: "capitalize",
    fontFamily: 'Poppins',
    fontSize: '13px',
    color: '#111111',
    fontWeight: 600,
}));
export const ScrollAbleGrid = styled(Grid)(() => ({
    overflowY: "auto",
    maxHeight: "37vh",
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
        width: '8px',
        marginLeft: '-4px'
    },
    '&::-webkit-scrollbar-track': {
        background: "white",
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D9D9D9',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#D9D9D9',
    }
}));
export const Text = styled(Box)(() => ({
    fontFamily: 'Poppins-Medium',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#111111',
    "& span": {
        textDecorationLine: "underline",
        color: "#1E3625",
        fontWeight: 500,
        textUnderlineOffset: "3px",
        cursor: 'pointer',

    }
}));
export const NoTripText = styled('h4')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '23px',
    textAlign: 'center',
    color: '#111111',
}));
export const LeaveReviewBox = styled(Grid)(() => ({
    display: 'flex',
    marginTop: 20,
    height: '44vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
}));
export const FormBox = styled(Box)(() => ({
    width: '40%',
    overflowY: 'scroll',
    border: '1px solid #E5E5E5',
    height: '94vh',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    overflow: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 20px',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(max-width: 1200px)': {
        width: '50%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        padding: '10px 12px',
        width: '93%',
        height: 'max-content',
    },
}));