import React, { useState } from 'react';
import moment from 'moment';

import { days } from '../../data';
import { Header } from './ModalHeader';
import { DateMultiInput } from '../InputFields/DateMultiInput';
import { style, GreenButton, Description, Label } from './Elements';
import { Box, Checkbox, Modal, Grid, FormControlLabel, FormGroup } from '@mui/material';
import { convertDateStringToObjectrrrr, getZero, swalWithStyledButton } from '../../utills/constants';
import './style.css';
import { useEffect } from 'react';

// const getDatesBetweenDates = (startDate, endDate, days) => {
//   let dates = [];
//   const theDate = new Date(startDate);
//   while (theDate < new Date(endDate)) {
//     dates = [...dates, new Date(theDate)];
//     theDate.setDate(theDate.getDate() + 1);
//   }
//   dates = [...dates, new Date(endDate)];
//   const finalDays = [];
//   dates.forEach((res) => {
//     if (days.some((w) => res?.toString().includes(w))) {
//       finalDays.push(res);
//     } else return;
//   });
//   return finalDays;
// };
function getDatesBetweenDates(startDate, endDate, days) {
  const result = [];
  const current = moment.utc(startDate);
  const end = moment.utc(endDate);

  while (current <= end) {
    if (days.includes(current.format('ddd'))) {
      result.push(current.clone().utc().format());
    }
    current.add(1, 'days');
  }

  return result;
}
export const UnAvailableDays = ({
  grandState,
  parentState,
  open,
  handle,
  dateRange,
  handleState,
}) => {
  const [state, setState] = useState({
    dayWeekText: '',
    excludeDateText: '',
    daysOfWeek: [],
    disableDates: [],
    disableDatesText: '',
    repeatEvery: 'weeks',
    repeatEveryNumber: 1,
    disableDatesFormated: [],
    unavailableDaysOfWeek: [],
    excludeSpecificDates: [],
  });

  const handleClose = () => {
    handle('openUnAvailableDays', false);
    setState({
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      excludeSpecificDates: [],
    });
  };
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  useEffect(() => {
    if (parentState) {
      setState(parentState?.unAvailableDays);
    }
    // eslint-disable-next-line
  }, [open]);

  // const calculateDisableDays = (value, days) => {
  //   const fromDate = moment.utc(
  //     dateRange?.from?.year + '-' + dateRange?.from?.month + '-' + dateRange?.from?.day
  //   ).format();
  //   const toDate = moment.utc(value?.year + '-' + value?.month + '-' + value?.day).format();
  //   const arr = getDatesBetweenDates(fromDate, toDate, days);
  //   const customFormatedDates = [];
  //   arr.forEach((res) => {
  //     customFormatedDates.push({
  //       year: Number(res.getFullYear()),
  //       month: Number(moment.utc(res).format('MM')),
  //       day: Number(moment.utc(res).format('DD')),
  //       className: 'customDisableDays',
  //     });
  //   });
  //   handleChange('disableDates', arr);
  //   handleChange('disableDatesFormated', customFormatedDates);
  //   handleChange('unavailableDaysOfWeek', customFormatedDates);
  //   handleChange('allUnavailableDates', customFormatedDates);
  // };
  const calculateDisableDays = (value, days) => {
    // console.log('days: ', days);
    // console.log('value: ', value);
    const fromDate = moment
      .utc(dateRange?.from?.year + '-' + dateRange?.from?.month + '-' + dateRange?.from?.day)
      .format();
    const to = value?.year + '-' + value?.month + '-' + value?.day;
    const toDate = moment.utc(to).format();
    const arr = getDatesBetweenDates(fromDate, toDate, days);
    console.log('arr', arr);
    console.log('fromDate', fromDate);
    console.log('toDate', toDate);
    console.log('to', moment.utc(to).format());

    const customFormatedDates = [];
    arr.forEach((res) => {
      customFormatedDates.push({
        className: 'customDisableDays',
        ...convertDateStringToObjectrrrr(res),
      });
    });
    handleChange('disableDates', arr);
    handleChange('disableDatesFormated', customFormatedDates);
    handleChange('unavailableDaysOfWeek', customFormatedDates);
    handleChange('allUnavailableDates', customFormatedDates);
  };
  const handleDays = (value) => {
    let newDaysArray = [...state?.daysOfWeek];
    if (!newDaysArray.includes(value)) {
      newDaysArray.push(value);
    } else {
      newDaysArray.splice(newDaysArray.indexOf(value), 1);
    }
    let uniq = [...new Set(newDaysArray)];
    handleChange('daysOfWeek', uniq);
    let dayWeekTextValue =
      [uniq.slice(0, -1).join(', '), uniq.slice(-1)[0]].join(uniq.length < 2 ? '' : ' and ') +
      ' , (Weekly)';
    if (dayWeekTextValue == ' , (Weekly)') {
      dayWeekTextValue = '';
    }
    handleChange('dayWeekText', dayWeekTextValue);
    calculateDisableDays(dateRange?.to, uniq);
  };

  const handleSave = () => {
    if (dateRange?.from != null && dateRange?.to != null) {
      const fromDate = moment.utc(
        dateRange?.from?.year +
          '-' +
          getZero(dateRange?.from?.month) +
          '-' +
          dateRange?.from?.day +
          ' 00:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const toDate = moment.utc(
        dateRange?.to?.year +
          '-' +
          getZero(dateRange?.to?.month) +
          '-' +
          dateRange?.to?.day +
          ' 24:00:00',
        'YYYY-MM-DD HH:mm:ss'
      );
      const diffInDays = toDate.diff(fromDate, 'days');
      const unAvailableDaysLength = state?.allUnavailableDates.length;
      const availableDaysCount = diffInDays - unAvailableDaysLength;
      const _input =
        grandState?.durationTitle == 'weeks'
          ? Number(grandState?.durationValue) * 7
          : grandState?.durationValue;

      if (_input > availableDaysCount) {
        swalWithStyledButton.fire({
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false,
          text: `There are not enough available dates to meet the required duration.`,
          confirmButtonText: 'Okay',
          showClass: { popup: 'animate__animated animate__fadeInDown' },
          hideClass: { popup: 'animate__animated animate__fadeOutUp' },
        });
      } else {
        handleState('unAvailableDays', state);
        handleClose();
      }
    }
  };

  console.log('state wth the abdullah', state);
  const clearSelectedDates = () => {
    handleChange('excludeSpecificDates', []);
    handleChange('excludeDateText', '');
    handleState('unAvailableDays', {
      dayWeekText: '',
      excludeDateText: '',
      daysOfWeek: [],
      disableDates: [],
      disableDatesText: '',
      repeatEvery: 'weeks',
      repeatEveryNumber: 1,
      disableDatesFormated: [],
      unavailableDaysOfWeek: [],
      excludeSpecificDates: [],
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 'max-content',
            height: 'max-content',
            alignItems: 'flex-start',
            p: 3,
          }}
        >
          <Header heading="Set Unavailable Days" handleClose={handleClose} />
          <Description sx={{ textAlign: 'left', mt: 2, fontFamily: 'PoppinsLight' }}>
            Choose the days when this trip is not available. The days you <br /> specify here will
            be unavailable to your guest.
          </Description>
          <FormGroup sx={{ mt: 2 }}>
            <Label sx={{ mb: 0 }}>Days of Week </Label>
            <Grid container>
              {days.map((res, i) => {
                return (
                  <Grid key={i} item xs={4} md={4} lg={2.2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: '#1E3625' }}
                          checked={state?.daysOfWeek?.includes(res) == true ? true : false}
                          onChange={() => handleDays(res)}
                        />
                      }
                      label={res === 'Thu' ? 'Thur' : res}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>
          <Grid container spacing={2} sx={{ mt: 0.3 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Label>Exclude Specific Dates </Label>
              <DateMultiInput
                state={state}
                handleChange={handleChange}
                dateRange={dateRange}
                placeholder="Select dates"
              />
            </Grid>
            <button className="ClearDates" onClick={clearSelectedDates}>
              clear Dates
            </button>
          </Grid>

          <Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end', width: '100%' }}>
            <GreenButton onClick={handleSave} type="submit" variant="contained" disableripple>
              Save and Continue
            </GreenButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
