import React from 'react';

import { CancelButton } from '../Button';
import { Box, Grid, Modal } from '@mui/material';
import { Heading, style, RedButton } from './Elements';
import { GreenButton, PlanName, Text } from '../../pages/Settings/Elements';
import { useSelector } from 'react-redux';
import moment from 'moment';
// import { Text } from '../../pages/Elements';

const SwitchPlan = ({ open, cancelFunction, handleChange, returnEndDate, setOpen }) => {
  const { userInfo, isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 550,
            height: 'max-content',
            pb: 4,
            pt: 2,
            px: 2,
            alignItems: 'flex-start',
          }}
        >
          <Heading sx={{ my: 2 }}>Change Plan</Heading>
          <Text>
            <PlanName>Are you sure you want to switch your plan?</PlanName>
          </Text>
          <Text>
            Your current subscription is granting you full access to Trip Trader until
            <PlanName>{moment(userInfo?.subscription?.endDate).format('MM/DD/YYYY')}</PlanName>.
            Your subscription will automatically update when your current plan expires.
            {/* Your current
            subscription is granting you access to our Premium services until
             You
            will be subscribed automatically to the new plan once the current subscription ends. */}
          </Text>

          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 2 }}>
            <RedButton
              sx={{ mr: 2 }}
              onClick={cancelFunction}
              type="submit"
              variant="contained"
              disableripple
            >
              Yes
            </RedButton>
            <CancelButton
              type="submit"
              onClick={() => setOpen(false)}
              variant="contained"
              disableripple
            >
              No, keep it
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SwitchPlan;
