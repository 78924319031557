import news1 from '../assets/news/1.png';
import news2 from '../assets/news/2.png';
import news3 from '../assets/news/3.png';
import news4 from '../assets/news/4.png';
import bell2 from '../assets/sidebar/Bell2.svg';
// import bell from '../assets/sidebar/Bells.svg';
import home from '../assets/sidebar/Home.svg';
import lock from '../assets/settings/lock.png';
import offers from '../assets/sidebar/tradeoffer.svg';
import support from '../assets/sidebar/support.svg';
import addTrip from '../assets/sidebar/addtrip.svg';
import message from '../assets/sidebar/message.svg';
import fpBg from '../assets/create account/fpBg.png';
import settings from '../assets/sidebar/settings.svg';
import cofirm from '../assets/notification/cofirm.png';
import savedTrip from '../assets/sidebar/savedtrip.svg';
import blockUser from '../assets/settings/blockUser.png';
import manageSubs from '../assets/settings/manageSubs.png';
import password from '../assets/notification/password.png';
import uploadBg from '../assets/create account/leftside.png';
import confirmedTrip from '../assets/sidebar/confirmedtrip.svg';
import subscriptionBg from '../assets/create account/subsBg.png';
import identityBg from '../assets/create account/identitybg.png';
import uploadoverlay from '../assets/create account/overlay.png';
import successPlanBg from '../assets/create account/successPlanBg.png';
import identityOverlay from '../assets/create account/identityOverlay.png';

import bottomIcon1 from '../assets/home/b1.svg';
import bottomIcon2 from '../assets/home/b2.svg';
import bottomIcon3 from '../assets/home/b3.svg';
import bottomIcon4 from '../assets/home/b4.svg';

export const daysDropdown = [{ name: 'days' }, { name: 'weeks' }];

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const smallNews = [
  {
    date: 'June 4, 2022',
    content:
      'Version 2.5 is now available in the App Store. Update now to get the latest improvements and bug fixes.',
    heading: 'Trip Trader iOS Update',
  },
  {
    date: 'May 29, 2022',
    content:
      'We’ll show you several ways to optimize your profile and photos to attract other members and new trade offers.',
    heading: '5 Ways to Boost Your Profile Engagement',
  },
  {
    date: 'May 26, 2022',
    content: 'Put your best foot forward when it comes to photography for your trips.',
    heading: 'Put your best foot forward when it comes to photography for your trips.',
  },
  {
    date: 'May 21, 2022',
    content:
      'Building trust and credibility will significantly enhance your reputation in the community and lead to more successful trades..',
    heading: 'Get More Trades With ID Verification',
  },
  {
    date: 'May 19, 2022',
    content:
      'Version 2.1 is now available in the Google Play Store. Update now for the latest bug fixes and improvements..',
    heading: 'Trip Trader Android Update',
  },
  {
    date: 'May 19, 2022',
    content:
      'Version 2.1 is now available in the Google Play Store. Update now for the latest bug fixes and improvements..',
    heading: 'Trip Trader Android Update',
  },
];
export const bottomNews = [
  { date: 'May 25, 2022', image: news1, heading: 'Top 5 items to bring on your next trip' },
  { date: 'May 21, 2022', image: news2, heading: 'Offer more activities to boost engagement' },
  { date: 'May 19, 2022', image: news3, heading: 'The Best 2022 Outdoor Safety Apparel' },
  { date: 'May 17, 2022', image: news4, heading: 'The Best 2022 Outdoor Safety Apparel' },
];
export const signUpData = [
  'Find Trips that match your interests',
  'Send and receive trade offers',
  'Enhanced security with ID Verification',
  'Direct Messaging and more!',
];
export const planContent = [
  'Create trips and get offers',
  'Make trade offers',
  'Send and receive messages',
  'Bookmark trips',
  'Advanced trip search',
];
export const sidebarList1 = [
  { name: 'Home', icon: home, url: '/search' },
  { name: 'Notifications', icon: bell2, url: '/notification' },
  { name: 'Messages', icon: message, url: '/messages' },
];
export const sidebarList2 = [
  { name: 'Create Trip', icon: addTrip, url: '/createTrip' },
  { name: 'Trade Offers', icon: offers, url: '/tradeOffer' },
  { name: 'Saved Trips', icon: savedTrip, url: '/savedTrips' },
  { name: 'Confirmed Trips', icon: confirmedTrip, url: '/confirmedTrips' },
];
export const sidebarList3 = [
  { name: 'Support', icon: support, url: '/support' },
  { name: 'Settings', icon: settings, url: '/settings' },
];
export const uploadImageLeft = {
  isOverlay: true,
  overlayImage: uploadoverlay,
  bg: uploadBg,
  heading: 'Build more trust and recognition with a profile photo',
  content:
    'We recommend using a clear photo of yourself to help create a safer community and better experience.',
};
export const subscriptionLeft = {
  isOverlay: false,
  bg: subscriptionBg,
  heading: 'Enjoy full access to all features when you subscribe',
  content: 'You can choose an annual or monthly plan. Change or cancel your plan anytime',
};
export const forgetPasswordLeft = {
  isOverlay: false,
  overlayImage: identityOverlay,
  bg: fpBg,
  heading: 'We’ll help you reset your password and get you back on track',
  content: '',
};
export const guestNotifications = [
  {
    title: 'Become a member to unlock full access',
    type: 'msg',
    isRead: false,
    message: (
      <p style={{ marginTop: 0, marginBottom: 0 }}>
        As a member, you’ll unlock all features and benefits for the best experience.{' '}
        <a href="/#/join" style={{ color: '#1E3625', textDecoration: 'none' }}>
          <b>Join now</b>
        </a>
      </p>
    ),
    time: '',
    icon: cofirm,
  },
  {
    title: 'Welcome to Trip Trader!',
    type: 'msg',
    isRead: true,
    message:
      'We’re excited you’re here. Feel free to browse the community as a guest before signing up.',
    time: '10 mins ago',
    icon: password,
  },
];
export const paidUserSettingsList = [
  { icon: manageSubs, name: 'Manage Subscription' },
  { icon: manageSubs, name: 'Cards' },
  { icon: blockUser, name: 'Blocked Users' },
  { icon: lock, name: 'Change Password' },
];
export const guestUserSettingsList = [
  // { icon: bellSettings, name: 'Notifications' },
  { icon: blockUser, name: 'Blocked Users' },
];
export const successSubscriptionLeft = {
  bg: successPlanBg,
  heading: <>{<b>Congratulations!</b>} We’re excited you joined our growing community.</>,
  content: [
    'Search for trips by activity and location',
    'Send and receive trade offers',
    'Enhanced security with ID Verification',
    'Direct Messaging and more!',
  ],
};
export const identityVerificationLeft = {
  isOverlay: true,
  overlayImage: identityOverlay,
  bg: identityBg,
  heading: 'Get 3x more trade offers with identity verification',
  content:
    'Getting verified provides enhanced security for you and other traders, and it can significantly increase engagement and successful trade offers.',
};
export const homeBottomIcons = [
  { img: bottomIcon1, title: 'Adventurous' },
  { img: bottomIcon2, title: 'Ethical' },
  { img: bottomIcon3, title: 'Affordable' },
  { img: bottomIcon4, title: 'Trustworthy' },
];
