import React, { useState } from 'react';
import MakeOffer from '../../MakeOffer';
import NoData from '../ReviewComponent/NoData';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { ScrollAbleGrid } from './Elements';
import { useNavigate } from 'react-router-dom';
import { CountName, TransparentBtn } from '../Elements';
import { DateWithoutFormat, getDurationFromDate } from '../../../utills/constants';
import { UserName, ActivityDetail, ActivityHeading } from '../../Elements';
import { TripBox, MainReviewGrid } from './Elements';
import moment from 'moment';

const Trips = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({ openMakeOffer: false });

  const { trips } = useSelector((state) => state.auth);

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const displaydate = (datefrom, dateto) => {
    const sd = DateWithoutFormat(datefrom);
    const sdy = parseInt(new Date(sd).getFullYear());
    const ed = DateWithoutFormat(dateto);
    const edy = parseInt(new Date(ed).getFullYear());
    let favlbl = '';

    if (sdy == edy) {
     return moment.utc(sd).format('MMM DD') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
    } else {
      return moment.utc(sd).format('MMM DD, YYYY') + ' - ' + moment.utc(ed).format('MMM DD, YYYY');
    }
  };
  return (
    <MainReviewGrid>
      {trips?.length === 0 ? (
        <NoData text={'No trips available.'} />
      ) : (
        <CountName sx={{ my: 2 }}>
          {trips?.length} trip{trips?.length > 1 ? 's' : ''} available
        </CountName>
      )}

      <ScrollAbleGrid>
        {trips?.map((res, i) => {
          return (
            <TripBox key={i} sx={{ marginRight: 2 }}>
              <UserName
                sx={{ color: res.status === 'suspended' ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}
              >
                {res?.tradeType} Trip&nbsp;
                {res.status === 'suspended' && <ActivityDetail>(Suspended)</ActivityDetail>}
              </UserName>
              <Grid container sx={{ width: '100%', my: 1 }}>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                  <ActivityHeading>Offering</ActivityHeading>
                  <ActivityDetail
                    sx={{ color: res.status === 'suspended' ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}
                  >
                    {res?.title}
                  </ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <ActivityHeading>TRIP AVAILABILITY</ActivityHeading>
                  <ActivityDetail
                    sx={{ color: res.status === 'suspended' ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}
                  >
                    {/* {getDurationFromDate(res?.availableFrom, res?.availableTo)} */}
                    {displaydate(res?.availableFrom, res?.availableTo)}
                  </ActivityDetail>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: 1 }}>
                  <ActivityHeading>IN RETURN FOR</ActivityHeading>
                  <ActivityDetail
                    sx={{ color: res.status === 'suspended' ? 'rgba(17, 17, 17, 0.4)' : '#101B10' }}
                  >
                    {res?.returnActivity}
                  </ActivityDetail>
                </Grid>
                <Grid item sx={{ my: 1 }}>
                  <TransparentBtn
                    onClick={() => navigate(`/editTrip/${res?._id}`)}
                    sx={{ color: '#30563A', px: 3 }}
                  >
                    Edit Trip
                  </TransparentBtn>
                </Grid>
              </Grid>
            </TripBox>
          );
        })}
      </ScrollAbleGrid>
      <MakeOffer open={state.openMakeOffer} handle={handleChange} stateName="openMakeOffer" />
    </MainReviewGrid>
  );
};
export default Trips;
