import React, { useState, useEffect } from 'react';

import { dateStyles } from '../../../components/InputFields/styles';
import { InputAdornment, TextField, Popover, FormHelperText } from '@mui/material';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import { GreenButton, CancelButton } from '../../../components/InputFields/Elements';

import moment from 'moment';
import calender from '../../../assets/createTrip/calender.png';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { getZero, getDateRangeA } from '../../../utills/constants';

export const DateSingleInput = ({ state, handleChange, res }) => {
  console.log(state);
  const classes = dateStyles();
  const [selectedDay, setSelectedDay] = useState([]);

  const getFormatedResult = () => {
    var test = [],
      name = [],
      dateformat = 'YYYY-MM-DD';
    state?.selectedDay?.forEach((res) => {
      const date = res?.year + '-' + getZero(res?.month) + '-' + getZero(res?.day);
      test.push(date);
    });
    var result = test
      .sort(function (a, b) {
        return new Date(a) - new Date(b);
      })
      .reduce(function (acc, val) {
        var present,
          date = moment.utc(val, dateformat);
        acc.forEach(function (arr, index) {
          if (present) return;
          else if (
            arr.indexOf(date.clone().subtract(1, 'day').format(dateformat)) > -1 ||
            arr.indexOf(date.clone().add(1, 'day').format(dateformat)) > -1
          ) {
            present = true;
            arr.push(val);
          }
        });
        if (!present) acc.push([val]);
        return acc;
      }, []);
    result.forEach(function (arr, index) {
      if (arr.length > 1) {
        let custom = `${moment.utc(arr[0]).format('MMM DD, YYYY')} - ${moment
          .utc(arr[arr.length - 1])
          .format('MMM DD, YYYY')}`;
        name.push(custom);
      } else {
        name.push(moment.utc(arr[0]).format('MMM DD, YYYY'));
      }
    });

    return name.toString();
  };
  useEffect(() => {
    handleChange('defaultCalenderValue', []);
    // handleChange('minDate', {});
  }, []);
  const handleDate = (data) => {
    handleChange('maxDateError', false);

    const _value = data[data.length - 1];

    const totalDays =
      res?.hostTrip?.duration?.title == 'weeks'
        ? res?.hostTrip?.duration?.value * 7
        : res?.hostTrip?.duration?.value;

    const dateFrom = moment
      .utc(`${_value.month} ${_value.day}, ${_value.year}`)
      .format('DD MMM YYYY');
    const range = getDateRangeA(dateFrom, totalDays, state.allUnavailableDates);

    const maxDateCheck = moment
      .utc(`${state?.maxDate?.month} ${state?.maxDate?.day}, ${state?.maxDate?.year}`)
      .format('DD MMM YYYY');
    const hasGreaterDates = range?.some((date) => new Date(date) > new Date(maxDateCheck));

    if (!hasGreaterDates) {
      const arr = [];

      range.forEach((res) => {
        arr.push({
          month: new Date(res).getMonth() + 1,
          year: new Date(res).getFullYear(),
          day: new Date(res).getDate(),
        });
      });
      // setSelectedDay(arr);
      handleChange('selectedDay', arr);
      handleChange('defaultCalenderValue', arr);
      handleChange('tripDates', range);
      handleChange('tripDates1', arr);
      handleChange('preferredDateError', false);
    } else {
      handleChange('maxDateError', true);
      handleChange('tripDates', []);
      handleChange('tripDates1', []);
      handleChange('defaultCalenderValue', []);
    }
  };
  console.log('state?.disableDays', state.disableDays);
  useEffect(() => {
    handleChange('defaultCalenderValue', []);
    // handleChange('minDate', {});
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <TextField
        onClick={handleClick}
        aria-describedby={id}
        sx={{ width: '100%' }}
        className={classes.root}
        value={state?.selectedDay?.length > 0 ? getFormatedResult() : ''}
        placeholder={'Select dates'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        readOnly
        helperText={
          <FormHelperText sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginTop: 0 }}>
            {state?.preferredDateError ? 'Select dates.' : ''}
          </FormHelperText>
        }
        error={state?.preferredDateError ? true : false}
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Calendar
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          calendarSelectedDayClassName="calendarSelectedDay"
          disabledDays={state?.disableDays}
          customDaysClassName={state?.disableDays}
          minimumDate={state?.minDate}
          maximumDate={state?.maxDate}
          value={state.defaultCalenderValue}
          onChange={handleDate}
          colorPrimary="#3C6B49"
          calendarPopperPosition="auto"
          inputPlaceholder="Select a day range"
        />
        {state?.maxDateError && (
          <FormHelperText
            sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginBottom: 1, pl: 2.5 }}
          >
            {'Please choose a date within trip duration.'}
          </FormHelperText>
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </CancelButton>
          <GreenButton onClick={handleClose}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};
