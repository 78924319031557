import React, { useState } from "react";
import * as yup from "yup";
import Loader from "../../assets/loader1.gif";

import { FP, LoaderSpan } from "./Elements";
import { Input } from "../../components/InputFields";
import { CustomGreenLargeButton } from "../../components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { forgetPassword } from "../../features/auth/action";
import { useDispatch, useSelector } from "react-redux";
import { FormHelperText } from "@mui/material";

const Email = ({ setStep, handleScreen }) => {
  const dispatch = useDispatch();
  const schema = yup.object().shape({ email: yup.string().email() });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { loading } = useSelector((state) => state.auth);

  const navigateTo = () => {
    setStep(2);
  };
  const [isEmailError, setEmailError] = useState(false);
  const submitFunction = async (formdata) => {
    if (formdata?.email) {
      setEmailError(false);
      formdata["url"] = "password/forgot";
      handleScreen("email", formdata.email);
      dispatch(forgetPassword(formdata, navigateTo));
    } else {
      setEmailError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(submitFunction)} className="submitForm">
      <div>
        <Input
          title="Email Address"
          error={errors.email?.message}
          register={register}
          registerFor="email"
        />
        {isEmailError ? (
          <FormHelperText
            sx={{
              fontFamily: "Poppins-Medium",
              color: "#d32f2f",
              marginTop: 0,
            }}
          >
            {"Email address is required"}
          </FormHelperText>
        ) : null}
      </div>
      <CustomGreenLargeButton type="submit" variant="contained" disableripple disabled={loading}>
        {" "}
        {loading ? (
          <LoaderSpan>
            <img
              src={Loader}
              width={30}
              alt="loading..."
              style={{ marginRight: 6 }}
            />
            Send Code
          </LoaderSpan>
        ) : (
          "Send Code"
        )}{" "}
      </CustomGreenLargeButton>

      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <FP
          onClick={() => {
            handleScreen("withPhone", true);
            handleScreen("withEmail", false);
          }}
        >
          Use Phone Number instead
        </FP>
      </div>
    </form>
  );
};
export default Email;
