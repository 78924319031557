import React, { useState } from "react";

import api from "../../../api"
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFullName, config } from "../../../utills/constants"
import { Grid, useMediaQuery, Drawer, Avatar } from "@mui/material"
import { updateFuntion, getFunction } from "../../../features/auth/action";
import { FollowersRow, NameSpan, GreenText, FollowersHeading } from "./Elements"
import { stopLoading, startLoading } from "../../../features/auth/reducer";
import "../style.css"

const Follower = ({ mobileOpen, handleDrawerToggle }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const matches = useMediaQuery('(max-width:1000px)');

    const [selectedTab, setSelectedTab] = useState('one')

    const { follower, following, userInfo, role } = useSelector(state => state.auth)

    const bodyFollower = { name: 'follower', url: `/user/followers/${userInfo?._id}` }

    const bodyFollowing = { name: 'following', url: `/user/following/${userInfo?._id}` }

    const GetFilterBox = ({ children }) => {
        if (matches) {
            return <Drawer
                anchor={'right'}
                open={mobileOpen}
                onClose={handleDrawerToggle} >
                <div style={{ padding: 10 }}>{children}</div>
            </Drawer>
        }
        else if (!matches) { return children }
    }

    const handleNavigation = (res) => {
        if (role === 'user') {
            if (userInfo?._id === res?.userId?._id) {
                navigate(`/profile/${res?.userId?._id}`)
            }
            else navigate(`/profile/public/${res?.userId?._id}`)
        }
        else return;
    }

    const followAUser = async (userId) => {
        const body = { url: `/user/followAUser/${userInfo?._id}/${userId}` }
        dispatch(startLoading());
        await api.put(body.url, body, config)
            .then((res) => {
                dispatch(stopLoading());
                dispatch(getFunction(bodyFollower))
                dispatch(getFunction(bodyFollowing))
            })
            .catch((err) => {
                if (err.response) {
                    toast.error(err.response.data.message, { duration: 4000, });
                    dispatch(stopLoading());
                }
            });
        // dispatch(updateFuntion(body))

    }

    const unFollowAUser = (userId) => {
        const body = { url: `/user/unFollowAUser/${userInfo?._id}/${userId}` }
        dispatch(updateFuntion(body))
        dispatch(getFunction(bodyFollower))
        dispatch(getFunction(bodyFollowing))
    }

    const getClassName = (value) => {
        if (value === selectedTab) { return "tab selectedTab" }
        else return "tab"
    }
    return <Grid container sx={{ backgroundColor: 'white', height: '95vh', margin: 'auto', display: 'flex', flexDirection: 'column' }}>
        <GetFilterBox>
            <FollowersHeading>
                <span value="one" className={getClassName('one')} onClick={(e) => setSelectedTab('one')}>Followers</span>
                <span value="two" className={getClassName('two')} onClick={(e) => setSelectedTab('two')}>Following</span>
            </FollowersHeading>
            {selectedTab === "one" && <Grid sx={{ width: '100%' }}>
                {follower?.length === 0 &&
                    <GreenText sx={{ justifyContent: 'center', mt: 30 }} >{'No follower(s) found'}</GreenText>
                }
                {follower?.map((res, i) => {
                    return <FollowersRow key={i}>
                        <NameSpan onClick={() => handleNavigation(res)}>
                            <Avatar src={res?.userId?.image} style={{ marginRight: 10 }} />
                            {res?.userId != null ? getFullName(res?.userId) : 'Trip Trader User'}
                        </NameSpan>
                        {res?.following && res?.follower ?
                            <GreenText onClick={() => { unFollowAUser(res?.userId?._id) }}>Unfollow</GreenText> :
                            !res?.following && res?.follower ? <GreenText onClick={() => { followAUser(res?.userId?._id) }}>Follow</GreenText> :
                                <GreenText onClick={() => { followAUser(res?.userId?._id) }}>Follow</GreenText>}

                    </FollowersRow>
                })}
            </Grid>}
            {selectedTab === "two" && <Grid sx={{ width: '100%' }}>
                {following?.length === 0 &&
                    <GreenText sx={{ justifyContent: 'center', mt: 30 }} >{'You are not following anyone yet'}</GreenText>
                }
                {following?.map((res, i) => {
                    return <FollowersRow key={i}>
                        <NameSpan onClick={() => handleNavigation(res)}>
                            <Avatar src={res?.userId?.image} style={{ marginRight: 10 }} />
                            {res?.userId != null ? getFullName(res?.userId) : 'Trip Trader User'}

                        </NameSpan>
                        <GreenText onClick={() => { unFollowAUser(res?.userId?._id) }}>Unfollow</GreenText>
                    </FollowersRow>
                })}
            </Grid>}
        </GetFilterBox>
    </Grid >

}
export default Follower