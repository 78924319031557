import React, { useEffect, useState } from 'react';
import moment from 'moment';
import api from '../../api';
import Sidebar from '../../components/Sidebar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { config, getDate } from '../../utills/constants';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { MainBox, UserName } from '../Elements';
import { useMediaQuery, Grid, Paper, TextField, Box, Button } from '@mui/material';
import { CustomGreenLargeButton } from '../../components/Button';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Back, FormBox, PlanName, FormBoxA, SideHeading, GreenButton, Text } from './Elements';
import { Divider, FormHelperText } from '@mui/material';
import { getTheAddedCards } from '../../features/stripe/action';
import { Input } from '../../components/InputFields';
import { CustomLabel2 } from '../../components/InputFields/Elements';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import Loader from '../../assets/loader1.gif';

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Poppins-Regular',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Poppins-Regular',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
const schema = yup.object().shape({
  fullName: yup.string().required('Please enter your full name.'),
});
const AddNewCard = ({ state, handleChange }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const matches600 = useMediaQuery('(max-width:600px)');
  const matches400 = useMediaQuery('(max-width:400px)');
  const { isAuth } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [cardData, setcardData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [isPromo, setIsPromo] = useState(false);
  const [processing, setProcessing] = useState('');
  const [succeeded, setSucceeded] = useState(false);
  const [prevState, setPrevState] = useState({ ...state });
  const [code, setcode] = useState('');
  const [promoDisc, setpromoDisc] = useState('');
  const [codevalid, setcodevalid] = useState(null);
  const [promoDiscAmt, setpromoDiscAmt] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { loading, userInfo } = useSelector((state) => state.auth);
  const [strpeLoading, setStripeLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmitStripe = async (e) => {
    // e.preventDefault();
    try {
      setStripeLoading(true);
      const cardElement = elements.getElement(CardElement);

      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: e?.fullName,
          },
        })
        .then(async function (result) {
          // console.log('THE RESULT OF THE API IS', result?.paymentMethod?.id);
          const UpdateCardbody = {
            payment_card_id: result?.paymentMethod?.id,
            customer_id: userInfo?.customerId,
            // customer_id: 'cus_OZX32K61HE82S4',
          };
          const resp = await api.post('/cardmangment/updateCardInfo', UpdateCardbody);

          // console.log('THE UPDATE CARD IS', resp);
          toast.success(resp?.data?.message, { duration: 4000 });
          setStripeLoading(false);
          navigate('/manage/cards');
        })
        .catch((error) => {
          console.log('Line 102', error.response?.data?.message);
          setStripeLoading(false);
        });
    } catch (err) {
      setStripeLoading(false);
      if (err.response) {
        setStripeLoading(false);
        setError(`Payment failed ${err.response.data.error.message}`);
        setcodevalid(false);
      }
    }
  };

  const handleChangeKey = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  // const navigateFunction = () => {
  //   setTimeout(() => {
  //     navigate('/settings');
  //     // dispatch(stopLoading());
  //   }, 300);
  // };
  // const handleRemove = () => {
  //   handleChange('total', prevState?.total);
  //   setcode('');
  //   setpromoDisc(0);
  //   setpromoDiscAmt(0);
  //   setcodevalid(null);
  // };
  // useEffect(() => {
  //   dispatch(getTheAddedCards(userInfo?.customerId, navigateFunction));
  // }, [userInfo]);
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
      <Sidebar />
      <FormBox>
        <FormBoxA sx={{ height: 'max-content' }}>
          <Back sx={{ ml: matches400 ? 0 : 1, mt: 1 }} onClick={() => navigate('/settings')}>
            <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
            Back to Settings
          </Back>

          <UserName sx={{ fontSize: '18px', ml: matches400 ? 0 : 2, mt: 1, mb: 1.5 }}>
            Add New Card
          </UserName>
          <UserName
            sx={{
              fontSize: '18px',
              ml: matches400 ? 0 : '',
              mt: 1,
              mb: 1.5,
              textAlign: 'center',
              display: 'block',
            }}
          >
            Enter Card Information
          </UserName>
          {/* <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
              <SideHeading sx={{ ml: matches400 ? 0 : 2 }}>Current plan </SideHeading> :{' '}
              <PlanName>
                {' '}
                {userInfo?.subscription?.title} (${userInfo?.subscription?.charges} /mo)
              </PlanName>
            </Grid> */}
          {/* <Grid sx={{ display: 'flex', flexDirection: 'row', mb: 1, mt: 0.5 }}>
              {' '}
              <SideHeading sx={{ ml: matches400 ? 0 : 2 }}>Card </SideHeading> :{' '}
              <PlanName>
                {cardData && cardData[cardData?.length - 1]?.card?.brand}••••
                {cardData && cardData[cardData?.length - 1]?.card?.last4}
              </PlanName>
            </Grid> */}
          {/* <div className="EditContainer">
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <Formik
                  initialValues={{
                    cardNumber: '',
                    expiryDate: '',
                    cvc: '',
                    cardNumberName: '',
                  }}
                  onSubmit={(data) => console.log('THE DATA IS GOING TO CONSOLE>LOG IS', data)}
                  validate={(values) => {
                    let errors = {};
                    if (!values.cardNumberName) {
                      errors.cardNumberName = 'Required';
                    }
                    if (meta.erroredInputs.cardNumber || !values?.cardNumber) {
                      errors.cardNumber = meta.erroredInputs.cardNumber;
                      errors.cardNumber = 'Required';
                    }
                    if (meta.erroredInputs.expiryDate) {
                      errors.expiryDate = meta.erroredInputs.expiryDate;
                    }
                    if (meta.erroredInputs.cvc) {
                      errors.cvc = meta.erroredInputs.cvc;
                    }
                    return errors;
                  }}
                >
                  {({ values, handleChange, handleBlur, handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter a User Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="cardNumberName"
                          value={values.cardNumberName}
                        />
                        <p
                          style={{
                            color: '#c9444d',
                            fontSize: '0.75rem',
                            marginTop: '0.25rem',
                          }}
                        >
                          {errors.cardNumber && touched.cardNumber && errors.cardNumber}
                        </p>
                        <PaymentInputsWrapper {...wrapperProps}>
                          <svg {...getCardImageProps({ images })} />

                          <Field
                            name="cardNumber"
                            style={{
                              width: '-webkit-fill-available',
                            }}
                          >
                            {({ field }) => (
                              <input
                                {...getCardNumberProps({
                                  onBlur: field.onBlur,
                                  onChange: field.onChange,
                                })}
                              />
                            )}
                          </Field>
                          <Field name="expiryDate">
                            {({ field }) => (
                              <input
                                {...getExpiryDateProps({
                                  onBlur: field.onBlur,
                                  onChange: field.onChange,
                                })}
                              />
                            )}
                          </Field>
                          <Field name="cvc">
                            {({ field }) => (
                              <input
                                {...getCVCProps({ onBlur: field.onBlur, onChange: field.onChange })}
                              />
                            )}
                          </Field>
                        </PaymentInputsWrapper>
                      </div>
                      <p
                        style={{
                          color: '#c9444d',
                          fontSize: '0.75rem',
                          marginTop: '0.25rem',
                        }}
                      >
                        {errors.cardNumberName && touched.cardNumberName && errors.cardNumberName}
                      </p>
                      <Button
                        style={{
                          backgroundColor: '#283c2e',
                          color: 'white',
                        }}
                        marginTop="major-2"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </Formik>

           
              </Box>

           
            </div> */}

          <form onSubmit={handleSubmit(handleSubmitStripe)} className="submitForm">
            <Input
              title="Full Name"
              placeholder="Card holder’s first and last name"
              error={errors.fullName?.message}
              register={register}
              registerFor="fullName"
            />
            <div className="AddnewCard">
              <CustomLabel2>Card</CustomLabel2>
              <CardElement id="card-element" options={cardStyle} onChange={handleChangeKey} />
              {error && (
                <FormHelperText
                  sx={{
                    fontFamily: 'Poppins-Medium',
                    color: '#d32f2f',
                    marginTop: 0,
                  }}
                >
                  {error}
                </FormHelperText>
              )}
            </div>

            {strpeLoading ? (
              <CustomGreenLargeButton variant="contained" disabled={strpeLoading}>
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                  Please Wait ...
                </LoaderSpan>
              </CustomGreenLargeButton>
            ) : (
              <CustomGreenLargeButton
                // style={{
                //   marginTop: '2rem',
                // }}
                type={'submit'}
                variant="contained"
                disbale={strpeLoading}
                disableripple
              >
                Save
              </CustomGreenLargeButton>
            )}
          </form>
        </FormBoxA>
      </FormBox>
    </MainBox>
  );
};
export default AddNewCard;
