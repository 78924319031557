import { styled } from '@mui/material/styles';
import { Grid, Box, Button } from '@mui/material';

export const LeftGrid = styled(Grid)(() => ({
    width: "80%",
    marginTop: 10,
    height:'100vh',
    background: '#F9F9F9',
    paddingLeft: "6px !important",
}));
export const FilterGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'

}));
export const NestedGrid = styled(Grid)(() => ({
    maxHeight: '80vh',
    overflow: 'auto',
    display: 'flex', width: '100%', justifyContent: 'flex-start', alignContent: 'flex-start',
    '@media(max-width:600px)': {
        maxHeight: "100%",
    },

}));
export const ButtonGrid = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
}));
export const RightGrid = styled(Grid)(() => ({
    marginTop: 30,
    paddingLeft: "8px !important",
    paddingRight: "8px !important"

}));
export const SearchedRow = styled(Grid)(() => ({
    fontFamily: 'Poppins-Light',
    color: '#111111',
    cursor: 'pointer',
    fontSize: '14px',
    '& > :first-of-type': {
        fontFamily: 'Poppins-Medium',
        fontStyle: 'normal',
        letterSpacing: "0.0168em",
    },
}));
export const Filter = styled(Box)(() => ({
    fontFamily: 'Poppins-SemiBold',
    color: '#0E2932',
    fontSize: '18px',
}));
export const Reset = styled(Box)(() => ({
    fontFamily: 'Poppins-Medium',
    color: '#111111',
    fontSize: '12px',
    opacity: 0.6,
    cursor: 'pointer'
}));

export const FeaturedItem = styled(Box)(() => ({
    display: 'flex',
    background: "#FFFFFF",
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.12)',
    borderRadius: "10px",
    flexDirection: 'column',
    margin: '8px',
    '@media(max-width:1200px)': {
        width: "30vw",
    },
    '@media(max-width:1000px)': {
        width: "40vw",
    },
    '@media(max-width:800px)': {
        width: "40vw",
    },
    '@media(max-width:700px)': {
        width: "38vw",
        margin: '0',

    },
    '@media(max-width:600px)': {
        width: "100%",
    },
}));
export const FeaturedItemImage = styled(Box)(() => ({
    width: '100%',
    height: '24vh',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    '@media(max-width:1200px)': {
        height: '45vh',
    },
    '@media(max-width:1000px)': {
        height: '45vh',
    },
    '@media(max-width:800px)': {
        height: '38vh',
    },
    '@media(max-width:700px)': {
        height: '34vh',
    },
    '@media(max-width:600px)': {
        height: '44vh',
    },
    '@media(max-width:400px)': {
        height: '40vh',
    },
}));
export const FeaturedItemTop = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: "12px 20px",
    '& > :first-of-type': {
        display: 'flex',
        cursor: 'pointer'
    },
}));
export const NameSection = styled(Box)(() => ({
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: "0px 10px",
    '& > :first-of-type': {
        fontFamily: 'Poppins-SemiBold',
        fill:'solid',
        fontSize: '14px',
        color: '#3C6B49',
    },
}));
export const RatingBox = styled(Box)(() => ({
    display: 'inline-block',
    width: '100%',
    alignItems: 'center',
    verticalAlign: 'middle',
    fontFamily: 'Poppins-Medium',
    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        verticalAlign: 'middle',
    }
}));
export const RatingCount = styled('span')(() => ({
    color: '#101B10',
    fontWeight: 600,
    fontSize: '12px',
    marginLeft: "2px",
    verticalAlign: 'text-bottom',

    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },


}));
export const ReviewCount = styled('span')(() => ({
    textDecorationLine: 'underline',
    color: 'rgba(17, 17, 17, 0.4)',
    fontSize: '12px',
    marginLeft: "2px",

    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3vw',
    },
}));
export const ActivityDetails = styled(Box)(() => ({
    padding: "8px 18px",
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));
export const ActivityHeading = styled('span')(() => ({
    fontFamily: 'Poppins-SemiBold',
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    fontSize: '11px',
    color: "#101B10",
    // fontWeight: 600,
    opacity: 0.6,
    marginBottom: 4
}));
export const Location = styled('span')(() => ({
    fontSize: '12px',
    color: '#3C6B49',
    marginBottom: 10,
    letterSpacing: "0.03em",
    fontFamily: 'Poppins-Medium',
    textTransform:'capitalize'
}));
export const Offered = styled('span')(() => ({
    flexGrow: 0,
    flex: "none",
    marginBottom: 4,
    color: "#101B10",
    fontSize: "13px",
    lineHeight: "140%",
    alignSelf: 'stretch',
    fontFamily: 'Poppins-Medium',
    textTransform:'capitalize'

}));
export const Return = styled('span')(() => ({
    fontFamily: 'Poppins-Light',
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "140%",
    flex: "none",
    alignSelf: 'stretch',
    flexGrow: 0,
}));

export const GreenButton = styled(Button)({
    boxShadow: 'none',
    height: "36px",
    textTransform: 'none',
    fontSize: "14px",
    textSizeAdjust: "inherit",
    padding: '6px 12px',
    border: '1px solid',
    color: '#FFFFFF',
    lineHeight: 1.5,
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
    borderRadius: "4px",
    fontFamily: 'Poppins-SemiBold',
       "&:disabled": {
        backgroundColor: '#3C6B49',
        color: '#FFFFFF',
        borderColor: '#3C6B49',
        boxShadow: 'none',
        },
    '&:hover': {
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3C6B49',
        borderColor: '#3C6B49',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #3C6B49',
    },

    '@media(max-width: 700px)': {
        fontSize: '1.9vw'
    },
    '@media(max-width: 600px)': {
        height: '6vh',
        fontSize: '2.4vw',
        width: '100%'
    },
    '@media(max-width: 400px)': {
        height: '6vh',
        fontSize: '13px'
    },
});

export const WhiteButton = styled(Button)({
    boxShadow: 'none',
    width: "100%",
    height: "36px",
    textTransform: 'none',
    fontSize: "14px",
    textSizeAdjust: "inherit",
    padding: '6px 12px',
    border: '1px solid',
    color: 'rgba(17, 17, 17, 0.6)',
    lineHeight: 1.5,
    backgroundColor: 'white',
    borderColor: 'rgba(17, 17, 17, 0.6)',
    borderRadius: "4px",
    fontFamily: 'Poppins-SemiBold',
    '&:hover': {
        backgroundColor: 'white',
        borderColor: 'rgba(17, 17, 17, 0.6)',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: 'white',
        borderColor: 'rgba(17, 17, 17, 0.6)',
    },
    '&:focus': {
        boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.12)',
    },
    '@media(max-width: 700px)': {
        fontSize: '1.9vw'
    },
    '@media(max-width: 600px)': {
        height: '6vh',
        fontSize: '2.4vw'
    },
    '@media(max-width: 400px)': {
        height: '6vh',
        fontSize: '13px'
    },
});