import { Grid, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import MuiAvatar from '@mui/material/Avatar';

export const LeftBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const RightBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: 6,
  cursor: 'pointer',
}));
export const NameSection = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '0px 10px',
  '& > :first-of-type': {
    fontFamily: 'Poppins-SemiBold',
    color: '#101B10',
    fontSize: '14px',
  },
}));
export const Count = styled('span')(() => ({
  fontFamily: 'Poppins-Medium',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#111111',
  opacity: 0.6,
  marginBottom: 5,
  '@media(max-width: 500px)': {
    fontSize: '3vw',
  },
}));
export const MainBox = styled(Grid)(() => ({
  display: 'flex',
  background: '#F9F9F9',
  justifyContent: 'center',
}));
export const UserName = styled('h4')(() => ({
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  marginTop: 0,
  marginBottom: 0,
  fontSize: '16px',
  lineHeight: '140%',
  color: '#101B10',
  display: 'flex',
  alignItems: 'center',
  '& > :first-of-type': {
    fontSize: '14px',
  },
}));
export const RightSideRating = styled('span')(() => ({
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  marginTop: 0,
  marginBottom: 0,
  fontSize: '14px',
  lineHeight: '140%',
  color: '#101B10',
  display: 'flex',
  alignItems: 'center',
  '@media(max-width: 600px)': {
    fontSize: 'small',
  },
}));
export const BrowseFiles = styled('span')(() => ({
  fontFamily: 'Poppins-Light',
  fontStyle: 'normal',
  fontWeight: 600,
  color: '#1A5B2C',
  textUnderlineOffset: '3px',
  marginBottom: 2,
  fontSize: '.9rem',
  letterSpacing: '0.0168em',
  cursor: 'pointer',
  width: 'fit-content',
  textDecorationLine: 'underline',
  '@media(max-width: 600px)': {
    fontSize: '.7rem',
  },
}));
export const Text = styled('p')(() => ({
  fontFamily: 'Poppins-Light',
  fontStyle: 'normal',
  color: '#FFFFFF',
  fontSize: '.9rem',
  marginBottom: 2,
  letterSpacing: '0.0168em',
  cursor: 'pointer',
}));

export const CustomAvatar = styled(MuiAvatar)(() => ({
  width: 70,
  height: 70,
  '@media(max-width: 600px)': {
    width: 50,
    height: 50,
  },
}));
export const Skip = styled('p')(() => ({
  fontFamily: 'Poppins-Regular',
  textAlign: 'center',
  fontStyle: 'normal',
  fontSize: '.9rem',
  color: 'rgba(17, 17, 17, 0.6)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}));
export const ActivityHeading = styled('p')(() => ({
  textTransform: 'uppercase',
  fontFamily: 'Poppins-SemiBold',
  letterSpacing: '3%',
  fontSize: '13px',
  color: 'rgba(17, 17, 17, 0.4)',
  margin: 0,
  '@media(max-width: 600px)': {
    fontSize: '2.4vw',
  },
  '@media(max-width: 400px)': {
    fontSize: '3.4vw',
  },
}));
export const ActivityDetail = styled('p')(() => ({
  fontFamily: 'Poppins-Medium',
  fontStyle: 'normal',
  fontSize: '14px',
  lineHeight: '140%',
  color: '#111111',
  marginTop: 5,
  marginBottom: 5,
  '@media(max-width: 600px)': {
    fontSize: '2.7vw',
  },
  '@media(max-width: 400px)': {
    fontSize: '3.2vw',
  },
}));
export const InstructionsSpan = styled('span')(() => ({
  fontFamily: 'Poppins-Regular',
  fontStyle: 'normal',
  fontSize: '14px',
  margin: 0,
  color: 'rgba(17, 17, 17, 0.6)',
  '@media(max-width: 600px)': {
    fontSize: '2.7vw',
  },
  '@media(max-width: 400px)': {
    fontSize: '3.2vw',
  },
}));
export const GreenButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '14px',
  height: '45px',
  textSizeAdjust: 'inherit',
  padding: '6px 12px',
  border: '1px solid',
  color: '#FFFFFF',
  lineHeight: 1.5,
  backgroundColor: '#3C6B49',
  borderColor: '#3C6B49',
  borderRadius: '4px',
  fontFamily: 'Poppins-SemiBold',
  '&:disabled': {
    backgroundColor: '#3C6B49',
    color: '#FFFFFF',
    borderColor: '#3C6B49',
    boxShadow: 'none',
  },
  '&:hover': {
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#3C6B49',
    borderColor: '#3C6B49',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #3C6B49',
  },

  '@media(max-width: 700px)': {
    fontSize: '1.9vw',
  },
  '@media(max-width: 600px)': {
    height: '6vh',
    fontSize: '2.6vw',
  },
  '@media(max-width: 400px)': {
    height: '6vh',
    fontSize: '3.8vw',
  },
});
export const RedButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '14px',
  height: '45px',
  textSizeAdjust: 'inherit',
  padding: '6px 12px',
  color: '#B93B3B',
  lineHeight: 1.5,
  backgroundColor: 'white',
  border: '1px solid #DFDFDF',
  borderRadius: '4px',
  fontFamily: 'Poppins-Medium',
  '&:hover': {
    backgroundColor: 'white',
    borderColor: '#DFDFDF',
    boxShadow: 'none',
  },
  '&:active': {
    backgroundColor: 'white',
    borderColor: '#DFDFDF',
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #DFDFDF',
  },

  '@media(max-width: 700px)': {
    fontSize: '1.9vw',
  },
  '@media(max-width: 600px)': {
    height: '6vh',
    fontSize: '2.6vw',
  },
  '@media(max-width: 400px)': {
    height: '6vh',
    fontSize: '3.8vw',
  },
});
export const TransparentBtn = styled(Button)(() => ({
  display: 'flex',
  height: '45px',
  justifyContent: 'space-around',
  textTransform: 'capitalize',
  alignItems: 'center',
  borderRadius: '5px',
  color: '#30563A',
  border: '1px solid #DFDFDF',
  fontFamily: 'Poppins-SemiBold',
  fontSize: '13px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'white',
    borderColor: '#DFDFDF',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'white',
    borderColor: '#DFDFDF',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem white',
  },
  '@media(max-width: 400px)': {
    height: '6vh',
    fontSize: '3.8vw',
  },
}));
export const CountName = styled(Box)(() => ({
  fontFamily: 'Poppins-Medium',
  fontStyle: 'normal',
  fontSize: '13px',
  lineHeight: '20px',
  color: '#111111',
  opacity: 0.6,
  marginTop: 20,
  marginBottom: 8,
  '@media(max-width: 800px)': {
    fontSize: '1.7vw',
  },
  '@media(max-width: 600px)': {
    fontSize: '2.2vw',
  },
  '@media(max-width: 500px)': {
    fontSize: '2.8vw',
  },
  '@media(max-width: 400px)': {
    fontSize: '3vw',
  },
}));
