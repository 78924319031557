import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const ArrowGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: .3,
    paddingTop: 26
}));
export const NameSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: "0px 10px",
    '& > span': {
        fontFamily: 'Poppins-SemiBold',
        color: '#101B10',
        fontSize: '14px'
    },
}));
export const LeftBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
}));
export const TripBoxHeader = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '10px 10px 0px 0px'
}));
export const TripBox = styled(Grid)(() => ({
    boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    height: 'max-content',
    padding: '12px 20px'

}));
export const ActivityFeatures = styled('p')(() => ({
    fontStyle: 'normal',
    fontSize: '13px',
    fontFamily: 'Poppins-Regular',
    lineHeight: '140%',
    color: '#101B10',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '@media(max-width: 800px)': {
        fontSize: '1.7vw',
    },
    '@media(max-width: 600px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '2.8vw',
    },
    '@media(max-width: 400px)': {
        fontSize: '3.2vw',
    },
}));
export const FormBox = styled(Box)(() => ({
    width: '45%',
    border: '1px solid #E5E5E5',
    overflow: 'auto',
    background: '#FFFFFF',
    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.07)',
    borderRadius: '5px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '10px 30px',
    height: '94vh',
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    '@media(max-width: 1200px)': {
        width: '50%',
    },
    '@media(max-width: 1000px)': {
        width: '70%',
    },
    '@media(max-width: 900px)': {
        width: '70%',
    },
    '@media(max-width: 800px)': {
        width: '80%',
    },
    '@media(max-width: 600px)': {
        width: '93%',
        padding: '10px 12px',
        height: 'max-content',
    },
}));
