import React, { useState } from 'react';
import calender from '../../assets/createTrip/calender.png';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { birthdateStyles, dateStyles } from './styles';
import { monthName } from '../../utills/constants';
import { GreenButton, CancelButton } from './Elements';
import { InputAdornment, TextField, Popover } from '@mui/material';
import { Calendar, utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { useEffect } from 'react';

export const DateSingleInput = ({ value, onChange, dateRange }) => {
  const classes = dateStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <TextField
        onClick={handleClick}
        aria-describedby={id}
        sx={{ width: '100%' }}
        className={classes.root}
        value={`${monthName(value?.month)} ${value?.day}, ${value?.year}`}
        placeholder={'Select dates'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20 } }}
        readOnly
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Calendar
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          calendarSelectedDayClassName="calendarSelectedDay"
          value={value}
          minimumDate={dateRange?.from}
          maximumDate={dateRange?.to}
          onChange={onChange}
          colorPrimary="#3C6B49"
          calendarPopperPosition="auto"
          inputPlaceholder="Select a day range"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </CancelButton>
          <GreenButton onClick={handleClose}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};
export const DateSingleInputV = ({ registerFor, register, error, settakeBirthdatDate }) => {
  const classes = birthdateStyles();

  const [selectedDays, setselectedDays] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    day: new Date().getDate(),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    settakeBirthdatDate(`${selectedDays?.year}-${selectedDays?.month}-${selectedDays?.day}`);
  }, [selectedDays]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <TextField
        onClick={handleClick}
        aria-describedby={id}
        {...register(registerFor, { required: true })}
        sx={{ width: '100%' }}
        error={error ? true : false}
        helperText={error}
        className={classes.root}
        value={`${monthName(selectedDays?.month)} ${selectedDays?.day}, ${selectedDays?.year}`}
        placeholder={'Select dates'}
        inputProps={{ style: { color: '#1E3625', zIndex: 20, height: 19 } }}
        readOnly
        InputProps={{
          endAdornment: (
            <InputAdornment sx={{ zIndex: 20 }} position="end">
              <img src={calender} style={{ cursor: 'pointer' }} alt="eye" />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Calendar
          calendarClassName="responsive-calendar"
          calendarRangeStartClassName="calendarRangeStart"
          calendarRangeBetweenClassName="calendarRangeBetween"
          calendarRangeEndClassName="calendarRangeEnd"
          calendarSelectedDayClassName="calendarSelectedDay"
          value={selectedDays}
          onChange={setselectedDays}
          maximumDate={utils().getToday()}
          selectorEndingYear={new Date().getFullYear()}
          selectorStartingYear={new Date().getFullYear() - 100}
          colorPrimary="#3C6B49"
          calendarPopperPosition="auto"
          inputPlaceholder="Select a day range"
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingBottom: '1rem',
            paddingRight: '1rem',
          }}
        >
          <CancelButton onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </CancelButton>
          <GreenButton onClick={handleClose}>Apply</GreenButton>
        </div>
      </Popover>
    </>
  );
};
