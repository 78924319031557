import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import "./style.css"

export const Status = styled(Typography)(() => ({
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '1rem',
    color: "#101B10",
    opacity: 0.6
}));

export const Value = styled(Typography)(() => ({
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '1rem',
    color: "#3D8451",
}));

export const LinearProgressWithLabel = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '98%', }}>
                <Status>{`Uploading...`}</Status>
                <Value>{`${Math.round(props.value)}%`}</Value>
            </Box>
            <Box sx={{ width: '98%',mt:1 }}>
                <div className={'meter'} >
                    <span style={{ width: `${Math.round(props.value)}%` }}></span>
                </div>
                {/* <BorderLinearProgress sx={{ height: '20px', color: '#569C69' }} variant="determinate" {...props} /> */}
            </Box>
        </Box>
    );
}
