import React from "react";

import { Box } from "@mui/material"
import { CustomTripBox } from "./TripBox"
import { useSelector } from "react-redux";
import { CountName, Edit } from "./Elements"
import { useNavigate } from "react-router-dom";

import GreenLoader from "../../assets/greenLoader.gif"

import "./style.css"

const Sent = ({ loading,getTime, cancelOffer }) => {

    const navigate = useNavigate()

    const { sent } = useSelector(state => state.auth);
    return <>
        {sent?.length > 0 ?
            <>
                <CountName>You have sent {sent?.length} offer(s)</CountName>
                {loading ?
                    <div style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', left: "50%", top: "50%" }}>
                        <img src={GreenLoader} width="80" alt="GreenLoader" />
                    </div>
                    : sent?.map((res, i) => { return <CustomTripBox loading={loading} cancelOffer={cancelOffer} getTime={getTime} res={res} key={i} /> })
                }

            </> : <Box sx={{ display: 'flex', flexDirection: 'column', height: '77vh', justifyContent: 'center', alignItems: 'center' }}>
                <CountName>No trade offer(s) sent</CountName>
                <Edit onClick={() => navigate('/search')} sx={{ width: 'max-content', px: 2, py: 1, height: 'max-content' }} >Find a Trip</Edit>
            </Box>}

    </>

}
export default Sent