import React, { useState } from 'react';
import verification from '../../assets/verification.png';

import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signout } from '../../features/auth/action';
import { useSelector, useDispatch } from 'react-redux';
import { Heading, DescriptionX, style, GreenButton, RedButton } from './Elements';

export const LinkSent = ({ open, data, reSendLink }) => {
  const dispatch = useDispatch();

  const navigation = useNavigate();

  const [isLinkResent, setIsLinkResent] = useState(false);

  const { userInfo } = useSelector((state) => state.auth);

  const resendLinkFunction = () => {
    reSendLink();
    setIsLinkResent(true);
  };

  const logout = () => {
    let body = { userId: userInfo?._id, registrationCode: '', notificationEnabled: false };
    dispatch(signout(body, navigation));
  };

  return (
    <Modal
      open={open}
      // open={false}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width: 400, height: 450, alignItems: 'center', pt: 3, px: 3, pb: 3 }}>
        <Heading>{isLinkResent ? 'Email Resent!' : 'Check your email!'}</Heading>
        {/* <Header heading="Check your email!" handleClose={handleClose} /> */}

        <img src={verification} alt="verification" />
        <div style={{ textAlign: 'center' }}>
          <DescriptionX>
            To activate your account, click the button in the verification email we sent to:{' '}
          </DescriptionX>
          <DescriptionX sx={{ fontWeight: 'bold' }}>{data?.email}</DescriptionX>
        </div>
        <DescriptionX sx={{ opacity: 0.6 }}>
          If you haven't received an email within a few minutes, please check your spam folder.
        </DescriptionX>
        <Box sx={{ width: '100%' }}>
          <GreenButton
            onClick={resendLinkFunction}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%' }}
          >
            Resend Verification Email
          </GreenButton>
          <RedButton
            onClick={logout}
            type="submit"
            variant="contained"
            disableripple
            sx={{ width: '100%', mt: 1 }}
          >
            Logout
          </RedButton>
        </Box>
      </Box>
    </Modal>
  );
};
