import React from "react";

import { LeaveReviewBox, NoTripText } from "./../Elements"

const NoData = ({ text }) => {
    return <LeaveReviewBox >
        <NoTripText>{text}</NoTripText>
    </LeaveReviewBox >
}

export default NoData