import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import bg from '../../../assets/home/bg.png';

export const JoinNowBoxLeft = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '35vw',
  margin: 'auto',
  paddingTop: '12vh',
  '@media(max-width: 1250px)': {
    width: '40vw',
  },
  '@media(max-width: 800px)': {
    width: '48vw',
  },
  '@media(max-width: 650px)': {
    width: '82vw',
  },
  '@media(max-width: 500px)': {
    width: '80vw',
  },
}));
export const JoinNowBoxRight = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: 30,
  paddingTop: 30,
  width: '29vw',
  margin: '5.8vh 6.5vw',
  background: '#FFFFFF',
  boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.2)',
  borderRadius: '10px',
  '@media(max-width: 2000px)': {
    margin: '8.8vh 6.5vw',
  },
  '@media(max-width: 1500px)': {
    margin: '5.8vh 6.5vw',
  },
  '@media(max-width: 1200px)': {
    width: '40vw',
  },
  '@media(max-width: 1200px)': {
    width: '40vw',
  },
  '@media(max-width: 650px)': {
    display: 'none',
  },
}));
export const SearchGrid = styled(Grid)(() => ({
  height: '90vh',
  display: 'flex',
  '@media(max-width: 600px)': {
    alignItems: 'end',
    height: 'max-content',
  },
}));
export const MainGrid = styled(Grid)(() => ({
  backgroundImage: `url(${bg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  // height:'100vh'
}));
export const Description = styled('p')(() => ({
  fontFamily: 'Poppins-Light',
  fontStyle: 'normal',
  color: '#FFFFFF',
  '@media(max-width: 2000px)': {
    fontSize: '1.2vw',
  },
  '@media(max-width: 1500px)': {
    fontSize: '1.3vw',
  },
  '@media(max-width: 1200px)': {
    fontSize: '1.7vw',
  },
  '@media(max-width: 1000px)': {
    fontSize: '2.2vw',
  },
  '@media(max-width: 600px)': {
    fontSize: '3.2vw',
  },
}));
export const Heading = styled('h1')(() => ({
  fontFamily: 'Poppins-SemiBold',
  fontStyle: 'normal',
  color: '#FFFFFF',
  fontSize: '3.2vw',
  marginBottom: 0,
  letterSpacing: 3,
  '@media(max-width: 800px)': {
    fontSize: '4.2vw',
  },
  '@media(max-width: 650px)': {
    fontSize: '6.3vw',
  },
}));
export const HeadingFind = styled('h2')(() => ({
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  color: 'black',
  fontSize: '1.6vw',
  letterSpacing: 3,
  textAlign: 'center',
  marginTop: 0,
}));
export const CustomBox = styled(Box)(() => ({
  width: '90%',
  margin: 'auto',
}));
export const ButtonBox = styled(Box)(() => ({
  marginTop: '5vh',
  marginBottom: 0,
}));
export const BottomGrid = styled(Box)(() => ({
  height: '24vh',
  display: 'flex',
  background: 'rgba(17, 17, 17,0.16)',
  marginTop: '-19vh',
  alignItems: 'center',
  '@media(max-width: 600px)': {
    marginTop: 0,
    height: '20vh',
  },
  '@media(max-width: 400px)': {
    height: '16vh',
  },
}));
export const BottomBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 1,
  '& img': {
    maxWidth: '70%',
    maxHeight: '70%',
    verticalAlign: 'middle',
  },
  '@media(max-width: 600px)': {
    '& img': {
      maxWidth: '40%',
      maxHeight: '40%',
      verticalAlign: 'middle',
    },
  },
}));
export const BottomBoxText = styled(Box)(() => ({
  fontFamily: 'Poppins-SemiBold',
  marginTop: 10,
  color: 'white',
  fontSize: 'small',
  '@media(max-width: 2000px)': {
    fontSize: '1vw',
  },
  '@media(max-width: 1500px)': {
    fontSize: '1vw',
  },
  '@media(max-width: 1000px)': {
    fontSize: '1.4vw',
  },
  '@media(max-width: 600px)': {
    fontSize: '13px',
  },
  '@media(max-width: 400px)': {
    fontSize: '10px',
  },
}));
export const BottomSubGrid = styled(Box)(() => ({
  width: '50vw',
  display: 'flex',
  paddingLeft: '5.4vh',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'baseline',
  '@media(max-width: 1000px)': {
    width: '50%',
  },
  '@media(max-width: 650px)': {
    width: '100%',
    paddingLeft: 0,
    margin: 'auto',
  },
}));
