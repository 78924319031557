import React, { useState, useEffect } from 'react';

import { json, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searchFunction, searchTrips } from '../../../features/trip/action';
import { getDropdowns } from '../../../features/general/action';
import { CustomGreenLargeButton } from '../../../components/Button';
import { Dropdown, SearchInput } from '../../../components/InputFields';
import { JoinNowBoxRight, HeadingFind, CustomBox, ButtonBox } from './Elements';

import buildingIcon from '../../../assets/home/Building.svg';
import activityIcon from '../../../assets/home/Activity.svg';
import speciesIcon from '../../../assets/home/Species.svg';
import { capitalize } from '../../../utills/constants';

const FindTrip = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [state, setState] = useState({ location: '', query: '', species: '', tradeType: '' });

  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const { types, species, states } = useSelector((state) => state.general);
  const [filteredSpecies, setFilteredSpecies] = useState([]);

  useEffect(() => {
    dispatch(getDropdowns());
    // eslint-disable-next-line
  }, []);

  const search = () => {
    console.log(state, state?.query);
    // var lowerCase = state?.query ? state?.query?.toLowerCase() : '';
    const query = `?query=${capitalize(state?.query)}&location=${state.location}&species=${
      state.species
    }&acitvity=${state.tradeType}&`;
    dispatch(searchTrips(query, 'home', navigate));
    // dispatch(searchFunction(lowerCase));
    localStorage.setItem('searchedFromHome', true);
    localStorage.setItem('queryFromHome', state && JSON.stringify(state));

    navigate('/search');
  };
  useEffect(() => {
    const newSpecies = species?.filter((elm) => elm?.type?.name === state?.tradeType);
    setFilteredSpecies(newSpecies);
    handleChange('species', '');
  }, [state?.tradeType]);
  return (
    <JoinNowBoxRight>
      <CustomBox>
        <HeadingFind>Find a Trip</HeadingFind>
        <SearchInput
          handleChange={handleChange}
          handleSearch={search}
          icon={buildingIcon}
          isEndAdornment={true}
        />
        <Dropdown
          handleChange={handleChange}
          handleFor="location"
          label="Any state"
          data={states}
          title="Trip Location"
          icon={buildingIcon}
        />
        <Dropdown
          handleChange={handleChange}
          handleFor="tradeType"
          label="Any activity"
          data={types}
          title="Activity"
          icon={activityIcon}
        />
        <Dropdown
          handleChange={handleChange}
          handleFor="species"
          label="Any species"
          data={filteredSpecies}
          title="Species"
          icon={speciesIcon}
        />
        <ButtonBox>
          <CustomGreenLargeButton variant="contained" onClick={search} disableripple>
            {' '}
            {'Find Trips'}{' '}
          </CustomGreenLargeButton>
        </ButtonBox>
      </CustomBox>
    </JoinNowBoxRight>
  );
};

export default FindTrip;
