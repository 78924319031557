import React from 'react';

import { Grid } from '@mui/material';
import { MainGrid, LeftGrid, SubGrid, ImageGrid, Heading } from './Elements';
import { RightGrid, Black, Orange, IOSAndroindHeading, Description } from './Elements';

import iPhone from '../../../assets/home/iPhone.png';
import appleBadge from '../../../assets/home/appleVector.svg';
import googleBadge from '../../../assets/home/google-play.svg';
import { Link } from 'react-router-dom';

const DownloadApp = ({ refs }) => {
  return (
    <MainGrid ref={refs}>
      <SubGrid container>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <LeftGrid>
            <Heading>
              <Orange> Download </Orange> <Black> Mobile App</Black>
            </Heading>
            <Description>
              Join other outdoor enthusiasts and turn trades into memorable experiences.
            </Description>
            <IOSAndroindHeading>Available for iOS & Android</IOSAndroindHeading>
            <ImageGrid>
              <a href="https://apps.apple.com/us/app/trip-trader/id6449032922">
                <img src={appleBadge} alt="appleBadge" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.triptrader">
                <img src={googleBadge} alt="appleBadge" />
              </a>
            </ImageGrid>
          </LeftGrid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <RightGrid>
            <img src={iPhone} alt="iPhone" />
          </RightGrid>
        </Grid>
      </SubGrid>
    </MainGrid>
  );
};
export default DownloadApp;
