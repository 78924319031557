import React from "react";
import { LeftGrid, LeftHeading,OverlayGrid,  Centered, HeadingDiv } from "./Elements";
import Logo from "../../../components/Logo"
import correct from "../../../assets/create account/correct.png"
import { Text } from "../../Elements";

const LeftPanel = ({ data }) => {
    return <LeftGrid item xs={12} sm={12} md={6} lg={6} className="col2signup">
        <img src={data.bg} alt="" />
        <Centered>
            <div style={{ paddingLeft: "6vw" }}>
                <Logo />
            </div>
            {data.isOverlay && <OverlayGrid>
                <img src={data.overlayImage} alt="overlayImage"/>
            </OverlayGrid>}

            <HeadingDiv >
                <LeftHeading>{data.heading}</LeftHeading>
                {typeof data.content === "string" ?
                    <Text>{data.content}</Text> :
                    typeof data.content === "object" ?
                        data.content.map((res, i) => {
                            return <Text key={i}><img src={correct} style={{ paddingRight: 15 }} />{res}</Text>
                        }) : ''}
            </HeadingDiv>
        </Centered>
    </LeftGrid>

}
export default LeftPanel