import React from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CenterHeader } from "../../components/Modal/ModalHeader";
import { GreenButton, Description } from "../../components/Modal/Elements";
import { searchTrips } from "../../features/trip/action";
const DeleteError = ({ handleChange, handleClose, state, handle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _handleClose = () => {
    handleChange("step", 1);
    handle("openMakeOffer", false);
  };

  const reload = () => {
    handleClose();
    handle("openMakeOffer", false);
    let searchedFromHome = localStorage.getItem("searchedFromHome");
    if (searchedFromHome == "false" || searchedFromHome == null) {
      const query = `?`;
      dispatch(searchTrips(query, "search", navigate));
    }
  };

  return (
    <>
      <CenterHeader heading="Offer Sent" handleClose={_handleClose} />
      <Description sx={{ mt: 3, fontFamily: "Poppins-Regular", mx: "auto" }}>
        {state?.deleteErrorMessage}
      </Description>
      <GreenButton
        onClick={reload}
        type="submit"
        variant="contained"
        disableripple
        sx={{ mx: "auto", mt: 2 }}
      >
        Reload
      </GreenButton>
    </>
  );
};
export default DeleteError;
