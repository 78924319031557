import React from "react";
import CheckoutForm from "./CheckoutForm";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { secretStripeKey } from "../../../utills/constants"

import "./stripe.css"
import "./style.css"

const promise = loadStripe(secretStripeKey);

const Step2 = ({ state, handleChange }) => {

    return <Elements zipCode={false} stripe={promise}>
        <CheckoutForm state={state} handleChange={handleChange} />
    </Elements>

}

export default Step2