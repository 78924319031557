import React, { useState } from 'react';

import { Box, Modal } from '@mui/material';
import { Heading, style } from './Elements';
import { pinCodeFieldStyles } from '../InputFields/styles';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { CustomGreenLargeButton } from '../../components/Button';
import Loader from '../../assets/loader1.gif';
import toast from 'react-hot-toast';
import { LoaderSpan } from '../../pages/SignIn/Elements';

export const OtpVerification = ({
  phoneNumber,
  open,
  handle,
  stateName,
  updateProfileFunction,
}) => {
  const handleClose = () => handle([stateName], false);

  const classes = pinCodeFieldStyles();
  const [pin, setPin] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (newValue) => {
    setPin(newValue);
  };
  const verifyOtp = (e) => {
    e.preventDefault();
    setIsLoading(true)
    const confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(pin)
      .then((result) => {
        toast.success(`Otp verified.`, { duration: 4000 });
        updateProfileFunction();
      })
      .catch((error) => {
        handleChange('openOtpModal', false);
        toast.error(`Invalid code.`, { duration: 4000 });
        handleChange('openOtpModal', false);
        setPin('')
      }).finally(()=>{
        setIsLoading(false)
      });
  };

  console.log(pin,"pin")
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 450, height: 300, alignItems: 'center', pt: 3, px: 3, pb: 3 }}>
          <Heading>Enter the code we send over SMS to {phoneNumber} </Heading>
          {/* <form onSubmit={verifyOtp} className="submitForm"> */}
          {/* <Controller
                            name="pin"
                            control={control}
                            rules={{ validate: (value) => value.length === 6 }}
                            render={({ field, fieldState }) => ( */}

          <Box>
            <MuiOtpInput
              className={classes.root}
              value={pin}
              sx={{ gap: 1 }}
              length={6}
              onChange={handleChange}
              style={{fontSize:"1rem"}}
            />
            {/* {fieldState.invalid ? (
                                        <FormHelperText sx={{ fontFamily: "Poppins-Medium", color: "#d32f2f", marginTop: 0 }}>{'PIN code is incorrect.'}</FormHelperText>
                                    ) : null} */}
          </Box>
          {/* )} */}
          {/* /> */}
          <CustomGreenLargeButton onClick={verifyOtp} variant="contained" disableripple disabled={isLoading || pin.length !== 6}>
            {isLoading ? (
              <LoaderSpan>
                <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                Submiting...
              </LoaderSpan>
            ) : (
              ' Submit Code'
            )}
          </CustomGreenLargeButton>
          {/* <CustomGreenLargeButton type="submit" variant="contained" disableripple> {loading ? <LoaderSpan><img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />Submit Code</LoaderSpan> : 'Submit Code'} </CustomGreenLargeButton> */}
          {/* </form > */}
        </Box>
      </Modal>
    </div>
  );
};
