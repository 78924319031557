import React, { useEffect } from "react";

import CustomTripBox from "./CustomTripBox"
import Sidebar from "../../components/Sidebar"
import GreenLoader from "../../assets/greenLoader.gif"

import { MainBox, UserName, } from "../Elements";
import { FormBox, CountName, } from "./Elements"
import { useMediaQuery, Box } from "@mui/material"
import { useSelector, useDispatch } from "react-redux";
import { getFunction } from "../../features/auth/action";

const SavedTrips = () => {

    const dispatch = useDispatch()

    const { userInfo, loading, isAuth } = useSelector(state => state.auth)

    const matches600 = useMediaQuery('(max-width:600px)');

    const updateUser = () => {
        let body = { name: 'userInfo', url: `/user/getUser/${userInfo?._id}` }
        dispatch(getFunction(body))
    }

    useEffect(() => {
        if (isAuth) { updateUser() }
        // eslint-disable-next-line
    }, [])

    return <MainBox sx={{ marginTop: matches600 ? 6 : 0 }}>
        <Sidebar />
        <FormBox>
            <UserName sx={{ fontSize: '18px' }}>Saved Trips</UserName>
            {loading &&
                <div style={{ position: 'absolute', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', left: "50%", top: "50%" }}>
                    <img src={GreenLoader} width="80" alt="GreenLoader" />
                </div>}
            {userInfo?.savedTrips?.length === 0 ?
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '94vh', justifyContent: 'center', alignItems: 'center' }}>
                    <CountName>No saved trip(s) found</CountName>
                </Box>
                :
                <>
                    <CountName sx={{ mt: 2, mb: 1 }}>You have {userInfo?.savedTrips?.length} trip(s) saved</CountName>
                    {userInfo?.savedTrips?.map((res, i) => {
                        if(res?.tripId != null){
                            return <CustomTripBox updateUser={updateUser} res={res?.tripId} key={i} />
                        }
                    })}
                </>}

        </FormBox >
    </MainBox >
}
export default SavedTrips