import React, { useRef, useEffect, useCallback } from "react"

import JoinUs from "./JoinUs";
import Reviews from "./Review";
import DownloadApp from "./DownloadApp";
import FeaturedTraders from "./Featured";
import Footer from "../../components/Footer";

import { useNavigate } from "react-router-dom"
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { searchTrips } from "../../features/trip/action";
import { getFeatured } from "../../features/general/action"
const Home = () => {
    const navigate = useNavigate()
    const home = useRef(null),
        featured = useRef(null),
        reviews = useRef(null),
        download = useRef(null),
        dispatch = useDispatch()

    const fetchData = useCallback(() => {
        dispatch(getFeatured())
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        fetchData()
        const query = "?";
        dispatch(searchTrips(query, 'home', navigate))
        // eslint-disable-next-line
    }, [fetchData])
    // eslint-disable-next-line

    useEffect(() => {
        localStorage.setItem("searchedFromHome", false);
    }, [])
    return (<Grid >
        <JoinUs refs={{ featured, home, reviews, download }} />
        <FeaturedTraders refs={featured} />
        <Reviews refs={reviews} />
        <DownloadApp refs={download} />
        <Footer refs={{ featured, home, reviews, download }} />
    </Grid >)
}

export default Home