import React from 'react';

import { CancelButton } from '../Button';
import { Box, Modal } from '@mui/material';
import { Heading, style, RedButton } from './Elements';
import { Text } from '../../pages/Settings/Elements';
// import { Text } from '../../pages/Elements';

const ChangePlan = ({ open, cancelFunction, handleChange, returnEndDate, setOpen }) => {
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 550,
            height: 'max-content',
            pb: 4,
            pt: 2,
            px: 2,
            alignItems: 'flex-start',
          }}
        >
          <Heading sx={{ my: 2 }}>Change Plan</Heading>
          <Text>Are you sure to delete it</Text>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 2 }}>
            <RedButton
              sx={{ mr: 2 }}
              onClick={cancelFunction}
              type="submit"
              variant="contained"
              disableripple
            >
              Yes
            </RedButton>
            <CancelButton
              type="submit"
              onClick={() => setOpen(false)}
              variant="contained"
              disableripple
            >
              No, keep it
            </CancelButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChangePlan;
