import React, { useState } from 'react';

import { Header } from "./ModalHeader"
import { CancelButton } from "../Button"
import { getFullName } from '../../utills/constants';
import { useSelector, useDispatch } from 'react-redux';
import { LoaderSpan } from '../../pages/SignIn/Elements';
import { modalStyle, DisputeDescription, CheckBoxLabel } from "./Elements"
import { GreenButton, ReviewMessage, DisputeReason } from "./Elements"
import { Box, Modal, FormControlLabel, Checkbox } from '@mui/material';
import { stopLoading, startLoading } from '../../features/auth/reducer';
import { config, swalWithStyledButton } from '../../utills/constants';
import { reload } from "../../features/auth/action"
import api from "../../api"
import toast from 'react-hot-toast';
import Loader from "../../assets/loader1.gif"
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

export const OpenDispute = ({ apiCall, data, open, handle, stateName }) => {

    const dispatch = useDispatch();

    const handleClose = () => { handle([stateName], false); apiCall('reviews') };

    const { loading, userInfo } = useSelector(state => state.auth)

    const [state, setState] = useState({ agree: false });

    const navigate=useNavigate()
    const handleSubmit = async () => {
        dispatch(startLoading());
        if (state.agree) {

            const body = {
                isDisputeUpdate: true,
                recepientId: data?.guestId?._id,
                guestImage: userInfo?.image,
                'url': `/review/${data?._id}`, status: 'dispute', disputeOpenDate: new Date(),
                icon: 'https://triptrader-assets.s3.amazonaws.com/dispute-1677063279066.png',
                disputantName: getFullName(userInfo)
            }
            await api
                .put(body.url, body, config)
                .then((res) => {
                    if (res.data.message == `This user doesn't exist.` ||res.data.message=='This user has blocked you.') {
                        handleClose()
                        swalWithStyledButton.fire({
                            icon: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            text: res.data.message,
                            confirmButtonText: 'Reload',
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            }
                        })
                            .then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    dispatch(reload(userInfo?._id,navigate))
                                    dispatch(stopLoading());
                                    handle([stateName], false);
                                } else if (result.isDenied) {
                                    dispatch(stopLoading());
                                    handle([stateName], false);
                                }
                            })

                    } else {
                        dispatch(stopLoading());
                        handleClose()
                    }
                })
                .catch((err) => {
                    if (err.response) {
                        toast.error(err.response.data.message, { duration: 4000, });
                        dispatch(stopLoading());
                    }
                });
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle, width: '600px', alignItems: 'flex-start', p: 3, }}>
                    <Header heading="Dispute Review" handleClose={handleClose} />
                    <DisputeDescription sx={{ mt: 2 }}>
                        <span>You are about to open a dispute on the member review below.</span> Once a dispute is opened,
                        you will not be able to reply to the review or interact with this member until the dispute is settled.
                    </DisputeDescription>
                    <DisputeDescription sx={{ mt: 2 }}>
                        If we find that this member violated any terms of our User Agreement or Privacy Policy, we will take
                        appropriate action. We also may contact you if we need more information.
                    </DisputeDescription>
                    <ReviewMessage sx={{ mt: 2 }}>
                        <DisputeDescription><span>{getFullName(data?.guestId)} wrote:</span></DisputeDescription>
                        <DisputeReason>{data?.messages?.length > 0 && data?.messages[0]?.message}.</DisputeReason>
                    </ReviewMessage>
                    <FormControlLabel sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', mt: 2 }}
                        label={<CheckBoxLabel>
                            <span>This review may contain personally identifiable information.</span> I hereby declare that by checking
                            this box, the information provided here is true and correct. I also understand that any
                            willful dishonesty may result in permanent suspension of my account across all Trip Trader
                            services.
                        </CheckBoxLabel>}
                        control={<Checkbox style={{ color: "#1E3625" }} sx={{ padding: '16px 10px' }} onChange={() => setState({ ...state, agree: !state.true })} />}
                    />
                    <Box sx={{ display: 'flex', mt: 2, width: '100%' }}>
                        <GreenButton disabled={loading} type="submit" onClick={handleSubmit} variant="contained" disableripple sx={{ mr: 2, opacity: !state.agree ? 0.4 : 1 }}>
                            {loading ? <LoaderSpan><img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} /> Submit Dispute...</LoaderSpan> : ' Submit Dispute'}
                        </GreenButton>
                        <CancelButton onClick={handleClose} type="submit" variant="contained" disableripple>
                            Cancel
                        </CancelButton>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}