import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stripeAddedCard: [],
};

const StripeAddedCardSlice = createSlice({
  name: 'stripeAddedCard',
  initialState,
  reducers: {
    StripeData: (state, data) => {
      console.log('THE DATA FROM THE REDUX IS ', data?.payload);
      state.stripeAddedCard = data?.payload;
    },
  },
});

const StripeReducer = StripeAddedCardSlice.reducer;
export default StripeReducer;
export const { StripeData } = StripeAddedCardSlice.actions;
