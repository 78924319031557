import React, { useEffect, useState } from 'react';

import * as yup from 'yup';
import Logo from '../../components/Logo';
import LeftPanel from '../SignIn/LeftPanel';
import Loader from '../../assets/loader1.gif';
import CustomCopyright from '../../components/Footer/CopyRight';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../utills/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { startLoading, stopLoading } from '../../features/auth/reducer';

import { useDispatch, useSelector } from 'react-redux';
import { CustomLabel2 } from '../../components/InputFields/Elements';
import { createAccount, _postFuntion } from '../../features/auth/action';
import { DateSingleInputV } from '../../components/InputFields/DateSingleInput';
import { InputRightIcon, Input, CustomCheckbox } from '../../components/InputFields';
import { CrossDesktopButton, CrossMobileButton } from '../../components/Button/CrossButton';
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from '../../components/Button';
import {
  MobileGrid,
  RightGridCA,
  SubHeading,
  Heading,
  RightSubGridCA,
  MainGrid,
  LoaderSpan,
} from '../SignIn/Elements';

const schema = yup.object().shape({
  email: yup.string().required('Please enter your email.').email('only accepts an email'),
  password: yup
    .string()
    .required('Please enter a password.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  firstName: yup.string().required('Please enter your first name.'),
  birthDate: yup.string().required('Please enter your birth date.'),
  terms: yup.boolean().oneOf([true], 'Agreement to Terms and Conditions is required.'),
});

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [takeBirthdatDate, settakeBirthdatDate] = useState();
  const [birthDateError, setBirthDateError] = useState('');
  const { loading } = useSelector((state) => state.auth);
  function calculateAge(birthdate) {
    const birthDateObj = new Date(takeBirthdatDate);

    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - birthDateObj;

    // Calculate the number of milliseconds in a year
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // accounting for leap years

    // Calculate the age
    const age = Math.floor(timeDifference / millisecondsInYear);
    return age;
  }

  // Example usage:

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const navigateFunction = () => {
    setTimeout(() => {
      navigate('/uploadImage');
      dispatch(stopLoading());
    }, 300);
  };

  const submitFunction = async (data) => {
    dispatch(startLoading());
    setBirthDateError('');
    capitalize(data['firstName']);
    capitalize(data['lastName']);
    data['role'] = 'user';
    data['registrationCode'] = [];
    data['notificationEnabled'] = true;
    data['mode'] = 'web';
    data['email'] = data['email'].toLowerCase();
    data['birthDate'] = takeBirthdatDate;
    data['image'] = '';
    data['identityProof'] = '';
    const age = calculateAge(takeBirthdatDate);
    console.log('Let’s get started', age);
    if (age >= 18) {
      dispatch(createAccount(data, navigateFunction));
    } else {
      setBirthDateError('The age must be greater then 18');
      dispatch(stopLoading());
    }
    setTimeout(() => {
      dispatch(stopLoading());
    }, 2000000000000000000);
  };

  useEffect(() => {
    dispatch(stopLoading());
  }, []);
  return (
    <form onSubmit={handleSubmit(submitFunction)}>
      <MainGrid container spacing={2}>
        <MobileGrid>
          <Logo />
          <CrossMobileButton />
        </MobileGrid>
        <LeftPanel />
        <RightGridCA item xs={12} sm={12} md={6} lg={6}>
          <CrossDesktopButton />
          <RightSubGridCA>
            <div>
              <Heading>Let’s get started</Heading>
              <SubHeading>
                Already a member?{' '}
                <a style={{ paddingLeft: 4, paddingRight: 4 }} href="/#/signin">
                  {' '}
                  Sign in
                </a>
              </SubHeading>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Input
                  title="First Name"
                  error={errors.firstName?.message}
                  register={register}
                  registerFor="firstName"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Input
                  title="Last Name"
                  error={errors.lastName?.message}
                  register={register}
                  registerFor="lastName"
                />
              </Grid>
            </Grid>
            <Input
              title="Email Address"
              error={errors.email?.message}
              register={register}
              registerFor="email"
            />
            <InputRightIcon
              title="Password"
              error={errors.password?.message}
              register={register}
              registerFor="password"
            />
            <Grid>
              <CustomLabel2>{'Date of Birth'}</CustomLabel2>
              <DateSingleInputV
                error={errors.terms?.birthDate}
                register={register}
                registerFor="birthDate"
                settakeBirthdatDate={settakeBirthdatDate}
              />
              <p
                style={{
                  color: '#d32f2f',
                  fontWeight: '600',
                  fontFamily: 'Roboto ,Helvetica, Arial ,sans-serif',
                  fontSize: '0.75rem',
                  lineHeight: '1.66',
                  letterSpacing: '0.03333em',
                  textAlign: 'left',
                  marginTop: '3px',
                  marginRight: '14px',
                  marginBottom: '0',
                }}
              >
                {birthDateError}
              </p>
            </Grid>
            <CustomCheckbox
              register={register}
              error={errors.terms?.message}
              title={
                <>
                  I agree to the <a href="/#/termsandconditions">Terms & Conditions</a>
                </>
              }
            />
            <CustomGreenLargeButton
              type="submit"
              variant="contained"
              disableripple
              disabled={loading}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                  Create Account
                </LoaderSpan>
              ) : (
                'Create Account'
              )}
            </CustomGreenLargeButton>
            <CustomGreenTransLargeButton onClick={() => navigate('/search')}>
              Continue as a guest
            </CustomGreenTransLargeButton>
          </RightSubGridCA>
          <CustomCopyright />
        </RightGridCA>
      </MainGrid>
    </form>
  );
};

export default CreateAccount;
