import React, { useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { signIn } from '../../features/auth/action';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { InputRightIcon, Input, CustomCheckbox } from '../../components/InputFields';
import { CrossDesktopButton, CrossMobileButton } from '../../components/Button/CrossButton';
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from '../../components/Button';
import {
  FP,
  MobileGrid,
  RightGrid,
  SubHeading,
  Heading,
  RightSubGrid,
  MainGrid,
  LoaderSpan,
} from './Elements';
import { stopLoading } from '../../features/auth/reducer';

import * as yup from 'yup';
import LeftPanel from './LeftPanel';
import Logo from '../../components/Logo';
import Loader from '../../assets/loader1.gif';
import CustomCopyright from '../../components/Footer/CopyRight';

const schema = yup.object().shape({
  email: yup.string().required('Please fill in your email.'),
  password: yup.string().required('Please fill in your password.'),
});

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, isAuth } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const loginFunction = async (formdata) => {
    formdata['notificationEnabled'] = false;
    formdata['registrationCode'] = '';
    formdata['email'] = formdata['email'].toLowerCase();
    dispatch(signIn(formdata, navigate));
  };
  useEffect(() => {
    dispatch(stopLoading());
  }, []);

  if (isAuth) {
    return <Navigate to="/search" />;
  }

  return (
    <form onSubmit={handleSubmit(loginFunction)}>
      <MainGrid container spacing={2}>
        <MobileGrid>
          <Logo />
          <CrossMobileButton />
        </MobileGrid>
        <LeftPanel />
        <RightGrid item xs={12} sm={12} md={6} lg={6}>
          <CrossDesktopButton />
          <RightSubGrid>
            <div>
              <Heading>Sign In</Heading>
              <SubHeading>
                Not a member? <a href="/#/join">Join now</a>
              </SubHeading>
            </div>
            <Input
              title="Email Address"
              error={errors.email?.message}
              register={register}
              registerFor="email"
            />
            <InputRightIcon
              title="Password"
              error={errors.password?.message}
              register={register}
              registerFor="password"
            />
            <CustomCheckbox title="Save Password" />
            <CustomGreenLargeButton
              type="submit"
              variant="contained"
              disableripple
              disabled={loading}
            >
              {loading ? (
                <LoaderSpan>
                  <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
                  Sign In
                </LoaderSpan>
              ) : (
                'Sign In'
              )}{' '}
            </CustomGreenLargeButton>
            <CustomGreenTransLargeButton onClick={() => navigate('/search')}>
              Continue as a guest
            </CustomGreenTransLargeButton>
            <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
              <FP href="/#/forgetpassword">Forgot password</FP>
            </div>
          </RightSubGrid>
          <CustomCopyright />
        </RightGrid>
      </MainGrid>
    </form>
  );
};
export default SignIn;
