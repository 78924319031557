import React, { useState, useEffect } from 'react';

import sort from '../../assets/messaging/sort.png';
import search from '../../assets/search/search.png';

import { UserName } from '../Elements';
import { Check } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { SearchInput } from '../../components/InputFields';
import { getFullName, dateToFromNowDaily } from '../../utills/constants';
import {
  Grid,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import {
  LeftGrid,
  MessageBox,
  Message,
  NameSectionUnRead,
  NameSectionRead,
  Time,
  NameSection,
  CountName,
} from './Elements';
import { db } from '../../firebase';
import { collection, where, query, onSnapshot } from 'firebase/firestore';
import { FireStore } from '../../utills/FireStore';

import { saveData } from '../../features/auth/reducer';
import { Link } from 'react-router-dom';
const CustomMessageBox = ({ res, handleParentChange }) => {
  let user;

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);

  if (userInfo?._id === res?.userId1?._id) {
    user = res?.userId2;
  } else if (userInfo?._id === res?.userId2?._id) {
    user = res?.userId1;
  }

  const handleSelection = () => {
    handleParentChange('isMobile', true);
    handleParentChange('selectedChat', res);
    handleParentChange('receiverId', user?._id);
  };
  const checkifConvoIsRead = () => {
    return res?.latestMessage?.isRead === false && res?.latestMessage?.user?._id !== userInfo?._id;
  };
  if (!res?.latestMessage?.deletedBy?.includes(userInfo?._id)) {
    return (
      <MessageBox
        onClick={userInfo?.subscription?.status === 'freemium' ? '' : handleSelection}
        sx={{
          background:
            userInfo?.subscription?.status === 'freemium'
              ? 'unset !important'
              : checkifConvoIsRead()
              ? '#EAF1E3'
              : 'white',
          filter: userInfo?.subscription?.status === 'freemium' ? 'blur(5px)' : '',
        }}
      >
        <Avatar src={user?.image} alt="user" />
        {checkifConvoIsRead() ? (
          <NameSection>
            <NameSectionUnRead>
              {getFullName(user)}
              <Time sx={{ color: '#3C6B49' }}>
                {dateToFromNowDaily(res?.latestMessage?.createdAt)}
              </Time>
            </NameSectionUnRead>
            <Message sx={{ color: '#101B10' }}>{res?.latestMessage?.message}</Message>
          </NameSection>
        ) : (
          <NameSection>
            <NameSectionRead>
              {getFullName(user)}
              <Time>{dateToFromNowDaily(res?.latestMessage?.createdAt)}</Time>
            </NameSectionRead>
            <Message>{res?.latestMessage?.message}</Message>
          </NameSection>
        )}
      </MessageBox>
    );
  }
};

const Inbox = ({ getInbox, handleParentChange }) => {
  const dispatch = useDispatch();

  const { inbox = [] } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(
    () => {
      setData(inbox);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    var initState = true;
    const chatroomsRef = collection(db, 'chatrooms');
    const userQuery = query(chatroomsRef, where(`user.${userInfo?._id}`, '==', true));

    const observer = onSnapshot(userQuery, async (querySnapshot) => {
      if (initState) {
        initState = false;
      } else {
        // FireStore.getAllCurrentUserRooms(userInfo?._id, () => { }, "");
        const inbox = await FireStore.getAllCurrentUserRooms(userInfo?._id);
        dispatch(saveData({ name: 'inbox', data: inbox }));
      }
    });
    return () => observer();
  }, []);

  const { userInfo } = useSelector((state) => state.auth);

  const handleFilter = (event, query) => {
    const searchWord = query;
    const newFilter = inbox?.filter((value) => {
      return (
        value?.userId1?.firstName?.toLowerCase().includes(searchWord.toLowerCase()) ||
        value?.userId2?.firstName?.toLowerCase().includes(searchWord.toLowerCase())
      );
    });
    setData([...newFilter]);
    if (searchWord === '') {
      setData(inbox);
    } else {
      setData(newFilter);
    }
  };
  const handleSort = (type) => {
    let newFilter;
    if (type === 'Date sent') {
      setSelectedIndex(1);
      newFilter = [...inbox]?.sort((a, b) => {
        return new Date(b?.createdAt) - new Date(a?.createdAt);
      });
      setData(newFilter);
    } else if (type === 'Date received') {
      setSelectedIndex(2);
      newFilter = [...inbox]?.sort((a, b) => {
        return new Date(a?.createdAt) - new Date(b?.createdAt);
      });
      setData(newFilter);
    } else if (type === 'Has attachments') {
      setSelectedIndex(3);
      newFilter = inbox?.filter((value) => {
        return value?.latestMessage?.image?.length > 0;
      });
      setData(newFilter);
    } else if (type === 'Unread') {
      setSelectedIndex(0);
      newFilter = inbox?.filter((value) => {
        return (
          value?.latestMessage?.isRead === false &&
          value?.latestMessage?.user?._id !== userInfo?._id
        );
      });
      setData(newFilter);
    }
  };

  const getUnReadCount = () => {
    return inbox?.filter((value) => {
      return (
        value?.latestMessage?.isRead === false && value?.latestMessage?.user?._id !== userInfo?._id
      );
    }).length;
  };
  const matches700 = useMediaQuery('(max-width:700px)');

  const options = [
    { name: 'Unread', handleClick: handleSort },
    { name: 'Date sent', handleClick: handleSort },
    { name: 'Date received', handleClick: handleSort },
  ];

  return (
    <LeftGrid item xs={12} sm={matches700 ? 12 : 5} md={4} lg={4}>
      <UserName sx={{ px: 3, pt: 2 }}>Inbox</UserName>
      <Grid sx={{ px: 2, py: 1 }}>
        <SearchInput
          icon={search}
          handleSearch={handleFilter}
          handleChange={handleFilter}
          isEndAdornment={false}
        />
      </Grid>
      {inbox?.length > 0 && (
        <CountName sx={{ px: 2.6, pt: 2, pb: 1 }}>
          {data?.length} message(s), {getUnReadCount()} unread
          <img src={sort} alt="sort" onClick={handleClick} />
        </CountName>
      )}
      {data.length > 0 && userInfo?.subscription?.status === 'freemium' ? (
        <Link
          style={{
            position: 'relative',
            color: 'black',
            fontWeight: 900,
            zIndex: '999999',
            top: '5rem',
            marginLeft: '1rem',
          }}
          to="/freePlan"
        >
          Subscribe to view conversations
        </Link>
      ) : (
        ''
      )}
      {data?.map((res, i) => {
        return (
          <CustomMessageBox
            getInbox={getInbox}
            handleParentChange={handleParentChange}
            res={res}
            key={i}
          />
        );
      })}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {options?.map((res, index) => {
          return (
            <MenuItem
              sx={{ fontFamily: 'Poppins-Regular', fontSize: 'small' }}
              selected={index === selectedIndex}
              onClick={() => res?.handleClick(res.name)}
            >
              <ListItemIcon>
                {index === selectedIndex && <Check sx={{ color: '#3C6B49', mr: 2 }} />}
              </ListItemIcon>
              <ListItemText>{res?.name}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </LeftGrid>
  );
};
export default Inbox;
