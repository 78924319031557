import { makeStyles } from '@mui/styles';

export const textFieldStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            fontWeight: 500,
            backgroundColor: '#FFFFFF',
            color: 'black',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: "Poppins-Regular !important",
            padding: "13px 10px",
            fontSize: '.8rem',
            color: "#1E3625",

            '@media(max-width: 600px)': {
                paddingTop: "9px !important",
                paddingBottom: "9px !important",
            },
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
            fontWeight: 500
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
export const promoFieldStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderBottomLeftRadius: '8px',
            borderTopLeftRadius: '8px',
            fontWeight: 500,
            backgroundColor: '#FFFFFF',
            color: 'black',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: "Poppins-Regular !important",
            padding: "10px",
            fontSize: '.8rem',
            color: "#1E3625",
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
            fontWeight: 500
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
export const pinCodeFieldStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            fontWeight: 500,
            color: 'black',
            backgroundColor: 'white'
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: "Poppins-Regular !important",
            padding: "10px",
            height: 40,
            zIndex: 20,
            fontSize: 'large',
            color: "#1E3625",
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
            fontWeight: 500
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
export const textField1Styles = makeStyles({
    root: {
        "& .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root": { padding: 10 },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            fontWeight: 500,
            backgroundColor: '#FFFFFF',
            color: 'black',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: "Poppins-Regular !important",
            fontSize: '.8rem',
            color: "#1E3625",
            padding: "0px",
            "&::placeholder": {    // <----- Add this.
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
            fontWeight: 500
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
export const dropdownStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '10px',
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",

        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            color: "#1E3625",
            height: '12px',
            padding: "10px",
            fontFamily: "Poppins-Regular !important",
            "&::placeholder": {
                fontSize: 'medium',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        }
    }
});
export const customDropDownStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '10px',
            padding:"9px 0px !important"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",

        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-root":{
            padding:"9px 0px !important"
        },
        "& .MuiOutlinedInput-input": {
            color: "#1E3625",
            height: '12px',
            padding: "10px",
            fontFamily: "Poppins-Regular !important",
            paddingLeft:"9px 0px !important",
            "&::placeholder": {
                fontSize: '14px',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        }
    },
});
export const daysdropdownStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            fontFamily: 'Poppins-Regular'
        },
        ".MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root]": {
            background: "green"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            color: "#1E3625",
            height: '14px',
            paddingLeft: "0px !important",
            '@media(max-width: 600px)': {
                height: '15px',
                paddingTop: "3px !important",
                paddingBottom: "3px !important",
            },

            "&::placeholder": {    // <----- Add this.
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
                '@media(max-width: 6000px)': {
                },
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        }
    }
});
export const dateStyles = makeStyles({
    root: {
        width: '100%',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            color: 'black',
            width: '100%',
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        // "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root":{
        //     paddingRight:0,
        // },
        "& .MuiOutlinedInput-input": {
            fontFamily: "Poppins-Regular !important",
            padding: "10px",
            color: "#1E3625",
            fontSize: '.8rem',
            width: '100%',
            height: 25,
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
export const birthdateStyles = makeStyles({
    root: {
        // width: '90%',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            color: 'black',
            // width: '90%',
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root":{
            // paddingRight:0,
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: "Poppins-Regular !important",
            padding: "10px",
            color: "#1E3625",
            fontSize: '.8rem',
            width: '100%',
            height: 25,
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
export const messageInputStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F2F3F1",
            borderRadius: 100,
            height: '44px',
            margin: 'auto',
            backgroundColor: '#F2F3F1',
            color: 'black',
            width: '100%'
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: 'Poppins-Regular',
            color: "#1E3625",
            fontSize: '.8rem',
            paddingLeft: 20,
            paddingRight: 20,
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins-Regular',
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});