import React from 'react';

import { Box, Modal } from '@mui/material';
import { style } from "./Elements"
import { CenterHeader } from './ModalHeader';
import { getFullName } from '../../utills/constants';
const PhotoModal = ({ data, open, handle }) => {
    const handleClose = () => handle('openPhoto', false);

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: 550, height: 'max-content', py: 2, px: 2, alignItems: 'flex-start' }}>
                    <CenterHeader heading={getFullName(data)} handleClose={handleClose} />

                    <div style={{ width: "500px", height: "420px", marginLeft: 'auto', marginRight: 'auto',paddingTop:10 }}>
                        <img src={data?.image} style={{ width: '100%', height: '100%' }} />
                    </div>
                </Box>
            </Modal>

        </div >
    );
}

export default PhotoModal