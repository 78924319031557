import React from 'react';
import * as yup from 'yup';

import { Back } from './Elements';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MainBox, UserName } from '../Elements';
import { useMediaQuery, Grid } from '@mui/material';
import { FormBox, Edit } from '../Profile/Elements';
import { logout } from '../../features/auth/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { forgetPassword } from '../../features/auth/action';
import { InputRightIcon } from '../../components/InputFields';

import Sidebar from '../../components/Sidebar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const schema = yup.object().shape({
  curr_pass: yup.string(),
  newPassword: yup
    .string()
    .required('Please enter a password.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'New Password must match with the Confirm Password'),
});

const ChangePassword = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutUser = () => {
    dispatch(logout());
    navigate('/signin');
  };

  const submitFunction = async (formdata) => {
    formdata['email'] = userInfo?.email;
    formdata['url'] = 'changePassword';
    dispatch(forgetPassword(formdata, logoutUser));
  };

  const matches600 = useMediaQuery('(max-width:600px)');
  return (
    <form onSubmit={handleSubmit(submitFunction)}>
      <MainBox sx={{ marginTop: matches600 ? 6 : 0, height: '100vh' }}>
        <Sidebar />
        <FormBox sx={{ height: 'max-content' }}>
          <Back sx={{ mt: 1 }} onClick={() => navigate('/settings')}>
            <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
            Back to Settings
          </Back>

          <UserName sx={{ fontSize: '18px', mb: 3, mt: 1, ml: 1 }}>Change Password</UserName>
          <Grid container spacing={matches600 ? 0 : 3}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: matches600 ? 1 : 0, ml: 1 }}>
              <InputRightIcon
                type="password"
                title="Current Password"
                error={errors.curr_pass?.message}
                register={register}
                registerFor="curr_pass"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: matches600 ? 1 : 0, ml: 1 }}>
              <InputRightIcon
                type="password"
                title="New Password"
                error={errors.newPassword?.message}
                register={register}
                registerFor="newPassword"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ my: matches600 ? 1 : 0, ml: 1 }}>
              <InputRightIcon
                type="password"
                title="Confirm Password"
                error={errors.confirmPassword?.message}
                register={register}
                registerFor="confirmPassword"
              />
            </Grid>
          </Grid>

          <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 4, mb: 1, ml: 1 }}>
            <Edit type="submit" variant="contained" disableripple sx={{ px: { lg: 4 }, py: 2.4 }}>
              Save Password
            </Edit>
          </Grid>
        </FormBox>
      </MainBox>
    </form>
  );
};
export default ChangePassword;
