import React, { useEffect } from 'react';
import Footer from '../../components/Footer';
import MiscHeader from '../../components/Header/MiscHeader';

import { Grid, Box, Typography } from '@mui/material';
import {
  LargeHeading,
  Orange,
  Black,
  DefinationHeading,
  MainHeading,
  SideHeading,
  SmallHeading,
  Text,
} from './Elements';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MiscHeader />
      <Grid container sx={{ px: 5, width: '100%', background: '#F3F1EE', display: 'flow-root' }}>
        <MainHeading>
          <Black sx={{ mr: 1 }}>Privacy</Black> <Orange>Policy</Orange>
        </MainHeading>
        {/* <Text>
          This Privacy Policy describes Our policies and procedures on the collection, use and
          disclosure of Your information when You use the Service and tells You about Your privacy
          rights and how the law protects You.{' '}
        </Text> */}
        {/*       
        <Text>
          We use Your Personal data to provide and improve the Service. By using the Service, You
          agree to the collection and use of information in accordance with this Privacy Policy.{' '}
        </Text> */}
        {/* introduction */}
        <DefinationHeading>Last Revised: October 18, 2023</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>1. Introduction</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading sx={{ my: 0.5 }}>1.1. About Trip Trader</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          At Trip Trader, we are deeply committed to safeguarding the privacy and security of your
          personal data. This Privacy Policy (“Policy”) has been prepared to provide you with a
          clear understanding of how we collect, utilize, and share your personal data.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          Trip Trader, referred to as “Trip Trader,” “we,” “us,” or “our” throughout this Policy,
          serves as the data controller within the context of data protection laws applicable in the
          United States and other relevant local data protection regulations. Our objective is to
          ensure that your personal data is processed in strict compliance with these regulations.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading sx={{ my: 0.5 }}>1.2. Consent and Agreement</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          By using or accessing our services (the “Service”), you signify your unequivocal consent
          to adhere to the terms and conditions articulated within this Privacy Policy.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading sx={{ my: 0.5 }}>1.3. Updates to the Privacy Policy</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          It is important to note that we may update this Privacy Policy periodically to reflect
          changes in our information practices. In cases where these changes are substantial, we
          will provide conspicuous notice of such amendments on our website for a minimum period of
          7 days before they become effective. The “Last Revised” date located at the end of this
          Policy indicates the most recent revision date. Your continued use of our Service after
          the stated notice period constitutes your acknowledgment and acceptance of these
          revisions.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading sx={{ my: 0.5 }}>1.4. Data Controller</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          As the data controller, Trip Trader is responsible for ensuring that all data processing
          activities associated with the Service adhere to the data protection laws applicable in
          the United States and any other relevant Data Protection Laws. This Privacy Policy
          outlines your rights and obligations concerning your personal data and its processing
          within the scope of the Service.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading sx={{ my: 0.5 }}>1.5. Definitions</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          The following definitions are essential for the understanding of this Privacy Policy:
        </Text>
        <Text>
          <div style={{ height: '20px' }}></div>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            Personal Data:
          </span>
          Any information that relates to an identified or identifiable natural person, hereafter
          referred to as the “Data Subject.” An identifiable natural person is someone who can be
          directly or indirectly identified, in particular by reference to an identifier such as a
          name, an identification number, location data, an online identifier, or to one or more
          factors specific to the physical, physiological, genetic, mental, economic, cultural, or
          social identity of that natural person.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            Data Controller:
          </span>
          The entity responsible for determining the purposes, conditions, and means of processing
          personal data.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            Data Processor:
          </span>
          An entity that processes personal data on behalf of the Data Controller.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            Consent:
          </span>
          The Data Subject's unambiguous indication of their wishes by which they, by a statement or
          by a clear affirmative action, signify their agreement to the processing of personal data
          relating to them.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            Data Protection Laws:
          </span>
          Refers to the data protection laws applicable in the United States and any other relevant
          data protection legislation.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>1.6. Contact Information</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          For any questions, concerns, or requests related to this Privacy Policy or your personal
          data, please contact us at:
          <div style={{ height: '20px' }}></div>
          <Text
            sx={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            Email: support@trip-trader.com
          </Text>
          <div style={{ height: '20px' }}></div>
        </Text>
        <DefinationHeading>2. Information We Collect</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>2.1. Information You Provide</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          As a user of the Trip Trader Service, you may be required to provide certain personal
          information for account registration and profile management. The data we collect includes:
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Name:
          </span>
          Your full name is essential for personal identification and account management.
        </Text>
        <div style={{ height: '20px' }}></div>

        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Email:
          </span>
          We collect your email address for communication purposes, such as notifying you about your
          account, transactions, and for delivering important Service-related updates.
        </Text>
        <div style={{ height: '20px' }}></div>

        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Phone Number:
          </span>
          To ensure the security of your account and transactions, we may collect your phone number
          to use for one-time password (OTP) verification.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Date of Birth:
          </span>
          We request your date of birth to confirm that you are of legal age to use our Service. The
          Service is intended for individuals aged 18 years and older.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>2.2. Device Permissions</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          Our Service may require certain device permissions to offer a seamless and secure
          experience. These permissions include access to your camera and gallery. Camera access may
          be required for functions such as uploading a profile picture. Gallery access may be used
          to select and upload images from your device
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>2.3. Third-Party Services</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>Trip Trader may utilize third-party services to enhance your user experience:</Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            OTP Verification:
          </span>
          We use a trusted third-party service, to facilitate OTP verification during the
          registration process. This is done to ensure the security of your account.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>2.4. Payments</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          When making purchases on our Service, your payment and card information may be collected.
          We take great care to ensure the security of this data. Please note that Trip Trader does
          not store your payment information. We rely on reputable payment service providers to
          process your payments securely.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>2.5. Cookies and Tracking Technologies</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          Trip Trader does not utilize cookies or other tracking technologies. Your privacy is a top
          priority, and we do not engage in tracking your online activities.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>2.6. Personal Data Usage</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>The personal data collected is primarily used for:</Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Account Creation and Profile Management:
          </span>
          Your information is essential for setting up and managing your account, ensuring a
          personalized user experience.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - OTP Verification:
          </span>
          To enhance the security of your account, we use your phone number for OTP verification.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Communication:
          </span>
          Your email address is used to contact you with important account-related updates and
          information.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>3. Use and Disclosure of Personal Information</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>3.1. Use of Personal Information</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          Trip Trader may use the personal information we collect for the following business or
          commercial purposes (as defined under the applicable data protection regulations,
          including the California Consumer Privacy Act (CCPA)):
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Operation of the Service:{' '}
          </span>
          We use your personal information to operate and provide you with our Service. This
          includes enabling you to trade outdoor recreational opportunities with other users.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Customer Support:
          </span>
          Your personal information is utilized to provide customer support and address your
          inquiries, concerns, or technical issues. We may also use it for monitoring and enhancing
          our Service to ensure a seamless user experience.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Fulfillment of Requests:
          </span>
          If you provide us with your personal information for a specific reason, such as asking a
          question about our Service or making a purchase, we use this information to fulfill your
          request, process payments, and facilitate deliveries.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Compliance with Legal Obligations:
          </span>
          We may need to respond to law enforcement requests, comply with court orders, or adhere to
          governmental regulations as required by applicable law. Your data may be used to address
          legal obligations.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Internal Administration:
          </span>
          We may utilize personal information for internal administrative purposes, such as
          record-keeping, auditing, and data analysis.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>3.2. Disclosure of Personal Information</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          Trip Trader does not sell your personal information. We do not engage in the sale, rental,
          release, disclosure, or dissemination of your personal data to third parties for valuable
          consideration, as defined by the CCPA and other relevant data protection laws.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>3.3. Sharing of Personal Information</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          We may share your personal information, as identified in the previous sections, with the
          following categories of third parties:
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Service Providers:
          </span>
          Third-party service providers who assist us in delivering the Service, which may include
          but is not limited to, payment processors, app hosting, and customer support providers.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Affiliates:
          </span>
          Partners and affiliates that assist us in offering the Service.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Business Partners:
          </span>
          Entities we collaborate with to provide you with products and services.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            - Third Party Vendors:
          </span>
          In certain cases, you may authorize us to disclose your personal information to specific
          third-party vendors in connection with products or services offered through our platform.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>3.4. Sale of Personal Information of Minors</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          Trip Trader does not knowingly collect personal information from minors under the age of
          17. While certain third-party websites we link to may do so, we encourage parents and
          legal guardians to monitor their children's internet usage and instruct their children
          never to provide information on other websites without their permission.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          We do not sell the personal information of consumers we actually know are less than 17
          years of age, unless we receive affirmative authorization (the "right to opt-in") from
          either the consumer between 13 and 17 years of age or the parent or guardian of a consumer
          less than 13 years of age.
        </Text>
        <div style={{ height: '20px' }}></div>
        <DefinationHeading>4. Notification of Changes to Privacy Policy</DefinationHeading>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            4.1.
          </span>
          We are committed to keeping you informed about any changes to this Privacy Policy. Your
          privacy is of utmost importance to us, and we strive to ensure transparency.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            4.2.
          </span>
          When we make material changes to our Privacy Policy, we will notify you through in-app
          notifications and, if you have opted in, via push notifications.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            4.3.
          </span>
          We will also post the revised Privacy Policy on our website. We recommend checking the
          Privacy Policy regularly for updates to stay informed about how we process and protect
          your personal information.
        </Text>
        <div style={{ height: '20px' }}></div>
        <Text>
          <span
            style={{
              fontFamily: 'Poppins-SemiBold',
              color: '#313131',
              fontSize: '15px',
            }}
          >
            4.4.
          </span>
          By continuing to use our Service after such changes have been made, you acknowledge and
          agree to the updated Privacy Policy. If you do not agree with the changes, you should stop
          using our Service and contact us if you have concerns.
        </Text>
        <div style={{ height: '50px' }}></div>
        <Text>
          Please don't hesitate to reach out to us at support@trip-trader.com if you have any
          questions or need further assistance regarding your privacy rights or this Privacy Policy.
        </Text>
        <div style={{ height: '50px' }}></div>
        <Text>
          Thank you for your attention to this Privacy Policy, which is designed to safeguard your
          personal information while providing you with the best experience when using our service.
        </Text>
        <div style={{ height: '200px' }}></div>
        {/* <Grid item>
          <br />
          <LargeHeading sx={{ my: 1 }}>Interpretation and Definitions </LargeHeading>
          <DefinationHeading sx={{ my: 0.5 }}>Interpretation</DefinationHeading>
          <Text>
            The words of which the initial letter is capitalized have meanings defined under the
            following conditions. The following definitions shall have the same meaning regardless
            of whether they appear in singular or in plural.{' '}
          </Text>
          <DefinationHeading sx={{ my: 0.5 }}>Definitions</DefinationHeading>
          <Text>For the purposes of this Privacy Policy: </Text>
          <Grid container>
            <Grid item>
              <Text>
                <b>Account</b> means a unique account created for You to access our Service or parts
                of our Service.{' '}
              </Text>

              <Text>
                <b>Affiliate</b> means an entity that controls, is controlled by or is under common
                control with a party, where "control" means ownership of 50% or more of the shares,
                equity interest or other securities entitled to vote for election of directors or
                other managing authority.
              </Text>

              <Text>
                <b>Application</b> refers to Trip Trader, the software program provided by the
                Company.
              </Text>

              <Text>
                <b>Business</b>, for the purpose of the CCPA (California Consumer Privacy Act),
                refers to the Company as the legal entity that collects Consumers' personal
                information and determines the purposes and means of the processing of Consumers'
                personal information, or on behalf of which such information is collected and that
                alone, or jointly with others, determines the purposes and means of the processing
                of consumers' personal information, that does business in the State of California.
              </Text>

              <Text>
                <b>Company </b>(referred to as either "the Company", "We", "Us" or "Our" in this
                Agreement) refers to Trip Trader LLC, 12650 County Road 49 Live Oak Florida 23505.
              </Text>

              <Text>
                <b>Consumer </b>, for the purpose of the CCPA (California Consumer Privacy Act),
                means a natural person who is a California resident. A resident, as defined in the
                law, includes (1) every individual who is in the USA for other than a temporary or
                transitory purpose, and (2) every individual who is domiciled in the USA who is
                outside the USA for a temporary or transitory purpose.
              </Text>

              <Text>
                <b>Cookies </b>are small files that are placed on Your computer, mobile device or
                any other device by a website, containing the details of Your browsing history on
                that website among its many uses.
              </Text>

              <Text>
                <b>Country</b> refers to: Florida, United States
              </Text>

              <Text>
                <b> Device </b>means any device that can access the Service such as a computer, a
                cellphone or a digital tablet.
              </Text>

              <Text>
                {' '}
                <b>Do Not Track</b> (DNT) is a concept that has been promoted by US regulatory
                authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                industry to develop and implement a mechanism for allowing internet users to control
                the tracking of their online activities across websites.
              </Text>

              <Text>
                <b> Personal Data </b>is any information that relates to an identified or
                identifiable individual.
              </Text>

              <Text>
                For the purposes of the CCPA, Personal Data means any information that identifies,
                relates to, describes or is capable of being associated with, or could reasonably be
                linked, directly or indirectly, with You.
              </Text>

              <Text>
                <b>Sale</b>, for the purpose of the CCPA (California Consumer Privacy Act), means
                selling, renting, releasing, disclosing, disseminating, making available,
                transferring, or otherwise communicating orally, in writing, or by electronic or
                other means, a Consumer's personal information to another business or a third party
                for monetary or other valuable consideration.
              </Text>

              <Text>
                <b>Service</b> refers to the Application or the Website or both.
              </Text>

              <Text>
                <b>Service Provider</b> means any natural or legal person who processes the data on
                behalf of the Company. It refers to third-party companies or individuals employed by
                the Company to facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service or to assist the Company in
                analyzing how the Service is used.
              </Text>

              <Text>
                <b>Third-party Social Media Service</b> refers to any website or any social network
                website through which a User can log in or create an account to use the Service.
              </Text>

              <Text>
                <b>Usage Data </b>refers to data collected automatically, either generated by the
                use of the Service or from the Service infrastructure itself (for example, the
                duration of a page visit).
              </Text>

              <Text>
                <b>Website</b> refers to Trip Trader, accessible from www.trip-trader.com
              </Text>

              <Text>
                <b>You </b>means the individual accessing or using the Service, or the company, or
                other legal entity on behalf of which such individual is accessing or using the
                Service, as applicable.{' '}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>Collecting and Using Your Personal Data </LargeHeading>
          <DefinationHeading>Types of Data Collected </DefinationHeading>
          <DefinationHeading>Personal Data </DefinationHeading>
          <Text>
            While using Our Service, We may ask You to provide Us with certain personally
            identifiable information that can be used to contact or identify You. Personally
            identifiable information may include, but is not limited to:{' '}
          </Text>
          <ul>
            {[
              'Email address',
              'First name and last name',
              'Phone number',
              'Address, State, Province, ZIP/Postal code, City',
              'Usage Data',
            ].map((res) => {
              return (
                <li>
                  <Text>{res} </Text>
                </li>
              );
            })}
          </ul>
          <DefinationHeading>Usage Data </DefinationHeading>
          <Text>Usage Data is collected automatically when using the Service.</Text>
          <br />
          <Text>
            Usage Data may include information such as Your Device's Internet Protocol address (e.g.
            IP address), browser type, browser version, the pages of our Service that You visit, the
            time and date of Your visit, the time spent on those pages, unique device identifiers
            and other diagnostic data.{' '}
          </Text>
          <br />
          <Text>
            When You access the Service by or through a mobile device, We may collect certain
            information automatically, including, but not limited to, the type of mobile device You
            use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data.{' '}
          </Text>
          <br />
          <Text>
            We may also collect information that Your browser sends whenever You visit our Service
            or when You access the Service by or through a mobile device.{' '}
          </Text>
          <br />
          <DefinationHeading>Information from Third-Party Social Media Services </DefinationHeading>
          <Text>
            The Company allows You to create an account and log in to use the Service through the
            following Third-party Social Media Services:{' '}
          </Text>
          <ul>
            {['Google', 'Facebook', 'Twitter', 'LinkedIn'].map((res) => {
              return (
                <li>
                  <Text>{res} </Text>
                </li>
              );
            })}
          </ul>
          <Text>
            If You decide to register through or otherwise grant us access to a Third-Party Social
            Media Service, We may collect Personal data that is already associated with Your
            Third-Party Social Media Service's account, such as Your name, Your email address, Your
            activities or Your contact list associated with that account.{' '}
          </Text>
          <br />
          <Text>
            You may also have the option of sharing additional information with the Company through
            Your Third-Party Social Media Service's account. If You choose to provide such
            information and Personal Data, during registration or otherwise, You are giving the
            Company permission to use, share, and store it in a manner consistent with this Privacy
            Policy.{' '}
          </Text>
          <br />
          <DefinationHeading>Information Collected while Using the Application </DefinationHeading>
          <Text>
            While using Our Application, in order to provide features of Our Application, We may
            collect, with Your prior permission:{' '}
          </Text>
          <ul>
            {[
              'Information regarding your location',
              `Information from your Device's phone book (contacts list)`,
              `Pictures and other information from your Device's camera and photo library`,
            ].map((res) => {
              return (
                <li>
                  <Text>{res} </Text>
                </li>
              );
            })}
          </ul>
          <Text>
            We use this information to provide features of Our Service, to improve and customize Our
            Service. The information may be uploaded to the Company's servers and/or a Service
            Provider's server or it may be simply stored on Your device.{' '}
          </Text>
          <br />
          <Text>
            You can enable or disable access to this information at any time, through Your Device
            settings.{' '}
          </Text>
          <br />
          <DefinationHeading>Tracking Technologies and Cookies </DefinationHeading>
          <Text>
            We use Cookies and similar tracking technologies to track the activity on Our Service
            and store certain information. Tracking technologies used are beacons, tags, and scripts
            to collect and track information and to improve and analyze Our Service. The
            technologies We use may include:{' '}
          </Text>
          <ul>
            <li>
              <Text>
                <b>Cookies or Browser Cookies.</b> A cookie is a small file placed on Your Device.
                You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is
                being sent. However, if You do not accept Cookies, You may not be able to use some
                parts of our Service. Unless you have adjusted Your browser setting so that it will
                refuse Cookies, our Service may use Cookies.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>Web Beacons.</b> Certain sections of our Service and our emails may contain small
                electronic files known as web beacons (also referred to as clear gifs, pixel tags,
                and single-pixel gifs) that permit the Company, for example, to count users who have
                visited those pages or opened an email and for other related website statistics (for
                example, recording the popularity of a certain section and verifying system and
                server integrity).{' '}
              </Text>
            </li>
          </ul>
          <Text>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your
            personal computer or mobile device when You go offline, while Session Cookies are
            deleted as soon as You close Your web browser. Learn more about cookies on the Free
            Privacy Policy website article.{' '}
          </Text>{' '}
          <br />
          <Text>We use both Session and Persistent Cookies for the purposes set out below: </Text>
          <ul style={{ listStyleType: 'none' }}>
            <li>
              <Text>
                <b>Necessary / Essential Cookies</b>
              </Text>
            </li>
            <li>
              <Text>Type: Session Cookies</Text>
            </li>
            <li>
              <Text>Administered by: Us</Text>
            </li>
            <li>
              <Text>
                Purpose: These Cookies are essential to provide You with services available through
                the Website and to enable You to use some of its features. They help to authenticate
                users and prevent fraudulent use of user accounts. Without these Cookies, the
                services that You have asked for cannot be provided, and We only use these Cookies
                to provide You with those services.
              </Text>
            </li>
            <br />
            <li>
              <Text>
                <b>Cookies Policy / Notice Acceptance Cookies </b>
              </Text>
            </li>
            <li>
              <Text>Type: Persistent Cookies</Text>
            </li>
            <li>
              <Text>Administered by: Us</Text>
            </li>
            <br />

            <li>
              <Text>
                <b>Functionality Cookies </b>
              </Text>
            </li>
            <li>
              <Text>Type: Persistent Cookies</Text>
            </li>
            <li>
              <Text>Administered by: Us</Text>
            </li>
            <li>
              <Text>
                Purpose: These Cookies allow us to remember choices You make when You use the
                Website, such as remembering your login details or language preference. The purpose
                of these Cookies is to provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the Website.
              </Text>
            </li>
            <br />

            <li>
              <Text>
                <b>Tracking and Performance Cookies</b>
              </Text>
            </li>
            <li>
              <Text>Type: Persistent Cookies</Text>{' '}
            </li>
            <li>
              <Text>Administered by: Third-Parties</Text>{' '}
            </li>
            <li>
              <Text>
                Purpose: These Cookies are used to track information about traffic to the Website
                and how users use the Website. The information gathered via these Cookies may
                directly or indirectly identify you as an individual visitor. This is because the
                information collected is typically linked to a pseudonymous identifier associated
                with the device you use to access the Website. We may also use these Cookies to test
                new pages, features or new functionality of the Website to see how our users react
                to them.{' '}
              </Text>{' '}
            </li>
            <br />

            <li>
              <Text>
                <b>Targeting and Advertising Cookies </b>
              </Text>
            </li>
            <li>
              <Text>Type: Persistent Cookies</Text>{' '}
            </li>
            <li>
              <Text>Administered by: Third-Parties</Text>{' '}
            </li>
            <li>
              <Text>
                Purpose: These Cookies track your browsing habits to enable Us to show advertising
                which is more likely to be of interest to You. These Cookies use information about
                your browsing history to group You with other users who have similar interests.
                Based on that information, and with Our permission, third party advertisers can
                place Cookies to enable them to show adverts which We think will be relevant to your
                interests while You are on third party websites.{' '}
              </Text>{' '}
            </li>
          </ul>
          <Text>
            For more information about the cookies we use and your choices regarding cookies, please
            visit our Cookies Policy or the Cookies section of our Privacy Policy.{' '}
          </Text>
          <br />
          <DefinationHeading>Use of Your Personal Data </DefinationHeading>
          <Text>The Company may use Personal Data for the following purposes: </Text>
          <ul>
            <li>
              <Text>
                <b>To provide and maintain our Service</b>, including to monitor the usage of our
                Service.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>To manage Your Account:</b> to manage Your registration as a user of the Service.
                The Personal Data You provide can give You access to different functionalities of
                the Service that are available to You as a registered user.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>For the performance of a contract:</b> the development, compliance and
                undertaking of the purchase contract for the products, items or services You have
                purchased or of any other contract with Us through the Service.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>To contact You:</b> To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile application's push
                notifications regarding updates or informative communications related to the
                functionalities, products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>To provide You:</b> with news, special offers and general information about other
                goods, services and events which we offer that are similar to those that you have
                already purchased or enquired about unless You have opted not to receive such
                information.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>To manage Your requests:</b> To attend and manage Your requests to Us.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>To deliver targeted advertising to You:</b> We may use Your information to
                develop and display content and advertising (and work with third-party vendors who
                do so) tailored to Your interests and/or location and to measure its effectiveness.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>For business transfers: </b> We may use Your information to evaluate or conduct a
                merger, divestiture, restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us
                about our Service users is among the assets transferred.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>For other purposes: </b> We may use Your information for other purposes, such as
                data analysis, identifying usage trends, determining the effectiveness of our
                promotional campaigns and to evaluate and improve our Service, products, services,
                marketing and your experience.{' '}
              </Text>
            </li>
            <br />
            <Text>We may share Your personal information in the following situations: </Text>
            <br />
            <li>
              <Text>
                <b>With Service Providers: </b> We may share Your personal information with Service
                Providers to monitor and analyze the use of our Service, to show advertisements to
                You to help support and maintain Our Service, to advertise on third party websites
                to You after You visited our Service, for payment processing, to contact You.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>For business transfers: </b> We may share or transfer Your personal information
                in connection with, or during negotiations of, any merger, sale of Company assets,
                financing, or acquisition of all or a portion of Our business to another company.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>With Affiliates: </b> We may share Your information with Our affiliates, in which
                case we will require those affiliates to honor this Privacy Policy. Affiliates
                include Our parent company and any other subsidiaries, joint venture partners or
                other companies that We control or that are under common control with Us.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>With other users: </b> when You share personal information or otherwise interact
                in the public areas with other users, such information may be viewed by all users
                and may be publicly distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your contacts on the
                Third-Party Social Media Service may see Your name, profile, pictures and
                description of Your activity. Similarly, other users will be able to view
                descriptions of Your activity, communicate with You and view Your profile.{' '}
              </Text>
            </li>
            <li>
              <Text>
                <b>With Your consent: </b> We may disclose Your personal information for any other
                purpose with Your consent.{' '}
              </Text>
            </li>
          </ul>
          <br />
          <DefinationHeading sx={{ mb: 1 }}>Retention of Your Personal Data </DefinationHeading>
          <Text sx={{ mb: 1 }}>
            The Company will retain Your Personal Data only for as long as is necessary for the
            purposes set out in this Privacy Policy. We will retain and use Your Personal Data to
            the extent necessary to comply with our legal obligations (for example, if we are
            required to retain your data to comply with applicable laws), resolve disputes, and
            enforce our legal agreements and policies.{' '}
          </Text>
          <Text>
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this data is used to
            strengthen the security or to improve the functionality of Our Service, or We are
            legally obligated to retain this data for longer time periods.{' '}
          </Text>
          <br />
          <DefinationHeading sx={{ mb: 1 }}>Transfer of Your Personal Data </DefinationHeading>
          <Text sx={{ mb: 1 }}>
            Your information, including Personal Data, is processed at the Company's operating
            offices and in any other places where the parties involved in the processing are
            located. It means that this information may be transferred to — and maintained on —
            computers located outside of Your state, province, country or other governmental
            jurisdiction where the data protection laws may differ than those from Your
            jurisdiction.{' '}
          </Text>
          <Text sx={{ mb: 1 }}>
            Your consent to this Privacy Policy followed by Your submission of such information
            represents Your agreement to that transfer.{' '}
          </Text>
          <Text>
            The Company will take all steps reasonably necessary to ensure that Your data is treated
            securely and in accordance with this Privacy Policy and no transfer of Your Personal
            Data will take place to an organization or a country unless there are adequate controls
            in place including the security of Your data and other personal information.{' '}
          </Text>
          <br />
          <DefinationHeading sx={{ mb: 1 }}>Delete Your Personal Data </DefinationHeading>
          <Text sx={{ mb: 1 }}>
            You have the right to delete or request that We assist in deleting the Personal Data
            that We have collected about You.{' '}
          </Text>
          <Text sx={{ mb: 1 }}>
            Our Service may give You the ability to delete certain information about You from within
            the Service.{' '}
          </Text>
          <Text sx={{ mb: 1 }}>
            You may update, amend, or delete Your information at any time by signing in to Your
            Account, if you have one, and visiting the account settings section that allows you to
            manage Your personal information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.{' '}
          </Text>
          <Text>
            Please note, however, that We may need to retain certain information when we have a
            legal obligation or lawful basis to do so.{' '}
          </Text>
          <br />
          <DefinationHeading>Disclosure of Your Personal Data </DefinationHeading>
          <SideHeading sx={{ my: 0.8 }}>Business Transactions </SideHeading>
          <Text>
            If the Company is involved in a merger, acquisition or asset sale, Your Personal Data
            may be transferred. We will provide notice before Your Personal Data is transferred and
            becomes subject to a different Privacy Policy.{' '}
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Law enforcement </SideHeading>
          <Text>
            Under certain circumstances, the Company may be required to disclose Your Personal Data
            if required to do so by law or in response to valid requests by public authorities (e.g.
            a court or a government agency).{' '}
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Other legal requirements </SideHeading>
          <Text>
            The Company may disclose Your Personal Data in the good faith belief that such action is
            necessary to:{' '}
          </Text>
          <ul>
            {[
              'Comply with a legal obligation ',
              'Protect and defend the rights or property of the Company ',
              'Prevent or investigate possible wrongdoing in connection with the Service ',
              'Protect the personal safety of Users of the Service or the public ',
              'Protect against legal liability ',
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
          </ul>
          <SideHeading sx={{ my: 0.8 }}>Security of Your Personal Data </SideHeading>
          <Text>
            The security of Your Personal Data is important to Us, but remember that no method of
            transmission over the Internet, or method of electronic storage is 100% secure. While We
            strive to use commercially acceptable means to protect Your Personal Data, We cannot
            guarantee its absolute security.{' '}
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>
            Detailed Information on the Processing of Your Personal Data{' '}
          </LargeHeading>
          <Text>
            The Service Providers We use may have access to Your Personal Data. These third-party
            vendors collect, store, use, process and transfer information about Your activity on Our
            Service in accordance with their Privacy Policies.{' '}
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Analytics </SideHeading>
          <Text>
            We may use third-party Service providers to monitor and analyze the use of our Service.
          </Text>
          <br />
          <Text>
            <b>Google Analytics</b>{' '}
          </Text>
          <Text>
            Google Analytics is a web analytics service offered by Google that tracks and reports
            website traffic. Google uses the data collected to track and monitor the use of our
            Service. This data is shared with other Google services. Google may use the collected
            data to contextualize and personalize the ads of its own advertising network.{' '}
          </Text>
          <Text>
            You can opt-out of having made your activity on the Service available to Google
            Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents
            the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information
            with Google Analytics about visits activity.{' '}
          </Text>
          <Text>
            You may opt-out of certain Google Analytics features through your mobile device
            settings, such as your device advertising settings or by following the instructions
            provided by Google in their Privacy Policy: https://policies.google.com/privacy{' '}
          </Text>
          <Text>
            For more information on the privacy practices of Google, please visit the Google Privacy
            & Terms web page: https://policies.google.com/privacy{' '}
          </Text>
          <br />
          <Text>
            <b>Firebase </b>
          </Text>
          <Text>Firebase is an analytics service provided by Google Inc.</Text>
          <Text>
            You may opt-out of certain Firebase features through your mobile device settings, such
            as your device advertising settings or by following the instructions provided by Google
            in their Privacy Policy: https://policies.google.com/privacy{' '}
          </Text>
          <Text>
            We also encourage you to review the Google's policy for safeguarding your data:
            https://support.google.com/analytics/answer/6004245{' '}
          </Text>
          <Text>
            For more information on what type of information Firebase collects, please visit the How
            Google uses data when you use our partners' sites or apps webpage:
            https://policies.google.com/technologies/partner-sites{' '}
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Advertising </SideHeading>
          <Text>
            We may use Service Providers to show advertisements to You to help support and maintain
            Our Service.{' '}
          </Text>
          <br />
          <Text>
            <b>Google AdSense & DoubleClick Cookie </b>{' '}
          </Text>
          <Text>
            Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use
            of the DoubleClick cookie enables it and its partners to serve ads to our users based on
            their visit to our Service or other websites on the Internet.{' '}
          </Text>
          <Text>
            You may opt out of the use of the DoubleClick Cookie for interest-based advertising by
            visiting the Google Ads Settings web page: http://www.google.com/ads/preferences/{' '}
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Payments </SideHeading>
          <Text>
            We may provide paid products and/or services within the Service. In that case, we may
            use third-party services for payment processing (e.g. payment processors).{' '}
          </Text>
          <br />
          <Text>
            We will not store or collect Your payment card details. That information is provided
            directly to Our third-party payment processors whose use of Your personal information is
            governed by their Privacy Policy. These payment processors adhere to the standards set
            by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of
            brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help
            ensure the secure handling of payment information.{' '}
          </Text>
          <Text>
            <b>Stripe</b>
          </Text>
          <Text>Their Privacy Policy can be viewed at https://stripe.com/us/privacy </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Behavioral Remarketing </SideHeading>
          <Text>
            The Company uses remarketing services to advertise to You after You accessed or visited
            our Service. We and Our third-party vendors use cookies and non-cookie technologies to
            help Us recognize Your Device and understand how You use our Service so that We can
            improve our Service to reflect Your interests and serve You advertisements that are
            likely to be of more interest to You{' '}
          </Text>
          <br />
          <Text>
            These third-party vendors collect, store, use, process and transfer information about
            Your activity on Our Service in accordance with their Privacy Policies and to enable Us
            to:{' '}
          </Text>
          <ul>
            {[
              'Measure and analyze traffic and browsing activity on Our Service',
              'Show advertisements for our products and/or services to You on third-party websites or apps ',
              'Measure and analyze the performance of Our advertising campaigns ',
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
          </ul>
          <Text>
            Some of these third-party vendors may use non-cookie technologies that may not be
            impacted by browser settings that block cookies. Your browser may not permit You to
            block such technologies. You can use the following third-party tools to decline the
            collection and use of information for the purpose of serving You interest-based
            advertising:{' '}
          </Text>
          <ul>
            {[
              `The NAI's opt-out platform: http://www.networkadvertising.org/choices/`,
              `The EDAA's opt-out platform http://www.youronlinechoices.com/ `,
              `The DAA's opt-out platform: http://optout.aboutads.info/?c=2&lang=EN`,
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
          </ul>
          <Text>
            You may opt-out of all personalized advertising by enabling privacy features on Your
            mobile device such as Limit Ad Tracking (iOS) and Opt Out of Ads Personalization
            (Android). See Your mobile device Help system for more information.{' '}
          </Text>
          <br />
          <Text>
            We may share information, such as hashed email addresses (if available) or other online
            identifiers collected on Our Service with these third-party vendors. This allows Our
            third-party vendors to recognize and deliver You ads across devices and browsers. To
            read more about the technologies used by these third-party vendors and their
            cross-device capabilities please refer to the Privacy Policy of each vendor listed
            below.{' '}
          </Text>
          <br />
          <Text>The third-party vendors We use are: </Text>
          <br />
          <Text>
            <b>Google Ads (AdWords) </b>{' '}
          </Text>
          <Text>Google Ads (AdWords) remarketing service is provided by Google Inc. </Text>
          <Text>
            You can opt-out of Google Analytics for Display Advertising and customise the Google
            Display Network ads by visiting the Google Ads Settings page:
            http://www.google.com/settings/ads{' '}
          </Text>
          <Text>
            Google also recommends installing the Google Analytics Opt-out Browser Add-on -
            https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics
            Opt-out Browser Add-on provides visitors with the ability to prevent their data from
            being collected and used by Google Analytics.{' '}
          </Text>
          <Text>
            For more information on the privacy practices of Google, please visit the Google Privacy
            & Terms web page: https://policies.google.com/privacy{' '}
          </Text>
          <br />
          <Text>
            <b>Facebook</b>{' '}
          </Text>
          <Text>Facebook remarketing service is provided by Facebook Inc.</Text>
          <Text>
            You can learn more about interest-based advertising from Facebook by visiting this page:
            https://www.facebook.com/help/516147308587266{' '}
          </Text>
          <Text>
            To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:
            https://www.facebook.com/help/568137493302217{' '}
          </Text>
          <Text>
            Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising
            established by the Digital Advertising Alliance. You can also opt-out from Facebook and
            other participating companies through the Digital Advertising Alliance in the USA
            http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada
            http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in
            Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.{' '}
          </Text>
          <Text>
            For more information on the privacy practices of Facebook, please visit Facebook's Data
            Policy: https://www.facebook.com/privacy/explanation{' '}
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>CCPA/CPRA Privacy Notice</LargeHeading>
          <Text>
            This privacy notice section for California residents supplements the information
            contained in Our Privacy Policy and it applies solely to all visitors, users, and others
            who reside in the State of California.{' '}
          </Text>
          <SideHeading sx={{ my: 0.8 }}>Categories of Personal Information Collected </SideHeading>
          <Text>
            We collect information that identifies, relates to, describes, references, is capable of
            being associated with, or could reasonably be linked, directly or indirectly, with a
            particular Consumer or Device. The following is a list of categories of personal
            information which we may collect or may have been collected from California residents
            within the last twelve (12) months.{' '}
          </Text>
          <Text>
            Please note that the categories and examples provided in the list below are those
            defined in the CCPA. This does not mean that all examples of that category of personal
            information were in fact collected by Us, but reflects our good faith belief to the best
            of our knowledge that some of that information from the applicable category may be and
            may have been collected. For example, certain categories of personal information would
            only be collected if You provided such personal information directly to Us.{' '}
          </Text>
          <br />
          <Text>
            <b>Category A: Identifiers. </b>{' '}
          </Text>
          <Text>
            Examples: A real name, alias, postal address, unique personal identifier, online
            identifier, Internet Protocol address, email address, account name, driver's license
            number, passport number, or other similar identifiers.{' '}
          </Text>
          <Text>Collected: Yes. </Text>
          <br />
          <Text>
            <b>
              Category B: Personal information categories listed in the California Customer Records
              statute (Cal. Civ. Code § 1798.80(e)).{' '}
            </b>{' '}
          </Text>
          <Text>
            Examples: A name, signature, Social Security number, physical characteristics or
            description, address, telephone number, passport number, driver's license or state
            identification card number, insurance policy number, education, employment, employment
            history, bank account number, credit card number, debit card number, or any other
            financial information, medical information, or health insurance information. Some
            personal information included in this category may overlap with other categories.{' '}
          </Text>
          <Text>Collected: Yes. </Text>
          <br />
          <Text>
            <b>
              Category C: Protected classification characteristics under California or federal law.{' '}
            </b>{' '}
          </Text>
          <Text>
            Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship,
            religion or creed, marital status, medical condition, physical or mental disability, sex
            (including gender, gender identity, gender expression, pregnancy or childbirth and
            related medical conditions), sexual orientation, veteran or military status, genetic
            information (including familial genetic information).{' '}
          </Text>
          <Text>Collected: No. </Text>
          <br />
          <Text>
            <b>Category D: Commercial information. </b>{' '}
          </Text>
          <Text>
            Examples: Records and history of products or services purchased or considered.{' '}
          </Text>
          <Text>Collected: Yes. </Text>
          <br />
          <Text>
            <b>Category E: Biometric information. </b>{' '}
          </Text>
          <Text>
            Examples: Genetic, physiological, behavioral, and biological characteristics, or
            activity patterns used to extract a template or other identifier or identifying
            information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans,
            keystroke, gait, or other physical patterns, and sleep, health, or exercise data.{' '}
          </Text>
          <Text>Collected: No. </Text>
          <br />
          <Text>
            <b>Category F: Internet or other similar network activity. </b>{' '}
          </Text>
          <Text>Examples: Interaction with our Service or advertisement.</Text>
          <Text>Collected: Yes. </Text>
          <br />
          <Text>
            <b>Category G: Geolocation data.</b>{' '}
          </Text>
          <Text>Examples: Approximate physical location. </Text>
          <Text>Collected: Yes. </Text>
          <br />
          <Text>
            <b>Category H: Sensory data. </b>{' '}
          </Text>
          <Text>
            Examples: Audio, electronic, visual, thermal, olfactory, or similar information.{' '}
          </Text>
          <Text>Collected: No. </Text>
          <br />
          <Text>
            <b>Category I: Professional or employment-related information. </b>
          </Text>
          <Text>Examples: Current or past job history or performance evaluations. </Text>
          <Text>Collected: No. </Text>
          <br />
          <Text>
            <b>
              Category J: Non-public education information (per the Family Educational Rights and
              Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).{' '}
            </b>
          </Text>
          <Text>
            Examples: Education records directly related to a student maintained by an educational
            institution or party acting on its behalf, such as grades, transcripts, class lists,
            student schedules, student identification codes, student financial information, or
            student disciplinary records.{' '}
          </Text>
          <Text>Collected: No. </Text>
          <br />
          <Text>
            <b>Category K: Inferences drawn from other personal information.</b>
          </Text>
          <Text>
            Examples: Profile reflecting a person's preferences, characteristics, psychological
            trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
          </Text>
          <Text>Collected: No.</Text>
          <br />
          <Text>
            <b>Category L: Sensitive personal information. </b>
          </Text>
          <Text>Examples: Account login and password information, geolocation data. </Text>
          <Text>Collected: Yes.</Text>
          <br />
          <Text>Under CCPA, personal information does not include: </Text>
          <ul>
            {[
              'Publicly available information from government records ',
              'Deidentified or aggregated consumer information ',
              `Information excluded from the CCPA's scope, such as:  `,
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
          </ul>
          <Box sx={{ px: 10 }}>
            <ul>
              {[
                'Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data ',
                `Personal Information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994 `,
              ].map((res) => {
                return (
                  <li>
                    <Text>{res}</Text>
                  </li>
                );
              })}
            </ul>
          </Box>
          <SideHeading sx={{ my: 0.8 }}>Sources of Personal Information </SideHeading>
          <Text>
            We obtain the categories of personal information listed above from the following
            categories of sources:{' '}
          </Text>
          <ul>
            {[
              {
                title: 'Directly from You',
                desc: 'For example, from the forms You complete on our Service, preferences You express or provide through our Service, or from Your purchases on our Service. ',
              },
              {
                title: 'Indirectly from You.',
                desc: 'For example, from observing Your activity on our Service. ',
              },
              {
                title: 'Automatically from You. ',
                desc: 'For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service. ',
              },
              {
                title: 'From Service Providers.',
                desc: 'For example, third-party vendors to monitor and analyze the use of our Service, third-party vendors to provide advertising on our Service, third-party vendors to deliver targeted advertising to You, third-party vendors for payment processing, or other third-party vendors that We use to provide the Service to You. ',
              },
            ].map((res) => {
              return (
                <li>
                  <Text>
                    <b>{res?.title} </b> {res?.desc}{' '}
                  </Text>
                </li>
              );
            })}
          </ul>
          <SideHeading sx={{ my: 0.8 }}>
            Use of Personal Information for Business Purposes or Commercial Purposes{' '}
          </SideHeading>
          <Text>
            We may use or disclose personal information We collect for "business purposes" or
            "commercial purposes" (as defined under the CCPA), which may include the following
            examples:{' '}
          </Text>
          <ul>
            {[
              'To operate our Service and provide You with our Service.',
              'To provide You with support and to respond to Your inquiries, including to investigate and address Your concerns and monitor and improve our Service. ',
              'To fulfill or meet the reason You provided the information. For example, if You share Your contact information to ask a question about our Service, We will use that personal information to respond to Your inquiry. If You provide Your personal information to purchase a product or service, We will use that information to process Your payment and facilitate delivery. ',
              'To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations. ',
              'As described to You when collecting Your personal information or as otherwise set forth in the CCPA. ',
              'For internal administrative and auditing purposes. ',
              'To detect security incidents and protect against malicious, deceptive, fraudulent or illegal activity, including, when necessary, to prosecute those responsible for such activities.',
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
          </ul>
          <Text>
            Please note that the examples provided above are illustrative and not intended to be
            exhaustive. For more details on how we use this information, please refer to the "Use of
            Your Personal Data" section.
          </Text>
          <Text>
            If We decide to collect additional categories of personal information or use the
            personal information We collected for materially different, unrelated, or incompatible
            purposes We will update this Privacy Policy.
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Disclosure of Personal Information </SideHeading>
          <Text>
            We may use or disclose and may have used or disclosed in the last twelve (12) months the
            following categories of personal information for business or commercial purposes:{' '}
          </Text>
          <ul>
            {[
              'Category A: Identifiers',
              'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)) ',
              'Category D: Commercial information',
              'Category F: Internet or other similar network activity ',
              'Category G: Geolocation data',
              'Category L: Sensitive personal information',
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
          </ul>
          <Text>
            Please note that the categories listed above are those defined in the CCPA. This does
            not mean that all examples of that category of personal information were in fact
            disclosed, but reflects our good faith belief to the best of our knowledge that some of
            that information from the applicable category may be and may have been disclosed.{' '}
          </Text>
          <Text>
            When We disclose personal information for a business purpose or a commercial purpose, We
            enter a contract that describes the purpose and requires the recipient to both keep that
            personal information confidential and not use it for any purpose except performing the
            contract.{' '}
          </Text>
          <SideHeading sx={{ my: 0.8 }}>Sale of Personal Information </SideHeading>
          <Text>
            {' '}
            As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing, disclosing,
            disseminating, making available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a consumer's personal information by the
            business to a third party for valuable consideration. This means that We may have
            received some kind of benefit in return for sharing personal information, but not
            necessarily a monetary benefit.{' '}
          </Text>
          <Text>
            Please note that the categories listed below are those defined in the CCPA. This does
            not mean that all examples of that category of personal information were in fact sold,
            but reflects our good faith belief to the best of our knowledge that some of that
            information from the applicable category may be and may have been shared for value in
            return.{' '}
          </Text>
          <Text>
            We may sell and may have sold in the last twelve (12) months the following categories of
            personal information:{' '}
          </Text>
          <ul>
            {[
              'Category A: Identifiers ',

              'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)) ',

              'Category D: Commercial information ',

              'Category F: Internet or other similar network activity ',

              'Category G: Geolocation data',
              'Category L: Sensitive personal information',
            ].map((res) => {
              return (
                <li>
                  <Text>{res} </Text>
                </li>
              );
            })}
          </ul>
          <SideHeading sx={{ my: 0.8 }}>Share of Personal Information </SideHeading>
          <Text>
            We may share Your personal information identified in the above categories with the
            following categories of third parties:{' '}
          </Text>
          <ul>
            {[
              'Service Providers ',
              'Payment processors ',
              'Our affiliates ',
              'Our business partners ',
              'Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You ',
            ].map((res) => {
              return (
                <li>
                  <Text>{res} </Text>
                </li>
              );
            })}
          </ul>
          <SideHeading sx={{ my: 0.8 }}>
            Sale of Personal Information of Minors Under 16 Years of Age{' '}
          </SideHeading>
          <Text sx={{ mb: 1 }}>
            We do not knowingly collect personal information from minors under the age of 16 through
            our Service, although certain third party websites that we link to may do so. These
            third-party websites have their own terms of use and privacy policies and we encourage
            parents and legal guardians to monitor their children's Internet usage and instruct
            their children to never provide information on other websites without their permission.
          </Text>
          <Text sx={{ mb: 1 }}>
            We do not sell the personal information of Consumers We actually know are less than 16
            years of age, unless We receive affirmative authorization (the "right to opt-in") from
            either the Consumer who is between 13 and 16 years of age, or the parent or guardian of
            a Consumer less than 13 years of age. Consumers who opt-in to the sale of personal
            information may opt-out of future sales at any time. To exercise the right to opt-out,
            You (or Your authorized representative) may submit a request to Us by contacting Us.
          </Text>
          <Text>
            If You have reason to believe that a child under the age of 13 (or 16) has provided Us
            with personal information, please contact Us with sufficient detail to enable Us to
            delete that information.{' '}
          </Text>
          <SideHeading sx={{ my: 0.8 }}>Your Rights under the CCPA </SideHeading>
          <Text>
            The CCPA provides California residents with specific rights regarding their personal
            information. If You are a resident of California, You have the following rights:{' '}
          </Text>
          <ul>
            {[
              {
                title: 'The right to notice.',
                desc: 'You have the right to be notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.  ',
              },
              {
                title: 'The right to request.',
                desc: 'Under CCPA, You have the right to request that We disclose information to You about Our collection, use, sale, disclosure for business purposes and share of personal information. Once We receive and confirm Your request, We will disclose to You:  ',
              },
            ].map((res) => {
              return (
                <li>
                  <Text>
                    <b>{res?.title} </b> {res?.desc}{' '}
                  </Text>
                </li>
              );
            })}
          </ul>
          <Box sx={{ px: 10 }}>
            <ul>
              {[
                'The categories of personal information We collected about You  ',
                `The categories of sources for the personal information We collected about You `,
                `Our business or commercial purpose for collecting or selling that personal information `,
                `The categories of third parties with whom We share that personal information `,
                `The specific pieces of personal information We collected about You `,
                `If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:  `,
              ].map((res) => {
                return (
                  <li>
                    <Text>{res}</Text>
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box sx={{ px: 20 }}>
            <ul>
              {[
                'The categories of personal information categories sold ',
                `The categories of personal information categories disclosed `,
              ].map((res) => {
                return (
                  <li>
                    <Text>{res}</Text>
                  </li>
                );
              })}
            </ul>
          </Box>
          <ul>
            {[
              {
                title: 'The right to say no to the sale of Personal Data (opt-out).',
                desc: 'You have the right to direct Us to not sell Your personal information. To submit an opt-out request please contact Us. ',
              },
              {
                title: 'The right to delete Personal Data.',
                desc: 'You have the right to request the deletion of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request, We will delete (and direct Our Service Providers to delete) Your personal information from our records, unless an exception applies. We may deny Your deletion request if retaining the information is necessary for Us or Our Service Providers to:  ',
              },
            ].map((res) => {
              return (
                <li>
                  <Text>
                    <b>{res?.title} </b> {res?.desc}{' '}
                  </Text>
                </li>
              );
            })}
          </ul>
          <Box sx={{ px: 10 }}>
            <ul>
              {[
                'Complete the transaction for which We collected the personal information, provide a good or service that You requested, take actions reasonably anticipated within the context of our ongoing business relationship with You, or otherwise perform our contract with You. ',
                `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.  `,
                `Debug products to identify and repair errors that impair existing intended functionality. `,
                `Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law. `,
                `Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.). `,
                `Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if You previously provided informed consent.  `,
                `Enable solely internal uses that are reasonably aligned with consumer expectations based on Your relationship with Us. `,
                `Comply with a legal obligation. `,
                `Make other internal and lawful uses of that information that are compatible with the context in which You provided it. `,
              ].map((res) => {
                return (
                  <li>
                    <Text>{res}</Text>
                  </li>
                );
              })}
            </ul>
          </Box>
          <ul>
            {[
              {
                title: 'The right not to be discriminated against.',
                desc: `You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:  `,
              },
            ].map((res) => {
              return (
                <li>
                  <Text>
                    <b>{res?.title} </b> {res?.desc}{' '}
                  </Text>
                </li>
              );
            })}
          </ul>
          <Box sx={{ px: 10 }}>
            <ul>
              {[
                'Denying goods or services to You ',
                `Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties  `,
                `Providing a different level or quality of goods or services to You `,
                `Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services `,
              ].map((res) => {
                return (
                  <li>
                    <Text>{res}</Text>
                  </li>
                );
              })}
            </ul>
          </Box>
          <SideHeading sx={{ my: 0.8 }}>Exercising Your CCPA Data Protection Rights </SideHeading>
          <Text>
            In order to exercise any of Your rights under the CCPA, and if You are a California
            resident, You can contact Us:{' '}
          </Text>
          <ul>
            <li>
              <Text>By email: support@trip-trader.com </Text>
            </li>
          </ul>
          <Text>
            Only You, or a person registered with the California Secretary of State that You
            authorize to act on Your behalf, may make a verifiable request related to Your personal
            information.{' '}
          </Text>
          <br />
          <Text>Your request to Us must: </Text>
          <ul>
            <li>
              <Text>
                Provide sufficient information that allows Us to reasonably verify You are the
                person about whom We collected personal information or an authorized representative
              </Text>{' '}
            </li>
            <li>
              <Text>
                Describe Your request with sufficient detail that allows Us to properly understand,
                evaluate, and respond to it
              </Text>{' '}
            </li>
          </ul>
          <Text>
            We cannot respond to Your request or provide You with the required information if We
            cannot:
          </Text>
          <ul>
            <li>
              <Text>Verify Your identity or authority to make the request</Text>{' '}
            </li>
            <li>
              <Text>And confirm that the personal information relates to You</Text>{' '}
            </li>
          </ul>
          <Text>
            We will disclose and deliver the required information free of charge within 45 days of
            receiving Your verifiable request. The time period to provide the required information
            may be extended once by an additional 45 days when reasonably necessary and with prior
            notice.
          </Text>
          <Text>
            Any disclosures We provide will only cover the 12-month period preceding the verifiable
            request's receipt.
          </Text>
          <Text>
            For data portability requests, We will select a format to provide Your personal
            information that is readily usable and should allow You to transmit the information from
            one entity to another entity without hindrance.{' '}
          </Text>
          <br />
          <SideHeading sx={{ my: 0.8 }}>Do Not Sell My Personal Information </SideHeading>
          <Text sx={{ mb: 1 }}>
            As defined in the CCPA/CPRA, "sell" and "sale" mean selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or otherwise communicating
            orally, in writing, or by electronic or other means, a Consumer's personal information
            by the Business to a third party for valuable consideration. This means that We may have
            received some kind of benefit in return for sharing personal information, but not
            necessarily a monetary benefit.
          </Text>
          <Text sx={{ mb: 1 }}>
            We do not sell personal information as the term sell is commonly understood. We do allow
            Service Providers to use Your personal information for the business purposes described
            in Our Privacy Policy, for activities such as advertising, marketing, and analytics, and
            these may be deemed a sale under CCPA/CPRA.
          </Text>
          <Text sx={{ mb: 1 }}>
            You have the right to opt-out of the sale of Your personal information. Once We receive
            and confirm a verifiable consumer request from You, we will stop selling Your personal
            information. To exercise Your right to opt-out, please contact Us.
          </Text>
          <Text sx={{ mb: 1 }}>
            The Service Providers we partner with (for example, our analytics or advertising
            partners) may use technology on the Service that sells personal information as defined
            by the CCPA/CPRA law. If you wish to opt out of the use of Your personal information for
            interest-based advertising purposes and these potential sales as defined under CCPA/CPRA
            law, you may do so by following the instructions below.
          </Text>
          <Text sx={{ mb: 1 }}>
            Please note that any opt out is specific to the browser You use. You may need to opt out
            on every browser that You use.
          </Text>
          <SmallHeading>Website</SmallHeading>
          <Text>
            If applicable, click "Privacy Preferences", "Update Privacy Preferences" or "Do Not Sell
            My Personal Information" buttons listed on the Service to review your privacy
            preferences and opt out of cookies and other technologies that We may use. Please note
            that You will need to opt out from each browser that You use to access the Service.
          </Text>
          <Text>
            Additionally, You can opt out of receiving ads that are personalized as served by our
            Service Providers by following our instructions presented on the Service:
          </Text>{' '}
          <ul>
            <ul>
              {[
                `The NAI's opt-out platform: http://www.networkadvertising.org/choices/ `,

                `The EDAA's opt-out platform http://www.youronlinechoices.com/ `,
                `The DAA's opt-out platform: http://optout.aboutads.info/?c=2&lang=EN `,
              ].map((res) => {
                return (
                  <li>
                    <Text>{res}</Text>
                  </li>
                );
              })}
            </ul>
            <Text>
              The opt out will place a cookie on Your computer that is unique to the browser You use
              to opt out. If you change browsers or delete the cookies saved by your browser, You
              will need to opt out again.{' '}
            </Text>
          </ul>
          <SmallHeading>Mobile Devices </SmallHeading>
          <Text>
            Your mobile device may give You the ability to opt out of the use of information about
            the apps You use in order to serve You ads that are targeted to Your interests:{' '}
          </Text>
          <ul>
            {[
              `"Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices  `,
              `"Limit Ad Tracking" on iOS devices  `,
            ].map((res) => {
              return (
                <li>
                  <Text>{res}</Text>
                </li>
              );
            })}
            <Text>
              You can also stop the collection of location information from Your mobile device by
              changing the preferences on Your mobile device.{' '}
            </Text>
          </ul>
          <LargeHeading sx={{ my: 0.5 }}>
            "Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)
          </LargeHeading>
          <Text>Our Service does not respond to Do Not Track signals. </Text>
          <Text>
            However, some third party websites do keep track of Your browsing activities. If You are
            visiting such websites, You can set Your preferences in Your web browser to inform
            websites that You do not want to be tracked. You can enable or disable DNT by visiting
            the preferences or settings page of Your web browser.{' '}
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>
            Your California Privacy Rights (California's Shine the Light law){' '}
          </LargeHeading>
          <Text>
            Under California Civil Code Section 1798 (California's Shine the Light law), California
            residents with an established business relationship with us can request information once
            a year about sharing their Personal Data with third parties for the third parties'
            direct marketing purposes.{' '}
          </Text>
          <Text>
            If you'd like to request more information under the California Shine the Light law, and
            if You are a California resident, You can contact Us using the contact information
            provided below.{' '}
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>
            California Privacy Rights for Minor Users (California Business and Professions Code
            Section 22581){' '}
          </LargeHeading>
          <Text>
            California Business and Professions Code Section 22581 allows California residents under
            the age of 18 who are registered users of online sites, services or applications to
            request and obtain removal of content or information they have publicly posted.{' '}
          </Text>
          <Text>
            To request removal of such data, and if You are a California resident, You can contact
            Us using the contact information provided below, and include the email address
            associated with Your account.{' '}
          </Text>
          <Text>
            Be aware that Your request does not guarantee complete or comprehensive removal of
            content or information posted online and that the law may not permit or require removal
            in certain circumstances.
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>Children's Privacy </LargeHeading>
          <Text>
            Our Service does not address anyone under the age of 13. We do not knowingly collect
            personally identifiable information from anyone under the age of 13. If You are a parent
            or guardian and You are aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data from anyone under
            the age of 13 without verification of parental consent, We take steps to remove that
            information from Our servers.{' '}
          </Text>
          <Text>
            If We need to rely on consent as a legal basis for processing Your information and Your
            country requires consent from a parent, We may require Your parent's consent before We
            collect and use that information
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>Links to Other Websites </LargeHeading>
          <Text>
            Our Service may contain links to other websites that are not operated by Us. If You
            click on a third party link, You will be directed to that third party's site. We
            strongly advise You to review the Privacy Policy of every site You visit.{' '}
          </Text>
          <Text>
            We have no control over and assume no responsibility for the content, privacy policies
            or practices of any third party sites or services.{' '}
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>Changes to this Privacy Policy </LargeHeading>
          <Text>
            We may update Our Privacy Policy from time to time. We will notify You of any changes by
            posting the new Privacy Policy on this page.{' '}
          </Text>
          <Text>
            We will let You know via email and/or a prominent notice on Our Service, prior to the
            change becoming effective and update the "Last updated" date at the top of this Privacy
            Policy.
          </Text>
          <Text>
            You are advised to review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.{' '}
          </Text>
          <br />
          <LargeHeading sx={{ my: 0.5 }}>Contact Us </LargeHeading>
          <Text>If you have any questions about this Privacy Policy, You can contact us: </Text>
          <ul>
            <li>
              <Text>By email: support@trip-trader.com </Text>
            </li>
          </ul>
        </Grid>
    */}
      </Grid>

      <Footer />
    </>
  );
};
export default PrivacyPolicy;
