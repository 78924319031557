import { styled } from '@mui/material/styles';
import { Grid, Box } from '@mui/material';

export const LeftGrid = styled(Grid)(() => ({
    '@media(max-width: 600px)': {
        display: 'none'
    },
}));
export const MainGrid = styled(Grid)(() => ({
    position: 'relative',
    display: 'flex',
    background: "#FAFAFA",

}));
export const Centered = styled(Grid)(() => ({
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '95vh',
    background: 'transparent',
    color: '#ffffff',
    marginTop: 20,
}))
export const RightGrid = styled(Grid)(() => ({
    background: "#FAFAFA",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
}));
export const RightMainGrid = styled(Grid)(() => ({
    display: 'flex',
    height: '70%',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'column',
    '@media(max-width: 600px)': {
        width: '75%',
        height: '80vh',
    },
    '@media(max-width: 400px)': {
        height: '90vh'
    },
}));
export const RightSubGrid = styled(Grid)(() => ({
    display: 'flex',
    height: '87vh',
    width: '62%',
    flexDirection: 'column',
    '@media(max-width: 600px)': {
        width: '78%',
        height: '80vh',
    },
    '@media(max-width: 400px)': {
        height: '90vh'
    },
}));
export const MobileGrid = styled(Grid)(() => ({
    display: 'none',
    '@media(max-width: 600px)': {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginTop: 50
    },

}));
export const Heading = styled('h2')(() => ({
    fontFamily: 'Poppins',
    fontSize: '2rem',
    fontStyle: 'normal',
    fontWeight: 600,
    color: "#0D2229",
    marginBottom: 0
}));
export const HeadingDiv = styled(Grid)(() => ({
    width: '85%',
    paddingLeft: "6vw"
}));
export const LeftHeading = styled('h2')(() => ({
    fontFamily: 'PoppinsLight',
    fontSize: '1.6rem',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#FFFFFF",
}));
export const UploadBox = styled(Box)(() => ({
    background: "#EEF6EF",
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 170,
    paddingTop: 30,
    border: "1px dashed #3C6B49"
}));
export const DisplayImageBox = styled(Box)(() => ({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 340
}));
export const ProgressBox = styled(Box)(() => ({
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 170,
    paddingTop: 30
}));
export const UploadText = styled('p')(() => ({
    fontFamily: 'PoppinsLight',
    fontStyle: 'normal',
    fontWeight: 500,
    color: "#1A5B2C",
    marginBottom: 2,
    fontSize: '.9rem',
    letterSpacing: "0.0168em",
    cursor: 'pointer'

}));


export const SubHeading = styled('p')(() => ({
    fontFamily: 'PoppinsLight',
    fontSize: '.9rem',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#1E3625",
    marginTop: "6px",
    '& > :first-of-type': {
        textDecorationLine: "underline",
        color: "#1E3625",
        fontWeight: 600,
        textUnderlineOffset: "3px",
        cursor: 'pointer',

    }
}));
export const FP = styled('span')(() => ({
    textDecoration: 'none',
    fontFamily: 'Poppins',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    color: "#1E3625",
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer'
}));
export const LoaderSpan = styled('span')(() => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
}));

export const VerificationRow = styled(Grid)(() => ({
    width:'85%',
}));

export const VerificationInput = styled('input')(() => ({
    width: '72px', height: '52px',
    background: '#FFFFFF',
    border: '1px solid #D8E1DB',
    borderRadius: '8px',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '140%',
    color: '#101B10',
}));
