import React, { useEffect } from 'react';
import Routes from './routes';
import store from './app/store';

import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material';
import { persistStore } from 'redux-persist';
import { ToastContainer, toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
// import 'sweetalert2/src/sweetalert2.scss'
import * as Sentry from '@sentry/react';
import { collection, where, query, onSnapshot } from 'firebase/firestore';
import { FireStore } from './utills/FireStore';

import { db } from './firebase';

import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { saveData } from './features/auth/reducer';
Sentry.init({
  dsn: 'https://77cce0210e1645478a8a95eefe6ac61e@o4505512458452992.ingest.sentry.io/4505517130842112',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const App = () => {
  let observer = null;
  let persistor = persistStore(store);
  const theme = createTheme();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  
  useEffect(() => {
    
    if (userInfo?._id) {
       
      var initState = true;
      const chatroomsRef = collection(db, 'chatrooms');
      const userQuery = query(chatroomsRef, where(`user.${userInfo?._id}`, '==', true));

      observer = onSnapshot(userQuery, async (querySnapshot) => {
        if (initState) {
          initState = false;
        } else {
          const inbox = await FireStore.getAllCurrentUserRooms(userInfo?._id);
          dispatch(saveData({ name: 'inbox', data: inbox }));
        }
      });
    }else{
      return () =>{
         if(observer!==null)  observer();
      }
    }

    return () =>{
       if(observer!==null)  observer();
    }
   
  }, [userInfo?._id]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="bottom-right"
          autoClose={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
        />
        {/* <button onClick={() => methodDoesNotExist()}>Break the world</button>; */}
        <Routes />
      </ThemeProvider>
    </PersistGate>
  );
};

export default App;
