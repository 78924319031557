import React from 'react';

import moment from 'moment';

import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { CustomLabel2 } from "./Elements"
import { Controller } from "react-hook-form";
import { CalendarMonth } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D8E1DB",
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            color: 'black',
        },

        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C6B49"
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: 'PoppinsLight',
            padding: "10px",
            color: "#1E3625",
            fontSize: '.8rem',
            fontWeight: 800,
            "&::placeholder": {    // <----- Add this.
                fontWeight: 500,
                fontSize: 'small',
                fontFamily: 'Poppins'
            },
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined": {
            color: "green"
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "#1E3625",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#1E3625",
        },
        "& .css-1wc848c-MuiFormHelperText-root.Mui-error": {
            marginLeft: "0 !important",
            fontFamily: "Poppins !important"
        }
    },

});
function CalendarIcon() {
    return (<CalendarMonth style={{ color: "#487454", zIndex: 20 }} />
    );
}
export const DateInput = ({ title, error, control }) => {
    const classes = useStyles();
    return (
        <div>
            <CustomLabel2>{title}</CustomLabel2>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {control ? <Controller
                    name="birthday"
                    control={control}
                    render={({ field }) => {
                        return (
                            <DatePicker
                                value={field.value}
                                onChange={(date) => { field.onChange(date); }}
                                className={classes.root}
                                inputFormat="MM/DD/YYYY"
                                components={{ OpenPickerIcon: CalendarIcon }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        value={moment(field.value).format("MM / DD / YYYY")}
                                        error={error ? true : false}
                                        helperText={error}
                                        inputProps={{ style: { color: "#1E3625", zIndex: 20 } }} sx={{ width: '100%' }} />}
                            />
                        );
                    }}
                /> :
                    <DatePicker
                        // onChange={(date) => { field.onChange(date); }}
                        className={classes.root}
                        inputFormat="MM/DD/YYYY"
                        components={{ OpenPickerIcon: CalendarIcon }}
                        renderInput={(params) =>
                            <TextField {...params}
                                value={moment().format("MM / DD / YYYY")}
                                error={error ? true : false}
                                helperText={error}
                                inputProps={{ style: { color: "#1E3625", zIndex: 20 } }} sx={{ width: '100%' }} />}
                    />}
            </LocalizationProvider>
        </div>
    );
}