import React, { useState } from 'react';

import * as yup from 'yup';
import Phone from './Phone';
import Email from './Email';
import Loader from '../../assets/loader1.gif';

import { Box } from '@mui/system';
import { FormHelperText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../components/InputFields';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { forgetPassword } from '../../features/auth/action';
import { CustomGreenLargeButton } from '../../components/Button';
import { pinCodeFieldStyles } from '../../components/InputFields/styles';
import { RightMainGrid, VerificationRow, SubHeading, Heading, LoaderSpan } from './Elements';
import toast from 'react-hot-toast';

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required('Please enter a password.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  passwordConfirmation: yup.string().oneOf([yup.ref('newPassword'), null], 'New Password must match with the Confirm Password'),
});

const Step1 = ({ setStep, state, handleChange }) => {
  return state.withEmail ? (
    <Email handleScreen={handleChange} setStep={setStep} />
  ) : (
    <Phone setStep={setStep} handleScreen={handleChange} />
  );
};
const Step2 = ({ state, setStep }) => {
  const dispatch = useDispatch();
  const classes = pinCodeFieldStyles();

  const { loading } = useSelector((state) => state.auth);
  const [isLoading,setIsLoading] = useState(false)
  const { control, handleSubmit,reset  } = useForm({ defaultValues: { pin: '' } });

  const handleStep = () => {
    setStep(3);
  };

  const verifyOtp = (pin) => {
    setIsLoading(true)
    const confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(pin)
      .then((result) => {
        handleStep();
      })
      .catch((error) => {
        if(error.code === "auth/invalid-verification-verification"){
          toast.error("Invalid verification verification", { duration: 4000 })
        }else{
          toast.error("Try again", { duration: 4000 })
        }
      }).finally(()=>{
        reset()
        setIsLoading(false)
      })
  };

  const onSubmit = (data) => {
    const body = { ...data };
    body['url'] = 'password/verifyOtp';
    if (state.withEmail) {
      body['email'] = state.email;
      dispatch(forgetPassword(body, handleStep));
    } else if (state.withPhone) {
      body['phone'] = state.phone;
      verifyOtp(data['pin']);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="submitForm">
      <VerificationRow>
        <Controller
          name="pin"
          control={control}
          rules={{ validate: (value) => value.length === 6 }}
          render={({ field, fieldState }) => (
            <Box>
              <MuiOtpInput className={classes.root} sx={{ gap: 1 }} {...field} length={6} />
              {fieldState.invalid ? (
                <FormHelperText
                  sx={{ fontFamily: 'Poppins-Medium', color: '#d32f2f', marginTop: 0 }}
                >
                  {'PIN code is incorrect.'}
                </FormHelperText>
              ) : null}
            </Box>
          )}
        />
      </VerificationRow>
      <CustomGreenLargeButton type="submit" variant="contained" disableripple disabled={loading || isLoading}>
        {' '}
        {loading || isLoading ? (
          <LoaderSpan>
            <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
            Submit Code
          </LoaderSpan>
        ) : (
          'Submit Code'
        )}{' '}
      </CustomGreenLargeButton>
    </form>
  );
};
const Step3 = ({ state }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { loading } = useSelector((state) => state.auth);
  const [isLoading,setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate('/signin');
  };
  const onSubmit = (data) => {
    setIsLoading(true)
    const body = { ...data };
    body['url'] = 'password/reset';
    if (state.withEmail) {
      body['email'] = state?.email?.toLowerCase();
    } else if (state.withPhone) {
      body['phone'] = state.phone.replace('+', '');
    }
    dispatch(forgetPassword(body, navigateTo));
    setIsLoading(false)
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        type="password"
        title="New Password"
        error={errors.newPassword?.message}
        register={register}
        registerFor="newPassword"
      />

      <Input
        type="password"
        title="Confirm Password"
        error={errors.passwordConfirmation?.message}
        register={register}
        registerFor="passwordConfirmation"
      />

      <CustomGreenLargeButton type="submit" variant="contained" disableripple sx={{ mt: 4 }} disabled={loading || isLoading}>
        {loading || isLoading ? (
          <LoaderSpan>
            <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
            Updating...
          </LoaderSpan>
        ) : (
          'Update Password'
        )}
      </CustomGreenLargeButton>
    </form>
  );
};
const ForgetPassword = () => {
  const [step, setStep] = useState(1);
  const [state, setState] = useState({
    email: '',
    newPassword: '',
    phone: '',
    withEmail: true,
    withPhone: false,
  });
  const handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <RightMainGrid>
      <div>
        <Heading>
          {step === 1
            ? 'Forgot Password'
            : step === 2
            ? 'Verify PIN Code'
            : step === 3
            ? 'Password Reset'
            : ''}
        </Heading>
        <SubHeading>
          {step === 1
            ? 'Enter your Email/Phone Number below and we’ll send you a one-time PIN code for verification.'
            : step === 2
            ? state.withEmail
              ? `Enter the 6-digit PIN code we sent to your registered email ${state?.email}`
              : `Enter the 6-digit PIN code we sent to your registered Phone Number ${state.phone}`
            : step === 3
            ? 'Create a new password that is between 8-32 characters and contains at least one number, letter, and special character (i.e., _#%@!)'
            : ''}
        </SubHeading>
      </div>
      {step === 1 && <Step1 setStep={setStep} state={state} handleChange={handleChange} />}
      {step === 2 && <Step2 setStep={setStep} state={state} handleChange={handleChange} />}
      {step === 3 && <Step3 setStep={setStep} state={state} handleChange={handleChange} />}
    </RightMainGrid>
  );
};
export default ForgetPassword;
