import { styled } from '@mui/material/styles';
import { Link, Button } from '@mui/material';

export const JoinNow = styled(Button)(() => ({
    color: 'white',
    cursor: 'pointer',
    border: "2px solid #D07E02",
    padding: "9px 18px",
    width: 'max-content',
    height: 'max-content',
    textTransform: 'none',
    background: "#D07E02",
    gap: "10px",
    textDecoration: 'none',
    fontFamily: 'Poppins-SemiBold',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 2000px)': {
        fontSize: "1.2vw",
    },
    '&:hover': {
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #D07E02',
    },
    '@media(max-width: 960px)': {
        padding: "0px",
        width: '10vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6.4vh',
        margin: "20px auto",
    },
}));
export const JoinNowMob = styled(Button)(() => ({
    color: 'white',
    cursor: 'pointer',
    padding: "9px 18px",
    width: 'max-content',
    height: 'max-content',
    textTransform: 'none',
    background: "#D07E02",
    gap: "10px",
    textDecoration: 'none',
    fontFamily: 'Poppins-SemiBold',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
        boxShadow: 'none',
    }, '&:active': {
        boxShadow: 'none',
        backgroundColor: '#D07E02',
        borderColor: '#D07E02',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #D07E02',
    },

}));
export const SignIn = styled(Button)(() => ({
    padding: "9px 18px",
    width: 'max-content',
    height: 'max-content',
    textTransform: 'none',
    color: 'white',
    fontFamily: 'Poppins-SemiBold',
    cursor: 'pointer',
    background: "rgba(0, 37, 65, 0.01)",
    border: "2px solid #FFFFFF",
    textDecoration: 'none',
    borderRadius: "5px",
    margin: "0 15px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 2000px)': {
        fontSize: "1.2vw",
    },
    '@media(max-width: 960px)': {
        padding: "0px",
        width: '10vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '6vh',
        margin: "20px 8px",
    },
}));
export const SignInMob = styled(Button)(() => ({
    margin: 'auto',
    padding: "9px 18px",
    width: 'max-content',
    height: 'max-content',
    textTransform: 'none',
    color: 'white',
    fontFamily: 'Poppins-SemiBold',
    cursor: 'pointer',
    background: "rgba(0, 37, 65, 0.01)",
    border: "2px solid #FFFFFF",
    textDecoration: 'none',
    borderRadius: "5px",
    margin: "0 15px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',


}));
export const CustomLink = styled(Link)(() => ({
    fontFamily: 'Poppins-SemiBold',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    cursor: 'pointer',
    padding: "10px 0px",
    margin: "0 20px",
    textDecoration: 'none',
    textTransform: 'capitalize',
    '@media(max-width: 2000px)': {
        fontSize: "1.2vw",
    },
    '@media(max-width: 1200px)': {
        margin: "0 15px",
    },
    '@media(max-width: 960px)': {
        margin: "0 8px",
        display: 'flex',
        justifyContent: 'center'
    },
}));
export const Border = styled('div')(() => ({
    marginTop: 8,
    borderTop: "3px solid white",
    borderRadius: "3px",
}));