import React from 'react';

import { Option, CustomOption } from "./Elements"
import { TextField, InputAdornment } from '@mui/material'
import { CustomLabel } from "../../components/InputFields/Elements"
import { customDropDownStyles } from "../../components/InputFields/styles"
import { CustomAutocomplete } from "../../components/InputFields/Elements"
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';

export const DropDown = ({ state, data, label, title, icon, handleChange }) => {

    const classes = customDropDownStyles();

    const customData = [...data, { title: 'custom offer' }]

    const handleDropDownChange = (value) => {
        handleChange('hostTripError', false)
        handleChange('hostOffer', value)
    }
    const handleCustomOption = () => {
        handleChange('hostTripError', false)
        handleChange('hostOffer', {})
        handleChange('isCustomOfferSelected', true)
        handleChange('step', 4)
    }
    return (
        <>
            <CustomLabel>{title}</CustomLabel>
            <CustomAutocomplete
                disablePortal
                placeholder='Select a trip or create custom offer...'
                onChange={(e, value) => { handleDropDownChange(value) }}
                id="combo-box-demo"
                renderOption={(props, option) => {
                    return (option.title !== "custom offer" ?
                        <Option {...props}>{`${option?.species} ${option?.tradeType}`}</Option> :
                        <CustomOption onClick={handleCustomOption}>Create Custom Offer...</CustomOption>
                    );
                }}
                getOptionLabel={(option) => `${option?.species} ${option?.tradeType}`}
                options={customData}
                popupIcon={< KeyboardArrowDownIcon />}
                renderInput={(params) =>
                    <TextField {...params}
                        className={classes.root}
                        error={state?.hostTripError ? true : false}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">{icon ? <img src={icon} alt={label} /> : ""}</InputAdornment>
                            )
                        }}
                        placeholder={'Select a trip or create custom offer...'}
                    />
                }
            />
        </>
    );
}
