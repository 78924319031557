import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';

export const MainContainer = styled(Container)(() => ({
    height: "120vh",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'normal',
    fontFamily: 'Poppins',
    '@media(max-width: 960px)': {
        height: "max-content",
    },

}));
export const Heading = styled('h1')(() => ({
    fontFamily: 'Poppins-SemiBold',
    fontStyle: 'normal',
    lineHeight: "64px",
    fontSize: '2.3rem',
    letterSpacing: "-0.005em",
    '@media(max-width: 450px)': {
        fontSize: "6.3vw",
    },
}));
export const Orange = styled('span')(() => ({
    color: "#E38C09",
}));
export const Black = styled('span')(() => ({
    color: "#313131",
}));
export const Description = styled(Box)(() => ({
    fontFamily: 'Poppins-Light',
    fontStyle: 'normal',
    marginBottom: 40,
    color: '#241C10',
    opacity: 0.5,
    '@media(max-width: 450px)': {
        fontSize: "3.5vw",
        textAlign: 'center'
    },
}));
export const FeaturedItem = styled(Box)(() => ({
    display: 'flex',
    background: "#FFFFFF",
    boxShadow: "1px 24px 50px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    flexDirection: 'column',
    margin: "20px 25px",
    width: "24vw",
    '@media(max-width:960px)': {
        width: "50vw",
    },
    '@media(max-width:800px)': {
        width: "100%",
        margin: "14px 5px",
    },
}));
export const FeaturedItemImage = styled(Box)(() => ({
    width: '100%',
    height: '30vh',
    '& img': {
        width: '100%',
        height: '100%',
        // objectFit: 'cover',
        // verticalAlign: 'middle',
    }
}));
export const FeaturedItemTop = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: "8px 18px"
}));
export const NameSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: "0px 10px",
    '& > :first-of-type': {
        fontFamily: 'Poppins-SemiBold',
        color: '#3C6B49',
        textTransform: 'capitalize',
        '@media(max-width:960px)': {
            fontSize: "2vw",
        },
        '@media(max-width:800px)': {
            fontSize: "3vw",
        },
        '@media(max-width:600px)': {
            fontSize: "3vw",
        },
        '@media(max-width:500px)': {
            fontSize: '12px',
        },
    },
}));
export const RatingBox = styled(Box)(() => ({
    display: 'inline-block',
    width: '100%',
    alignItems: 'center',
    verticalAlign: 'middle',
    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        // filter: 'drop-shadow(2px 3px 10px rgba(0, 0, 0, .5))',
        verticalAlign: 'middle',
    }
}));
export const RatingCount = styled('span')(() => ({
    color: '#101B10',
    fontSize: '1vw',
    marginLeft: "2px",
    '@media(max-width: 960px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '13px',
    },
}));
export const ReviewCount = styled('span')(() => ({
    textDecorationLine: 'underline',
    fontFamily:'Poppins-Medium',
    color: 'rgba(17, 17, 17, 0.4)',
    fontSize: '1vw',
    marginLeft: "2px",
    '@media(max-width: 960px)': {
        fontSize: '2.2vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '12px',
    },
}));
export const ActivityDetails = styled('span')(() => ({
    padding: "8px 18px",
    display: 'flex',
    flexDirection: 'column'
}));
export const ActivityHeading = styled('span')(() => ({
    letterSpacing: "0.03em",
    textTransform: "uppercase",
    fontSize: 'small',
    color: "#111111",
    fontFamily:'Poppins-SemiBold',
    opacity: 0.6,
    marginBottom: 6,
    '@media(max-width: 960px)': {
        fontSize: '2vw',
    },
    '@media(max-width: 660px)': {
        fontSize: '3vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '3.5vw',
    },
}));
export const Offered = styled('span')(() => ({
    fontFamily:'Poppins-SemiBold',
    fontStyle: 'normal',
    fontSize: "16px",
    lineHeight: "140%",
    color: "#101B10",
    flex: "none",
    alignSelf: 'stretch',
    flexGrow: 0,
    marginBottom: 16,
    '@media(max-width: 960px)': {
        fontSize: '2vw',
    },
    '@media(max-width: 660px)': {
        fontSize: '3vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '3.5vw',
    },
}));
export const Return = styled('span')(() => ({
    fontFamily: 'Poppins-Medium',
    fontSize: "15px",
    lineHeight: "140%",
    flex: "none",
    alignSelf: 'stretch',
    flexGrow: 0,
    marginBottom: 8,
    '@media(max-width: 960px)': {
        fontSize: '2vw',
    },
    '@media(max-width: 660px)': {
        fontSize: '3vw',
    },
    '@media(max-width: 500px)': {
        fontSize: '3.5vw',
    },
}));