import React, { useEffect, useState } from 'react';

import api from '../../../api';
import PublicReviewBox from './PublicReviewBox';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LeaveReviewText } from './../Elements';
import { config } from '../../../utills/constants';
import { CountName, ScrollAbleGrid } from '../Elements';
import { OpenDispute } from '../../../components/Modal/DisputeModals';
import { DeleteReview, DeleteReply } from '../../../components/Modal/Review';
import { ReplyToReview, EditReply } from '../../../components/Modal/ReplyToReview';

const PublicReviews = ({ apiCall, handleChange }) => {
  const { id } = useParams();

  const { reviews } = useSelector((state) => state.profile);

  const { userInfo, confirmedTrips } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    isReviewed: false,
    isTripFound: false,
    editData: { message: '' },
    edited: {},
    replied: {},
    dispute: {},
    delete: {},
    openEditModal: false,
    openReviewModal: false,
    openDisputeModal: false,
    openDeleteReview: false,
  });

  const _handleChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const checkIfAlreadyReviewed = async () => {
    const newArray = confirmedTrips?.filter(function (el) {
      return el?.offeredTo?._id === id || el?.offeredBy?._id === id;
    });
    if (newArray?.length > 0) {
      _handleChange('isTripFound', true);
      await api
        .get(`/review/checkIfAlreadyReviewed/${id}/${userInfo?._id}`, config)
        .then((data) => {
          if (data.data.message === 'You have already reviewed this user.') {
            _handleChange('isReviewed', true);
          } else if (data.data.message !== 'No review found.') {
            _handleChange('isReviewed', false);
          }
        });
    } else _handleChange('isReviewed', false);
  };

  useEffect(() => {
    checkIfAlreadyReviewed();
    // eslint-disable-next-line
  }, [reviews?.length]);
  const loggedInUserReview = reviews.find((review) => review?.guestId?._id === userInfo?._id);
  const otherReviews = reviews.filter((review) => review?.guestId?._id !== userInfo?._id);
  return (
    <>
      <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <CountName sx={{ my: 2 }}>
          Showing {reviews?.length} of {reviews?.length}
        </CountName>
        {!state?.isReviewed && state.isTripFound && id !== userInfo?._id && (
          <LeaveReviewText onClick={() => handleChange('openLeaveReviewModal', true)}>
            Leave a review
          </LeaveReviewText>
        )}
      </Grid>

      <ScrollAbleGrid>
        {loggedInUserReview && (
          <PublicReviewBox
            state={state}
            handleChange={_handleChange}
            key={loggedInUserReview?._id}
            res={loggedInUserReview}
          />
        )}
        {otherReviews?.map((res, i) => {
          return <PublicReviewBox state={state} handleChange={_handleChange} key={i} res={res} />;
        })}
      </ScrollAbleGrid>
      <ReplyToReview
        apiCall={apiCall}
        data={state.replied}
        open={state.openReviewModal}
        handle={_handleChange}
        stateName="openReviewModal"
      />
      <OpenDispute
        apiCall={apiCall}
        data={state.dispute}
        open={state.openDisputeModal}
        handle={_handleChange}
        stateName="openDisputeModal"
      />
      <EditReply
        apiCall={apiCall}
        data={state.edited}
        state={state}
        open={state.openEditModal}
        handle={_handleChange}
        stateName="openEditModal"
      />
      <DeleteReview
        state={state}
        apiCall={apiCall}
        data={state.delete}
        open={state.openDeleteReview}
        handle={_handleChange}
        stateName="openDeleteReview"
      />
      <DeleteReply
        state={state}
        apiCall={apiCall}
        data={state.delete}
        open={state.openDeleteReply}
        handle={_handleChange}
        stateName="openDeleteReply"
      />
    </>
  );
};
export default PublicReviews;
