import React from 'react';

import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import limitedAccess from "../../assets/modal/limited-access.png"
import { Heading, Description, BrowseFiles, style } from "./Elements"
import { CustomGreenLargeButton, CustomGreenTransLargeButton } from '../Button';

export const Restriction = ({ open, handle, stateName }) => {
    const navigate = useNavigate()
    const handleClose = () => handle([stateName], false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                // BackdropProps={{style: {backgroundColor: 'white'}}}
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: 380, height: 500, alignItems: 'center', pt: 5, px: 3, pb: 3, }}>
                    <img src={limitedAccess} />
                    <Heading>Limited Guest Access</Heading>
                    <Description>This feature is only available to members. <br />
                        Please sign in or create an account.
                    </Description>
                    <Box sx={{ width: '100%' }}>
                        <CustomGreenLargeButton onClick={() => navigate('/join')} type="submit" variant="contained" disableripple sx={{ mb: 1 }}>
                            Join Now
                        </CustomGreenLargeButton>
                        <CustomGreenTransLargeButton onClick={() => navigate('/signin')} type="submit" variant="contained" disableripple>
                            Sign In
                        </CustomGreenTransLargeButton>
                    </Box> <BrowseFiles onClick={handleClose}>I want to continue as a guest</BrowseFiles>
                </Box>
            </Modal>

        </div>
    );
}
export const FreeUserRestriction = ({ open, handle, stateName }) => {
    const navigate = useNavigate()
    const handleClose = () => handle([stateName], false);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: 400, height: 400, alignItems: 'center', pt: 5, px: 3, pb: 3, }}>
                    <Heading>Limited Access</Heading>
                    <Description>This feature is only available for paid members. <br />
                        Please buy our plan to continue unlimited access.
                    </Description>
                    <Box sx={{ width: '100%' }}>
                        <CustomGreenLargeButton onClick={() => navigate('/freePlan')} type="submit" variant="contained" disableripple sx={{ mb: 1 }}>
                            Buy now
                        </CustomGreenLargeButton>
                        <CustomGreenTransLargeButton onClick={handleClose} type="submit" variant="contained" disableripple>
                            Cancel
                        </CustomGreenTransLargeButton>
                    </Box>
                </Box>
            </Modal>

        </div>
    );
}

