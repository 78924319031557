import React from 'react';

import { GreenButton } from "../Elements"
import { useSelector } from "react-redux"
import { TransparentBtn } from './Elements';
import { DropDown } from "./CustomTripDropdown"
import { Box, Grid, FormHelperText } from '@mui/material';
import { Header } from "../../components/Modal/ModalHeader"
import { HeadingSub, GreyText, Description } from "./Elements"

const Step2 = ({ handleClose, state, handleChange }) => {
    
    const { trips } = useSelector(state => state.auth)

    const changeStep = () => {
        if (typeof state?.hostOffer?.title == 'undefined') { handleChange('hostTripError', true) }
        else { handleChange('step', 6); }
    }

    return (
        <>
            <Header handleClose={handleClose} heading="Make Offer" />
            <Description sx={{ textAlign: 'left' }}>Now, let's fill out the details of your offer.</Description>
            <Grid container sx={{ width: '100%' }}>
                <Grid item xs={12} lg={12} >
                    <HeadingSub sx={{ fontSize: { xs: '13px', sm: '15px', md: '16px', lg: '16px' } }}>What are you offering to trade?</HeadingSub>
                    <DropDown state={state} data={trips} handleChange={handleChange} title={""} placeholder="Choose a date or date range" />
                    {state?.hostTripError && <FormHelperText sx={{ fontFamily: "Poppins-Medium", color: "#d32f2f", marginTop: 0 }}>{'Please select trip'}</FormHelperText>}
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', mt: 2, width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <GreyText sx={{ fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px' } }} >Step {state.step} of 4</GreyText>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TransparentBtn sx={{ px: { lg: 3 } }} onClick={() => handleChange('step', 1)}>Back</TransparentBtn>
                    <GreenButton onClick={changeStep} type="submit" variant="contained" disableripple>
                        Continue
                    </GreenButton>
                </Box>
            </Box>
        </>

    );
}

export default Step2