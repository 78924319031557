import * as React from 'react';

import { Box, Grid } from '@mui/material';
import { ImageContainer } from './Elements';
import { useNavigate } from 'react-router-dom';
import { getFullName } from '../../utills/constants';
import { CancelButton } from '../../components/Button';
import { CenterHeader } from '../../components/Modal/ModalHeader';
import { GreenButton, Description } from '../../components/Modal/Elements';

import successImage from '../../assets/create account/successImage.png';
import correctCircle from '../../assets/create account/correctCircle.png';

const SuccessStep = ({ handleChange, parentState, handle }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    handleChange('step', 1);
    handle('openMakeOffer', false);
  };

  const { selectedTrip } = parentState;

  return (
    <>
      <CenterHeader heading="Offer Sent" handleClose={handleClose} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <ImageContainer>
          <img src={successImage} alt="successImage" />
          <span>
            <img src={correctCircle} alt="correctCircle" />
          </span>
        </ImageContainer>
        <Description sx={{ mt: 3, fontFamily: 'Poppins-Regular' }}>Offer sent to</Description>
        <Description sx={{ textAlign: 'center', my: 1 }}>
          {getFullName(selectedTrip?.hostId)}
        </Description>
      </Box>
      <Grid sx={{ width: '100%' }}>
        <GreenButton
          onClick={handleClose}
          type="submit"
          variant="contained"
          disableripple
          sx={{ width: '100%', mt: 1 }}
        >
          Done
        </GreenButton>
        <CancelButton
          onClick={() => navigate('/tradeOffer?offer=send')}
          type="submit"
          variant="contained"
          disableripple
          sx={{ width: '100%', mt: 2 }}
        >
          Go to Trade offers
        </CancelButton>
      </Grid>
    </>
  );
};
export default SuccessStep;
