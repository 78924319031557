import React from 'react';
import GreenLoader from '../../assets/greenLoader.gif';

const Loader = ({ styl, width }) => {
  return (
    <div
      style={
        styl
          ? styl
          : {
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              left: '48%',
              top: '50%',
            }
      }
    >
      <img src={GreenLoader} width={width || '80'} alt="GreenLoader" />
    </div>
  );
};
export default Loader;
