import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/Sidebar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link, useNavigate } from 'react-router-dom';

import Loader from '../../assets/loader1.gif';
import { MainBox, UserName } from '../Elements';
import { Divider, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getTheAddedCards } from '../../features/stripe/action';
import { Back, FormBox, FormBoxA } from './Elements';
import StripeCard from './StripeCard';
import api from '../../api';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import DeleteTheCard from '../../components/Modal/DeleteTheCard';

const CardManagment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches600 = useMediaQuery('(max-width:600px)');
  const matches400 = useMediaQuery('(max-width:400px)');
  const [primaryCard, setPrimaryCard] = useState('');
  const [updateTheCardID, setUpdateTheCardID] = useState({});
  const { profileInfo, userInfo, isAuth } = useSelector((state) => state.auth);
  const StripeData = useSelector((state) => state.stripe?.stripeAddedCard);
  const [loading, setLoading] = useState(false);
  console.log('Respponse of the primary key', primaryCard);
  const checkprimaryCard = async () => {
    setLoading(true);
    const resp = await api.get(`/stripe/subscriptions/${userInfo?.customerId}`);
    const result = resp?.data?.subscriptions?.data;
    // console.log('Respponse of the primary key', resp?.data?.subscriptions?.data);

    let data = null;
    if (result.length > 0) {
      const filterData = result.filter(
        (val) =>
          (val?.status === 'active' || val?.status === 'canceled') && val?.default_payment_method
      );

      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        data = element;
        if (filterData.length > 0) {
          if (element.status === 'active' && data?.default_payment_method) {
            break;
          }

          if (element.status === 'canceled' && data?.default_payment_method) {
            data = element;
            break;
          }
        } else {
          break;
        }
      }
    }

    // console.log('Tauseef Data', data);
    // // console.log('management Data', data);

    resp?.data?.subscriptions?.data && setPrimaryCard(data);
    setLoading(false);
  };

  useEffect(() => {
    checkprimaryCard();
  }, []);
  // console.log('THE PRIMARY CARD IS');
  useEffect(() => {
    if (isAuth) {
      dispatch(getTheAddedCards(userInfo?.customerId));
      // dispatch(getTheAddedCards('cus_OZX32K61HE82S4'));
    }
  }, []);

  console.log('primary Card Work', primaryCard);
  const updateThePrimaryCard = async (data) => {
    try {
      const body = {
        subscriptionId: primaryCard?.id, // subscribed key in the primaryCard
        newPaymentMethod: data?.id, //card Id
      };
      const resp = await api.post('/cardmangment/UpdateSubcribedCardInformation', body);
      toast.success(resp?.data?.message, { duration: 2000 });
      navigate('/settings');
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || error);
    }
  };

  const deleteTheCardFunction = async (data) => {
    try {
      const body = {
        paymentMethod: data?.id,
      };
      const resp = await api.post('/cardmangment/deleteCard', body);
      // console.log(resp);
      toast.success(resp?.data?.message, { duration: 2000 });
      navigate('/settings');
    } catch (error) {
      toast.error(error?.response?.data?.message, { duration: 4000 });
    }
  };
  const primaryCardInfo = StripeData?.filter(
    (el) => el?.id === primaryCard?.default_payment_method?.id
  );
  const nonPrimaryCardInfo = StripeData?.filter(
    (el) => el?.id !== primaryCard?.default_payment_method?.id
  );
  return (
    <MainBox sx={{ marginTop: matches600 ? 6 : 0 }} className="loadingclass">
      <Sidebar />
      <FormBox>
        <FormBoxA sx={{ height: 'max-content' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Back sx={{ ml: matches400 ? 0 : 1, mt: 1 }} onClick={() => navigate('/settings')}>
              <ChevronLeftIcon sx={{ fontSize: 'medium' }} />
              Back to Settings
            </Back>
            <Link
              to="/manage/addnewcard"
              style={{
                textDecoration: 'none',
              }}
            >
              <UserName
                sx={{
                  fontSize: '10px',
                  ml: matches400 ? 0 : 2,
                  mt: 1,
                  mb: 1.5,
                  p: 1,
                  border: '2px solid gray',
                  borderRadius: '7px',
                }}
              >
                Add New Card +
              </UserName>
            </Link>
          </div>
          <UserName sx={{ fontSize: '18px', ml: matches400 ? 0 : 2, mt: 1, mb: 1.5 }}>
            Manage Cards
          </UserName>
          {/* <UserName
            sx={{
              fontSize: '18px',
              ml: matches400 ? 0 : '',
              mt: 1,
              mb: 1.5,
              textAlign: 'center',
              display: 'block',
            }}
          >
            Primary Card
          </UserName> */}
          {/* <div>
            {StripeData?.filter((e) => e?.id === primaryCard?.default_payment_method?.id).map(
              (s) => (
                <StripeCard data={s} showDeletebtn={'false'} />
              )
            )}
          </div> */}

          {/* <UserName
            sx={{
              fontSize: '18px',
              ml: matches400 ? 0 : '',
              mt: 1,
              mb: 1.5,
              textAlign: 'center',
              display: 'block',
            }}
          >
            Other Cards
          </UserName> */}
          {loading ? (
            <img src={Loader} width={30} alt="loading..." style={{ marginRight: 6 }} />
          ) : (
            <>
              {primaryCardInfo?.map((s) => (
                <StripeCard
                  data={s}
                  setUpdateTheCardID={setUpdateTheCardID}
                  // updateThePrimaryCard={updateThePrimaryCard}
                  showDeletebtn={'true'}
                  deleteTheCardFunction={deleteTheCardFunction}
                  MakeItPrimaryCard={updateThePrimaryCard}
                  primaryCardId={primaryCard?.default_payment_method?.id}
                />
              ))}
              {nonPrimaryCardInfo?.map((s) => (
                <StripeCard
                  data={s}
                  setUpdateTheCardID={setUpdateTheCardID}
                  // updateThePrimaryCard={updateThePrimaryCard}
                  showDeletebtn={'true'}
                  deleteTheCardFunction={deleteTheCardFunction}
                  MakeItPrimaryCard={updateThePrimaryCard}
                  primaryCardId={primaryCard?.default_payment_method?.id}
                />
              ))}
            </>
          )}
          {/* {StripeData?.map((e) => (
            <StripeCard data={e} />
          ))} */}
        </FormBoxA>
        {/* )} */}
      </FormBox>
    </MainBox>
  );
};
export default CardManagment;
